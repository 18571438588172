import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, Image, OverlayTrigger,Tooltip,Card } from "react-bootstrap";
import { FlashMess, GET_API, POST_API,Loader } from "../Helpers/Helpers";

import badge from "./images/quality.6bf5c001.svg"
import tick from "./images/tick.f2adc176.svg"
import noimage from '../images/no-image.png';


export default class TopFiveCoaches extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
                        
 
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/jobseeker-personal-profile',
                        help:'',
                        hrcontacts:{},
                        searchInput: "",
                        filteredData: [],
                        coaches:[],
                        coachflag:1

                        
                     };
                     this.handlesubmit=this.handlesubmit.bind(this);
        

    }
    handlesubmit()
    {
        this.props.handleCoach(this.state.coachflag); 
        var formdata=new FormData();
        formdata.append("flag", this.state.coachflag);
        let request = {
            end_point : '/lifeguruCoachSelected',
            token: localStorage.getItem("gs_token"),
            formdata:formdata
            

        };
        this.setState({loader:'visible'})
            POST_API(request).then(resp=>{
                this.setState({loader:'hidden'})
                    // console.log("joblist",resp)
                    // this.setState({joblist:resp.jobs})
                    window.open("https://login.lifeguru.me/direct-login/"+localStorage.getItem("gs_auth_code"), "_blank")
                    }).catch((ee)=>{

                    });
    }
    componentDidMount()
    {
        fetch('https://lifegurudash.com/consumer/fetchTopcoaches',{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify({"email": this.state.email, "password": this.state.password})
        }).then(res => res.json())
        .then(resData => {
            // console.log("coach",resData);            
            this.setState({coaches:resData.value});
        });
    }
    render() {
        let sliced_data=this.state.coaches.slice(0,4)
        let no_image=noimage;
        // console.log("coaches",sliced_data)
        return (
            <div>

            <Container fluid style={{marginTop:"20px"}} >
            <Loader loadmsg="please wait" visibility={this.state.loader}/>
                <h3>Career and Live Coaching</h3>
                 <Row className="my-3">
                 {sliced_data.length>0 && (<>
                 {sliced_data.map((person, index) => (
                     <Col md={3} >
                                <div className="searchPeopleList" style={{maxHeight:"400px"}}>

                                <div className="sImgHolder">
                                    <img className="sUserImg" alt="LG-Img" src={person.avatar_path!=null?person.avatar_path:no_image}/>
                                    <img className="sbi" alt="LG-Img" src={badge} width="35px"/>
                                </div>
                                <h4>{person.name}</h4>
                                <h6>
                                    <span>
                                        {person.coaching_category!=null && person.coaching_category!='null'  &&(<>
                                            {JSON.parse(person.coaching_category).map(x=>x.value+',')}
                                        </>)}
                                    </span>
                                </h6>
                                <h5>{JSON.parse(person.address).location}</h5>
                                <div className="ratingView">
                                    <i className="fa fa-star rActive" aria-hidden="true"></i>
                                    <i className="fa fa-star rActive" aria-hidden="true"></i>
                                    <i className="fa fa-star" aria-hidden="true"></i>
                                </div>
                                <p></p>
                                {/* <a className="defaultBtn" target="_blank" href={"https://lifeguru.me/profile-coach-view/"+person.coach_uid}>
                                    <img alt="LG-Img" className="mr-1" src={tick} width="20px"/> View Details</a> */}
                                    {/* <a className="defaultBtn" target="_blank" href={"https://lifeguru.me/profile-coach-view/"+person.coach_uid}>
                                    <img alt="LG-Img" className="mr-1" src={tick} width="20px"/> View Details</a> */}
                                    <a className="defaultBtn" target="_blank" href={"https://lifeguru.me/coach-profile/"+person.name+"/"+person.coach_uid}>
                                    <img alt="LG-Img" className="mr-1" src={tick} width="20px"/> View Details</a>
                                    
                            </div>






                    </Col>
                ))}
                </>)}
                 </Row>
                 
                 {/* <Button style={{marginBottom:"15px"}} onClick={()=> window.open("https://login.lifeguru.me/direct-login/"+localStorage.getItem("gs_auth_code"), "_blank")} className="btn-1" >Access your premium account now</Button> */}
                 <Button style={{marginBottom:"15px"}} onClick={this.handlesubmit} className="btn-1" >Access your premium account now</Button>
            </Container>
            </div>
        )
    }
}
