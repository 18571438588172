import React, { Component } from "react";
import "./jobseeker.css";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Image,
  OverlayTrigger,
  Tooltip,
  Card,
} from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";

import badge from "./images/quality.6bf5c001.svg";
import tick from "./images/tick.f2adc176.svg";
import noimage from "../images/no-image.png";

export default class TopInstructors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Msg: "",
      MsgType: "",
      loader: "hidden",
      urlPath: "/jobseeker-personal-profile",
      help: "",
      hrcontacts: {},
      searchInput: "",
      filteredData: [],
      coaches: [],
      coachesTopCourse: [],
      coachflag: 1,
      auth: [],
      on: false,
      toggleArray: [],
    };
    this.handlesubmit = this.handlesubmit.bind(this);
  }

  handlesubmit() {
    this.props.handleCoach(this.state.coachflag);
    var formdata = new FormData();
    formdata.append("flag", this.state.coachflag);
    let request = {
      end_point: "/lifeguruCoachSelected",
      token: localStorage.getItem("gs_token"),
      formdata: formdata,
    };
    this.setState({ loader: "visible" });
    POST_API(request)
      .then((resp) => {
        this.setState({ loader: "hidden" });
        // console.log("joblist",resp)
        // this.setState({joblist:resp.jobs})
        window.open(
          "https://login.lifeguru.me/direct-login/" +
            localStorage.getItem("gs_auth_code"),
          "_blank"
        );
      })
      .catch((ee) => {});
  }

  componentDidMount() {
    fetch("https://lifegurudash.com/consumer/fetchTopcoaches", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({"email": this.state.email, "password": this.state.password})
    })
      .then((res) => res.json())
      .then((resData) => {
        // console.log("/////////////////////////////////////");
        // console.log("coach", resData);

        this.setState({ coaches: resData.value });
        // this.setState({ coachesTopCourse: resData.value });

        let auth1 = [];
        let toggleArray = [];
        resData.value.map((rows, index) => {
          // toggleArray[index] = false;

          if (rows.packages.length > 0) {
            auth1.push({
              coachName: rows.name,
              uid: rows.uid,
              packages: rows.packages[0],
            });
          }
          return this;
        });
        this.setState({ auth: auth1, toggleArray });
      });
  }

  toggle = (index) => {
    let { toggleArray } = this.state;
    toggleArray[index] = !toggleArray[index];
    this.setState({
      toggleArray,
    });
  };

  render() {
    let sliced_data = this.state.coaches.slice(0, 4);
    let no_image = noimage;
    let nestedData = this.state.auth.slice(0, 4);

    return (
      <div>
        <Container fluid style={{ marginTop: "20px" }}>
          <Loader loadmsg="please wait" visibility={this.state.loader} />
          <h3>Career and Live Coaching</h3>
          <Row className="my-3">
            {sliced_data.length > 0 && (
              <>
                {sliced_data.map((person, index) => (
                  <Col md={3}>
                    <div
                      className="searchPeopleList"
                      style={{ maxHeight: "400px" }}
                    >
                      <div className="sImgHolder">
                        <img
                          className="sUserImg"
                          alt="LG-Img"
                          src={
                            person.avatar_path != null
                              ? person.avatar_path
                              : no_image
                          }
                        />
                        <img
                          className="sbi"
                          alt="LG-Img"
                          src={badge}
                          width="35px"
                        />
                      </div>
                      <h4>{person.name}</h4>
                      <h6>
                        <span>
                          {person.coaching_category != null &&
                            person.coaching_category != "null" && (
                              <>
                                {JSON.parse(person.coaching_category).map(
                                  (x) => x.value + ","
                                )}
                              </>
                            )}
                        </span>
                      </h6>
                      <h5>{JSON.parse(person.address).location}</h5>
                      {/* <div className="ratingView">
                        <i className="fa fa-star rActive" aria-hidden="true"></i>
                        <i className="fa fa-star rActive" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                      </div> */}
                      <p></p>
                      {/* <a className="defaultBtn" target="_blank" href={"https://lifeguru.me/profile-coach-view/"+person.coach_uid}>
                                    <img alt="LG-Img" className="mr-1" src={tick} width="20px"/> View Details</a> */}
                      {/* <a className="defaultBtn" target="_blank" href={"https://lifeguru.me/profile-coach-view/"+person.coach_uid}>
                                    <img alt="LG-Img" className="mr-1" src={tick} width="20px"/> View Details</a> */}
                      <a
                        className="defaultBtn"
                        target="_blank"
                        href={
                          "https://lifeguru.me/coach-profile/" +
                          person.name +
                          "/" +
                          person.coach_uid
                        }
                      >
                        <img
                          alt="LG-Img"
                          className="mr-1"
                          src={tick}
                          width="20px"
                        />{" "}
                        View Details
                      </a>
                    </div>
                  </Col>
                ))}
              </>
            )}
          </Row>
{/* 
          <h3 style={{ marginTop: "2.5rem", marginBottom: "3rem" }}>
            Top Course of our coaches
          </h3>
          {nestedData.length > 0 && (
            <>
              {nestedData.map((rows, index) => {
                let session_details = JSON.parse(
                  rows.packages.session_details
                )[0];

                return (
                  <div className="packborder">
                    <div className="packageList">
                      <div className="packageInfo">
                        <h3>{rows.packages.title}</h3>
                        <br />
                        <p>Coach: {rows.coachName}</p>
                        <p>
                          <i
                            className="fa fa-certificate mr-2"
                            aria-hidden="true"
                          ></i>
                          {rows.packages.total_sessions} Session
                          <i className="fa-solid fa-clock ml-2 mr-2"></i>
                          {rows.packages.duration / 60} hour
                        </p>
                        <button
                          type="button"
                          aria-expanded="false"
                          className="btn btn-outline-info"
                          id="package_collapse"
                          onClick={() => {
                            this.toggle(index);
                          }}
                        >
                          Description
                          <i className="fa fa-angle-down ml-2"> </i>
                        </button>

                        {this.state.toggleArray[index] && (
                          <>
                            <div id="package_collapse">
                              <div className="short_desc_caption">
                                <p>{rows.packages.description}</p>
                                <div className="container_pk_item">
                                  <div>
                                    <div>
                                      <div class style={{ margin: "10px" }}>
                                        <div className="packageList">
                                          <div className="packageInfo">
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                              }}
                                            >
                                              <h6>
                                                <i className="fa-solid fa-clock"></i>
                                                &nbsp; {session_details.Length}{" "}
                                                minutes
                                              </h6>
                                            </div>
                                            <h5>
                                              {session_details.sessionName}
                                            </h5>
                                            <p>
                                              {
                                                session_details.sessionDescription
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="packageActon">
                        <h4>
                          {rows.packages.price.split("##") &&
                          rows.packages.price.split("##")[0] === "GBP" ? (
                            <i className="fa-solid fa-sterling-sign mr-1"></i>
                          ) : rows.packages.price.split("##")[0] === "USD" ? (
                            <i className="fa-solid fa-dollar-sign mr-1"></i>
                          ) : (
                            <i className="fa-solid fa-euro-sign mr-1"></i>
                          )}
                          {rows.packages.price.split("##")[1]}
                        </h4>
                        <div></div>
                        <div>
                          <div>
                            <div>
                              <a
                                className="defaultBtn"
                                target="_blank"
                                href={`https://lifeguru.me/coach-profile/${rows.coachName}/${rows.uid}`}
                              >
                                Book
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                );
              })}
            </>
          )} */}

          {/* <Button style={{marginBottom:"15px"}} onClick={()=> window.open("https://login.lifeguru.me/direct-login/"+localStorage.getItem("gs_auth_code"), "_blank")} className="btn-1" >Access your premium account now</Button> */}
          <Button
            style={{ marginBottom: "15px" }}
            onClick={this.handlesubmit}
            className="btn-1"
          >
            Access your premium account now
          </Button>
        </Container>
      </div>
    );
  }
}
