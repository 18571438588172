import React, { Component } from 'react';
import { Container, Navbar, Nav, ButtonGroup, Button } from 'react-bootstrap';
import { logout, roleName } from "../Helpers/Helpers";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import {GBTW_URL} from  "../Config";

import './css/header.css';

//import logo from '../images/logo.png';

import hlogo from '../images/header-logo.png';
import hlogo2 from '../images/GlobalWorkInitiative2.png';
import Moment from "moment";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth_token: null,
      userDetails: {},
      FromData: {

        profileimage: ""
      },
      FromDataError: {
        profileimage: "false",

      }
    };

    this.handaleChange = this.handaleChange.bind(this)
  }

  componentWillMount() {
    if (localStorage.getItem("gs_user") == null || localStorage.getItem("gs_user") == undefined || localStorage.getItem("gs_user") == '') {
      localStorage.clear();

    }
  }
  componentDidMount() {
    let roletype = "";

    if (localStorage.getItem("gs_user") != null) {
      //let FromData = JSON.parse(localStorage.getItem("gs_user"))
      let userDetails = JSON.parse(localStorage.getItem("gs_user"));

      this.setState({ userDetails }, () => {
        roletype = roleName(userDetails.roleID)
        // switch (userDetails.roleID) {
        //   case 2:
        //     roletype = "Sponsor (Corporate)";
        //     break;
        //   case 6:
        //     roletype = "Sponsor (NGO)";
        //     break;
        //   case 5:
        //     roletype = "Sponsor (Indivisual)";
        //     break;
        //   case 3:
        //     roletype = "Employee";
        //     break;
        //   case 7:
        //     roletype = "Manager";
        //     break;
        //   case 4:
        //     roletype = "Sponsored Job Seeker";
        //     break;
        //   case 8:
        //     roletype = "Affiliate (Individual)";
        //     break;
        //   case 9:
        //     roletype = "Affiliate (Corporate)";
        //     break;
        //   case 10:
        //     roletype = "Affiliate (Associate)";
        //     break;
        //   case 11:
        //     roletype = "Affiliate (Member)";
        //     break;
        //   case 12:
        //     roletype = "Affiliate (Manager)";
        //     break;
        // }
        this.setState({ roletype: roletype });
      });
    }
  }
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  handaleChange(e) {
    var element = e.target.id;

    document.getElementById("becomesponsor").classList.remove("active")
    document.getElementById("becomejobseeker").classList.remove("active")
    document.getElementById(element).classList.add("active");

  }
  // roleName (rolId)
  // {
  //   switch (rolId) {
  //     case "4": return "Sponsored Job Seeker"
  //               break;
  //     case "2": return "Corporate Sponsor"
  //               break;
  //     case "3": return "Employee"
  //               break;
  //     case "5": return "Individual Sponsor"
  //               break;
  //     case "6": return "NGO Sponsor"
  //               break;
  //     case "7": return "Manager"
  //               break;

  //     case "8": return "Affiliate"
  //               break;

  //     case "9": return "Corporate Affiliate"
  //               break;
  //     case "10": return "Associate Affiliate"
  //               break;
  //     case "11": return "Affiliate Employee"
  //               break;
  //     case "12": return "Affiliate Manager"
  //               break;
  //     default: return ""
  //               break;
  //   }
  // }

  render() {
    let userDetails = this.state.userDetails;
    return (
      <div className="header">
        <Container fluid >
          <Navbar expand="lg" className="align-items-center navbar-dark" >
            <Navbar.Brand href={GBTW_URL}><img src={hlogo2} className="img-fluid imgSize" alt="logo" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">

              {/* <Navbar.Nav ml="auto">
                <Nav.Link href="/">Login</Nav.Link> */}
              {/* <Nav.Link href="/company">Company</Nav.Link>
                  <Nav.Link href="/products">Products</Nav.Link> */}
              {/* </Navbar.Nav> */}


              <Nav className="ml-auto" as="ul">
                {!localStorage.getItem("gs_token" )  ? (<>
                  <Nav.Item as="li">
                    
                        </Nav.Item>

                </>) : (<>


                  <Nav.Item as="li">
                    <Nav.Link href={localStorage.getItem("dashboardLink")}>Dashboard</Nav.Link>
                  </Nav.Item>

                </>)}

                {localStorage.getItem('roleID') == 4 ? (<>
                  <Nav.Item as="li">
                    <Nav.Link href="#/jobseeker-personal-profile"> 

                    My Profile</Nav.Link>
                  </Nav.Item>
                </>) : (<></>)}

                {localStorage.getItem('roleID') == 4 ? (<>
                  <Nav.Item as="li">
                    <Nav.Link href="#/jobseeker-password-change"> 
                    

                    <svg 
xmlns="http://www.w3.org/2000/svg"  
width="14" 
height="14" 
viewBox="0 0 256 256">

<defs>
</defs>
<g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
	<path d="M 45 65.125 c -4.717 0 -8.554 -3.837 -8.554 -8.554 V 53.27 c 0 -4.717 3.837 -8.555 8.554 -8.555 s 8.555 3.838 8.555 8.555 v 3.302 C 53.555 61.288 49.717 65.125 45 65.125 z M 45 48.715 c -2.511 0 -4.554 2.043 -4.554 4.555 v 3.302 c 0 2.511 2.043 4.554 4.554 4.554 c 2.512 0 4.555 -2.043 4.555 -4.554 V 53.27 C 49.555 50.758 47.512 48.715 45 48.715 z" />
	<path d="M 55.606 79.055 H 34.394 c -1.104 0 -2 -0.896 -2 -2 v -5.527 c 0 -5.736 4.667 -10.402 10.402 -10.402 h 4.408 c 5.736 0 10.402 4.666 10.402 10.402 v 5.527 C 57.606 78.159 56.711 79.055 55.606 79.055 z M 36.394 75.055 h 17.213 v -3.527 c 0 -3.53 -2.872 -6.402 -6.402 -6.402 h -4.408 c -3.53 0 -6.402 2.872 -6.402 6.402 V 75.055 z" />
	<path d="M 69.629 90 H 20.371 c -4.524 0 -8.205 -3.681 -8.205 -8.205 V 41.899 c 0 -4.524 3.681 -8.205 8.205 -8.205 h 49.258 c 4.524 0 8.205 3.681 8.205 8.205 v 39.896 C 77.834 86.319 74.153 90 69.629 90 z M 20.371 37.694 c -2.319 0 -4.205 1.886 -4.205 4.205 v 39.896 c 0 2.318 1.886 4.205 4.205 4.205 h 49.258 c 2.318 0 4.205 -1.887 4.205 -4.205 V 41.899 c 0 -2.319 -1.887 -4.205 -4.205 -4.205 H 20.371 z" />
	<path d="M 65.985 37.694 c -1.104 0 -2 -0.896 -2 -2 V 21.259 C 63.985 11.743 56.242 4 46.726 4 h -3.451 c -9.517 0 -17.259 7.743 -17.259 17.259 v 14.435 c 0 1.104 -0.896 2 -2 2 s -2 -0.896 -2 -2 V 21.259 C 22.015 9.537 31.552 0 43.274 0 h 3.451 c 11.723 0 21.26 9.537 21.26 21.259 v 14.435 C 67.985 36.799 67.09 37.694 65.985 37.694 z" />
</g>
</svg> 
                    Change Password</Nav.Link>
                  </Nav.Item>
                </>) : (<></>)}


                


                {!localStorage.getItem("gs_token") ||
                  localStorage.getItem("gs_user") == null ||
                  localStorage.getItem("gs_user") == undefined ||
                  localStorage.getItem("gs_user") == ''
                  ? (<>
                  {/* Close temporary */}
                    {/* <Nav.Item as="li">
                      <Nav.Link href="#/login">Login</Nav.Link>
                    </Nav.Item> */}

                  </>) : (<>

                    <Nav.Item as="li">
                      {/* <Nav.Link href="javascript:" onClick={()=>logout(this.props.history)} >Logout</Nav.Link> */}
                      <Nav.Link href="#/logout" >Logout</Nav.Link>
                    </Nav.Item>
                  </>)}

              </Nav>
              {[2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15].includes(Number(localStorage.getItem('roleID'))) ? (<>
                <div className="user_info info_white d-flex align-items-center">
                  <a
                    className="profile_img mr-2"
                    href="#"
                    id="navbarDropdownMenuLink2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={localStorage.getItem('profile_image')} alt="" className="img-fluid" />
                    

                  </a>
                  <div>
                    <h5>
                      {JSON.parse(localStorage.getItem("gs_user")).firstname} {JSON.parse(localStorage.getItem("gs_user")).lastname}

                    </h5>
                    <span><i>{roleName(localStorage.getItem('roleID'))}</i></span>
                  </div>

                </div>
              </>) : (<></>)}

              {!localStorage.getItem("gs_token") && 1==0 ? (<>
                <ButtonGroup aria-label="Basic example">
                  <Button className="btn_header mr-1" onClick={this.handaleChange} id="becomesponsor" href="#/becomesponsor">Become Sponsor</Button>
                  <Button className="btn_header ml-1 " onClick={this.handaleChange} id="becomejobseeker" href="#/becomejobseeker">JobSeeker Signup</Button>
                </ButtonGroup>
              </>) : (<></>)}

            </Navbar.Collapse>


          </Navbar>
        </Container>
      </div>
    );
  }
}

export default Header;