import React, { Component } from 'react';
import { Container, Row, Col,
  //  Tab, Nav,
    Form, Button, Card } from "react-bootstrap";
import { FlashMess, GET_API, POST_API,Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';
// import Leftpanel from './Section/LeftPanel';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


class NgoProfile extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
                       
                        FromData: {
                                        company_logo : '',
                                        company_banner : '',
                                        company_name : '',
                                        company_email : '',
                                        company_tagline : '',
                                        company_employee_no : '',
                                        company_address : '',
                                        company_weburl : '',
                                        company_description : '',
                                    },
                        FromDataError :
                                        {
                                          company_logo : '',
                                          company_banner : '',
                                          company_name : 'false',
                                          company_email : 'false',
                                          company_tagline : '',
                                          company_employee_no : 'false',
                                          company_address : '',
                                          company_weburl : '',
                                          company_description : '',
                                        },
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/sponser-company-profile',

                        
                     };

        //Binding

        this.handaleChange = this.handaleChange.bind(this)
        this.handaleSubmit = this.handaleSubmit.bind(this)
        

    }

    validateForm  (errors) {
        let valid = true;
        let sta = this;
        let FromDataError = this.state.FromDataError;

        for (const [key, value] of Object.entries(errors)) {
          if(value.length > 0 )
          {

            if(value=='false')
            {
              FromDataError[key] = 'This field is required';
              sta.setState({FromDataError})
            }
            
            valid = false;
          }
        }
      
        return valid;
};

    

    handaleChange(data)
    {
      //  let sta = this;
        let FromDataError = this.state.FromDataError;
        

        let name = data.target.name;
        let value = '';
        let FromData = this.state.FromData;
        

        if(name=='company_logo' || name=='company_banner')
        {
          value = data.target.files[0];
        }
        else{
           value = data.target.value;
        }

        FromData[name] = value;

        this.setState({FromData},()=>{
          
        }) 

         //validate from
         var valid_obj = {
          value:value,
          rules:data.target.getAttribute("validaterule"),
          message:data.target.getAttribute("validatemsg")
        }

              validation(valid_obj).then((error)=>{
                  FromDataError[name] = error
                  this.setState({FromDataError}) //from error state
              })

       
    }

    handaleSubmit(e)
    {   
        
        e.preventDefault();

        let sta = this;
        let FromDataError = this.state.FromDataError;
        let FromData = this.state.FromData;

        // console.log(this.state)
        // return 0;


        

        if(this.validateForm(FromDataError))
        {

          sta.setState({loader:'visible'})///loader on

     


          var formdata = new FormData();
             
                if(FromData.company_logo!=undefined && typeof FromData.company_logo == 'object')
                {
                formdata.append("company_logo", FromData.company_logo,FromData.company_logo.name);

                }

                if(FromData.company_banner!=undefined && typeof FromData.company_banner == 'object')
                {
                formdata.append("company_banner", FromData.company_banner,FromData.company_banner.name);

                }
                formdata.append("company_name", FromData.company_name  || '');
                formdata.append("company_email", FromData.company_email  || '');
                formdata.append("company_employee_no", FromData.company_employee_no  || '');
                formdata.append("company_description", FromData.company_description  || '');
                
                formdata.append("company_weburl", FromData.company_weburl || '');
                
                formdata.append("company_address", FromData.company_address  || '');
                formdata.append("company_tagline", FromData.company_tagline  || '');

                let request = {
                  end_point : '/edit-company-profile-details',
                  formdata:formdata,
                  token: localStorage.getItem("gs_token")

                };

                console.log('......request',formdata)

                POST_API(request).then(resp=>{

                  sta.setState({loader:'hidden'})///loader off


                        if(resp.status == 'success')
                        {
                          sta.setState({Msg:'NGO Profile Update Successfully',MsgType:'success'})
                          
                          
                            
                           
                          setTimeout(() => {
                            window.location.reload()
                            
                          }, 3000);

                          

                        }
                        else{
                            
                            
                                sta.setState({Msg:' NGO Profile Update Failed',MsgType:'danger'})
                        }
                        }).catch((ee)=>{
                         sta.setState({loader:'hidden'})///loader on

                            console.log('/CompanyEditERR',ee)
                        });
                      


        }else
        {
            sta.setState({Msg:'Fill the form correctly!'})
            sta.setState({MsgType:'danger'})
        }

       
    }


    componentDidMount()
    {

        let sta = this;
        let FromData =this.state.FromData
        let FromDataError =this.state.FromDataError

        

        //for redirectin message show
        if(sta.props.location.state!=undefined)
        {
            sta.setState({Msg:sta.props.location.state.Msg})
            sta.setState({MsgType:sta.props.location.state.MsgType})
        }

        try {
            if(localStorage.getItem("gs_user") != null)
            {
                //let FromData = JSON.parse(localStorage.getItem("gs_user"))
                sta.setState({loader:'visible'})///loader on
                
                GET_API({token:localStorage.getItem("gs_token"),end_point:'/get-profile-details'}).then(resp=>{


                    if(resp.status == 'success')
                    {

                    
                        
                     
                      FromData['company_logo'] = resp.data.company_profile.company_logo || '';
                      // FromDataError['company_logo'] = resp.data.company_profile.company_logo!=null? '' : 'false';
                     

                      FromData['company_banner'] = resp.data.company_profile.company_banner || '';
                      // FromDataError['company_banner'] = resp.data.company_profile.company_banner!=null? '' : 'false';
                     


                      FromData['company_name'] = resp.data.company_profile.company_name || '';
                      FromDataError['company_name'] = resp.data.company_profile.company_name!=null? '' : 'false';
                     


                      FromData['company_email'] = resp.data.company_profile.company_email;
                      FromDataError['company_email'] = resp.data.company_profile.company_email!=null? '' : 'false';
                   
                      FromData['company_tagline'] = resp.data.company_profile.company_tagline;
                      //FromDataError['company_tagline'] = resp.data.company_profile.company_tagline!=null? '' : '';
                      

                      FromData['company_address'] = resp.data.company_profile.company_address;
                      FromDataError['company_address'] = resp.data.company_profile!=null? '' : 'false';
                    
                     
                      FromData['company_weburl'] = resp.data.company_profile.company_weburl;
                      FromDataError['company_weburl'] = resp.data.company_profile.company_weburl!=null? '' : 'false';
                    


                      FromData['company_description'] = resp.data.company_profile.company_description || '';
                      FromDataError['company_description'] = resp.data.company_profile.company_description !=null? '' : '';
                     


                      FromData['company_employee_no'] = resp.data.company_profile.company_employee_no || '';
                      FromDataError['company_employee_no'] = resp.data.company_profile.company_employee_no !=null? '' : 'false';
                      

                      

                      this.setState({FromData})
                    }
          sta.setState({loader:'hidden'})///loader off

                   
                  }).catch((ee)=>{
          sta.setState({loader:'hidden'})///loader off

                        console.log('/userdetailsEditERR',ee)
                    });
            }
            
           
          } 
          catch (error) {
            
          }
     

      
    }
    render() {
        let  FromData = this.state.FromData
        
        return (
            <div>
                 {FromData.hasOwnProperty("company_name")? (<>
            

            <Container fluid className="dash_container">
          <Row>
            {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
            <Col md={12}>
            <Loader loadmsg="please wait" visibility={this.state.loader}/>
              
            <div className="tabpanelOneForm pt-3">
                <Card className="p-5 body_card">
                      <h3>Organization Profile</h3>
                      <Form
                        className="tabform"
                        onSubmit={this.handaleSubmit}
                      >
                        <Row className="m-0 mt-4 mb-4">
                          <Col md={12} className="pl-0 ">
                                              {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
              {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
            
          
                            
                            <Form.Row>
                            <Form.Group controlId="formBasicEmail" as={Col}>
                            <Col md={12}>
                                {typeof FromData.company_logo == 'string' ? <img src={FromData.company_logo  } className="img-fluide pimage_logo" alt=""/> : '' }
                            </Col>
                                  <Form.Label>Organization Logo</Form.Label>
                                    <Form.File id="custom-file-translate-scss" 
                                              name='company_logo'  
                                              onChange={this.handaleChange}
                                              label={FromData.company_logo.name || "Choose file"} 
                                              validaterule={['filesize|250000','mime|jpeg/png']}
                                              validatemsg={[]}
                                              lang="en"  custom />
                                      <span>Size must be less than 2 MB</span>         
                                    <p style={{ color: 'red' }}>{this.state.FromDataError.company_logo=='false'?'': this.state.FromDataError.company_logo}</p>

                              </Form.Group>
                              

                              <Form.Group controlId="formBasicEmail" as={Col}>
                              <Col md={12}>
                              {typeof FromData.company_banner == 'string' ? <img src={FromData.company_banner  } className="img-fluide pimage_banaer" alt=""/> : '' }
                              </Col>
                                  <Form.Label>Organization Banner</Form.Label>
                                    <Form.File id="custom-file-translate-scss" 
                                              name='company_banner'  
                                              onChange={this.handaleChange}
                                              label={FromData.company_banner.name || "Choose file"} 
                                              validaterule={['filesize|250000','mime|jpeg/png']}
                                              validatemsg={[]}
                                              lang="en"  custom />
                                              <span>Size must be less than 2 MB</span> 
                                    <p style={{ color: 'red' }}>{this.state.FromDataError.company_banner=='false'?'': this.state.FromDataError.company_banner}</p>

                              </Form.Group>
                              
                            </Form.Row>

                            <Form.Row>
                            <Form.Group controlId="formBasicEmail" as={Col}>

                                <Form.Label>Organization Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="company_name"
                                  onChange={this.handaleChange}
                                  placeholder="Enter Organization name"
                                  value={FromData.company_name || ''}
                                  readOnly
                                
                                />
                                    <p style={{ color: 'red' }}>{this.state.FromDataError.company_name=='false'?'': this.state.FromDataError.company_name}</p>
                              </Form.Group>

                              <Form.Group controlId="formBasicEmail" as={Col}>
                                <Form.Label>Organization Email</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="company_email"
                                  onChange={this.handaleChange}
                                  placeholder="Enter Organization email"
                                  value={FromData.company_email || ''}
                                  validaterule={['required','isEmail']}
                                  validatemsg={['This field is required']}
                                />
                                    <p style={{ color: 'red' }}>{this.state.FromDataError.company_email=='false'?'': this.state.FromDataError.company_email}</p>
                              </Form.Group>                       
                            </Form.Row>
                            <Form.Row>
                            <Form.Group controlId="formBasicEmail" as={Col}>
                                <Form.Label>Organization TagLine</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="company_tagline"
                                  onChange={this.handaleChange}
                                  placeholder="Enter Organization Tag Line"
                                  value={FromData.company_tagline || ''}
                                  
                                />
                                    <p style={{ color: 'red' }}>{this.state.FromDataError.company_tagline=='false'?'': this.state.FromDataError.company_tagline}</p>
                              </Form.Group>    
                            </Form.Row>
                            <Form.Row>
                            <Form.Group controlId="formGridAddress1"  as={Col}>
                                <Form.Label>Organization Web Url</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="company_weburl"
                                  value={FromData.company_weburl || ''}
                                  onChange={this.handaleChange}
                                  placeholder="Enter Organization web Url"

                                />
                                    <p style={{ color: 'red' }}>{this.state.FromDataError.company_weburl=='false'?'': this.state.FromDataError.company_weburl}</p>
                              </Form.Group>

                              <Form.Group controlId="formBasicEmail" as={Col}>
                                <Form.Label>Organization Employe Total</Form.Label>
                                <Form.Control
                                  type="number"
                                  name="company_employee_no"
                                  onChange={this.handaleChange}
                                  placeholder="Enter your Organization employe total"
                                  value={FromData.company_employee_no || ''}
                                  validaterule={['required']}
                                  validatemsg={['This field is required']}
                                />
                                    <p style={{ color: 'red' }}>{this.state.FromDataError.company_employee_no=='false'?'': this.state.FromDataError.company_employee_no}</p>
                              </Form.Group>
                            </Form.Row>
                            <Form.Group controlId="formGridAddress1">
                                <Form.Label>Organization Address</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={2}
                                  name='company_address'
                                  placeholder="Example : 241 Turnpike Rd, Montague, MA, 01351, USA"
                                  onChange={this.handaleChange}
                                  value={FromData.company_address || ''}
                                  validaterule={['required','min|10']}
                                  validatemsg={['This field is required','Enter Full Address']}
                                />
                                    <p style={{ color: 'red' }}>{this.state.FromDataError.company_address=='false'?'': this.state.FromDataError.company_address}</p>

                              </Form.Group>
                      

                              
                              <Form.Group controlId="formGridAddress1" className="ck-editor__editable_inline">
                                <Form.Label>Organization Description</Form.Label>
                                      <CKEditor 
                                          editor={ ClassicEditor }
                                          data={FromData.company_description || ''}
                                          onInit={ editor => {
                                              // You can store the "editor" and use when it is needed.
                                              // console.log( 'Editor is ready to use!', editor );
                                          } }
                                          onChange={ ( event, editor ) => {
                                              const data = editor.getData();
                                              FromData.company_description = data;
                                              this.setState({FromData})
                                          } }
                                          onBlur={ ( event, editor ) => {
                                              //console.log( 'Blur.', editor );
                                          } }
                                          onFocus={ ( event, editor ) => {
                                              //console.log( 'Focus.', editor );
                                          } }
                                      />
                              </Form.Group>

                              {/* company_description */}

                          </Col>
                          <Col md={8} className="pl-5 tabformRight">
                            <Button
                              variant="primary"
                              type="submit"
                              className="btn btn-primary btn-md tabformbutton"
                            >
                              Submit
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                </Card>
            </div>
            </Col>     
          </Row>
        </Container>

        </>):(<p>....Loading</p>) }


        </div>
          
                
                
           
        );
    }
}

export default NgoProfile;