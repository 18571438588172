import React from "react";
import Cards from "react-credit-cards";
import { Container, Row, Col, Tab, Nav, Form, Button, Modal } from "react-bootstrap";
import "./css/becomesponsor.css";

import "react-credit-cards/es/styles-compiled.css";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';

import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";


export default class ConfirmDetails extends React.Component {


  constructor(props) {
    super(props)
    this.state = {
      FromData: {
        firstname: '',
        lastname: '',
        email: '',
        contact_no: '',
        password: '',
        id: '',
        referral_code: '',
        category: '',
        affiliate_code: '',
        score: '',
        job_title: '',


      },

      FromDataError:
      {
        firstname: 'false',
        lastname: 'false',
        email: 'false',
        contact_no: 'false',
        id: '',
        referral_code: '',
        category: '',
        affiliate_code: ''
      },
      Msg: "",
      MsgType: "",
      loader: 'hidden',
      disableButton: false,
      msgAffilicate: "",
      MsgAffiliateType: "",
      mailmsg: "",
      MailMsgType: "",
      shortName: ''


    };

    this.handaleChange = this.handaleChange.bind(this)
    this.handaleSubmit = this.handaleSubmit.bind(this)
    this.stateCreate = this.stateCreate.bind(this)
  }
  handalePhoneNoChange(data) {
    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError;
    let value = '';
    FromData['contact_no'] = data;
    this.setState({ FromData }, () => {

    })

    if (FromData.contact_no != '') {
      var valid_obj = {
        value: value,
        rules: 'required',
        message: 'This field is required'
      }
      validation(valid_obj).then((error) => {
        FromDataError['contact_no'] = true
        this.setState({ FromDataError }) //from error state
      })
    }

  }


  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
      }
    }

    return valid;
  };


  handaleChange(data) {
    let sta = this;
    let FromDataError = this.state.FromDataError;


    let name = data.target.name;
    let value = data.target.value;
    let FromData = this.state.FromData;

    FromData[name] = value;


    this.setState({ FromData }, () => {

      // console.log(FromData)

    })


    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg")
    }



    validation(valid_obj).then((error) => {



      FromDataError[name] = error
      this.setState({ FromDataError })



    })





  }




  handaleSubmit = (e) => {
    e.preventDefault();
    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;
    let data = this.state.FromData.job_title;
    // let splitdata=data.split(',');
    let mapdata = data.map(x => ({ label: x, value: x }));
    let job_title = JSON.stringify(mapdata);
    // console.log("string",stringify);





    if (this.validateForm(FromDataError)) {
      sta.setState({ loader: 'visible' })///loader on

      var formdata = new FormData();
      formdata.append("firstname", FromData.firstname || '');
      formdata.append("lastname", FromData.lastname || '');
      formdata.append("phone", FromData.contact_no || '');
      formdata.append("email", FromData.email || '');
      formdata.append("id", FromData.id || '');
      formdata.append("category", FromData.category || '');
      formdata.append("password", FromData.password || '');
      formdata.append("referral_code", FromData.referral_code || '');
      formdata.append("affiliate_code", FromData.affiliate_code || '');
      formdata.append("role_id", 4);
      formdata.append("score", FromData.score || '');
      formdata.append("job_title", job_title || '');
      formdata.append("servicetype", this.state.shortName || '');






      let request = {
        end_point: '/registration',
        formdata: formdata,


      };

      console.log('......request', formdata)




      POST_API(request).then(resp => {

        sta.setState({ loader: 'hidden' })///loader off


        if (resp.status == 'success') {
          localStorage.removeItem("affiliate_code")
          localStorage.removeItem('referal_link_code')
          sta.props.history.push({
            pathname: '/login',
            state: { Msg: 'Registration Successfully Done, Check your mail to login.', MsgType: 'success' }

          })





        }
        else {


          sta.setState({ mailmsg: resp.message.email.join(), MailMsgType: 'danger' })





        }


      }).catch((ee) => {
        sta.setState({ loader: 'hidden' })///loader off

        sta.setState({ Msg: 'Something Went Wrong,Please try again.', MsgType: 'danger' })


        console.log('/paymentERR', ee)


      });
    }
    else {
      sta.setState({ Msg: 'Fill the form correctly!' })
      sta.setState({ MsgType: 'danger' })
    }

  }

  stateCreate() {

    console.log("props", this.props.servicetype)

    // if(!this.state.FromData.email)
    // {
    // console.log('valid status',this.props.userDetails.is_valid)
    let FromData = this.props.userDetails
    let FromDataError = this.state.FromDataError;




    this.setState({ FromData }, () => {

      FromDataError['firstname'] = FromData.firstname != null ? '' : 'false';
      FromDataError['lastname'] = FromData.lastname != null ? '' : 'false';
      FromDataError['email'] = FromData.email != null ? '' : 'false';
      FromDataError['contact_no'] = FromData.contact_no != null ? '' : 'false';
      this.setState({ FromDataError })
      // console.log('valid status in function',this.props.userDetails.is_valid)
      // if(this.props.userDetails.referral_code != undefined )
      // {     

      if (this.props.userDetails.is_valid == 1 && FromData.referral_code != undefined) {

        this.setState({ Msg: 'Promo "' + FromData.referral_code + '" Applied!', MsgType: 'success' })
        this.setState({ disableButton: false })
      }
      else {
        if (FromData.referral_code != undefined) {
          // console.log('is vaild if',this.props.userDetails.is_valid)
          this.setState({ Msg: 'Promo "' + FromData.referral_code + '" Invalid', MsgType: 'danger' })
          this.setState({ disableButton: true })
        }
        else {
          this.setState({ Msg: '', MsgType: '' })
          this.setState({ disableButton: false })
        }
      }

      // }

      if (FromData.affiliate_code) {
        if (FromData.affiliateisValid) {
          this.setState({ msgAffilicate: 'Affiliate "' + FromData.affiliate_code + '" Applied!', MsgAffiliateType: 'success' })
        }
        else {
          this.setState({ msgAffilicate: 'Affiliate "' + FromData.affiliate_code + '" Invalid', MsgAffiliateType: 'danger' })
          this.setState({ disableButton: true })
        }

      }



    })

    // }
    this.setState({ shortName: this.props.servicetype })


  }



  render() {

    let { FromData } = this.state;
    // console.log('valid status in render',this.props.userDetails.is_valid)

    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
          backdrop="static"
          keyboard={false}
          onHide={this.props.close}
          onShow={this.stateCreate}
          id="modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Loader loadmsg="please wait" visibility={this.state.loader} />

            {this.state.msgAffilicate !== '' ? (<><FlashMess m={this.state.msgAffilicate} mtype={this.state.MsgAffiliateType ? this.state.MsgAffiliateType : 'success'} /></>) : (<></>)}
            {(this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>))}
            {this.state.mailmsg !== '' ? (<><FlashMess m={this.state.mailmsg} mtype={this.state.MailMsgType ? this.state.MailMsgType : 'danger'} /></>) : (<></>)}
            {(FromData.email != undefined) ? (<>
              <div className="tabpanelOneForm">
                <Form className="tabform" onSubmit={this.handaleSubmit}>
                  <input type="hidden" value={FromData.id} name='id' onChange={this.handaleChange} />
                  {/* <input type="hidden" value={FromData.referral_code} name='referral_code' onChange={this.handaleChange}/> */}
                  <input type="hidden" value={this.props.userDetails.password} name='password' onChange={this.handaleChange} />
                  <input type="hidden" value={this.props.userDetails.referral_code} name='referral_code' onChange={this.handaleChange} />
                  <input type="hidden" value={FromData.category} name='category' onChange={this.handaleChange} />
                  <input type="hidden" value={FromData.affiliate_code} name='affiliate_code' onChange={this.handaleChange} />
                  <Row className="m-0 mt-4 mb-4">
                    <Col md={12} className="pl-0 pr-5 ">

                      <Form.Row>
                        <Form.Group controlId="formBasicEmail" as={Col}>
                          <Form.Label>First Name <span className="text-danger"> *</span> </Form.Label>
                          <Form.Control
                            type="text"
                            name="firstname"
                            value={FromData.firstname}
                            onChange={this.handaleChange}
                            placeholder="Enter your first name"
                            validaterule={['required', 'onlySpaceValueCheck']}
                            validatemsg={['This field is required', 'White or empty space is not allowed']}
                          />
                          <p style={{ color: 'red' }}>{this.state.FromDataError.firstname == 'false' ? '' : this.state.FromDataError.firstname}</p>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail" as={Col}>
                          <Form.Label>Last Name <span className="text-danger"> *</span> </Form.Label>
                          <Form.Control
                            type="text"
                            name="lastname"
                            value={FromData.lastname}
                            onChange={this.handaleChange}
                            placeholder="Enter your last name"
                            validaterule={['required', 'onlySpaceValueCheck']}
                            validatemsg={['This field is required', 'White or empty space is not allowed']}
                          />
                          <p style={{ color: 'red' }}>{this.state.FromDataError.lastname == 'false' ? '' : this.state.FromDataError.lastname}</p>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group controlId="formBasicEmail" as={Col}>
                          <Form.Label>Email <span className="text-danger"> *</span> </Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={FromData.email}
                            onChange={this.handaleChange}
                            placeholder="Enter your email"
                            validaterule={['required', 'isEmail']}
                            validatemsg={['This field is required', 'Invalid Email']}
                          />
                          <p style={{ color: 'red' }}>{this.state.FromDataError.email == 'false' ? '' : this.state.FromDataError.email}</p>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail" as={Col}>
                          <Form.Label>Phone <span className="text-danger"> *</span> </Form.Label>
                          <PhoneInput
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="US"
                              value={FromData.contact_no}
                              name="contact_no"
                              onChange={this.handalePhoneNoChange.bind(this)}
                              validaterule={['required', 'phone']}
                              validatemsg={['This field is required', 'Invalid Phone number']}
                            />
                          <p style={{ color: 'red' }}>{this.state.FromDataError.contact_no == 'false' ? '' : this.state.FromDataError.contact_no}</p>
                        </Form.Group>
                      </Form.Row>
                    </Col>
                    {/* {console.log('button status',this.state.disableButton)} */}
                    <Col md={12} className="pl-5 ">
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn btn-primary btn-md tabformbutton"
                        disabled={this.state.loader != 'hidden' ? true : false || this.state.disableButton}
                      >
                        Submit
                      </Button>
                    </Col>


                  </Row>
                </Form>

              </div>
            </>) : (<></>)}
          </Modal.Body>

        </Modal>
      </div>
    );
  }
}
