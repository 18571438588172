import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension"; // used for debugging purpose
import thunk from "redux-thunk"; // To make the backend API call's with redux
import rootReducers from "./Reducers";

const store = createStore(
  rootReducers,
  composeWithDevTools(applyMiddleware(thunk)),
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  
  // You need to create a composeEnhancer which will take in your Redux Dev Tools extension as shown
  // Uncaught Error: It looks like you are passing several store enhancers to createStore(). This is not supported. Instead, compose them together to a single function.
);

// const store = createStore(changeState)
export default store;
