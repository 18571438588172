// import './css/dashboard.css';
import Leftpanel from './Section/LeftPanel';
import React, { Component } from 'react'
import { Container, Row, Col, Tab, Nav, Form, Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import profileimage from "../images/dashboard_profile_image.jpg";
import DataTable from "react-data-table-component";
import AllContacts from './AllHrContact';
import MyHrContacts from './MyHrContacts';
import MyHrContactsGroup from './MyHrContactsGroup';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import AutocompleteSearch from "../AutocompleteSearch";


export default class HrContacts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Msg: '',
            MsgType: '',
            urlPath: '/jobseeker-personal-profile',
            help: '',
            hrcontacts: {},
            searchInput: "",
            filteredData: [],
            industryData: [],
            searchIndustry: [],
            searchAddressType: "",
            searchAddress: "",
            loading: false,
            loader: 'hidden',
            contact_count: 0,
            import_div: false,
            city_state_country: [],
            clickable: true,
            searchable_data: [],
            myContactsSearchable_data: [],
            loading_searchable: false,
            resetHr: false,
            resetMyHR: false,
            resetAutoComplete: false,
            status_type: "",
            searchData: {
                searchIndustry: [],
                searchAddressType: "",
                searchAddress: "",
                status_type: ""

            },
        };

    }

    componentDidMount() {

        let request_api = {
            end_point: '/get-industry',
            token: localStorage.getItem("gs_token")

        };

        // this.setState({loader:'visible'})///loader on


        GET_API(request_api).then(resp => {


            this.setState({ industryData: resp.contacts_industry })
            // this.setState({loader:'hidden'})

        }).catch((ee) => {

            // this.setState({loader:'hidden'})
        });
        this.checkHasContacts();
    }




    // componentDidMount() {

    //     let request = {
    //         end_point: '/get-hrContacts',
    //         token: localStorage.getItem("gs_token")

    //     };

    //     // this.setState({loader:'visible'})///loader on


    //     GET_API(request).then(resp => {




    //         this.setState({ hrcontacts: resp.contacts })
    //         // this.setState({loader:'hidden'})

    //     }).catch((ee) => {

    //         // this.setState({loader:'hidden'})
    //     });





    // }
    handleChange = (event) => {
        this.setState({ searchInput: event.target.value })
    };

    industryChange = (event) => {
        //console.log(event);

        // for (let index = 0; index < event.length; index++) {
        //     dataT = [event[index].value];
        // }
        //console.log(dataT);
        this.setState({ searchIndustry: event })
    };

    addressTypeChnage = (event) => {
        this.setState({ resetAutoComplete: false })
        this.setState({ searchAddressType: event.target.value })
        if (event.target.value != '') {
            var formdata = new FormData();
            formdata.append("type", event.target.value);
            let request_api_type = {
                end_point: '/get-type-wise-contacts',
                formdata: formdata,
                token: localStorage.getItem("gs_token")

            };
            // this.setState({loader:'visible'})///loader on
            POST_API(request_api_type).then(resp => {
                // this.setState({loader:'hidden'})
                this.setState({ city_state_country: resp.contacts_address })
                this.setState({ loader: 'hidden' })
            }).catch((ee) => {

                // this.setState({loader:'hidden'})
            });
        }
    };

    addressChnage = (event) => {
        this.setState({ searchAddress: event.target.value })
    };

    checkHasContacts = () => {
        //this.setState({ loader: 'visible' })///loader on
        let request_api_has = {
            end_point: '/has-contact',
            token: localStorage.getItem("gs_token")

        };
        // this.setState({loader:'visible'})///loader on
        GET_API(request_api_has).then(resp => {
            this.setState({ contact_count: resp.contacts_count })
            // this.setState({loader:'hidden'})
            //this.setState({ loader: 'hidden' })
        }).catch((ee) => {

            // this.setState({loader:'hidden'})
        });
    };

    hrContactsClick = () => {
        this.setState({ clickable: true })
    }

    myContactsClick = () => {
        this.setState({ clickable: false })
    }

    myHrContactSearch = () => {
        this.setState({ loading_searchable: false })
        this.setState({ loader: 'visible' })///loader on
        console.log('searchIndustry', JSON.stringify(this.state.searchIndustry));
        console.log('searchAddressType', this.state.searchAddressType);
        console.log('searchAddress', this.state.searchAddress);
        var formdata = new FormData();
        formdata.append("searchIndustry", JSON.stringify(this.state.searchIndustry));
        formdata.append("searchAddressType", this.state.searchAddressType);
        formdata.append("searchAddress", this.state.searchAddress);

        let request_post = {
            end_point: '/submit-my-hr-contact-search',
            formdata: formdata,
            token: localStorage.getItem("gs_token")

        };


        POST_API(request_post).then(resp => {
            this.setState({ loader: 'hidden' })
            if (resp.status == 'success') {

                // this.setState({ Msg: resp.message, MsgType: 'success' })
                //window.location.reload();
                this.setState({ myContactsSearchable_data: resp.contacts })
                console.log(this.state.myContactsSearchable_data);
                this.setState({ loading_searchable: true })
            } else {
                // this.setState({ Msg: resp.message, MsgType: 'danger' })
            }
            // this.checkHasContacts();
            // this.setState({ searchIndustry: [] })
            // this.setState({ searchAddressType: "" })
            // this.setState({ searchAddress: "" })

        }).catch((ee) => {

            console.log('error', ee)
        });
    }

    industryAddressSearch = () => {
        let searchData = this.state.searchData;

        //this.setState({ loader: 'visible' })///loader on
        console.log('searchIndustry', JSON.stringify(this.state.searchIndustry));
        console.log('searchAddressType', this.state.searchAddressType);
        console.log('searchAddress', this.state.searchAddress);
        var formdata = new FormData();
        formdata.append("searchIndustry", JSON.stringify(this.state.searchIndustry));
        formdata.append("searchAddressType", this.state.searchAddressType);
        formdata.append("searchAddress", this.state.searchAddress);
        formdata.append("status_type", this.state.status_type);

        searchData['searchIndustry'] = this.state.searchIndustry;
        searchData['searchAddressType'] = this.state.searchAddressType;
        searchData['searchAddress'] = this.state.searchAddress;
        searchData['status_type'] = this.state.status_type;

        this.setState({ searchData });
        console.log(this.state.searchData);
        this.setState({ loading: true })

        setTimeout(
            () => this.setState({ loading: false }),
            2000
        );
        // let request_post = {
        //     end_point: '/import-hr-search-contact',
        //     formdata: formdata,
        //     token: localStorage.getItem("gs_token")

        // };


        // POST_API(request_post).then(resp => {
        //     this.setState({ loader: 'hidden' })
        //     if (resp.status == 'success') {

        //         // this.setState({ Msg: resp.message, MsgType: 'success' })
        //         //window.location.reload();
        //         this.setState({ searchable_data: resp.contact_list })
        //         console.log(this.state.searchable_data);
        //         this.setState({ loading: true })
        //     } else {
        //         // this.setState({ Msg: resp.message, MsgType: 'danger' })
        //     }
        //     // this.checkHasContacts();
        //     // this.setState({ searchIndustry: [] })
        //     // this.setState({ searchAddressType: "" })
        //     // this.setState({ searchAddress: "" })

        // }).catch((ee) => {

        //     console.log('error', ee)
        // });
    }

    globalSearch = () => {
        console.log("searchInput")
        let { searchInput, hrcontacts } = this.state;
        let filteredData = hrcontacts.filter((value) => {
            if (value.Company_Name != null && value.Email != null && value.Phone != null && value.Country != null && value.Full_Name != null && value.City != null && value.State != null) {
                return (
                    value.Company_Name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.Email.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.Phone.includes(searchInput) ||
                    value.Country.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.Full_Name.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.City.toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.State.toLowerCase().includes(searchInput.toLowerCase())
                );
            }
        });
        this.setState({ filteredData });
    };

    getAddress = (value) => {
        if (value !== undefined) {
            let { city, postal_code, state, street_address, country } = value;

            console.log(country);

            if (this.state.searchAddressType == 'City') {
                this.setState({ searchAddress: city });
            } else if (this.state.searchAddressType == 'State') {
                this.setState({ searchAddress: state });
            } else if (this.state.searchAddressType == 'Country') {
                this.setState({ searchAddress: country });
            }
        }
    };


    resetAll = (e) => {
        e.preventDefault();
        this.setState({ searchIndustry: [] })
        //this.setState({ searchAddressType: "" })
        //this.setState({ searchAddress: '' })
        console.log('searchIndustry', this.state.searchIndustry);
        console.log('searchAddressType', this.state.searchAddressType);
        console.log('searchAddress', this.state.searchAddress);
        this.setState({ status_type: "" })

        this.setState({ searchAddress: "" }, () => {
            console.log(this.state.searchAddress, 'searchAddress');
        });
        document.getElementById("search-form").reset();
        this.setState({ resetAutoComplete: true })

        if (this.state.clickable) {
            this.setState({ resetHr: true })
        } else {
            this.setState({ resetMyHR: true })
        }

        setTimeout(
            () => this.setState({ resetHr: false }),
            3000
        );

    }


    statusTypeChnage = (event) => {
        this.setState({ status_type: event.target.value })
    }

    // myContacts = () => {
    //     let request = {
    //         end_point: '/get-my-hrContacts',
    //         token: localStorage.getItem("gs_token")

    //     };

    //     GET_API(request).then(resp => {




    //         this.setState({ hrcontacts: resp.contacts }, ()=>{this.globalSearch();})
    //         // this.setState({loader:'hidden'})


    //     }).catch((ee) => {

    //         // this.setState({loader:'hidden'})
    //     });
    // };

    // allContacts = () => {
    //     let request = {
    //         end_point: '/get-hrContacts',
    //         token: localStorage.getItem("gs_token")

    //     };

    //     GET_API(request).then(resp => {




    //         this.setState({ hrcontacts: resp.contacts }, ()=>{this.globalSearch();})
    //         // this.setState({loader:'hidden'})

    //     }).catch((ee) => {

    //         // this.setState({loader:'hidden'})
    //     });
    // };

    render() {
        // let FromData = this.state.FromData
        // let data = this.state.hrcontacts
        // console.log(",,,,,,,,,",this.state.filteredData)
        // let columns = [
        //     {
        //         name: "Company",
        //         selector: "Company_Name",
        //         sortable: true,
        //     },
        //     {
        //         name: "Address",
        //         selector: "address",
        //         sortable: true,
        //     },
        //     {
        //         name: "City",
        //         selector: "City",
        //         sortable: true,
        //     },
        //     {
        //         name: "State",
        //         selector: "State",
        //         sortable: true,
        //     },
        //     {
        //         name: "Zip",
        //         selector: "Zip",
        //         sortable: true,
        //     },
        //     {
        //         name: "Country",
        //         selector: "Country",
        //         sortable: true,
        //     },
        //     {
        //         name: "Phone",
        //         selector: "Phone",
        //         sortable: true,
        //     },
        //     {
        //         name: "Website",
        //         selector: "Company_Url",
        //         sortable: true,
        //     },
        //     {
        //         name: "Contact",
        //         selector: "Full_Name",
        //         sortable: true,
        //     },
        //     {
        //         name: "Title",
        //         selector: "Title",
        //         sortable: true,
        //     },
        //     {
        //         name: "Email",
        //         selector: "Email",
        //         sortable: true,
        //     },
        //     {
        //         name: "Sales",
        //         selector: "Sales",
        //         sortable: true,
        //     },
        //     {
        //         name: "Employees",
        //         selector: "Company_Size",
        //         sortable: true,
        //     },
        //     {
        //         name: "SIC Code",
        //         selector: "Sic_Code",
        //         sortable: true,
        //     },
        //     {
        //         name: "Industry",
        //         selector: "Industry",
        //         sortable: true,
        //     },

        // ]
        return (
            <div className='pt-5 pb-5 data_table_gwi'>
                <Container >
                    <Row>
                        <Col md={12}>
                        <p><a className="btn btn-primary btn-md tabformbutton btnfix btn_total btn btn-primary" href="/#/jobseeker-dash">Go to Dashboard</a></p>
                        <p style={{ fontWeight: 600 }}><span className='text-danger'>*</span> One "Contact" is equivalent to either one or three valid details consisting of email address, phone number or Linkedin profile. 1 Credit will be deducted if any of the 3 (email address, phone number, linkedin URL) is found valid</p>
                            <h2 className='jobseeker_heading_hr'>HR Contacts</h2>
                            <div className='w-100 mb-2'>&nbsp;</div>

                            {/* <Loader loadmsg="please wait" visibility={this.state.loader}/> */}
                            {/* <div style={{ width: "300px", float: "right" }}>
                                <Form.Control
                                    size="large"
                                    name="searchInput"
                                    value={this.state.searchInput || ""}
                                    onChange={this.handleChange}
                                    placeholder="Search State, Country and HR contacts"
                                />
                            </div> */}
                            <Loader loadmsg="please wait" visibility={this.state.loader} />
                            {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}

                            <div>
                                <Row>
                                    <Col sm="5">

                                    </Col>
                                    <Col sm="7">
                                        <Form.Control
                                            size="large"
                                            name="searchInput"
                                            value={this.state.searchInput || ""}
                                            onChange={this.handleChange}
                                            placeholder="Search Contact Name and Company Name"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            {/* {this.state.contact_count == 0 ? (<> */}
                            <div className='mb-2'>
                                <form id="search-form">
                                    <Row>
                                        <Col sm="4">
                                            {/* <Form.Control as="select" name="industry" onChange={this.industryChange} defaultValue={''}>
                                                <option value="">--Select Industry--</option>
                                                {this.state.industryData.map((items, key2) => {
                                                    return (
                                                        <option value={items.Industry}>{items.Industry}</option>
                                                    )
                                                })}
                                            </Form.Control> */}
                                            <strong>Industry</strong>
                                            <Select
                                                closeMenuOnSelect={false}
                                                defaultValue={''}
                                                components={makeAnimated()}
                                                isMulti
                                                value={this.state.searchIndustry}
                                                name="industry"
                                                options={this.state.industryData.map((items, key2) => ({
                                                    label: items.Industry,
                                                    value: items.Industry,
                                                }))}
                                                onChange={this.industryChange}
                                            />


                                        </Col>
                                        <Col sm="4">
                                            <strong>Location</strong>
                                            <Form.Control as="select" name="address_type" onChange={this.addressTypeChnage} defaultValue={''}>
                                                <option value="">--Select Type--</option>
                                                <option value="City">City</option>
                                                <option value="State">State</option>
                                                {/* <option value="Country">Country</option> */}
                                            </Form.Control>
                                        </Col>
                                        <Col sm="4">
                                            {/* <Form.Control as="select" name="searchAddress" onChange={this.addressChnage} defaultValue={''}>
                                            <option value="">--Select Address--</option>
                                            {this.state.city_state_country.map((items2, key3) => {
                                                return (
                                                    <option value={items2}>{items2}</option>
                                                )
                                            })}
                                        </Form.Control> */}
                                            {/* <Form.Control
                                            size="large"
                                            name="searchAddress"
                                            value={this.state.searchAddress || ""}
                                            onChange={this.addressChnage}
                                            placeholder="Search Address"
                                        /> */}
                                            <label></label>
                                            <strong>{this.state.searchAddressType == "City" ? "City" : this.state.searchAddressType == "State" ? "State" : this.state.searchAddressType == "Country" ? "Country" : ""}</strong>
                                            <AutocompleteSearch
                                                name="searchAddress"
                                                placeHolder={this.state.searchAddress}
                                                getAddress={this.getAddress}
                                                addressType={this.state.searchAddressType}
                                                //onChange={this.addressChnage}
                                                value={this.state.searchAddress}
                                                reset={this.state.resetAutoComplete}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <strong>Status</strong>
                                            <Form.Control as="select" name="status_type" onChange={this.statusTypeChnage} defaultValue={''}>
                                                <option value="">--Select Status--</option>
                                                <option value="lock">Lock</option>
                                                <option value="unlock">Unlock</option>
                                                {/* <option value="Country">Country</option> */}
                                            </Form.Control>
                                        </Col>
                                        <Col sm="1" style={{"margin-right":"1rem"}}>
                                            {this.state.clickable == true ? (<>
                                                <Button
                                                    variant="primary"
                                                    type="button"
                                                    className="btn btn-primary btn-md tabformbutton btnfix btn_total"
                                                    onClick={this.industryAddressSearch}
                                                >
                                                    Search
                                                </Button>
                                            </>) : (<>
                                                <Button
                                                    variant="primary"
                                                    type="button"
                                                    className="btn btn-primary btn-md tabformbutton btnfix btn_total"
                                                    onClick={this.myHrContactSearch}>
                                                    Search
                                                </Button>
                                            </>)}
                                        </Col>
                                        <Col sm="1" className='pl-4'>
                                            <Button
                                                variant="primary"
                                                type="button"
                                                className="btn btn-danger btn-md tabformbutton btnfix btn_total"
                                                onClick={this.resetAll}
                                            >
                                                Reset
                                            </Button>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                            {/* </>) : (<></>)} */}
                            <div className='clearfix'></div>

                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Row>
                                    <Col sm={12}>
                                        {/* <Nav variant="pills" className='nav-fill style_tabs'>
                                            <Nav.Item>
                                                <Nav.Link eventKey="first" onClick={this.allContacts}>All HR Contacts</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second" onClick={this.myContacts}>My Selected HR Contacts</Nav.Link>
                                            </Nav.Item>
                                        </Nav> */}
                                        <Nav variant="pills" className='nav-fill style_tabs'>
                                            <Nav.Item>
                                                <Nav.Link eventKey="first" onClick={this.hrContactsClick}>All HR Contacts</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second" onClick={this.myContactsClick}>My Selected HR Contacts</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">

                                                <AllContacts searchInput={this.state.searchInput} loading={this.state.loading} searchable_data={this.state.searchable_data} resetHr={this.state.resetHr} searchData={this.state.searchData} />

                                            </Tab.Pane>

                                            <Tab.Pane eventKey="second">

                                                {/* <MyHrContacts searchInput={this.state.searchInput} myContactsSearchable_data={this.state.myContactsSearchable_data} loading_searchable={this.state.loading_searchable} resetMyHR={this.state.resetMyHR} /> */}

                                                <MyHrContactsGroup searchInput={this.state.searchInput} myContactsSearchable_data={this.state.myContactsSearchable_data} loading_searchable={this.state.loading_searchable} resetMyHR={this.state.resetMyHR} />

                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>








                        </Col>
                    </Row>

                </Container>
            </div >
        )
    }
}
