import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-widgets/dist/css/react-widgets.css';
import '../App.css';

import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import FrontendRoute from './Layout/Frontend.js';
import Login from './Auth/Login';
import ForgetPassword from './Auth/ForgetPassword';
import ChangePassword from './Auth/ChangePassword'
import OtherProducts from "./Affiliate/OtherProducts"


import PasswordChange from './Sponser/PasswordChange';

// Jobseeker
import JobseekerDashboard from './Jobseeker/JobseekerDashboard';
import SponserHome from './Home/SponserHome';
import JobSeekerHome from './Home/JobSeeker';

// Affiliate
import MyTools from './Affiliate/MyTools';
import AffiliateSubscription from './Affiliate/AffiliateSubscription';
import OrganizationTransactionHistory from './Affiliate/OrganizationTransactionHistory';
import CreditHistory from './Affiliate/CreditHistory';
import CreditRequestComponent from './Affiliate/CreditRequestComponent';
import AffiliateDashboard from './Affiliate/AffiliateDashboard'
import AffiliatePersonalProfile from './Affiliate/AffiliatePersonalProfile'
import AffiliateContactDetails from './Affiliate/AffiliateContactDetails'
import AffiliateSponsershipList from './Affiliate/SponsershipList'
import AffiliateJobseekerList from './Affiliate/AffiliateJobseekerList'
import AffiliateUpdateContact from './Affiliate/UpdateContactdetails'
import AffiliateCampaignDetails from './Affiliate/AffiliateCampaignDetails'
import AffiliateTempleteDetails from './Affiliate/AffiliateTempleteDetails'
import AddCampaign from './Affiliate/addCampaign'
import EditCampaign from './Affiliate/EditCampaign'
import AffiliategroupDetails from './Affiliate/AffiliategroupDetails'
import AffiliateCommisionReport from './Affiliate/AffiliateCommisionReport'
import AffiliateEmployeeCommision from './Affiliate/AffiliateEmployeeCommision'
import AddAffiliateBankDetails from './Affiliate/AffiliateBankDetails'
import AddBankDetails from './Affiliate/addAffiliateBankDetails'
import EditBankDetails from './Affiliate/EditAffiliateBankDetails'

import ManageUserAffiliate from './Affiliate/Member/UserList'
import CreateMemberAffiliate from './Affiliate/Member/CreateMember'
import UpdateAffiliate from './Affiliate/Member/UpdateMember'
import NgoProfileAffiliate from './Affiliate/NgoProfile'
import CompanyProfileAffiliate from './Affiliate/CompanyProfile'
import EmployeeCommissionAffiliate from './Affiliate/EmployeeCommission'

import AddEmailSettings from './Affiliate/addAffiliateEmailSetting'
import AffiliatePromocode from './Affiliate/AffiliatePromocode'
import CreateAffiliatePromocode from './Affiliate/CreateAffiliatePromocode'
import EditAffiliatePromocode from './Affiliate/EditAffiliatePromocode'
import AffiliateReferalGroupDetails from './Affiliate/AffiliateReferalGroupDetails'
import AffiliateReferalContactDetails from './Affiliate/AffiliateReferalContactDetails';

import AffiliateSignup from './Home/AffiliateSignup'


// end

import Notfound from './Layout/Notfound';
import Logout from './Helpers/Logout';
import KnowledgeBaseVideo from './Affiliate/KnowledgeBaseVideo.js';
import DeveloperTool from './Affiliate/DeveloperTool.js';
// import ManageProducts from './Affiliate/manageProducts.js';

import ManageProjects from './Affiliate/ManageProjects.js';





function App() {
  return (
    <div className="RouteGroup">
      <HashRouter>
        <Switch>


          <Route exact path="/" render={() => <Redirect to="/login" />} />



          <FrontendRoute exact path="/logout" component={Logout} />
          <FrontendRoute exact path="/affiliate-signup/:code?" component={AffiliateSignup} />
          <FrontendRoute exact path="/login" component={Login} />
          <FrontendRoute exact path="/forget-password" component={ForgetPassword} />
          <FrontendRoute exact path="/change-password/:token" component={ChangePassword} />
          <FrontendRoute exact path="/password-change/" component={PasswordChange} auth="yes"  />

          {/*affiliate */}
          <FrontendRoute exact path="/my-tools" component={MyTools} auth="yes" roles={[8, 9, 10,11,12,18,19]} />
          <FrontendRoute exact path="/developer-tool" component={DeveloperTool} auth="yes" roles={[9]} />
          {/* <FrontendRoute exact path="/manage-products" component={ManageProducts} auth="yes" roles={[9]} /> */}
          { localStorage.getItem("gs_user") && JSON.parse(localStorage.getItem("gs_user")).isMLM == 'Y' && 
          <FrontendRoute exact path="/manage-salesorbit-projects" component={ManageProjects} auth="yes" roles={[9]} /> 
          }
          <FrontendRoute exact path="/affiliate-subscription" component={AffiliateSubscription} auth="yes" roles={[9,18,19]} /> 
          <FrontendRoute exact path="/credit-transaction-history" component={OrganizationTransactionHistory} auth="yes" roles={[9,11,18,19]} />
          <FrontendRoute exact path="/credits-detail" component={CreditHistory} auth="yes" roles={[9,11,18,19]} />
          <FrontendRoute exact path="/credit-request-history" component={CreditRequestComponent} auth="yes" roles={[9,11]} />
          <FrontendRoute exact path="/knowledge-base" component={KnowledgeBaseVideo} auth="yes" roles={[8, 9, 10,11,12,18,19]} />

          <FrontendRoute exact path="/affiliate-dash" component={AffiliateDashboard} auth="yes" roles={[8, 9, 10,18,19]} />
          <FrontendRoute exact path="/affiliate-personal-profile" component={AffiliatePersonalProfile} auth="yes" roles={[8, 9, 10,18,19, 11, 12]} />
          <FrontendRoute exact path="/contact-details" component={AffiliateContactDetails} auth="yes" roles={[8, 9, 10,18,19]} />
          <FrontendRoute exact path="/affiliate-sponsor-list" component={AffiliateSponsershipList} auth="yes" roles={[9, 10, 11, 12,18,19]} />
          <FrontendRoute exact path="/affiliate-yachtkarma-list" component={OtherProducts} type="yachtkarma" product_id = "1" auth="yes" roles={[9, 10, 11, 12,18,19]} />
          <FrontendRoute exact path="/affiliate/:type/:id" component={OtherProducts} auth="yes" roles={[9, 10, 11, 12,18,19]} />
          <FrontendRoute exact path="/affiliate-tripvalet-incentive-list" component={OtherProducts} type="Tripvalet Incentive" product_id = "2" auth="yes" roles={[9, 10, 11, 12,18,19]} />
          <FrontendRoute exact path="/affiliate-tripvalet-list" component={OtherProducts} type="Tripvalet" product_id = "3" auth="yes" roles={[9, 10, 11, 12,18,19]} />
          <FrontendRoute exact path="/affiliate-jobseeker-list" component={AffiliateJobseekerList} auth="yes" roles={[8, 9, 10,18,19, 11, 12]} />
          <FrontendRoute exact path="/update-contact/:id" component={AffiliateUpdateContact} auth="yes" roles={[8, 9, 10,18,19]} />

          <FrontendRoute exact path="/campaign-details" component={AffiliateCampaignDetails} auth="yes" roles={[8, 9, 10,18,19]} />
          <FrontendRoute exact path="/create-campaign" component={AddCampaign} auth="yes" roles={[8, 9, 10,18,19]} />
          <FrontendRoute exact path="/templete-details" component={AffiliateTempleteDetails} auth="yes" roles={[8, 9, 10,18,19]} />
          <FrontendRoute exact path="/edit-campaign/:code" component={EditCampaign} auth="yes" roles={[8, 9, 10,18,19]} />

          <FrontendRoute exact path="/contact-group-details" component={AffiliategroupDetails} auth="yes" roles={[8, 9, 10,18,19]} />
          <FrontendRoute exact path="/contact-details/:code" component={AffiliateContactDetails} auth="yes" roles={[8, 9, 10,18,19, 16]} />
          <FrontendRoute exact path="/commission-report/" component={AffiliateCommisionReport} auth="yes" roles={[8, 9, 10,18,19, 11, 12]} />
          <FrontendRoute exact path="/employee-commission/" component={AffiliateEmployeeCommision} auth="yes" roles={[9, 10]} />
          <FrontendRoute exact path="/add-affiliate-bank/" component={AddAffiliateBankDetails} auth="yes" roles={[8, 9, 10,18,19, 11, 12]} />
          <FrontendRoute exact path="/add-bank/" component={AddBankDetails} auth="yes" roles={[8, 9, 10,18,19, 11, 12]} />
          <FrontendRoute exact path="/edit-bank/:code" component={EditBankDetails} auth="yes" roles={[8, 9, 10,18,19, 11, 12]} />
          <FrontendRoute exact path="/add-email-setting/" component={AddEmailSettings} auth="yes" roles={[8, 9, 10,18,19]} />

          {/*affiliate member */}
          <FrontendRoute exact path="/members-affiliate" component={ManageUserAffiliate} auth="yes" roles={[9, 10,18,19]} />
          <FrontendRoute exact path="/create-members-affiliate" component={CreateMemberAffiliate} auth="yes" roles={[9, 10]} />
          <FrontendRoute exact path="/update-members-affiliate/:id" component={UpdateAffiliate} auth="yes" roles={[9, 10]} />
          <FrontendRoute exact path="/affiliate-company-profile" component={CompanyProfileAffiliate} auth="yes" roles={[9, 10]} />
          <FrontendRoute exact path="/employee-commission-setting" component={EmployeeCommissionAffiliate} auth="yes" roles={[9, 10]} />

          {/*affiliate member Login */}
          <FrontendRoute exact path="/affiliate-member-dash" component={AffiliateDashboard} auth="yes" roles={[11, 12]} />
          {/*affiliate promocode */}
          <FrontendRoute exact path="/affiliate-promocode" component={AffiliatePromocode} auth="yes" roles={[8, 9, 10,18,19]} />
          <FrontendRoute exact path="/create-affiliate-promocode" component={CreateAffiliatePromocode} auth="yes" roles={[8, 9, 10,18,19]} />
          <FrontendRoute exact path="/edit-affiliate-promocode/:id" component={EditAffiliatePromocode} auth="yes" roles={[8, 9, 10,18,19]} />
          <FrontendRoute exact path="/affiliate-referal-group-details" component={AffiliateReferalGroupDetails} auth="yes" />
          <FrontendRoute exact path="/affiliate-referal-contact-details/:id" component={AffiliateReferalContactDetails} auth="yes" roles={[8, 9, 10,18,19]} />
          <FrontendRoute component={Notfound} />
      </Switch>
      </HashRouter>


    </div>
  );
}

export default App;
