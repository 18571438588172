import React, { Component } from 'react'
import { FlashMess, GET_API, POST_API, Loader } from "./Helpers";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {API_URL} from '../Config';
import LeftPanel from '../Jobseeker/Section/LeftPanel'
import { Container, Row, Col, Tab, Nav, Form, Button, Image, OverlayTrigger,Tooltip } from "react-bootstrap";

export default class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = {
        loader: "hidden",
        Msg:"",
        Msgtype:""
        };
       
      }
      componentDidMount() {
        if(localStorage.getItem("gs_token") != null)
        {
            this.setState({loader:'visible'})
            fetch(API_URL+'/logout',{
              method:"POST",
              headers:{"Authorization":"Bearer "+ localStorage.getItem("gs_token")}
            }).then((result)=>{
                result.json().then((resp)=>{
                  console.log('...Logout',resp)
    
                  if(resp.status==='success')
                  {
                    this.setState({loader:'hidden'})
                    //alert('unauthorized')
                    localStorage.removeItem("gs_token")
                    localStorage.removeItem("gs_user")
                    localStorage.removeItem("gs_company")
                    localStorage.removeItem("gs_sponser_details")
                    localStorage.removeItem("gs_auth_code")
                    localStorage.removeItem("roleID")
                    localStorage.removeItem("get_category")
                    localStorage.removeItem("profile_image")
                    localStorage.removeItem("resumeScore")
                    this.props.history.push({
                      pathname: '/login',
                      state: { Msg:'Logout Successfully!',Msgtype:'succcess'}
                    });
                      
                    
                   
                  
                  }
                  else if(resp.message == "unauthorized")
                  {
                    localStorage.removeItem("gs_token")
                    localStorage.removeItem("gs_user")
                    localStorage.removeItem("gs_company")
                    localStorage.removeItem("gs_sponser_details")
                    localStorage.removeItem("gs_auth_code")
                    localStorage.removeItem("roleID")
                    localStorage.removeItem("get_category")
                    this.props.history.push({
                      pathname: '/login',
                      state: { Msg:'Logout Successfully!',Msgtype:'succcess'}
                    });
                  }
    
                  
    
                })
        
            })
            .catch((err)=>{
              console.log(err.response.data.message)
              if(err.response.data.message == "unauthorized")
              {
                localStorage.removeItem("gs_token")
                localStorage.removeItem("gs_user")
                localStorage.removeItem("gs_company")
                localStorage.removeItem("gs_sponser_details")
                localStorage.removeItem("gs_auth_code")
                localStorage.removeItem("roleID")
                localStorage.removeItem("get_category")
                this.props.history.push({
                  pathname: '/login',
                  state: {}
                });
    
              }
    
            })
        }
        else
        {
          this.props.history.push({
            pathname: '/login',
          });
        }
    
      
    

      }
    
    render() {
        return (
            <div>
             <Container fluid className="dash_container">
                <Row >
                    {/* <LeftPanel/> */}
                    <Col md={12}>
                    <Loader loadmsg="please wait" visibility={this.state.loader}/>
                    <h2 style={{ textAlign: "center", marginTop: "calc(40%)",color: "#000", opacity: 1}}>Please wait while we are logging you out</h2>
                    </Col>
                </Row>
            </Container>
            </div>
        )
    }
}
