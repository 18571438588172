const creditBalance = (state = [], action) => {
    switch (action.type) {
      case "CREDIT-BALANCE":
        return action.creditBalance;
      default:
        return state ;
    }
  };
  
  export default creditBalance;
  