import React, { Component } from 'react'
import { Container, Row, Col, Tab, Nav, Form, Button, Image, OverlayTrigger,Tooltip,Card } from "react-bootstrap";
import { FlashMess, GET_API, POST_API,Loader } from "../Helpers/Helpers";
import {ResumeScoringURL} from  "../Config";

export default class ResumeScoring extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
                        
 
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/jobseeker-personal-profile',
                        help:'',
                        hrcontacts:{},
                        searchInput: "",
                        filteredData: [],
                        coaches:[],
                        scoreflag:1

                        
                     };
                     this.handlesubmit=this.handlesubmit.bind(this);
        

    }
    handlesubmit()
    {
        this.props.handlescoring(this.state.scoreflag); 
        var formdata=new FormData();
        formdata.append("flag", this.state.scoreflag);
        let request = {
            end_point : '/ResumeScoringSelected',
            token: localStorage.getItem("gs_token"),
            formdata:formdata
            

        };
        this.setState({loader:'visible'})
            POST_API(request).then(resp=>{
                this.setState({loader:'hidden'})
                    // console.log("joblist",resp)
                    // this.setState({joblist:resp.jobs})
                    window.open(ResumeScoringURL+"/direct-login/token/"+localStorage.getItem('gs_token'), "_blank")
                    }).catch((ee)=>{

                    });
    }
    componentDidMount(){
        let request = {
            end_point : '/get-score',
            token: localStorage.getItem("gs_token")

        };
        GET_API(request).then(resp=>{

                   
            localStorage.setItem("resumeScore",resp.resume_score)          
                                
            this.setState({hrcontacts:resp.contacts})
            // this.setState({loader:'hidden'})
        
            }).catch((ee)=>{

                // this.setState({loader:'hidden'})
            });

    }
    render() {
        let percentage='';
        if(localStorage.getItem("resumeScore")>0 && localStorage.getItem("resumeScore")<=10)
        {
            percentage=10;
        }
        else if(localStorage.getItem("resumeScore")>10 && localStorage.getItem("resumeScore")<=20)
        {
            percentage=20;
        }
        else if(localStorage.getItem("resumeScore")>20 && localStorage.getItem("resumeScore")<=30)
        {
            percentage=30;
        }
        else if(localStorage.getItem("resumeScore")>30 && localStorage.getItem("resumeScore")<=40)
        {
            percentage=40;
        }
        else if(localStorage.getItem("resumeScore")>40 && localStorage.getItem("resumeScore")<=50)
        {
            percentage=50;
        }
        else if(localStorage.getItem("resumeScore")>50 && localStorage.getItem("resumeScore")<=60)
        {
            percentage=60;
        }
        else if(localStorage.getItem("resumeScore")>60 && localStorage.getItem("resumeScore")<=70)
        {
            percentage=70;
        }
        else if(localStorage.getItem("resumeScore")>70 && localStorage.getItem("resumeScore")<=80)
        {
            percentage=80;
        }
        else if(localStorage.getItem("resumeScore")>80 && localStorage.getItem("resumeScore")<=90)
        {
            percentage=90;
        }
        else if(localStorage.getItem("resumeScore")>90 && localStorage.getItem("resumeScore")<=100)
        {
            percentage=100;
        }
        else
        {
            percentage=0;
        }
       
        
        return (
           

            <Row>
            <Loader loadmsg="please wait" visibility={this.state.loader}/>
                  <Col md="8">
                    <div>
                        <h2>Resume Scoring</h2>
                        <p>See how your resume performs and download a detailed report on how your resume stacks up.</p>                        
                    </div>
                  </Col>
                  <Col md="4">

                    {percentage != 0 &&(

                        <div className="progress round" data-percentage={percentage}>
                        <span className="progress-left">
                            <span className="progress-bar"></span>
                        </span>
                        <span className="progress-right">
                            <span className="progress-bar"></span>
                        </span>
                        <div className="progress-value">
                            <div>
                            {localStorage.getItem("resumeScore") ?? 0}%<br/>
                                <span>completed</span>
                            </div>
                        </div>
                        </div>
                    )}

                   

                  </Col>

                  <Col md="12">
                  
                    <Button style={{marginBottom:"15px"}} onClick={this.handlesubmit} className="btn-1" >Go to dashboard</Button>
                  </Col>
              </Row>



        )
    }
}
