import React, { Component, } from 'react';
import { Container, Row, Col,  Button, Dropdown } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import './css/dashboard.css';
import DataTable from "react-data-table-component";
import CampaingViewModal from './Section/CampaingViewModal'
import moment from 'moment';
import Threedots_img from '../images/dotsss.png'




export default class AffiliateCampaignDetails extends Component {



  constructor(props) {
    super(props)
    this.state = {

      FromData: {

        campaign_name: '',
        description: '',
        allcontactID: '',



      },
      FromDataError:
      {

        campaign_name: 'false',
        description: 'false',
        allcontactID: 'false',



      },
      Msg: '',
      MsgType: '',
      loader: 'hidden',
      urlPath: '/campaign-details',
      ContactData: [],
      ContactDataError: [],

      TemplateData: [],
      campaign_id: 0,
      CampaignViewModal: false,
      help: ''

    };

    //Binding




  }




  handleClose(campaign_id = 0) {

    if (this.state.CampaignViewModal) {
      this.setState({ CampaignViewModal: false })
    }
    else {
      this.setState({ campaign_id: campaign_id, CampaignViewModal: true })

    }
  }

  editRedirect(id) {
    this.props.history.push('/edit-campaign/' + id);
  }

  columns = [

    // {
    //     name: "Campaign Name",
    //     cell: row => row.campaign_name ,
    //     sortable: true,
    //   },
    //   {
    //     name: "Description",
    //     cell: row => row.description ,
    //     sortable: true,
    //   },
    //   {
    //     name: "Template Name",
    //     cell: row => row.templete_name ,
    //     sortable: true
    //   },
    //   {
    //     name: "Template Type",
    //     cell: row => row.type ,
    //     sortable: true
    //   },
    //   {
    //     name: "Group Name",
    //     cell: row => row.group_name ,
    //     sortable: true
    //   },
    //   {
    //     name: "Scheduled At",
    //     cell: row => row.schedule_at ,
    //     sortable: true
    //   },
    //   {
    //     name: "Has executed",
    //     cell: row =>
    //     {
    //       return(
    //         <div>{row.has_executed==1?'Send':'Pending'}</div>



    //           )
    //           } 
    //          ,
    //     sortable: true
    //   },
    //   {
    //     name: "Created At",
    //     cell: row => row.created_at ,
    //     sortable: true
    //   },

    //   {
    //     name: "Actions",
    //     cell: row =>  {
    //                 return(
    //                   <div><a href={'#/edit-campaign/'+row.id}><Button variant="primary mr-1">Edit</Button></a>
    //                            <a href='javascript:'><Button variant="primary mr-1" onClick={()=>this.handleClose(row.id)}>View</Button></a> 
    //                          <Button disabled={false} variant="danger" onClick={()=>{if(window.confirm('Delete the Campaign?')){this.handaleDelete(row.id)}}}>Delete</Button>

    //                         </div>
    //                     )
    //                     } 
    //                    ,
    //     sortable: true,
    //     width: '250px'
    //   },

    {
      name: "SL. No.",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "100px",
      wrap: true,
    },
    {
      name: "Campaign Name",
      selector: row => row.campaign_name,
      width: '150px',
      wrap: true,
      sortable: true,
    },
    // {
    //   name: "Description",
    //   selector: row => row.description ,
    //   width:'200px',
    //   sortable: true,
    // },
    {
      name: "Template",
      selector: row => row.templete_name,
      width: '200px',
      wrap: true,
      sortable: true
    },
    // {
    //   name: "Template Type",
    //   selector: row => row.type,
    //   sortable: true
    // },
    {
      name: "List Name",
      selector: row => row.group_name,
      width: '150px',
      wrap: true,
      sortable: true
    },
    {
      name: "Scheduled At",
      selector: row => moment(row.schedule_at).format('MM-DD-YYYY LTS'),
      width: '150px',
      wrap: true,
      sortable: true
    },
    {
      name: "Mail Executed",
      selector: row => {
        return (
          <div>{row.has_executed == 1 ? 'Sent' : 'Pending'}</div>



        )
      }
      ,
      // sortable: true
    },
    // {
    //   name: "Created At",
    //   selector: row => row.created_at ,
    //   width:'150px',
    //   wrap:true,
    //   sortable: true
    // },
    // {
    //   name: "Created At",
    //   selector: row => moment(row.created_at).format('MM-DD-YYYY'),
    //   width:'150px',
    //   sortable: true,
    //   wrap:true
    // },
    {
      name: "Created At",
      selector: row => moment(row.created_at).format('MM-DD-YYYY'),
      width: '150px',
      sortable: true,
      wrap: true
    },

    {
      name: "Actions",
      selector: row => {
        return (
          // <div>{row.save_time=='now'?<a href="#"><Button variant="primary mr-1" disabled>Edit</Button></a>:<a href={'#/edit-campaign/'+row.id}><Button variant="primary mr-1">Edit</Button></a>}
          //   {/* <a href={'#/edit-campaign/'+row.id}><Button variant="primary mr-1">Edit</Button></a> */}
          //          <a href='javascript:'><Button variant="primary mr-1" onClick={()=>this.handleClose(row.id)}>View</Button></a> 
          //        <Button disabled={false} variant="danger" onClick={()=>{if(window.confirm('Delete the Campaign?')){this.handaleDelete(row.id)}}}>Delete</Button>

          //       </div>

          <div className="three_dotss">
            <Dropdown className="drop_partts">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <img src={Threedots_img} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {/* <Dropdown.Item> */}
                {/* {row.save_time == 'now' ? <a href="#"><Button variant="primary mr-1" disabled> <i className="fa fa-pencil"></i> Edit</Button></a> : <a href={'#/edit-campaign/' + row.id}><Button variant="primary mr-1"> <i className="fa fa-pencil"></i> Edit</Button></a>} */}

                {/* </Dropdown.Item> */}
                {row.has_executed == 0 ? (<>
                  <Dropdown.Item >
                    <Button variant="primary mr-1" onClick={() => this.editRedirect(row.id)}> <i className="fa fa-pencil"></i> Edit</Button>
                  </Dropdown.Item>
                </>) : (<></>)}
                <Dropdown.Item >
                  <a href='javascript:'><Button variant="primary mr-1" onClick={() => this.handleClose(row.id)}> <i className="fa fa-eye"></i> View</Button></a>
                </Dropdown.Item>
                <Dropdown.Item >
                  <Button disabled={false} variant="danger" onClick={() => { if (window.confirm('Delete the Campaign?')) { this.handaleDelete(row.id) } }}> <i className="fa fa-trash"></i> Delete</Button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )
      }
      ,
      // sortable: true,
      width: '100px'
    },



  ];


  componentDidMount() {
    window.scrollTo(0, 0)
    let sta = this;
    // let ContactData = this.state.ContactData
    // let ContactDataError = this.state.ContactDataError



    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg })
      sta.setState({ MsgType: sta.props.location.state.MsgType })
    }

    sta.getAllCampaign();

    //help api
    let requestHelp = {
      end_point: '/help-details?key=affiliate_campaign_list',

      token: localStorage.getItem("gs_token")
    }

    sta.setState({ loader: 'visible' })///loader on

    GET_API(requestHelp).then((resph) => {
      sta.setState({ loader: 'hidden' })///loader off

      if (resph.status == 'success') {
        let help = resph.data.value
        sta.setState({ help })
      }

    }).catch(() => {
      sta.setState({ loader: 'hidden' })///loader off


    })





  }


  getAllCampaign() {

    let sta = this;
    let ContactData = this.state.ContactData
    //let ContactDataError = this.state.ContactDataError



    let request = {
      end_point: '/list-campaign',
      token: localStorage.getItem("gs_token")

    };

    sta.setState({ loader: 'visible' })///loader on


    GET_API(request).then(resp => {

      sta.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {

        ContactData = resp.data

        sta.setState({ ContactData })

      }
      else {



      }
    }).catch((ee) => {
      sta.setState({ loader: 'hidden' })///loader off

      console.log('/campaign list ERR', ee)
    });




  }


  handaleDelete(id) {
    let sta = this

    var formdata = new FormData();
    formdata.append("id", id)

    let request = {
      end_point: '/delete-campaign',
      formdata: formdata,
      token: localStorage.getItem("gs_token")
    };




    POST_API(request).then((resp) => {

      if (resp.status == 'success') {




        //  setTimeout(() => {
        //      window.location.reload()

        //  }, 3000);

        sta.getAllCampaign();
        sta.setState({ Msg: 'Campaign Deleted Successfully!', Msgtype: 'succcess' })



      }
      else {
        //console.log(resp.message.password.join())
        sta.setState({ Msg: "faild", MsgType: 'danger' })
      }

    }).catch(() => {

    })

  }

  render() {
    let FromData = this.state.FromData
    let ContactData = this.state.ContactData

    return (
      <div>
        {FromData.hasOwnProperty("campaign_name") ? (<>


          <Container fluid className="dash_container">
            <Row>
              {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
              <Col md={12}>
                <Loader loadmsg="please wait" visibility={this.state.loader} />

                <div className="tabpanelOneForm pt-3">


                  <Row>
                    <Col md={9}>
                      <div>
                        {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}
                      </div>

                      <h3>Campaign List</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.help
                        }}></p>

                    </Col>
                    <Col md={3} className="d-flex justify-content-end align-items-start">
                      {(this.state.campaign_id != 0) ? (<>
                        <CampaingViewModal {...this.props} campaign_id={this.state.campaign_id} show={this.state.CampaignViewModal} close={() => this.handleClose()} />
                      </>) : (<></>)}
                      <a href="#/create-campaign" className="btn btn-primary ghost_button mt-3 mb-4">Add Campaign</a>



                    </Col>
                  </Row>





                  {/*<div className="tooltip-block-redeem-list" style={{left: "200px", top:"20px"}}>   
                  <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-2"><div
                dangerouslySetInnerHTML={{
                  __html: this.state.help
                }}></div></Tooltip>}
              >
                     <Image
                      roundedCircle
                      src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020%2020%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1774ca3ddcc%20text%20%7B%20fill%3A%23FFF%3Bfont-weight%3Anormal%3Bfont-family%3A-apple-system%2CBlinkMacSystemFont%2C%26quot%3BSegoe%20UI%26quot%3B%2CRoboto%2C%26quot%3BHelvetica%20Neue%26quot%3B%2CArial%2C%26quot%3BNoto%20Sans%26quot%3B%2Csans-serif%2C%26quot%3BApple%20Color%20Emoji%26quot%3B%2C%26quot%3BSegoe%20UI%20Emoji%26quot%3B%2C%26quot%3BSegoe%20UI%20Symbol%26quot%3B%2C%26quot%3BNoto%20Color%20Emoji%26quot%3B%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1774ca3ddcc%22%3E%3Crect%20width%3D%2220%22%20height%3D%2220%22%20fill%3D%22%2328a745%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%227.1171875%22%20y%3D%2215.100000000000001%22%3EJ%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                    /> 
                    
            
               </OverlayTrigger>
              </div>*/}
                  <div style={{ float: "right", margin: '10px' }} className="table_search">
                    Search : <input type='text' placeholder='Search' name="search_val" onChange={this.searchChange} /></div>
                  <DataTable
                    title=""
                    columns={this.columns}
                    data={ContactData}
                    defaultSortField="title"
                    pagination={true}
                    NoDataComponent={"No data"}
                    noHeader={true}
                  />



                </div>
              </Col>
            </Row>
          </Container>

        </>) : (<p>....Loading</p>)}


      </div>




    );
  }
}

