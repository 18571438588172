import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Container, Row, Col, Tab, Nav, Form, Button, InputGroup } from "react-bootstrap";
import { withRouter } from "react-router";
import TokenVerify from "../Helpers/Helpers";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from "../Validate/validator";
import "./css/jobseekersignup.css"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import JobSekerBasicInfo from "./JobSekerBasicInfo";
import JobSeekerOTPandPass from "./JobSeekerOTPandPass";
import {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
class JobSeekerSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobseekerData: {},
      mailmsg: "",
      MailMsgType: "",
      Msg: "",
      MsgType: "",
      FromData: {
        save_card: false,
        file: '',
        linkedin_resume_url: '',
        linkedin_resume_url_name: '',
        referral_code: '',
        filename: '',
        format: '',
        affiliate_code: '',

        contact_no: "",
        firstname: "",
        lastname: "",
        email: "",
        confirm_email: "",
        profileimage: "",
        profilename: "",
        imageformat: "",
        id: "",
        score: '',
        job_title: '',
        otptext: "",
        password: '',
        confirm_password: '',
        linkedinImageformat: "",

        card_name: '',
        card_number: '',
        card_expiry: '',
        card_cvc: '',
        card_details: "",
      },
      FromDataError: {
        file: 'false',
        linkedin_resume_url: 'false',
        referral_code: '',
        filename: '',
        format: '',
        affiliate_code: '',

        contact_no: "false",
        firstname: "false",
        lastname: "false",
        email: "false",
        confirm_email: "false",
        profileimage: "",
        profilename: "",
        imageformat: "",
        linkedinImageformat: "",
        // recapcha:"false",
        // otptext: "false",
        // password : 'false',
        // confirm_password : 'false'

      },
      FromDataError1: {
        card_details: "false",
        recapcha: "false",
        otptext: "false",
        password: 'false',
        confirm_password: 'false',

        card_name: 'false',
        card_number: '',
        card_expiry: '',
        card_cvc: ''
      },
      loader: "hidden",
      loadingtext: "",
      liveUrl: "",
      pagesource: "",
      servicetype: "",
      apiStatus: "",
      VerifyapiStatus: "",
      step: 1,
      step1Msg: "",
      step1MsgType: "",
      siteDetails: {},
      serviceDetails: {},
      isPasswordValid: false,
      isMailValid: false,
      hasPromo: false,
      promoApplied: {},
      promoCode: "",
      pFileName: '',
      PFFileName: '',
      LFileName: '',

    };

    this.handaleChange = this.handaleChange.bind(this);
    this.handaleSubmit = this.handaleSubmit.bind(this);
  }

  cleanPromoApplied = () => {
    if (this.state.hasPromo == false) {
      this.setState({ promoCode: '', MsgType: '', Msg: '', promoApplied: this.state.siteDetails })
    }
  }

  validatePromo = (e) => {
    this.setState({ loader: 'visible' })///loader on
    console.log("a", this.state)


    var promo = document.getElementById("promoCode").value;

    // let promo = e.target.value

    let formSavedata = new FormData();
    formSavedata.append("amount", this.state.siteDetails.offerPrice);
    formSavedata.append("promo", promo);
    formSavedata.append("siteId", this.state.serviceDetails.id);

    let requestData = {
      end_point: '/validate-promocode',
      formdata: formSavedata,
      token: localStorage.getItem("gs_token")

    };

    console.log('......request', formSavedata)


    POST_API(requestData).then(resp => {


      this.setState({ loader: 'hidden' })///loader off


      if (resp.success == true) {
        this.setState({ Msg: resp.message, MsgType: 'success' })
        let promoApplied = { ...this.state.promoApplied }
        promoApplied.offerPrice = resp.amount
        //   console.log(this.state)
        //   return 0;

        this.setState({ promoApplied, promoCode: promo }, () => {
          console.log(this.state)
        })
      }
      else {
        this.setState({ Msg: (typeof resp.message == 'string') ? resp.message : "Invalid Promo", MsgType: 'danger' })
        this.setState({ promoCode: '' })




      }


    }).catch((ee) => {
      this.setState({ promoCode: '' })


      this.setState({ loader: 'hidden' })///loader off

      this.setState({ Msg: 'Something Went Wrong,Please try again later.', MsgType: 'danger' })



    });

  }

  componentDidMount() {
    this.handleSiteCheck()
    if (["CB", "RL", "HRC", "JNH"].includes(this.props.servicetype)) {
      let FromDataError = this.state.FromDataError
      FromDataError.file = ""
      FromDataError.linkedin_resume_url = ""
      this.setState({ FromDataError })
    }
    else if (["SPV"].includes(this.props.servicetype)) {
      let FromDataError = this.state.FromDataError
      FromDataError.file = ""
      this.setState({ FromDataError })
    } else {
      let FromDataError = this.state.FromDataError
      FromDataError.linkedin_resume_url = ""
      this.setState({ FromDataError })
    }



    const { match, location, history } = this.props;
    this.setState({ liveUrl: location.search });

    console.log(location.search);
    const queryParams = new URLSearchParams(location.search);
    this.setState({
      pagesource: queryParams.get("pagesource")
        ? queryParams.get("pagesource")
        : "",
    });
    this.setState({
      servicetype: queryParams.get("servicetype")
        ? queryParams.get("servicetype")
        : "",
    });
  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromDataError1 = this.state.FromDataError1

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
        if (value == "false") {
          FromDataError[key] = "This field is required";
          FromDataError1[key] = "This field is required";
          sta.setState({ FromDataError, FromDataError1 });
        }

        valid = false;
      }
    }

    return valid;
  }
  handaleChange(data) {
    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromDataError1 = this.state.FromDataError1

    //console.log("handle change", data.target.name);

    let name = data.target.name;
    let value = "";
    let FromData = this.state.FromData;

    if (name == "file") {
      let file = data.target.files[0];
      value = file;
      if (file.type == "application/pdf") {


        this.toBase64(data.target.files[0]).then((resp) => {
          FromData[name] = resp.split(",")[1];
          FromData.filename = file.name;
          FromData.format = file.type;
          this.setState({ PFFileName: file.name });
        });
      }
    }
    // else if (name == "linkedin_resume") {
    //   let file = data.target.files[0];
    //   value = file;

    else if (name == "profileimage") {
      let allowedExtension = ['image/jpeg', 'image/jpg', 'image/png'];
      let file = data.target.files[0];
      console.log(file);
      if (file != undefined) {
        let fileType = file.type;
        value = file;
        if (allowedExtension.indexOf(fileType) > -1) {

          this.profiletoBase64(data.target.files[0]).then((resp) => {
            FromData[name] = data.target.files[0];
            FromData.profilename = file.name;
            FromData.imageformat = file.type;
            this.setState({ pFileName: file.name })
          });
        }
      }
    } else if (name == "otptext") {
      value = data.target.value;
      FromData[name] = value;
      if (value.length >= 4) {
        this.verifyOTP();
      }

    } else if (name == "email" || name == "confirm_email") {
      value = data.target.value;
      FromData[name] = value;
      if (FromData.email == FromData.confirm_email) {
        FromDataError['confirm_email'] = "";
        this.setState({ isMailValid: true, FromDataError });
      } else {

        FromDataError['confirm_email'] = "Confirmed e-mail don't match";

        this.setState({ isMailValid: false, FromDataError });

      }

    }
    else if (name == "password" || name == "confirm_password") {
      value = data.target.value;
      FromData[name] = value;
      if (FromData.password == FromData.confirm_password) {
        FromDataError1['confirm_password'] = "";
        this.setState({ isPasswordValid: true, FromDataError1 });
      } else {
        if (FromData.confirm_password.length < 6) {
          FromDataError1['confirm_password'] = "password should be of at least 6 characters";
        } else {
          FromDataError1['confirm_password'] = "Confirmed Password should be same as password";
        }
        this.setState({ isPasswordValid: false, FromDataError1 });

      }

    }
    else {
      value = data.target.value;
      FromData[name] = value;
    }

    this.setState({ FromData }, () => {
      // console.log("formdataaaaaaaaaaaaaaaaaa",FromData,FromDataError);
    });

    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((error) => {
      //console.log(error)
      FromDataError[name] = error;
      FromDataError1[name] = error;
      this.setState({ FromDataError });
      this.setState({ FromDataError1 });


      setTimeout(() => {
        if (name == "file") {
          console.log("Call resume upload api")
          this.handaleResumeUpload()
        }


      }, 100);
    });
  }
  liinkedinResumeUpload = (e) => {

    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError


    let file = e.target.files[0];
    let allowedExtension = ['application/pdf'];
    console.log(file);
    if (file != undefined) {
      if (allowedExtension.indexOf(file.type) > -1) {
        if (file.size <= 2000000) {
          this.setState({ loader: "", loadingtext: "We are reading your linkedin profile ...." }); ///loader on

          // Updating file name
          FromData.linkedin_resume_url_name = file.name
          this.setState({ LFileName: file.name });
          this.setState({ FromData })

          // Make from data
          var formdata = new FormData();
          formdata.append("linkedin_resume", file);


          console.log("......upload-linkedin-resume", formdata);

          let request = {
            end_point: "/upload-linkedin-resume",
            formdata: formdata,
          };

          POST_API(request)
            .then((resp) => {
              this.setState({ loader: "hidden", loadingtext: "" }); ///loader off

              if (resp.status == "success") {
                FromData.linkedin_resume_url = resp.url
                FromDataError.linkedin_resume_url = ""


                this.setState({ FromData, FromDataError }, () => {
                  console.log(FromDataError)
                })
              }
              else {
                FromData.linkedin_resume_url = ''
                FromDataError.linkedin_resume_url = resp.message


                this.setState({ FromData, FromDataError }, () => {
                  console.log(FromDataError)
                })

              }
            }).catch((err) => {
              this.setState({ loader: "hidden", loadingtext: "" }); ///loader off
              console.log("liinkedinResumeUpload", err)
            })
        } else {
          FromDataError['linkedin_resume_url'] = "File too big";
          this.setState({ FromData, FromDataError }, () => {
            console.log(FromDataError)
          })
        }

      } else {
        FromDataError['linkedin_resume_url'] = "File format invalid";
        this.setState({ FromData, FromDataError }, () => {
          console.log(FromDataError)
        })
      }
    }


  }

  handlePhoneChange = (value) => {
    let { FromDataError, FromData, FromDataError1 } = this.state;
    if (value !== undefined) {
      FromData.contact_no = value;
      this.setState({ FromData });

      //let number = parsePhoneNumber(value);
      // if (number) {
      //   let country_code = "+" + number.countryCallingCode;
      //   let mobile = number.nationalNumber;
      //   this.setState({ country_code, mobile });
      // }
      console.log("Phone", value, isValidPhoneNumber(value))
      if (isValidPhoneNumber(value)) {
        FromDataError.contact_no = "";
        FromDataError1.contact_no = "";
      } else {
        FromDataError.contact_no = "Enter Valid Mobile Number";
        FromDataError1.contact_no = "Enter Valid Mobile Number";
      }
      this.setState({ FromDataError, FromDataError1 });
    } else {
      FromDataError.contact_no = "Enter Valid Mobile Number";
      FromDataError1.contact_no = "Enter Valid Mobile Number";
      this.setState({ FromDataError, FromDataError1 });
    }
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  profiletoBase64 = (profileimage) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(profileimage);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });



  async handaleSubmit(e) {
    e.preventDefault();
    //this.verifyOTP();

  }

  form_submited() {
    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromDataError1 = this.state.FromDataError1;
    let FromData = this.state.FromData;
    let data = this.state.FromData.job_title;
    // let splitdata=data.split(',');
    let job_title
    if (data != "") {
      let mapdata = data.map(x => ({ label: x, value: x }));
      job_title = JSON.stringify(mapdata);
    }

    if (FromData.profilename == '') {
      FromDataError['profileimage'] = "";
      this.setState({ FromDataError }, () => {
        console.log(FromDataError)
      })

      FromDataError1['profileimage'] = "";
      this.setState({ FromDataError1 }, () => {
        console.log(FromDataError1)
      })
    }


    if (this.validateForm(FromDataError1)) {
      sta.setState({ loader: "visible" }); ///loader on

      var formdata = new FormData();


      formdata.append("firstname", FromData.firstname || '');
      formdata.append("lastname", FromData.lastname || '');
      formdata.append("phone", FromData.contact_no || '');
      formdata.append("email", FromData.email || '');
      formdata.append("id", FromData.id || '');
      formdata.append("category", FromData.category || '');
      formdata.append("referral_code", this.props.referral_code || '');
      formdata.append("affiliate_code", this.props.affiliate_code || '');
      formdata.append("save_card", FromData.save_card);
      formdata.append("role_id", 4);
      formdata.append("score", FromData.score || '');
      formdata.append("job_title", job_title || '');
      formdata.append("linkedin_resume_url", FromData.linkedin_resume_url || '');
      formdata.append("servicetype", this.props.servicetype || '');
      formdata.append("pagesource", this.props.pagesource || '');
      formdata.append("promo", this.state.promoCode);

      // formdata.append("filename", FromData.filename);
      // formdata.append("base64", FromData.file);
      //   formdata.append("profileimage", FromData.filename);
      formdata.append("profilename", FromData.profilename || "");
      formdata.append("profileimage", FromData.profileimage || "");

      formdata.append("password", FromData.password);
      formdata.append("number", FromData.card_number);
      formdata.append("name", FromData.card_name);
      formdata.append("exp_month", FromData.card_expiry.substring(0, 2));
      formdata.append("exp_year", FromData.card_expiry.slice(-2));
      formdata.append("cvc", FromData.card_cvc);
      formdata.append("card_details", JSON.stringify(FromData.card_details));



      console.log("......request2", formdata);

      let request = {
        end_point: "/registration",
        formdata: formdata,
      };

      console.log("......request", formdata);

      POST_API(request)
        .then((resp) => {
          sta.setState({ loader: "hidden" }); ///loader off

          if (resp.status == "success") {
            let email = resp.data.email;
            let password = resp.code;

            //Auto login code
            var loginformdata = new FormData();
            loginformdata.append("email", email);
            loginformdata.append("password", password);


            let request2 = {
              end_point: '/login',
              formdata: loginformdata

            };
            POST_API(request2).then(resp => {

              if (resp.status == 'success') {
                localStorage.setItem('gs_token', resp.user.token)
                localStorage.setItem('roleID', resp.user.roleID)

                if (resp.user.resume_score != null) {
                  localStorage.setItem('resumeScore', resp.user.resume_score)
                }

                localStorage.setItem('gs_user', JSON.stringify(resp.user))


                if (localStorage.getItem("gs_token") != null) {
                  localStorage.setItem('profile_image', resp.image)

                  localStorage.setItem('dashboardLink', '#/jobseeker-dash')
                  TokenVerify()

                  localStorage.setItem('gs_auth_code', resp.user.auth_code)
                  localStorage.setItem('roleID', resp.user.roleID)
                  localStorage.setItem('get_category', resp.user.job_category)
                  localStorage.setItem('profile_image', resp.image)
                  this.props.history.push('/jobseeker-dash')



                }

              }
              else {
                sta.props.history.push({
                  pathname: "/login",
                  state: {
                    Msg: "Registration Successfully Done, Check your mail to login.",
                    MsgType: "success",
                  },
                });
              }



            }).catch((ee) => {

              console.log('/AutologinERR', ee)
              sta.props.history.push({
                pathname: "/login",
                state: {
                  Msg: "Registration Successfully Done, Check your mail to login.",
                  MsgType: "success",
                },
              });


            });



          } else {
            sta.setState({ loader: "hidden" }); ///loader off
            sta.setState({
              mailmsg: resp.message.email.join(),
              MailMsgType: "danger",
            });
          }
        })
        .catch((ee) => {
          sta.setState({ loader: "hidden" }); ///loader off

          sta.setState({
            Msg: "Something Went Wrong,Please try again.",
            MsgType: "danger",
          });

          console.log("/paymentERR", ee);
        });
    } else {
      console.log("FromDataError1", FromDataError1)
      sta.setState({ Msg: "Fill the form correctly!" });
      sta.setState({ MsgType: "danger" });
    }
  }

  async handaleResumeUpload(e) {

    // e.preventDefault();

    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;
    let category = '';

    // if(this.validateForm(FromDataError))
    // {

    sta.setState({ loader: 'visible', loadingtext: "We are reading your resume ...." })///loader on



    var formdata = new FormData();
    formdata.append("filename", FromData.filename);
    formdata.append("format", FromData.format);
    formdata.append("base64", FromData.file);
    formdata.append("referral_code", FromData.referral_code || '');
    formdata.append("category", "");
    formdata.append("affiliate_code", FromData.affiliate_code || '');



    // console.log(formdata)
    let request = {
      end_point: '/jobseeker-registration',
      formdata: formdata

    };

    console.log('......request', formdata)




    POST_API(request).then(resp => {

      sta.setState({ loader: 'hidden', loadingtext: "" })///loader off

      if (resp.status == 'success') {
        let userDetails = resp.data
        FromData.id = userDetails.id
        FromData.score = userDetails.score
        FromData.job_title = userDetails.job_title


        sta.setState(FromData)
        // sta.setState({ConfirmModal:true})
        // console.log(resp)
      }
      else {
        FromDataError.file = "Invalid Resume";

        sta.setState({ Msg: 'Please Upload avalid resume', MsgType: 'danger', FromDataError })
      }




    }).catch((ee) => {
      sta.setState({ loader: 'hidden', loadingtext: "" })///loader off
      sta.setState({ Msg: 'Something Went Wrong,Please try again later.', MsgType: 'danger' })

      console.log('/js registration ERR', ee)


    });





    // }else
    // {
    //     sta.setState({Msg:'Fill the form correctly!'})
    //     sta.setState({MsgType:'danger'})
    // }





  }

  capchaChange = (value) => {
    console.log("Captcha value:", value);
    let { FromData, FromDataError1, FromDataError } = this.state;
    FromData.recapcha = value;
    FromDataError.recapcha = "";
    FromDataError1.recapcha = "";
    this.setState({ FromData, FromDataError1 }, () => {
      this.validateForm(FromDataError);
      this.validateForm(FromDataError1);
    });
  };

  changeCardValue = (value) => {
    let { FromData, FromDataError1, FromDataError } = this.state;

    if (value.error) {
      this.setState({ FromData, FromDataError1, FromDataError }, () => {
        this.validateForm(FromDataError1);
      });
    } else {
      FromData.card_details = value;
      FromDataError1.card_details = "";
      FromDataError.card_details = "";
      this.setState({ FromData, FromDataError1, FromDataError }, () => {
        // this.validateForm(FromDataError1);
        this.form_submited();
        //this.verifyOTP()
      });
    }
  };
  closeMsg = (duration = 2000) => {
    setTimeout(() => {
      this.setState({ Msg: "", MsgType: "", step1Msg: "", step1MsgType: "" });
    }, duration);
  };

  sendOTP = () => {
    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;

    if (this.validateForm(FromDataError.email)) {
      var formdata = new FormData();
      formdata.append("email_id", FromData.email);
      formdata.append("servicetype", this.state.servicetype || "")

      let request2 = {
        end_point: "/email-otp-send",
        formdata: formdata
      };
      sta.setState({ loader: "visible" });
      POST_API(request2)
        .then((resp) => {
          sta.setState({ loader: "hidden" }); ///loader off

          if (resp.status == "success") {
            let respData = resp.status
            console.log("respData", respData)
            sta.setState({ apiStatus: respData })
            sta.setState({ Msg: "Your one time authentication code is sent to your email, please confirm the same", MsgType: "success" });

            const { step } = this.state
            if (step < 2) {
              this.setState({
                step: step + 1
              })
            }
            this.closeMsg(3000);
          } else {
            let respData = resp.status
            sta.setState({ apiStatus: respData })
            sta.setState({ Msg: "Some thing went wrong", MsgType: "danger" });
            this.setState({ step1Msg: resp.message });
            this.setState({ step1MsgType: "danger" });
            // this.setState({ step1Msg: resp.message.email_id.join() });
            // this.setState({ step1MsgType: "danger" });
            this.closeMsg(3000);
          }
        })
        .catch((ee) => {
          sta.setState({ loader: "hidden" }); ///loader off
          sta.setState({
            Msg: "Something Went Wrong, Please try again later.",
            MsgType: "danger",
          });

          console.log("/js registration ERR", ee);
        });
    } else {
      sta.setState({ Msg: "Please enter valid email", MsgType: "danger" });
    }
  }

  verifyOTP = () => {
    let sta = this;
    let FromDataError1 = this.state.FromDataError1;
    let FromData = this.state.FromData;

    var formdata = new FormData();
    formdata.append("email_id", FromData.email);
    formdata.append("servicetype", this.state.servicetype);
    formdata.append("otp", FromData.otptext);

    let request2 = {
      end_point: "/email-otp-verify",
      formdata: formdata
    };

    POST_API(request2)
      .then((resp) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          let respData = resp.status
          sta.setState({ VerifyapiStatus: respData })
          // sta.setState({ Msg: "One Time Code Verified", MsgType: "success"  });
          //this.form_submited();
        } else {
          let respData = resp.status
          sta.setState({ VerifyapiStatus: respData })
          sta.setState({ Msg: "One Time Code not valid!", MsgType: "danger" });
          FromDataError1.otptext = "One Time Code not valid!"
          sta.setState({ FromDataError1 });
          this.closeMsg()

        }
      })
      .catch((ee) => {
        sta.setState({ loader: "hidden" }); ///loader off
        sta.setState({
          Msg: "Something Went Wrong, Please try again later.",
          MsgType: "danger",
        });

        console.log("/js registration ERR", ee);
      });
  }
  handleSiteCheck() {
    let sta = this;
    const { match, location, history } = this.props;
    const queryParams = new URLSearchParams(location.search)
    this.setState({ pagesource: queryParams.get("pagesource") ? queryParams.get("pagesource") : "" })
    this.setState({ servicetype: queryParams.get("servicetype") ? queryParams.get("servicetype") : "" })

    if (queryParams.get("servicetype")) {
      let formdata = new FormData();
      formdata.append("site_short_name", queryParams.get("servicetype"))
      let request2 = {
        end_point: "/check-all-site",
        formdata: formdata
      };


      POST_API(request2).then((resp) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          let siteDetails = resp.package;
          let serviceDetails = resp.data;
          let promoApplied = resp.package;
          sta.setState({ siteDetails, promoApplied, serviceDetails });
        } else {
          sta.setState({ Msg: "Some thing went wrong", MsgType: "danger" });
        }
      })
        .catch((ee) => {
          sta.setState({ loader: "hidden" }); ///loader off
          sta.setState({
            Msg: "Something Went Wrong, Please try again later.",
            MsgType: "danger",
          });

          console.log("/js registration ERR", ee);
        });
    } else {
      return false
    }
  }


  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      A one time code will be sent to your email. Please use the code to validate you email ID.
    </Tooltip>
  );

  nextStep = () => {
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;
    if (FromData.profilename == '') {
      FromDataError['profileimage'] = "";
      this.setState({ FromDataError }, () => {
        console.log(FromDataError)
      })
    }
    if (this.validateForm(this.state.FromDataError)) {
      this.sendOTP()
      // const { step } = this.state
      // this.setState({
      //     step : step + 1
      // })
    } else {
      console.log(this.state.FromDataError);
      this.setState({ step1Msg: "Fill the form correctly!" });
      this.setState({ step1MsgType: "danger" });
      this.closeMsg();
    }
  }

  prevStep = () => {
    const { step } = this.state
    this.setState({
      step: step - 1
    })
    this.setState({ step1Msg: "" })
  }


  render() {
    let { FromData, FromDataError, servicetype, FromDataError1 } = this.state;

    let inputValues = { FromData }
    let formErr = { FromDataError }
    let formErr1 = { FromDataError1 }
    switch (this.state.step) {
      case 1:
        return <JobSekerBasicInfo
          nextStep={this.nextStep}
          handaleChange={this.handaleChange}
          liinkedinResumeUpload={this.liinkedinResumeUpload}
          inputValues={inputValues}
          formErr={formErr}
          // formErr1={formErr1}
          servicetype={servicetype}
          // VerifyapiStatus={this.state.VerifyapiStatus}
          step1Msg={this.state.step1Msg}
          step1MsgType={this.state.step1MsgType}
          loader={this.state.loader}
          loadingtext={this.state.loadingtext}
          handaleSubmit={this.handaleSubmit}
          handlePhoneChange={this.handlePhoneChange}
          isMailValid={this.state.isMailValid}
          {...this.props}
        />
      case 2:
        return <JobSeekerOTPandPass
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          handaleChange={this.handaleChange}
          inputValues={inputValues}
          siteDetails={this.state.siteDetails}
          // formErr={formErr}
          formErr1={formErr1}
          servicetype={servicetype}
          VerifyapiStatus={this.state.VerifyapiStatus}
          verifyOTP={this.verifyOTP}
          sendOTP={this.sendOTP}
          handaleSubmit={this.handaleSubmit}
          capchaChange={this.capchaChange}
          loader={this.state.loader}
          Msg={this.state.Msg}
          MsgType={this.state.MsgType}
          changeCardValue={this.changeCardValue}
          isPasswordValid={this.state.isPasswordValid}
          promoApplied={this.state.promoApplied}
          validatePromo={this.validatePromo}
          // cleanPromoApplied={this.cleanPromoApplied}
          applyCheckbox={() => {
            this.setState({ hasPromo: !this.state.hasPromo }, () => {
              this.cleanPromoApplied()
            })
          }}
          sta={this.state}

        />
    }

    // return (
    //   <Container>
    //     <Form onSubmit={this.handaleSubmit}>
    //       <Row>
    //         <Col lg={12} className="sponsorfrom p-4">
    //           <Loader loadmsg="please wait" visibility={this.state.loader} />
    //           {this.props.msgAffilicate!==''? (<><FlashMess m={this.props.msgAffilicate} mtype={this.props.MsgAffiliateType?this.props.MsgAffiliateType:'success'} /></>):(<></>)}
    //            {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
    //           <Form.Row>
    //             <Form.Group controlId="formBasicEmail" as={Col}>
    //               <Form.Label>
    //                 First Name
    //                 <span className="text-danger"> *</span>
    //               </Form.Label>
    //               <Form.Control
    //                 type="text"
    //                 name="firstname"
    //                 value={FromData.firstname}
    //                 onChange={this.handaleChange}
    //                 placeholder="Enter  first name"
    //                 validaterule={["required"]}
    //                 validatemsg={["This field is required"]}
    //               />
    //               <p style={{ color: "red" }}>
    //                 {this.state.FromDataError.firstname == "false"
    //                   ? ""
    //                   : this.state.FromDataError.firstname}
    //               </p>
    //             </Form.Group>

    //             <Form.Group controlId="formBasicEmail" as={Col}>
    //               <Form.Label>
    //                 Last Name
    //                 <span className="text-danger"> *</span>
    //               </Form.Label>
    //               <Form.Control
    //                 type="text"
    //                 name="lastname"
    //                 value={FromData.lastname}
    //                 onChange={this.handaleChange}
    //                 placeholder="Enter  last name"
    //                 validaterule={["required"]}
    //                 validatemsg={["This field is required"]}
    //               />
    //               <p style={{ color: "red" }}>
    //                 {this.state.FromDataError.lastname == "false"
    //                   ? ""
    //                   : this.state.FromDataError.lastname}
    //               </p>
    //             </Form.Group>
    //           </Form.Row>
    //           <Form.Row>
    //             <Form.Group controlId="formBasicEmail" as={Col} className="field_box">
    //               <div className="otp_field">
    //               <Form.Label>
    //                 Email
    //                 <span className="text-danger"> *</span>
    //               </Form.Label>
    //               <Form.Control
    //                 type="email"
    //                 name="email"
    //                 value={FromData.email}
    //                 onChange={this.handaleChange}
    //                 placeholder="Enter  email"
    //                 validaterule={["required", "isEmail"]}
    //                 validatemsg={["This field is required", "Invalid Email"]}
    //               />
    //               <p style={{ color: "red" }}>
    //                 {this.state.FromDataError.email == "false"
    //                   ? ""
    //                   : this.state.FromDataError.email}
    //               </p>
    //               </div>

    //             <Button type="button" 
    //                 variant="otp_btn"
    //                 onClick={this.sendOTP} className="otp_btn">
    //               <OverlayTrigger
    //                 placement="top"
    //                 delay={{ show: 250, hide: 400 }}
    //                 overlay={this.renderTooltip}>
    //                   <i className="fa-solid fa-circle-question"></i>
    //               </OverlayTrigger>  Confirm Email</Button>
    //             </Form.Group>

    //             <Form.Group controlId="formBasicEmail" as={Col} className="field_box">
    //               <div className="otp_field">
    //               <Form.Label>
    //                 One Time Code
    //                 <span className="text-danger"> *</span>
    //               </Form.Label>
    //               <Form.Control
    //                 type="text"
    //                 name="otptext"
    //                 value={FromData.otptext}
    //                 onChange={this.handaleChange}
    //                 placeholder="Enter one time code"
    //                 validaterule={["required"]}
    //                 validatemsg={["This field is required", "Invalid one time code"]}
    //               />
    //               <p style={{ color: "red" }}>
    //                 {this.state.FromDataError.otptext == "false"
    //                   ? ""
    //                   : this.state.FromDataError.otptext}
    //               </p>
    //               </div>
    //             <Button type="button"
    //              onClick={this.verifyOTP}
    //              variant="valid_otp_btn"
    //              className={
    //               this.state.VerifyapiStatus == "success"
    //                 ? "verified_otp_btn"
    //                 : this.state.VerifyapiStatus == "failed"
    //                 ? "error_btn_fix"
    //                 : "valid_otp_btn"
    //             }
    //              disabled={this.state.VerifyapiStatus == "success"}
    //              >{this.state.VerifyapiStatus == "success" ? "Email Verified" : "Verify Email"}</Button>
    //             </Form.Group>

    //             </Form.Row>

    //             <Form.Row>
    //             <Form.Group controlId="formBasicEmail" as={Col}>
    //               <Form.Label>
    //                 Phone
    //                 <span className="text-danger"> *</span>
    //               </Form.Label>
    //               <Form.Control
    //                       // style={{boxShadow:"none",borderLeft:"0", borderColor: "#ced4da"}}
    //                       type="number"
    //                       name="contact_no"
    //                       onChange={this.handaleChange}
    //                       value={FromData.contact_no}
    //                       placeholder="Ex- 999-999-9999"
    //                       validaterule={["required", "phone"]}
    //                       validatemsg={[
    //                         "This field is required",
    //                         "Invalid Phone number",
    //                       ]}
    //                     />

    //                 {/* <InputGroup>
    //                         <InputGroup.Text style={{backgroundColor:"white",borderRadius: "4px 0 0 4px",borderRight: "0"}}>+1 </InputGroup.Text>
    //                         <Form.Control
    //                               style={{boxShadow:"none",borderLeft:"0", borderColor: "#ced4da"}}
    //                               type="number"
    //                               name="contact_no"
    //                               onChange={this.handaleChange}
    //                               value={FromData.contact_no}
    //                               placeholder="Ex- 999-999-9999"
    //                               validaterule={["required", "phone"]}
    //                               validatemsg={[
    //                                 "This field is required",
    //                                 "Invalid Phone number",
    //                               ]}
    //                             />
    //                       </InputGroup> */}
    //               <p style={{ color: "red" }}>
    //                 {this.state.FromDataError.contact_no == "false"
    //                   ? ""
    //                   : this.state.FromDataError.contact_no}
    //               </p>
    //             </Form.Group>

    //             <Form.Group controlId="formBasicEmail" as={Col}>
    //                 <Form.Label>{"Profile Image (Optional)"}</Form.Label>
    //                 {/* <span className="text-danger"> *</span> */}
    //                 <Form.File
    //                   id="custom-file-translate-scss"
    //                   validaterule={["required", 
    //                   "mime|jpeg/png"]}
    //                   validatemsg={["This field is required"]}
    //                   name="profileimage"
    //                   onChange={this.handaleChange}
    //                   label={FromData.profilename || "Select file"}
    //                   lang="en"
    //                   custom
    //                 />
    //                 {/* <p style={{ color: "red" }}>
    //                   {this.state.FromDataError.profileimage == "false"
    //                     ? ""
    //                     : this.state.FromDataError.profileimage}
    //                 </p> */}
    //               </Form.Group>
    //             {!["CB","RL","HRC","JNH"].includes(this.props.pagesource) ? (
    //               <Form.Group controlId="formBasicEmail" as={Col}>
    //                 <Form.Label>Upload CV (.pdf)</Form.Label>
    //                 <span className="text-danger"> *</span>
    //                 <Form.File
    //                   id="custom-file-translate-scss"
    //                   validaterule={["required", "mime|pdf"]}
    //                   validatemsg={["This field is required"]}
    //                   name="file"
    //                   onChange={this.handaleChange}
    //                   label={FromData.filename || "Select file"}
    //                   lang="en"
    //                   custom
    //                 />
    //                 <p style={{ color: "red" }}>
    //                   {this.state.FromDataError.file == "false"
    //                     ? ""
    //                     : this.state.FromDataError.file}
    //                 </p>
    //               </Form.Group>
    //             ) : (<></>)}
    //           </Form.Row>
    //           <Form.Row>
    //             <Form.Group controlId="formBasicEmail" as={Col}>
    //               <Form.Label>Password</Form.Label>
    //               <Form.Control
    //                 type="password"
    //                 name="password"
    //                 onChange={this.handaleChange}
    //                 placeholder="Your Password"
    //                 validaterule={["required", "password"]}
    //                 validatemsg={[
    //                   "This field is required",
    //                   "password should be 6 charecter",
    //                 ]}
    //               />
    //               <p style={{ color: "red" }}>
    //                 {FromDataError.password == "false"
    //                   ? ""
    //                   : FromDataError.password}
    //               </p>
    //             </Form.Group>
    //             <Form.Group controlId="formBasicEmail" as={Col}>
    //               <Form.Label>Confirm Password</Form.Label>
    //               <Form.Control
    //                 type="password"
    //                 name="confirm_password"
    //                 onChange={this.handaleChange}
    //                 placeholder="Confirm Password"
    //                 validaterule={[
    //                   "required",
    //                   "password",
    //                   "same|" + FromData.password,
    //                 ]}
    //                 validatemsg={[
    //                   "This field is required",
    //                   "password should be 6 charecter",
    //                   "Confirm Password shoul be same as password",
    //                 ]}
    //               />
    //               <p style={{ color: "red" }}>
    //                 {FromDataError.confirm_password == "false"
    //                   ? ""
    //                   : FromDataError.confirm_password}
    //               </p>
    //             </Form.Group>
    //           </Form.Row>
    //           <Form.Row>
    //             <Form.Group controlId="formBasicEmail" as={Col}>
    //               <ReCAPTCHA
    //                 sitekey="6Lf7d4UjAAAAAPwzzA6RVnGmq6KAWQkHwjz3KtXy"
    //                 onChange={this.capchaChange}
    //               />


    //                 <p style={{ color: "red" }}>
    //                     {this.state.FromDataError.recapcha == "false"
    //                       ? ""
    //                       : this.state.FromDataError.recapcha}
    //                   </p>


    //               </Form.Group>

    //               </Form.Row>


    //           <Button
    //             variant="primary"
    //             type="submit"
    //             className="btn-main tabformbutton px-5"
    //             disabled={this.state.loader != "hidden" ? true : false}
    //           >
    //             Submit
    //           </Button>
    //         </Col>
    //       </Row>
    //     </Form>
    //   </Container>
    // );
  }
}

export default withRouter(JobSeekerSignup);
