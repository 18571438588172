import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Table,
  Modal,
} from "react-bootstrap";
import TokenVerify, { FlashMess, GET_API, POST_API, Loader } from "../../Helpers/Helpers";
import "./resumeupload.css";
import img4725573 from "../images/4725573.png"


export default class ResumeUpload extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loader: 'hidden',
      show: this.props.modalShow,
      FromData: {
        file: "",
        format: "",
        filename: "",

      },
      error: '',
      getFileName: '',
    }

    this.handaleChange = this.handaleChange.bind(this);
  }

  componentDidMount() {
    console.log(this.props);
  }


  handaleChange(data) {
    let sta = this;


    console.log("handle change", data.target.name);

    let name = data.target.name;
    let value = "";
    let FromData = this.state.FromData;

    if (name == "file") {
      console.log("a")
      let file = data.target.files[0];
      value = file;
      if (file?.type == "application/pdf") {
        this.toBase64(data.target.files[0]).then((resp) => {
          console.log("b", data.target.files[0].name)

          FromData[name] = resp.split(",")[1];
          FromData.filename = file.name;
          FromData.format = file.type;
          this.setState({ getFileName: file.name });
        });
      }
      else {
        this.setState({ error: "Please upload a PDF resume" })
      }
    }
    this.setState({ FromData });
    console.log(FromData);
  }
  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });


  uploadResume = () => {
    let sta = this
    let { FromData } = this.state;
    if (FromData.file != '') {
      sta.setState({ loader: "" }); ///loader on

      var formdata = new FormData();
      formdata.append("filename", FromData.filename);
      formdata.append("format", FromData.format);
      formdata.append("base64", FromData.file);



      console.log("......request2", formdata);

      let request = {
        end_point: "/update-resume",
        token: localStorage.getItem("gs_token"),
        formdata: formdata,
      };

      console.log("......request", formdata);

      POST_API(request)
        .then(async (resp) => {
          // sta.setState({ loader: "hidden" }); ///loader off

          if (resp.status == "success") {
            localStorage.setItem("gs_auth_code", resp.ml_auth_code);
            await TokenVerify();
            sta.setState({ loader: "hidden" }); ///loader off

            // setTimeout(() => {
            //   window.location.reload();
            // }, 3000);
            // setTimeout(() => {
            //   this.props.switchHandaleModal = true;
            // }, 3000);
            await this.props.userdataUpdate();
            this.props.switchHandaleModal(this.props.sectionClick);
            this.props.switchResumeModal();
          } else {
            sta.setState({ loader: "hidden" });
            this.setState({ error: "Please! upload a valid Resume" })
          }

        }).catch((ee) => {
          sta.setState({ loader: "hidden" })
          console.log('/resumeUpload error', ee)



        });


    }
    else {
      alert("Choose a file first")
    }

  }
  render() {
    let { FromData, error } = this.state
    console.log(FromData);
    return (
      <div id="resumeUpload">
        <Modal show={this.props.modalShow}
          backdrop="static"
          className='modal_upload'
          keyboard={false}
          onHide={this.props.switchResumeModal}
          id="modal"
        >
          {/* <Modal.Header closeButton>
            <Modal.Title>Upload Resume</Modal.Title>
          </Modal.Header> */}
          <Modal.Body style={{ minHeight: "250px" }}>
            <Loader loadmsg="please wait" visibility={this.state.loader} />
            {/* <Form.Group controlId="formBasicEmail" as={Col}> */}
            {/* <Form.Label>Upload CV (.pdf)</Form.Label>
                    <span className="text-danger"> *</span>
                    <input
                        type="file"
                      id="custom-file-translate-scss"
                      validaterule={["required", "mime|pdf"]}
                      validatemsg={["This field is required"]}
                      name="file"
                      className="form-control"
                      onChange={this.handaleChange}
                      label={FromData.filename || "Select file"}
                      lang="en"
                      custom
                    />
                    
                  </Form.Group> */}
            {/* <p>Congratulations for purchasing the "All in one package", you will get all the services of $1299 for now $99. Click below to upload your resume to start.</p> */}
            <div className="upload_file_area text-center">
              <label className="upload_file_label">
                <input
                  type="file"
                  id="custom-file-translate-scss"
                  validaterule={["required", "mime|pdf"]}
                  validatemsg={["This field is required"]}
                  name="file"
                  accept='application/pdf'
                  className="form-control"
                  onChange={this.handaleChange}
                  label={FromData.filename || "Select file"}
                  lang="en"
                  custom
                />
                <img width="120" src={img4725573} className="img-fluid" />
              </label>
              <div className="upload_info">
                <h4>Click the icon to Upload your Resume.</h4>
                {/* <p className="mb-0">File size should not be exceed 2mb</p> */}
                {/* {this.state.getFileName} */}
                {FromData.filename && <p style={{ color: "green", fontWeight: "bold" }}>{FromData.filename}</p>}
                {error && <p style={{ color: "red", fontWeight: "bold" }}>{error}</p>}
              </div>
              <div className="upload_action row">
                <div className="col pr-2">
                  <Button variant="primary" onClick={this.props.switchResumeModal} className="btn btn-danger tabformbutton btn-block btn_total">Cancel</Button>
                </div>
                <div className="col pl-2">
                  <Button onClick={this.uploadResume} variant="primary" type="submit" className="btn btn-primary tabformbutton btn-block btn_total">Upload</Button>
                </div>
              </div>
            </div>

          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="primary" onClick={this.props.switchResumeModal} className="btn-main tabformbutton mr-2">Cancel</Button>
            <Button onClick={this.uploadResume} variant="primary" type="submit" className="btn-main tabformbutton">Upload</Button>

          </Modal.Footer> */}
        </Modal>
      </div>
    )
  }
}
