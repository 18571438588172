import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import TokenVerify, { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import JobseekercPaymentCredit from "../Jobseeker/JobseekercPaymentCredit";
import LineWave from 'react-loader-spinner'
import reducer from "../../Reducers/index";
import getSettings from "../../Actions/getSettings";
import top_wallet from "../images/top_wallet.png";
import './css/credit.css';


const UserCreditBalance = (props) => {
  let dispatch = useDispatch();
  const [userData, setUserdata] = useState({});
  const [creditBalance, setcreditBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [lowbalance, setLoWbalance] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [PaymentModal, setPaymentModal] = useState(false);
  const [amount, setAmount] = useState(0);
  const [credit, setCredit] = useState(0);
  const refreshBalance = useSelector((state) => state?.refreshBalance);
  const setSettings = useSelector((state) => state.setSettings);

  useEffect(() => {
    if(localStorage.getItem("roleID") == 4)
    {

    
    let ud = localStorage.getItem("gs_user")
    ud = JSON.parse(ud)
    setUserdata(ud)
    GET_API({ end_point: '/get-app-settings/low_credit_indicate', token: localStorage.getItem("gs_token") })
      .then((resp) => {
        if (resp.status == "success") {
          let payload = {
            lowCreditIndicate: resp.data.value
          }
          dispatch(getSettings(payload))
          dispatch({ type: "UPDATE-BALANCE", refreshBalance: true })

        }


      })
      .catch((error) => {
        console.log(error)
      })

      setInterval(() => {
        dispatch({ type: "UPDATE-BALANCE", refreshBalance: true })
        
      }, 120000);
    }
  }, [])

  useEffect(() => {
    console.log("refreshBalance", refreshBalance, setSettings)
    if (refreshBalance == true) {
      setLoading(true)
      dispatch({ type: "UPDATE-BALANCE", refreshBalance: false })

      POST_API({
        end_point: "/get-jobseeker-balance",
        token: localStorage.getItem("gs_token"),
      }).then((resp) => {
        setLoading(false)
        if (resp.status == "success") {
          dispatch({ type: "CREDIT-BALANCE", creditBalance: resp.balance })
          setcreditBalance(resp.balance)
          setLoWbalance(resp.balance < setSettings.lowCreditIndicate)

        }
      })
        .catch((err) => {
          setLoading(false)
          console.log("Jobseeker credit balance error", err)
        })
    }


  }, [refreshBalance])

  let drawerOpen = () => {
    setDrawer(!drawer)
  }

  let purchaseCredits = (e) => {
    if (e.target.dataset.value) {
      let credit = e.target.dataset.value;
      let amount = credit * 1; // 1 represent $1 = 1 credit
      // Open payment popup
      setAmount(amount)
      setCredit(credit)
      setPaymentModal(true)
    }
  }

  let handleClose = () => {
    setPaymentModal(!PaymentModal);
    if (!PaymentModal == false) {
      setAmount(0)
      setCredit(0)
    }
  }


  return (
    <>
      {localStorage.getItem("roleID") == 4 && (<>

        <div className='d-flex align-items-center mr-auto ml-3 amt_drop' style={{
          color
            : lowbalance ? "red" : "inherit"
        }}>
          <div className='d-flex align-items-center mr-auto ml-3 amt_drop' style={{ cursor: "pointer" }} onClick={drawerOpen}>
            <img src={top_wallet} className="top_wallet" />
            <b>
              {loading ? (
                <LineWave
                  height="25px"
                  width="25px"
                  color="#93ddea"
                  ariaLabel="line-wave"
                  wrapperStyle={{}}
                  wrapperClass="loaderSpin"
                  visible={loading}
                  firstLineColor=""
                  middleLineColor=""
                  lastLineColor=""
                />
              ) : (
                `${creditBalance} Credits`
              )}
            </b>
          </div>
          <div style={{ width: '380px' }}>
            <span>Credits are currently assigned and can be purchased for Recruiter Direct and HR Direct only</span>
          </div>

          <div className='details_drop' style={{ display: drawer ? "" : "none" }}>
            <span className='close_details_drop' onClick={drawerOpen}>X</span>
            <div className='cBal d-flex flex-column text-center' style={{
              color
                : lowbalance ? "red" : "inherit"
            }}>{creditBalance} <span>Credits</span></div>
            <div className='amt_list d-flex' onClick={purchaseCredits}>
              <span className='cp' data-value="10">+ 10</span>
              <span className='cp' data-value="50">+ 50</span>
              <span className='cp' data-value="100">+ 100</span>
            </div>
            <a href='#/jobseeker-credit-transactions' className='cr_trans' onClick={drawerOpen}>Credits Transactions</a>
          </div>


        </div>

        {(userData != '' && PaymentModal == true) ? (<>

          <JobseekercPaymentCredit {...props} userDetails={userData} amount={amount} credit={credit} show={PaymentModal} close={handleClose} />
        </>) : (<></>)}

      </>)}</>
  )
}


export default UserCreditBalance;
