import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Table,
  Modal,
} from "react-bootstrap";
import TokenVerify, { FlashMess, GET_API, POST_API, Loader } from "../../Helpers/Helpers";
import "./resumeupload.css";
import logo_1 from "../../images/logo1.png";
import logo_2 from "../../images/logo2.png";
import logo_3 from "../../images/logo3.png";
import logo_4 from "../../images/logo4.png";
import logo_5 from "../../images/logo5.png";
import logo_6 from "../../images/logo6.png";
import logo_7 from "../../images/logo7.png";
import logo_8 from "../../images/logo8.png";
import logo_9 from "../../images/logo9.png";
import logo_10 from "../../images/logo10.png";
import logo_11 from "../../images/logo11.png";
import logo_12 from "../../images/logo12.png";


export default class PaymentConfirmModel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loader: 'hidden',
      show: this.props.modalShow,

    }


  }

  componentDidMount() {

  }
  logoManage = (packageId) => {
    switch (packageId) {
      case 1: return logo_12;
      case 2: return logo_6;
      case 3: return logo_1;
      case 4: return logo_2;
      case 5: return logo_5;
      case 6: return logo_7;
      case 7: return logo_3;
      case 8: return logo_4;
      case 9: return logo_8;
      case 10: return logo_10;
      case 11: return logo_11;


        break;

      default:
        break;
    }
  }



  render() {
    let { FromData } = this.state
    return (
      <div id="resumeUpload">
        <Modal show={this.props.modalShow}
          backdrop="static"
          className='modal_upload'
          keyboard={false}
          onHide={this.props.switchResumeModal}
          id="modal"
        >
          {/* <Modal.Header closeButton>
            <Modal.Title>Upload Resume</Modal.Title>
          </Modal.Header> */}
          <Modal.Body style={{ minHeight: "250px" }}>
            <Loader loadmsg="please wait" visibility={this.state.loader} />

            <div className="upload_file_area text-center">
              <label >
                <img width="120" src={this.logoManage(this.props.pack.package_id)} className="img-fluid" />
              </label>
              <div className="upload_info">
                <span>You are one step away from purchasing the <strong>{this.props.pack.package_name}</strong> service worth <strong>${this.props.pack.offerPrice}</strong>. Please proceed to pay.</span>
              </div>
              <div className="upload_action row">
                {/* <div className="col pr-2">
                  <Button variant="primary" onClick={this.props.switchResumeModal} className="btn btn-danger tabformbutton btn-block">Cancel</Button>
                  </div> */}
                <div className="col pl-2">
                  <Button onClick={() => {
                    this.props.switchConfirmModal()
                    this.props.confirm()
                  }} variant="primary" type="submit" className="btn btn-primary tabformbutton btn-block btn_total">Proceed</Button>
                </div>
              </div>
            </div>

          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
