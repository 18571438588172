import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import TokenVerify, { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';
import { PayPal } from '../Config';



import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Table,
  Modal,
} from "react-bootstrap";
var _ = require('lodash');

export default class JobseekerPayment extends React.Component {
  state = {
    key: "strip",
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
    amount: '',
    Msg: '',
    MsgType: '',
    loader: 'hidden',
    show: '',
    save_card: '',
    FromData: {},
    card_details: [],
    card_id_attr: '',
    button_text: 'Show My Cards',
    card_exits: false,
    FromDataError:
    {
      cvc: 'false',
      expiry: 'false',
      name: "false",
      number: "false",
      amount: '',
    },
    paydisabled: false,
    packageName: {}


  };

  tabsctive(j) {
    this.setState({ key: j });



  }

  onValueChange(event) {
    console.log("event", event.target.value);
    this.setState({
      card_id_attr: event.target.value,
    });

    document.getElementById("card_id").value = event.target.value;
  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
      }
    }

    return valid;
  };


  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    let FromDataError = this.state.FromDataError;

    if (name == 'save_card' && e.target.checked) {
      this.setState({ [name]: 1 });

    } else {

      var valid_obj = {
        value: value,
        rules: e.target.getAttribute("validaterule"),
        message: e.target.getAttribute("validatemsg")
      }



      validation(valid_obj).then((error) => {
        FromDataError[name] = error
        this.setState({ FromDataError })
      })

      this.setState({ [name]: value }, () => {
        //console.log(this.state)
      });

    }



  };

  clickSubmit(id) {
    if (id == 'strip') {
      this.handaleSubmit()
    }
    else {
      document.getElementById(id).submit();
    }




  }

  savedCardDetails = () => {
    this.setState({ loader: '' })
    let requestCard = {
      end_point: '/get-saved-cards',
      token: localStorage.getItem("gs_token")
    };
    GET_API(requestCard).then((resp) => {
      this.setState({ loader: 'hidden' })///loader off
      if (resp.data.length > 0) {
        this.setState({
          card_details: resp.data,
          card_exits: true,
        });
      }
      console.log('savedcard', this.state.card_details);
    }).catch(() => {
      this.setState({ loader: 'hidden' })///loader off
      console.log('savedcard error');
    })
  }



  handaleSubmit = (e) => {
    //e.preventDefault();

    let sta = this;
    let { FromData, FromDataError } = this.state


    //User details and amount 
    if (this.state.amount == '' && this.props.paymentDetails.offerPrice == 0) {
      FromDataError.amount = 'false'
      this.setState({ FromDataError }, () => {
        //console.log(FromData)
      })
    }


    console.log(FromData)

    if (this.state.card_exits == false) {

      if (this.validateForm(FromDataError)) {
        sta.setState({ loader: 'visible', paydisabled: true })///loader on

        var formdata = new FormData();
        formdata.append("card_name", this.state.name);
        formdata.append("card_number", this.state.number);
        formdata.append("card_cvc", this.state.cvc);
        formdata.append("card_exp_month", this.state.expiry.substring(0, 2));
        formdata.append("card_exp_year", this.state.expiry.slice(-2));
        formdata.append("description", "Jobseeker Payment for buying package");
        if (this.state.save_card == 1) {
          formdata.append("save_card", 1);
        }
        if (this.props.paymentDetails.offerPrice != 0) {

          formdata.append("amount", this.props.paymentDetails.offerPrice);


        }
        else {
          formdata.append("amount", this.state.amount);

        }

        formdata.append("package_id", this.props.paymentDetails.package_id);
        formdata.append("promo", this.props.promoCode);
        formdata.append("pagesource", localStorage.getItem("pagesource"));







        let request = {
          end_point: '/buy-package',
          formdata: formdata,
          token: localStorage.getItem("gs_token")

        };

        console.log('......request', formdata)




        POST_API(request).then(resp => {

          sta.setState({ loader: 'hidden' })///loader off


          if (resp.status == 'success') {
            localStorage.removeItem("pagesource")
            localStorage.removeItem("servicetype")
            sta.setState({ Msg: `Congratulations you have purchased the ${this.props.paymentDetails.package_name}, you will now be taken to the Dashboard, where you can access your service`, MsgType: 'success', paydisabled: true })

            if (resp.ml_auth_code != null && resp.ml_auth_code != '' && resp.ml_auth_code != undefined) {
              localStorage.setItem('gs_auth_code', resp.ml_auth_code)
            }
            TokenVerify()
            // let userD = localStorage.getItem("gs_user")
            // userD = JSON.parse(userD)
            // userD.package_id = this.props.paymentDetails.package_id
            // userD.is_paid = "1"
            // localStorage.setItem("gs_user",JSON.stringify(userD))

            setTimeout(() => {
              this.setState({ Msg: '' })
              sta.props.history.push({
                pathname: '/jobseeker-dash',
                state: { Msg: '', Msgtype: '' }

              })
            }, 2000);
            if (this.props.reloadAfterPay) {
              window.location.reload()
            }





          }
          else {


            sta.setState({ Msg: resp.message, MsgType: 'danger', paydisabled: false })




          }


        }).catch((ee) => {
          sta.setState({ loader: 'hidden', paydisabled: false })///loader off


          sta.setState({ Msg: 'Something Went Wrong,Please try again later.', MsgType: 'danger' })
          console.log('/paymentERR', ee)


        });
      }
      else {
        sta.setState({ Msg: 'Enter Valid Payment Details' })
        sta.setState({ MsgType: 'danger' })
      }
    } else {
      if (this.state.card_id_attr != '') {
        sta.setState({ loader: 'visible', paydisabled: true })///loader on

        var formSavedata = new FormData();
        if (this.props.paymentDetails.offerPrice != 0) {

          formSavedata.append("amount", this.props.paymentDetails.offerPrice);


        }
        else {
          formSavedata.append("amount", this.state.amount);

        }
        formSavedata.append("card_id", this.state.card_id_attr);
        formSavedata.append("description", "");
        formSavedata.append("package_id", this.props.paymentDetails.package_id);
        formSavedata.append("promo", this.props.promoCode);
        formSavedata.append("pagesource", localStorage.getItem("pagesource"));



        let requestData = {
          end_point: '/buy-package',
          formdata: formSavedata,
          token: localStorage.getItem("gs_token")

        };

        console.log('......request', formSavedata)



        POST_API(requestData).then(resp => {

          sta.setState({ loader: 'hidden' })///loader off


          if (resp.status == 'success') {
            localStorage.removeItem("pagesource")
            localStorage.removeItem("servicetype")
            sta.setState({ Msg: `Congratulations you have purchased the ${this.props.paymentDetails.package_name}, you will now be taken to the Dashboard, where you can access your service`, MsgType: 'success' })
            if (resp.ml_auth_code != null && resp.ml_auth_code != '' && resp.ml_auth_code != undefined) {
              localStorage.setItem('gs_auth_code', resp.ml_auth_code)
            }
            TokenVerify()
            // let userD = localStorage.getItem("gs_user")
            // userD = JSON.parse(userD)
            // userD.package_id = this.props.paymentDetails.package_id
            // userD.is_paid = "1"
            // localStorage.setItem("gs_user",JSON.stringify(userD))

            setTimeout(() => {
              this.setState({ Msg: '' })
              sta.props.history.push({
                pathname: '/jobseeker-dash',
                state: { Msg: '', Msgtype: '' }

              })
            }, 1000);
            if (this.props.reloadAfterPay) {
              window.location.reload()
            }





          }
          else {


            sta.setState({ Msg: resp.message, MsgType: 'danger', paydisabled: false })




          }


        }).catch((ee) => {
          sta.setState({ loader: 'hidden', paydisabled: false })///loader off


          sta.setState({ Msg: 'Something Went Wrong,Please try again later.', MsgType: 'danger' })
          console.log('/paymentERR', ee)


        });

      } else {
        sta.setState({ Msg: 'Enter Valid Payment Details' })
        sta.setState({ MsgType: 'danger' })
      }


    }

  }



  componentDidMount() {


    this.savedCardDetails();

  }

  handleClick = () => {
    if (this.state.card_exits) {
      this.setState({
        card_exits: false,
        card_id_attr: '',
        button_text: 'Show My Cards'
      });
    } else {
      this.setState({
        card_exits: true,
        card_id_attr: '',
        button_text: 'Add Card'
      });
    }
  }




  render() {
    console.log("this.state", this.props.packageName)


    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
          backdrop="static"
          keyboard={false}
          onHide={this.props.close}
          id="modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Payment<p style={{ fontSize: "10px", margin: "0" }}>We use stripe for payments</p></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ minHeight: "250px" }}>
            <Loader loadmsg="please wait" visibility={this.state.loader} />

            <div className="tabpanelOneForm" style={{ lineHeight: '33px' }}>
              <p className="text-center" style={{ color: '#108193' }}><b>You are about to make a payment of ${this.props.paymentDetails.offerPrice}.</b></p>
              <Button variant="primary" onClick={this.handleClick} className="btn-main mr-2 mt-3 mb-3">{this.state.card_exits ? 'Add New Card' : 'Show My Cards'}</Button>
              <Row className="mt-4 mb-4" style={{ display: this.state.card_exits ? "none" : "block" }}>
                <Col md={12} className="mb-5">
                  <Cards
                    cvc={this.state.cvc}
                    expiry={this.state.expiry}
                    focused={this.state.focus}
                    name={this.state.name}
                    number={this.state.number}
                  />
                </Col>
                <Col md={12} className="">
                  {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
                  {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}


                  <form id="strip" onSubmit={this.handaleSubmit}>
                    <Form.Group controlId="formBasicCardNo" className="" >
                      <input
                        type="hidden"
                        name="card_id"
                        id="card_id"
                        value={this.state.card_id_attr}
                      />
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Name on Card"
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                        validaterule={['required']}
                        validatemsg={['Card Name required']}
                      />
                      <p style={{ color: 'red' }}>{this.state.FromDataError.name == 'false' ? '' : this.state.FromDataError.name}</p>
                    </Form.Group>

                    <Row>
                      <Col md={6} className="">
                        <Form.Group controlId="formBasicCardNo">
                          <Form.Control
                            type="tel"
                            name="number"
                            placeholder="Card Number"
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                            maxLength="16"
                            validaterule={['required', 'max|16', 'min|14']}
                            validatemsg={['Card no required', 'Card number must be 14-16 character long', 'Card number must be 14-16 character long']}
                          />
                          <p style={{ color: 'red' }}>{this.state.FromDataError.number == 'false' ? '' : this.state.FromDataError.number}</p>


                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group controlId="formBasicCardNo">
                          <Form.Control
                            type="text"
                            name="expiry"
                            placeholder="MMYY"
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                            maxLength="4"
                            validaterule={['required', 'min|4', 'expire', 'numeric']}
                            validatemsg={['Card expire required', 'Card Expiry field must contain 4 digit', 'Expiry month and year should be a future date.', 'Invalid Number']}
                          />
                          <p style={{ color: 'red' }}>{this.state.FromDataError.expiry == 'false' ? '' : this.state.FromDataError.expiry}</p>
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group controlId="formBasicCardNo">

                          <Form.Control
                            type="text"
                            name="cvc"
                            placeholder="CVC"
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                            maxLength="3"
                            validaterule={['required', 'min|3', 'max|3', 'numeric']}
                            validatemsg={['Card expire required', 'CVC field must contain 3 digit', 'CVC field must contain 3 digit', 'Invalid Number']}
                          />
                          <p style={{ color: 'red' }}>{this.state.FromDataError.cvc == 'false' ? '' : this.state.FromDataError.cvc}</p>
                        </Form.Group>
                      </Col>
                    </Row>


                    {(this.props.paymentDetails.offerPrice == 0) ? (<>

                      <Form.Group controlId="formBasicCardNo">
                        <Form.Label>Sponsor Amount ($)</Form.Label>
                        <Form.Control
                          type="number"
                          name="amount"
                          validaterule={['required', 'numeric', 'priceValue|100', 'maxpriceValue|900000']}
                          validatemsg={['Amount required', 'Invalid Number', 'Minimum 100 $ need to sponsor', 'Amount is to long']}
                          // placeholder="Sponsor Amount"
                          placeholder="Please enter the amount you would like to sponsor"
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                        />
                        <p style={{ color: 'red' }}>{this.state.FromDataError.amount == 'false' ? '' : this.state.FromDataError.amount}</p>

                      </Form.Group>


                    </>) : (<>

                      <Form.Group controlId="formBasicCardNo" className="pr-0" as={Col}>
                        {/* <Form.Label>Pay Amount (<strong>${this.props.price}</strong>)</Form.Label> */}
                        <Form.Label>Amount Payable: (<strong>${this.props.paymentDetails.offerPrice}</strong>)</Form.Label>

                      </Form.Group>

                    </>)}





                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check onChange={this.handleInputChange} name="save_card" type="checkbox" label="Save This Card" />
                    </Form.Group>

                  </form>


                </Col>
              </Row>
              <Row className="mt-4 mb-4" style={{ display: this.state.card_exits ? "block" : "none" }}>
                <Col md={12} className="">
                  {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th colspan="3" className="text-center">My Saved Cards</th>
                      </tr>
                      <tr>
                        <th>Choose Card</th>
                        <th>Card Number</th>
                        <th>Card Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.card_details.map((data, key) => {
                        return (
                          <tr>
                            <td>
                              <input
                                type="radio"
                                name="card_token"
                                value={data.card_token}
                                onChange={this.onValueChange.bind(this)} />
                            </td>
                            <td>**** **** **** {data.last_digit}</td>
                            <td>{data.card_name}</td>
                          </tr>
                        )
                      })}

                      {
                        this.state.card_details.length <= 0 && (
                          <tr>
                            <td Colspan="3" className="justify-content-center text-center">Card's Not Found</td>

                          </tr>
                        )
                      }
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.props.close} className="btn-main tabformbutton mr-2">Cancel</Button>
            <Button onClick={() => { this.clickSubmit((this.state.key == 'strip') ? 'strip' : 'paypal') }} variant="primary" disabled={this.state.paydisabled} style={{ cursor: this.state.paydisabled ? 'none' : 'inherit' }} type="submit" className="btn-main tabformbutton">Pay</Button>

            {/* <input type="submit" name="pay_now" 
                                    className="btn btn-primary btn-md tabformbutton" id="pay_now"
                                    Value="Pay Now"/> */}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
