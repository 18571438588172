import React, { Component
  // , useState 
} from 'react';
import { Container, Row, Col,
  //  Tab, Nav, 
   Form, Button
  //  , Image 
  } from "react-bootstrap";
import { FlashMess, GET_API, POST_API,Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';
// import Leftpanel from './Section/LeftPanel';
import './css/dashboard.css';
// import DataTable from "react-data-table-component";


export default class EditAffiliateBankDetails extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
                        
                        FromData: {
                                       
                                        account_name : '',
                                        account_number : '',
                                        confirm_account_number:'',
                                        account_ifsc_code:'',
                                        bank_name:'',
                                        
                                    },
                        FromDataError :
                                        {
                                          
                                            account_name : 'false',
                                            account_number : 'false',
                                            confirm_account_number:'',
                                            account_ifsc_code:'false',
                                            bank_name:'false',
                                         
                                         
                                        
                                        },
                                       
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/add-affiliate-bank',
           
                     };

        //Binding

        this.handaleChange = this.handaleChange.bind(this)
        this.handaleSubmit = this.handaleSubmit.bind(this)
       
       
     
    }

    

    

    validateForm  (errors) {
      let valid = true;
      let sta = this;
      let FromDataError = this.state.FromDataError;

      for (const [key, value] of Object.entries(errors)) {
        if(value.length > 0 )
        {
          
          if(value=='false')
          {
            FromDataError[key] = 'This field is required';
            sta.setState({FromDataError})
          }
          
          valid = false;
          console.log('loop',valid)
        }
      
      }
     
     
      console.log('funct',valid)
    
      return valid;
};
  
    
    

    handaleChange(data)
    {
       
        // let sta = this;
        let FromDataError = this.state.FromDataError;
        

        let name = data.target.name;
        let value = '';
        let FromData = this.state.FromData;
        

        value = data.target.value;
        FromData[name] = value;
        this.setState({FromData},()=>{
         
        }) 

         //validate from
         var valid_obj = {
          value:value,
          rules:data.target.getAttribute("validaterule"),
          message:data.target.getAttribute("validatemsg")
        }

              validation(valid_obj).then((error)=>{
                  FromDataError[name] = error
                  this.setState({FromDataError}) //from error state
              })

       
    }

    handaleSubmit(e)
    {   
        
        e.preventDefault();

        let sta = this;
        let FromDataError = this.state.FromDataError;
        // let FromDataError1 = this.state.FromDataError1;
        let FromData = this.state.FromData;
        let id=this.props.match.params.code
        

      
    

       //console.log(FromData)

        if(this.validateForm(FromDataError)  )
        {
        
          sta.setState({loader:'visible'})///loader on


          var formdata = new FormData();
              
                formdata.append("holder_name", FromData.account_name);
                formdata.append("account_number", FromData.account_number);
                formdata.append("IFSC_code", FromData.account_ifsc_code);
                formdata.append("bank_name", FromData.bank_name);
                formdata.append("bankID", id);
                    
                let request = {
                  end_point : '/update-bankdetails',
                  formdata:formdata,
                  token: localStorage.getItem("gs_token")

                };

                

                POST_API(request).then(resp=>{

                  sta.setState({loader:'hidden'})///loader off

                 // console.log('......response',resp)

                        if(resp.status == 'success')
                        {
                         
                          
                          
                          this.props.history.push({
                            pathname: '/add-affiliate-bank',
                            state:{Msg:'Bank Details Updated Successfully',MsgType:'success'}
                  
                          })

                           
                        }
                        else{
                            
                           
                                sta.setState({Msg:'Bank Details Failed',MsgType:'danger'})
                        }
                        }).catch((ee)=>{

                            console.log('/BankErr',ee)
                        });
                      

           

        }else
        { 

   
           
            sta.setState({Msg:'Fill the form correctly!'})
            sta.setState({MsgType:'danger'})
           
        }

       
    }

  
  
    componentDidMount()
    {

                let sta = this;
                let id=this.props.match.params.code
                let  FromData = this.state.FromData
                let FromDataError = this.state.FromDataError;
                

                //for redirectin message show
                if(sta.props.location.state!=undefined)
                {
                    sta.setState({Msg:sta.props.location.state.Msg})
                    sta.setState({MsgType:sta.props.location.state.MsgType})
                }

                
                    let requestEdit = {
                      end_point : '/bank-details?bankID='+id,
                      token: localStorage.getItem("gs_token")
                      };
              
                      sta.setState({loader:'visible'})///loader on
                      
                      GET_API(requestEdit).then((resp)=>{
                        sta.setState({loader:'hidden'})///loader off
  
                      if(resp.status=='success')
                      {

                       // console.log(resp.data)
                    
                        FromData['account_name'] = resp.data.holder_name;
                        FromDataError['account_name'] = (resp.data.holder_name != null)?'': 'false'
  
                        FromData['bank_name'] =  resp.data.bank_name;
                        FromDataError['bank_name'] = ( resp.data.bank_name != null)?'': 'false'

                        FromData['account_number'] =  resp.data.account_number;
                        FromDataError['account_number'] = ( resp.data.account_number != null)?'': 'false'
                        
                        FromData['account_ifsc_code'] =  resp.data.IFSC_code;
                        FromDataError['account_ifsc_code'] = ( resp.data.IFSC_code != null)?'': 'false'
                      
                       
  
                         sta.setState({FromData})
                         sta.setState({FromDataError})
            
                      
                      }
                      else{
                                
                          sta.setState({Msg:'Not found',MsgType:'danger'})
                              
                          
                      }
  
          }).catch(()=>{
         
  
                
  
          })
      

      
    }

   
    


    render() {
        let  FromData = this.state.FromData
       
        return (
            <div>
                 {FromData.hasOwnProperty("account_name")? (<>
            

            <Container fluid className="dash_container">
          <Row>
            {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
            <Col md={12}>
            <Loader loadmsg="please wait" visibility={this.state.loader}/>

            <div className="tabpanelOneForm pt-3">
             
           
            <h3>Add Bank Details</h3>
                        <Form
                          className="tabform"
                          onSubmit={this.handaleSubmit}
                        >
                          <Row className="m-0 mt-4 mb-4">

                        
                            <Col md={12} className="pl-0 ">
                                {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
               {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
              
                
                       
                               <Form.Row>
                                <Form.Group controlId="formBasicEmail4" as={Col}>
                                  <Form.Label>Account Holder Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="account_name"
                                    onChange={this.handaleChange}
                                    placeholder="Enter your account holder name"
                                    value={FromData.account_name}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.account_name=='false'?'': this.state.FromDataError.account_name}</p>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail4" as={Col}>
                                  <Form.Label>Bank Account Number</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="account_number"
                                    onChange={this.handaleChange}
                                    placeholder="Enter your bank account number"
                                    value={FromData.account_number}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.account_number=='false'?'': this.state.FromDataError.account_number}</p>
                                </Form.Group>
                                
                        
                              </Form.Row>


                              <Form.Row>
                                

                                <Form.Group controlId="formBasicEmail4" as={Col}>
                                  <Form.Label>Your Bank IFSC Code</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="account_ifsc_code"
                                    onChange={this.handaleChange}
                                    placeholder="Enter your bank IFSC code"
                                    value={FromData.account_ifsc_code}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.account_ifsc_code=='false'?'': this.state.FromDataError.account_ifsc_code}</p>
                                </Form.Group>
                        
                              
                                <Form.Group controlId="formBasicEmail4" as={Col}>
                                  <Form.Label>Bank Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="bank_name"
                                    onChange={this.handaleChange}
                                    placeholder="Enter your bank name"
                                    value={FromData.bank_name}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.bank_name=='false'?'': this.state.FromDataError.bank_name}</p>
                                </Form.Group>

                              </Form.Row>

                         
                            </Col>

                            <Col md={8} className="pl-1 tabformRight">
                              <Button
                                variant="primary"
                                type="submit"
                                className="btn btn-primary btn-md tabformbutton"
                              >
                                Submit
                              </Button>
                            </Col>
                          </Row>
      
                        </Form>
           

                      </div>
            </Col>     
          </Row>
        </Container>

        </>):(<p>....Loading</p>) }


        </div>
          
                
                
           
        );
    }
}