export const BASE_URL = "https://uat-app.nexgenaiaffiliates.io/";
export const API_URL = "https://uat-api.nexgenaiaffiliates.io/api";
export const PayPal = ""
export const CaptchaKey = "6LdnyO8lAAAAAGK-MFxWiEkYeirIOxJ3_RBcicEx";
export const ResumeScoringURL = "https://uat.resumescoring.com";
export const SocialScoringURL = "https://uat.socialprofilescoring.io";

export const JobAlertsURL = "https://uat.jobalerts.io";
export const CoachmasterURL = "https://uat-login.coachmaster.io";
export const ResumeCertifiedURL = "https://uat-login.resumecertified.org";
export const ResumeSendingURL = "https://uat-login.resumesending.com";
export const JobseekerURL = "https://premium.jobseekernewshubb.com";
export const resumeboostURL = "https://login.resumeboost.io";
export const GBTW_URL = "https://uat.globalworkinitiative.org";

export const resumecertified_FRONT_URL = "https://uat.resumecertified.org/";
export const resumesending_FRONT_URL = "https://uat.resumesending.com/";
export const coachmaster_FRONT_URL = "https://coachmaster.io/";
export const careermaster_FRONT_URL = "http://careermaster.io/";
export const jobalerts_FRONT_URL = JobAlertsURL;
export const careerbooks_FRONT_URL = "https://careerbooks.io/"
export const jobseekernewshubb_FRONT_URL = JobseekerURL;
export const recruiterdirect_FRONT_URL = "https://uat.recruiterdirect.io/";
export const hrdirect_FRONT_URL = "https://uat.hrdirect.io/";
export const freeresumeaudit_FRONT_URL ="https://freeresumeaudit.com/";
export const BASE_API_URL = "https://uat-api.nexgenaiaffiliates.io";
export const STRIPE_KEY = "pk_test_6WFz3TC5pwohqFN7C9HEaGlz003nlIUD1x";

export const SPONSOR_URL = "https://globalworkinitiative.org/";
export const JOBSEEKER_URL = "https://getbacktowork.io/";
export const SHOW_ON_PROD = true;
