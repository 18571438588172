import React, { Component, useState } from "react";
import {
  Container,
  Row,
  Col,
  // Tab,
  // Nav,
  // Form,
  Button,
  //Image,
  Dropdown
} from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
// import validation from "../Validate/validator";
// import Leftpanel from "./Section/LeftPanel";
//import csvfilename from "./files/add-contact.csv";
import "./css/dashboard.css";
import DataTable from "react-data-table-component";
// import AddContactModal from "./Section/AddContactModal";
//import AddContactcsvModal from "./Section/AddContactcsvModal";
import EditContactModal from "./Section/EditContactModal";
import { BASE_URL } from "../Config";
import moment from "moment";
import Threedots_img from '../images/dotsss.png'

export default class AffiliateContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ContactData: {},
      ContactDataError: {},
      Msg: "",
      MsgType: "",
      loader: "hidden",
      urlPath: "/contact-group-details",
      ContactModal: false,
      ContactcsvModal: false,
      ContactEditModal: false,
      id: 0,
      group_id: 0,
      group_name: "",
      contactUrl: "",
      referSend: '',

      FromData: {
        group_id: "",
        import_code: "",
      },
    };

    //Binding
  }
  handleClose(group_id = 0) {
    if (this.state.ContactModal) {
      this.setState({ ContactModal: false });
    } else {
      this.setState({ group_id: group_id, ContactModal: true });
    }
  }
  handleClosCsv(group_id = 0) {
    if (this.state.ContactcsvModal) {
      this.setState({ ContactcsvModal: false });
    } else {
      this.setState({ group_id: group_id, ContactcsvModal: true });
    }
  }
  handleCloseEdit(id = 0) {
    console.log(id);

    if (this.state.ContactEditModal) {
      this.setState({ ContactEditModal: false });
    } else {
      this.setState({ id: id, ContactEditModal: true });
    }
  }

  handleReferal(contactID) {

    let sta = this;
    var formdata = new FormData();
    formdata.append("group_id", this.props.match.params.code);
    formdata.append("contact_id", contactID);
    formdata.append("link", BASE_URL);
    let request = {
      end_point: '/affiliate-reffer-jobseekers-remind',
      formdata: formdata,
      token: localStorage.getItem("gs_token")

    };

    sta.setState({ loader: 'visible' })///loader on

    POST_API(request).then(resp => {

      sta.setState({ loader: 'hidden' })///loader off


      if (resp.status == 'success') {

        sta.setState({ Msg: resp.message, MsgType: "success" });

        this.getContactList();
        setTimeout(() => {
          this.setState({ Msg: '' })

        }, 4000);
      }
      else {



        sta.setState({ Msg: resp.message, MsgType: "danger" });
      }


    }).catch((ee) => {
      sta.setState({ loader: 'hidden' })///loader off

      sta.setState({ Msg: "Something Went Wrong,Please try again later.", MsgType: "danger" });

      console.log('/codeGEN_ERR', ee)


    });
  }

  formatPhone = (text) => {
    var cleaned = ('' + text).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '+1 '),
        number = [intlCode, '', match[2], '-', match[3], '-', match[4]].join('');

      return number;
    } else {
      return text;
    }
  }


  componentDidMount() {
    let sta = this;
    // let ContactData = this.state.ContactData;
  //  let ContactDataError = this.state.ContactDataError;

    let group_id = this.props.match.params.code;
    this.setState({ group_id });
    localStorage.setItem("group_id", group_id);
    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg });
      sta.setState({ MsgType: sta.props.location.state.MsgType });
    }

    // if (localStorage.getItem("import_code") != null) {
    //   console.log(localStorage.getItem("import_code"));
    //   window.location =
    //     BASE_URL + "#/contact-details/" + localStorage.getItem("group_id");
    //   sta.setState({ loader: "visible" }); ///loader on
    //   let FromData = this.state.FromData;
    //   var formdata = new FormData();

    //   formdata.append("group_id", localStorage.getItem("group_id"));
    //   formdata.append("import_code", localStorage.getItem("import_code"));

    //   let requestSave = {
    //     end_point: "/import-google-contact",
    //     formdata: formdata,
    //     token: localStorage.getItem("gs_token"),
    //   };

    //   POST_API(requestSave)
    //     .then((respCon) => {
    //       sta.setState({ loader: "hidden" }); ///loader off

    //       console.log("save " + respCon);

    //       if (respCon.status == "success") {
    //         sta.setState({ Msg: respCon.message, MsgType: "success" });

    //         //localStorage.removeItem("group_id");
    //         localStorage.removeItem("import_code");
    //       } else {
    //         sta.setState({ Msg: respCon.message, MsgType: "danger" });
    //         //sta.setState({Msg:'Contact add Failed',MsgType:'danger'})
    //       }
    //     })
    //     .catch((ee) => {
    //       console.log("/addcontactERR", ee);
    //     });
    // }



    if (localStorage.getItem("import_code") != null) {
      this.setState({ loader: "visible" });
      let requestSave1 = {
        end_point: "/getresponse?auth_code=" + localStorage.getItem("import_code"),
        token: localStorage.getItem("gs_token"),
      };


      GET_API(requestSave1)
        .then((resp1) => {
          var formdata = new FormData();

          formdata.append("group_id", localStorage.getItem("group_id_storage"));
          formdata.append("import_code", localStorage.getItem("import_code"));
          formdata.append("accesstoken", resp1.access_token);
          let requestSave = {
            end_point: "/import-google-contact",
            formdata: formdata,
            token: localStorage.getItem("gs_token"),
          };

          POST_API(requestSave)
            .then((respCon) => {
              //this.setState({ loader: "hidden" });
              console.log("save " + respCon);
              localStorage.setItem('google_import_msg', respCon.message);
              localStorage.setItem('google_import_status', respCon.status);
              //window.location.href = BASE_URL + '#/contact-details/' + localStorage.getItem('group_id_storage');
              localStorage.removeItem("import_code");
              this.setState({ Msg: respCon.message, Msgtype: "success" });
              this.getContactList();
            })
            .catch((ee) => {
              this.setState({ loader: "hidden" });
              console.log("/addcontactERR", ee);
              this.setState({ Msg: 'Something went wrong!', Msgtype: "danger" });
            });
        })
        .catch((ee) => {

          this.setState({ loader: "hidden" });
          console.log("/code list ERR", ee);
        });
    }







    if (localStorage.getItem("google_import_msg") != null) {
      console.log(localStorage.getItem("google_import_status"));
      // if (localStorage.getItem("google_import_status") == 'faild') {
      //   this.setState({ Msg: localStorage.getItem("google_import_msg"), Msgtype: "danger" });
      // } else {
      //   this.setState({ Msg: localStorage.getItem("google_import_msg"), Msgtype: "success" });
      // }

      console.log(this.state.MsgType);
      localStorage.removeItem("google_import_msg");
      localStorage.removeItem("google_import_status");
    }

    this.getContactList();
  }

  searchChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    let group_id = this.props.match.params.code;

    let sta = this;
    let ContactData = this.state.ContactData;
   // let ContactDataError = this.state.ContactDataError;
    let request = {
      end_point: "/list-contact?search_key=" + value + "&group_id=" + group_id,
      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(request)
      .then((resp) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          ContactData = {};
          sta.setState({ ContactData });
          ContactData = resp.data;

          sta.setState({ ContactData });
          sta.setState({ referSend: resp.group_name.has_send });
        } else {
        }
      })
      .catch((ee) => {
        sta.setState({ loader: "hidden" }); ///loader off

        console.log("/code list ERR", ee);
      });
  };

  handaleDelete(id) {
    let sta = this;

    var formdata = new FormData();
    formdata.append("id", id);

    let request = {
      end_point: "/delete-contact",
      formdata: formdata,
      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    POST_API(request)
      .then((resp) => {
        if (resp.status == "success") {
          sta.setState({ loader: "hidden" }); ///loader off

          sta.setState({ Msg: "Contact deleted successfully!", Msgtype: "success" });
          this.getContactList();

          // setTimeout(() => {
          //    window.location.reload()

          // }, 3000);
        } else {
          //console.log(resp.message.password.join())
          sta.setState({ Msg: "faild", MsgType: "danger" });
        }
      })
      .catch(() => { });
  }

  getContactList() {
    let sta = this;
    let ContactData = this.state.ContactData;
//    let ContactDataError = this.state.ContactDataError;

    let group_id = this.props.match.params.code;

    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg });
      sta.setState({ MsgType: sta.props.location.state.MsgType });
    }

    let request = {
      end_point: "/list-contact?group_id=" + group_id,
      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(request)
      .then((resp) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          ContactData = resp.data;

          sta.setState({ ContactData });

          let group_name = resp.group_name.group_name;
          sta.setState({ group_name });
          sta.setState({ referSend: resp.group_name.has_send });
        } else {
        }
      })
      .catch((ee) => {
        sta.setState({ loader: "hidden" }); ///loader off

        console.log("/code list ERR", ee);
      });

    // let requestGoogle = {
    //   end_point: "/getAuthGoogle?group_id=" + group_id,
    //   token: localStorage.getItem("gs_token"),
    // };

    // sta.setState({ loader: "visible" }); ///loader on

    // GET_API(requestGoogle)
    //   .then((respG) => {
    //     sta.setState({ loader: "hidden" }); ///loader off

    //     //console.log(respG.data.contactUrl)
    //     if (respG.status == "success") {
    //       let contactUrl = respG.data.contactUrl;

    //       sta.setState({ contactUrl });
    //     } else {
    //     }
    //   })
    //   .catch((ee) => {
    //     sta.setState({ loader: "hidden" }); ///loader off

    //     console.log("/google url ERR", ee);
    //   });
  }


  columns = [
    // {
    //   name: "Name",
    //   cell: (row) => (row.firstname || "") + " " + (row.lastname || ""),
    //   sortable: true,
    // },
    // {
    //   name: "Email",
    //   cell: (row) => row.email,
    //   sortable: true,
    // },

    // {
    //   name: "Phone",
    //   cell: (row) => row.contact_no,
    //   sortable: true,
    // },
    // {
    //   name: "Created At",
    //   cell: (row) => row.created_at,
    //   sortable: true,
    // },
    // {
    //   name: "Actions",
    //   cell: (row) => {
    //     return (
    //       <div>
    //         <a href="javascript:">
    //           <Button
    //             variant="primary mr-1"
    //             onClick={() => this.handleCloseEdit(row.id)}
    //           >
    //             Edit
    //           </Button>
    //         </a>

    //         <Button
    //           disabled={false}
    //           variant="danger"
    //           onClick={() => {
    //             if (window.confirm("Delete the Contact?")) {
    //               this.handaleDelete(row.id);
    //             }
    //           }}
    //         >
    //           Delete
    //         </Button>
    //       </div>
    //     );
    //   },
    //   sortable: true,
    //   width: "250px",
    // },
    {
      name: "Name",
      selector: (row) => (row.firstname || "") + " " + (row.lastname || ""),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => <a href={'mailto:' + row.email}>{row.email}</a>,
      width: '300px',
      sortable: true,
      wrap: true,
    },

    {
      name: "Phone",
      selector: (row) => this.formatPhone(row.contact_no),
      width: '150px',
      sortable: true,
      wrap: true,
    },
    {
      name: "Created",
      // selector: (row) => row.created_at,
      selector: row => moment(row.created_at).format('MM-DD-YYYY'),
      width: '150px',
      sortable: true,
      wrap: true,
    },
    {
      name: "Mail sent",
      // selector: (row) => row.created_at,
      selector: row => row.has_send == 0 ? 'No' : row.has_send + ' Times',
      width: '100px',
      // sortable: true,
      // wrap:true,
    },
    {
      name: "Joined",
      // selector: (row) => row.created_at,
      selector: row => row.has_joined == 1 ? 'Yes' : 'No',
      width: '100px',
      // sortable: true,
      // wrap:true,
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <div className="three_dotss">
            <Dropdown className="drop_partts">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <img src={Threedots_img} alt=""/>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <Button
                    onClick={() => this.handleCloseEdit(row.id)} > <i className="fa fa-pencil"></i> Edit</Button>
                </Dropdown.Item>
                <Dropdown.Item >
                  <Button disabled={false}
                    onClick={() => {
                      if (window.confirm("Delete the Contact?")) {
                        this.handaleDelete(row.id);
                      }
                    }}> <i className="fa fa-trash"></i> Delete</Button>
                </Dropdown.Item>
                {row.has_joined == 0 && row.grpSend == 1 ? (<>
                  <Dropdown.Item >
                    <Button disabled={false}
                      onClick={() => { if (window.confirm('Are you sure want to send reminder?')) { this.handleReferal(row.id) } }}> <i className="fa fa-paper-plane"></i> Send Reminder</Button>
                  </Dropdown.Item>
                </>) : (<></>)}
              </Dropdown.Menu>
            </Dropdown>
          </div>


        );
      },
      // sortable: true,
      width: "250px",
    },
  ];

  render() {
    let ContactData = this.state.ContactData;
    return (
      <div>
        {ContactData != null ? (
          <>
            <Container fluid className="dash_container">
              <Row>
                {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
                <Col md={12}>
                  <Loader
                    loadmsg="please wait"
                    visibility={this.state.loader}
                  />

                  <div className="tabpanelOneForm pt-3">

                    {this.state.id != 0 ? (
                      <>
                        <EditContactModal
                          {...this.props}
                          id={this.state.id}
                          show={this.state.ContactEditModal}
                          close={() => this.handleCloseEdit()}
                        />
                      </>
                    ) : (
                      <></>
                    )}


                    <h3>Contacts List Of {this.state.group_name}</h3>
                    <p>Access the contact details of jobseekers belonging to {this.state.group_name}</p>


                    {/* <a
                      href={this.state.contactUrl}
                      className="btn btn-primary ghost_button mt-3"
                    >
                      Import google contacts
                    </a> */}
                    <div style={{ marginTop: '10px' }}>
                      {this.state.Msg !== "" ? (
                        <>
                          <FlashMess
                            m={this.state.Msg}
                            mtype={
                              this.state.MsgType ? this.state.MsgType : "success"
                            }
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div style={{ float: "right", margin: "10px" }}>
                      Search :{" "}
                      <input
                        type="text"
                        name="search_val"
                        placeholder="Search"
                        onChange={this.searchChange}
                      />
                    </div>
                    <DataTable
                      title=""
                      columns={this.columns}
                      data={ContactData}
                      defaultSortField="title"
                      pagination={true}
                      NoDataComponent={"No data"}
                      noHeader={true}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <p>....Loading</p>
        )}
      </div>
    );
  }
}
