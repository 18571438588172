import React, { Component } from 'react';
import { Container, Row, Col, 
  // Tab, Nav, 
  Form, Button } from "react-bootstrap";
import { FlashMess, 
  // GET_API,
   POST_API,Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';


class CreateAffiliatePromocode extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
                        list:{},
                        FromData: {
                            discount_type : '',
                            amount : '',
                            maximum_users : '',
                            valid_from_date : '',
                            valid_to_date : '',
                          
                        },
                        FromDataError :
                                        {
                                            discount_type : 'false',
                                            amount : 'false',
                                            maximum_users : 'false',
                                            valid_from_date : 'false',
                                            valid_to_date : 'false',
                                        },
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/affiliate-promocode',

                    }



                    ///bind
                    this.handaleChange = this.handaleChange.bind(this)
                    this.handaleSubmit = this.handaleSubmit.bind(this)
    }

    
    validateForm  (errors) {
        let valid = true;
        let sta = this;
        let FromDataError = this.state.FromDataError;
  
        for (const [key, value] of Object.entries(errors)) {
          if(value.length > 0 )
          {
  
            if(value=='false')
            {
              FromDataError[key] = 'This field is required';
              sta.setState({FromDataError})
            }
            
            valid = false;
          }
        }
      
        return valid;
  };
  




  handaleChange(data)
  {
      let sta = this;
      let {FromDataError,FromData} = sta.state;
      

      let name = data.target.name;
      let value =  data.target.value;
      FromData[name] = value;
    
      
     var valid_obj = {
        value:value,
        rules:data.target.getAttribute("validaterule"),
        message:data.target.getAttribute("validatemsg")
      }


      validation(valid_obj).then((error)=>{


        FromDataError[name] = error
        this.setState({FromDataError})
       

        this.setState({FromData},()=>{

         // console.log(FromDataError)
          
        })
      })
    
      
  }

  handaleSubmit(e)
    {   
        
        e.preventDefault();

        let sta = this;
        let {FromDataError,FromData} = this.state;


        if(this.validateForm(FromDataError))
        {
          sta.setState({loader:'visible'})///loader on

                    var formdata = new FormData();

                    formdata.append("discount_type", FromData.discount_type);
                    formdata.append("amount", FromData.amount);
                    formdata.append("maximum_users", FromData.maximum_users);
                    formdata.append("valid_from_date", FromData.valid_from_date);
                    formdata.append("valid_to_date", FromData.valid_to_date);

                        let request = {
                                    end_point : '/create-prmocode',
                                    formdata:formdata,
                                    token: localStorage.getItem("gs_token")
                                    };


                        POST_API(request).then((resp)=>{
                         sta.setState({loader:'hidden'})///loader on
                            if(resp.status=='success')
                            {

                                sta.props.history.push({
                                    pathname: '/affiliate-promocode',
                                    state: { Msg:'Promo Code Added Successfully!',Msgtype:'succcess'}
                                })

                            }
                            else{
                              
                                sta.setState({Msg:resp.message,MsgType:'danger'})
                            }

                        }).catch(()=>{
                          sta.setState({loader:'hidden'})///loader on
                          sta.setState({Msg:'Something Went Wrong,Please try again later.',MsgType:'danger'})
                       


                        })

        }
        else
        {
            sta.setState({Msg:'Fill the form correctly!'})
            sta.setState({MsgType:'danger'})
        }


       

    }


    render() {

        let {FromData} = this.state
        return (
            <div>
                 {'a'=='a'? (<>
            

            <Container fluid className="dash_container">
          <Row>
           
            <Col md={12}>
            <Loader loadmsg="please wait" visibility={this.state.loader}/>

            <div className="tabpanelOneForm pt-3">

            <h3>Create Promo Code</h3>
                {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
        

            <div className="tabpanelOneForm">
              <Form className="tabform" onSubmit={this.handaleSubmit}>
                  
                    <Row className="m-0 mt-4 mb-4">
                            <Col md={12} className="">
                             
                              <Form.Row>

                              <Form.Group as={Col} controlId="formGridState" >
                                    <Form.Label>Discount Type</Form.Label>
                                    <Form.Control
                                     validaterule={['required']}
                                     validatemsg={['This field is required']}
                                     as="select" defaultValue="Type" name="discount_type" onChange={this.handaleChange}>
                                    <option value="">Select Discount Type</option>
                                    <option value="1">Flat</option>
                                    <option value="2">Percentage</option>
                                    </Form.Control>
                                    <p style={{ color: 'red' }}>{this.state.FromDataError.discount_type=='false'?'': this.state.FromDataError.discount_type}</p>

                                </Form.Group>

                             
                                </Form.Row>
                                <Form.Row>

                                <Form.Group controlId="formBasicEmailaff" as={Col}>
                                  <Form.Label>Amount {FromData.discount_type=='2'?<><span>(%)</span></>:''}</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="amount"
                                    value={FromData.amount}
                                    onChange={this.handaleChange}
                                    placeholder="Enter amount"
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.amount=='false'?'': this.state.FromDataError.amount}</p>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmailaff1" as={Col}>
                                  <Form.Label>Maximum Users</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="maximum_users"
                                    value={FromData.maximum_users}
                                    onChange={this.handaleChange}
                                    placeholder="Enter maximum users"
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.maximum_users=='false'?'': this.state.FromDataError.maximum_users}</p>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group controlId="formBasicEmailaff2" as={Col}>
                                  <Form.Label>Valid From Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    name="valid_from_date"
                                    value={FromData.valid_from_date}
                                    onChange={this.handaleChange}
                                    placeholder="Enter valid from date"
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.valid_from_date=='false'?'': this.state.FromDataError.valid_from_date}</p>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmailaff3" as={Col}>
                                  <Form.Label>Valid To Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    name="valid_to_date"
                                    onChange={this.handaleChange}
                                    value={FromData.valid_to_date}
                                    placeholder="Enter valid to date"
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.valid_to_date=='false'?'': this.state.FromDataError.valid_to_date}</p>
                                </Form.Group>
                              </Form.Row>
                             
                            </Col>
                            <Col md={12} >
                              <Button
                                variant="primary"
                                type="submit"
                                className="btn btn-primary btn-md tabformbutton"
                                disabled={this.state.loader!='hidden'? true : false}
                              >
                                Submit
                              </Button>
                            </Col>
                         
                        
                    </Row>
                    </Form>
                      
            </div>
               
                   
            </div>
            </Col>     
          </Row>
        </Container>

        </>):(<p>....Loading</p>) }


        </div>
        );
    }
}

export default CreateAffiliatePromocode;