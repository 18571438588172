import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, Image, OverlayTrigger,Tooltip,Card } from "react-bootstrap";
import { BASE_URL,ResumeCertifiedURL } from '../Config';
import { FlashMess, GET_API, POST_API,Loader } from "../Helpers/Helpers";

import Resumecertifiedimg from "./images/Resume_Certified1.png"

export default class ResumeCertified extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
                        
 
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/jobseeker-personal-profile',
                        help:'',
                        hrcontacts:{},
                        searchInput: "",
                        filteredData: [],
                        coaches:[],
                        certifiedflag:1

                        
                     };
                     this.handlesubmit=this.handlesubmit.bind(this);
        

    }
    handlesubmit()
    {
        this.props.handlecertified(this.state.certifiedflag); 
        var formdata=new FormData();
        formdata.append("flag", this.state.certifiedflag);
        let request = {
            end_point : '/ResumecertifiedSelected',
            token: localStorage.getItem("gs_token"),
            formdata:formdata
            

        };
        this.setState({loader:'visible'})
            POST_API(request).then(resp=>{
                this.setState({loader:'hidden'})
                    // console.log("joblist",resp)
                    // this.setState({joblist:resp.jobs})
                    window.open(ResumeCertifiedURL+"/direct-login/"+localStorage.getItem("gs_auth_code"), "_blank")
                    }).catch((ee)=>{

                    });
    }
    render() {
        let auth_code=localStorage.getItem("gs_auth_code");
        // console.log("auth_code",auth_code)
        return (
            <div>
            <Loader loadmsg="please wait" visibility={this.state.loader}/>
                <h2>Resume Certified</h2>
                <p>Give your resume a competitive edge and show employers that you are the perfect candidate for the job!</p>

                <div className="resume_img">
                        <img src={Resumecertifiedimg} className="img-fluid"/>
                        
                        <Button style={{marginBottom:"15px"}} onClick={this.handlesubmit} className="btn-1" >Certify your resume now</Button>
                </div>
                

                
                
            </div>
            
        )
    }
}
