import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Alert, Navbar, Nav } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faHome, faEnvelopeOpen, faPhone } from '@fortawesome/free-solid-svg-icons'

import './css/footer.css';

import flogo from '../images/footer-logo.png';
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';


class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {

      FromData: {

        name: '',
        email: ''
      },
      FromDataError:
      {
        name: 'false',
        email: 'false'
      },
      Msg: '',
      MsgType: '',
      help: '',
      alert: 0


    };

    //Binding

    this.handaleChange = this.handaleChange.bind(this)
    this.handaleSubmit = this.handaleSubmit.bind(this)


  }
  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
        console.log('loop', valid)
      }

    }


    console.log('funct', valid)

    return valid;
  };




  handaleChange(data) {
    let sta = this;
    let FromDataError = this.state.FromDataError;


    let name = data.target.name;
    let value = '';
    let FromData = this.state.FromData;


    if (name == 'profileimage') {
      value = data.target.files[0];
    }
    else {
      value = data.target.value;
    }

    FromData[name] = value;

    this.setState({ FromData }, () => {

    })

    //validate from
    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg")
    }

    validation(valid_obj).then((error) => {
      FromDataError[name] = error
      this.setState({ FromDataError }) //from error state
    })


  }
  handaleSubmit(e) {

    e.preventDefault();

    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;
    let gs_user = '';


    if (this.validateForm(FromDataError)) {
      var formdata = new FormData();
      formdata.append("name", FromData.name);
      formdata.append("email", FromData.email);
      formdata.append("pagesource", 'GBW');

      let request = {
        end_point: '/news-letter',
        formdata: formdata,

      };

      console.log('......request', formdata)

      POST_API(request).then(resp => {

        if (resp.status == 'success') {
          FromData.name = ''
          FromData.email = ''
          this.setState({ FromData })
          this.setState({ alert: 1 })


        }
      }).catch((ee) => {

        console.log('/userEditERR', ee)
      });




    } else {
      sta.setState({ Msg: 'Fill the form correctly!' })
      sta.setState({ MsgType: 'danger' })
    }


  }


  render() {
    let FromData = this.state.FromData
    let condition = localStorage.getItem("gs_token") != null &&
      localStorage.getItem("gs_user") != null &&
      localStorage.getItem("gs_user") != undefined &&
      localStorage.getItem("gs_user") != ''
    return (
      <div className="footer">
        {!condition ? (
          <div className="footer_top">
            <Container>
              {/* <Row className="pt-5">
              <Col xs={6} md={3} >
                <img src={flogo} className="img-fluid mb-3" />
                <p>Why I say old chap that is sping lavatory chip shop gosh off his, xsashing boot are you taking the piss posh loo brilliant matie boy young.!!</p>
              </Col>
              <Col xs={6} md={3}>
                <h3>Company</h3>
                <ul>
                  <li><a href="#">Leadership</a></li>
                  <li><a href="#">Diversity</a></li>
                  <li><a href="#">Wavelenght</a></li>
                  <li><a href="#">Job</a></li>
                </ul>
              </Col>
              <Col xs={6} md={3}>
                <h3>Workflow Solutions</h3>
                <ul>
                  <li><a href="#">Management</a></li>
                  <li><a href="#">Task Management</a></li>
                  <li><a href="#">Reporting</a></li>
                  <li><a href="#">Work Tracking</a></li>
                  <li><a href="#">See All Uses</a></li>
                </ul>
              </Col>
              <Col xs={6} md={3}>
                <h3>Team Solutions</h3>
                <ul>
                  <li><a href="#">Engineering</a></li>
                  <li><a href="#">Designers</a></li>
                  <li><a href="#">Developers</a></li>
                  <li><a href="#">Marketing</a></li>
                  <li><a href="#">See All Terms</a></li>
                </ul>
              </Col>
            </Row> */}

              <Row>

                {/* <Col className="seperator" md={12}></Col>
            <Col className="" md={1}></Col>
            <Col className="" md={5}>
              <h3>Contact Information</h3>
              <Col className=" p-0 pb-3 content-box-wrapper content-wrapper link-area-link-icon icon-hover-animation-fade" data-animationoffset="100%">
                <div className="heading heading-with-icon icon-left">
                  <div className="micon">
                    <FontAwesomeIcon icon={faEnvelopeOpen} />
                  </div>
                  <h6 className="content-box-heading">Email</h6>
                </div>
                <div className="clearfix"></div>
                <div className="content-container">
                  <p><a href="mailto:global@workin.com">global@workin.com</a></p>
                </div>
              </Col>
              <Col className=" p-0 pb-3 content-box-wrapper content-wrapper link-area-link-icon icon-hover-animation-fade" data-animationoffset="100%">
                <div className="heading heading-with-icon icon-left">
                  <div className="micon">
                    <FontAwesomeIcon icon={faHome} />
                  </div>
                  <h6 className="content-box-heading">Address</h6>
                </div>
                <div className="clearfix"></div>
                <div className="content-container">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
              </Col>
              <Col className=" p-0 pb-3 content-box-wrapper content-wrapper link-area-link-icon icon-hover-animation-fade" data-animationoffset="100%">
                <div className="heading heading-with-icon icon-left">
                  <div className="micon">
                    <FontAwesomeIcon icon={faPhone} />
                  </div>
                  <h6 className="content-box-heading">Phone No.</h6>
                </div>
                <div className="clearfix"></div>
                <div className="content-container">
                  <p>+1 (855) 123 456 8740</p>
                </div>
              </Col>
            </Col> */}

                <Col sm={6} md={6} className="pr-5">
                  <div className="footer__cap">
                    <h2 className="text-light mb-4">Subscribe Now </h2>
                    <p>Sign up to receive all the latest jobseeker news & offers</p>
                  </div>
                </Col>

                <Col sm={6} md={6}>
                  {this.state.alert == 1 ? (<Alert variant="success">You have been successfully subscribed for the Newsletter. Please check your mail for latest updates and announcements</Alert>) : (<></>)}
                  <Form onSubmit={this.handaleSubmit}>
                    <Form.Group controlId="formBasicName">
                      <Form.Control type="text"
                        name="name"
                        onChange={this.handaleChange}
                        placeholder="Enter your name"
                        value={FromData.name}
                        validaterule={['required', 'isString']}
                        validatemsg={['This field is required', 'name must contain alphabets']} />
                      <p style={{ color: '#f96d54' }}>{this.state.FromDataError.name == 'false' ? '' : this.state.FromDataError.name}</p>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control type="email" placeholder="Enter your email"
                        name="email"
                        value={FromData.email}
                        onChange={this.handaleChange}
                        validaterule={['required', 'isEmail']}
                        validatemsg={['This field is required', 'Input a valid Email']} />
                      <p style={{ color: '#f96d54' }}>{this.state.FromDataError.email == 'false' ? '' : this.state.FromDataError.email}</p>
                    </Form.Group>
                    {/* <Form.Group controlId="formBasicMessage">    
                      <Form.Control as="textarea" rows={4} placeholder="Message" />
                    </Form.Group> */}
                    <Button variant="default" className="btn-main px-4" type="submit">Submit</Button>
                  </Form>
                </Col>

                {/* <Col md={12} className="mt-5">
                <div className='d-flex footer_navigation'>
                  <h3>Navigation</h3>
                  <div className='seperator_footer d-flex align-items-center'>
                    <div className="title-sep sep-single sep-solid"></div>
                  </div>
                </div>

                <Navbar className="align-items-center navbar-dark pl-0 pr-0 footer_nav" >
                      <Nav className="mr-auto flex-row" as="ul">     
                        <Nav.Item as="li">
                          <Nav.Link href="https://globalworkinitiative.org/about-us/">About Us</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link href="https://globalworkinitiative.org/contact-us/">Contact Us</Nav.Link>
                        </Nav.Item>
                      </Nav>
                </Navbar>
              </Col>
               */}

              </Row>

            </Container>
          </div>
        ) : (<></>)}
        <div className={!condition ? "copy_tight" : "copy_tight"}>
          Copyright © 2024 | NexGenAl Solutions Group, Inc. | All Rights Reserved
        </div>
      </div>
    );
  }
}

export default Footer;