import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  // Tab,
  // Nav,
  Form,
  Button,
  // Image,
  // OverlayTrigger,
  // Tooltip,
  Card
} from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from "../Validate/validator";
// import Leftpanel from "./Section/LeftPanel";
// import profileimage from "../images/dashboard_profile_image.jpg";
import "./css/dashboard.css";
import profileimageRemove from "../images/x-circle.svg";
import { BASE_API_URL } from "../Config";
import PhoneInput
// , {
//   parsePhoneNumber,
//   isValidPhoneNumber,
// } 
from "react-phone-number-input";
export default class AffiliatePersonalProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FromData: {
        profileimage: "",
        firstname: "",
        lastname: "",
        contact_no: "",
        address: "",
        // signature_image: "",
      },
      FromDataError: {
        profileimage: "false",
        firstname: "false",
        lastname: "false",
        contact_no: "false",
        // address: "false",
        // signature_image: "false",
        
      },
      FromData1:{
        // signature_image: "",
      },
      Msg: "",
      MsgType: "",
      loader: "hidden",
      urlPath: "/affiliate-personal-profile",
      help: "",
      profileimageCheck:null,
    };

    //Binding

    this.handaleChange = this.handaleChange.bind(this);
    this.handaleSubmit = this.handaleSubmit.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
        if (value == "false") {
          FromDataError[key] = "This field is required";
          sta.setState({ FromDataError });
        }

        valid = false;
        // console.log("loop", valid,key);
      }
    }

    console.log("funct", valid);

    return valid;
  }

  handaleChange(data) {
  //  let sta = this;
    let FromDataError = this.state.FromDataError;

    let name = data.target.name;
    let value = "";
    let FromData = this.state.FromData;
    let FromData1 = this.state.FromData1;

    if (name == "profileimage") {
      value = data.target.files[0];
    }
    //  else if(name == "signature_image"){
    //   value = data.target.files[0];
    // }
     else {
      value = data.target.value;
    }

    FromData[name] = value;
    FromData1[name] = value;

    this.setState({ FromData, FromData1}, () => {});

    //validate from
    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((error) => {
      FromDataError[name] = error;
      this.setState({ FromDataError }); //from error state
    });
  }

  handleSignature(e){
    
    let sta = this;
    if (this.validateForm(this.state.FromDataError) && (this.state.FromData1.signature_image != "" && this.state.FromData1.signature_image != undefined)) {
      sta.setState({ loader: "visible" }); ///loader on

      var formdata = new FormData();

      formdata.append("signature_image", this.state.FromData1.signature_image, this.state.FromData1.signature_image.name);

      let requestSignature = {
        end_point: "/upload-signature",
        formdata: formdata,
        token: localStorage.getItem("gs_token"),
      };


      POST_API(requestSignature)
        .then((resp) => {
          sta.setState({ loader: "hidden" }); ///loader off

          if (resp.status == "success") {
            sta.setState({
              Msg: "Digital Signature Successfully",
              MsgType: "success",
            });

          } else {
            sta.setState({ Msg: "Digital Signature Failed", MsgType: "danger" });
          }
        })
        .catch((ee) => {
          console.log("/userEditERR", ee);
        });
    }
  }


  handalePhoneNoChange(data) {
    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError;
    let value = '';
    FromData['contact_no'] = data;
    this.setState({ FromData }, () => {

    })

    if (FromData.contact_no != '') {
      var valid_obj = {
        value: value,
        rules: 'required',
        message: 'This field is required'
      }
      validation(valid_obj).then((error) => {
        FromDataError['contact_no'] = true
        this.setState({ FromDataError }) //from error state
      })
    }

  }
  handaleSubmit(e) {
    this.handleSignature();
    e.preventDefault();

    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;
  //  let gs_user = "";

    if (this.validateForm(FromDataError)) {
      sta.setState({ loader: "visible" }); ///loader on

      var formdata = new FormData();
      formdata.append("about_me", FromData.about_me || "");
      //formdata.append("dob", FromData.dob);

      if (
        FromData.profileimage != undefined &&
        typeof FromData.profileimage == "object"
      ) {
        formdata.append(
          "profileimage",
          FromData.profileimage,
          FromData.profileimage.name
        );
      }
      formdata.append("contact_no", FromData.contact_no);
      formdata.append("firstname", FromData.firstname);
      formdata.append("lastname", FromData.lastname);
      formdata.append("address", FromData.address);

      let request = {
        end_point: "/edit-user-profile-details",
        formdata: formdata,
        token: localStorage.getItem("gs_token"),
      };

      console.log("......request", formdata);

      POST_API(request)
        .then((resp) => {
          sta.setState({ loader: "hidden" }); ///loader off

          if (resp.status == "success") {
            sta.setState({
              Msg: "Profile Update Successfully",
              MsgType: "success",
            });

            localStorage.setItem("gs_user", JSON.stringify(resp.data.user));
            // update

            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            sta.setState({ Msg: "Profile Update Failed", MsgType: "danger" });
          }
        })
        .catch((ee) => {
          console.log("/userEditERR", ee);
        });
    } else {
      sta.setState({ Msg: "Fill the form correctly!" });
      sta.setState({ MsgType: "danger" });
    }
  }

  componentDidMount() {
    let sta = this;
    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError;

    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg });
      sta.setState({ MsgType: sta.props.location.state.MsgType });
    }

    try {
      if (localStorage.getItem("gs_user") != null) {
        //let FromData = JSON.parse(localStorage.getItem("gs_user"))
        sta.setState({ loader: "visible" }); ///loader on

        GET_API({
          token: localStorage.getItem("gs_token"),
          end_point: "/get-profile-details",
        })
          .then((resp) => {
            // console.log(resp)
            if (resp.status == "success") {
              sta.setState({profileimageCheck:resp.data.user.profileimageCheck})
              FromData["firstname"] = resp.data.user.firstname;
              FromDataError["firstname"] =
                resp.data.user.firstname != null ? "" : "false";

              FromData["lastname"] = resp.data.user.lastname;
              FromDataError["lastname"] =
                resp.data.user.lastname != null ? "" : "false";

              FromData["profileimage"] = resp.data.user.profileimage;
              FromDataError["profileimage"] =
                resp.data.user.profileimage != null ? "" : "false";
              FromData["signature_image"] = resp.data.profile.signature_url;
              // FromDataError["signature_image"] =
              //   resp.data.profile.signature_url != null ? "" : "false";

              FromData["contact_no"] = resp.data.profile.contact_no;
              FromDataError["contact_no"] =
                resp.data.profile.contact_no != null ? "" : "false";

                FromData["user_email"] = resp.data.user.email;

              FromData["address"] =
                resp.data.profile.address != null
                  ? resp.data.profile.address
                  : "";
              FromDataError["address"] =
                resp.data.profile.address != null ? "" : "";

              this.setState({ FromData });
            }
            sta.setState({ loader: "hidden" }); ///loader off
          })
          .catch((ee) => {
            sta.setState({ loader: "hidden" }); ///loader off

            console.log("/userdetailsEditERR", ee);
          });
      }
    } catch (error) {}

    //help api
    let requestHelp = {
      end_point: "/help-details?key=affiliate_my_profile",

      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(requestHelp)
      .then((resph) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resph.status == "success") {
          let help = resph.data.value;
          sta.setState({ help });
        }
      })
      .catch(() => {
        sta.setState({ loader: "hidden" }); ///loader off
      });
  }


  deleteProfileImg = async () =>{
    let self = this;
    const cnfirm = window.confirm(
        `Are you sure you want to delete this profile image?`
    )
    console.log("alert",cnfirm)
    if(cnfirm){

      let request = {
        end_point : '/delete-user-profile-image',
        token: localStorage.getItem("gs_token")

      };

      POST_API(request).then(resp=>{

        self.setState({loader:'hidden'})///loader off
        console.log("delete image",resp)

              if(resp.status == 'success')
              {
                self.setState({Msg:'Profile image deleted successfully',MsgType:'success'})
                
               // this.getuserDetails()
               setTimeout(() => {
                window.location.reload()
                
              }, 3000);

              }
              else{
                  
                  
                self.setState({Msg:'Profile image delete Failed',MsgType:'danger'})
              }
              }).catch((ee)=>{

                  console.log('/userEditERR',ee)
              });
            

    }
  }  


  render() {
    let FromData = this.state.FromData;

    return (
      <div>
        {FromData.hasOwnProperty("firstname") ? (
          <>
            <Container fluid className="dash_container">
          <Row>
            {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
            <Col md={12}>
            <Loader loadmsg="please wait" visibility={this.state.loader}/>

            <div className="tabpanelOneForm pt-3 mb-5">
<Card className="p-5 body_card">
            <h3>Personal Profile</h3>
            <p
                dangerouslySetInnerHTML={{
                  __html: this.state.help
                }}></p>
            {/*<div className="tooltip-block-redeem-list" style={{left: "220px", top:"20px"}}>   
                  <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-2"><div
                dangerouslySetInnerHTML={{
                  __html: this.state.help
                }}></div></Tooltip>}
              >
                     <Image
                      roundedCircle
                      src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020%2020%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1774ca3ddcc%20text%20%7B%20fill%3A%23FFF%3Bfont-weight%3Anormal%3Bfont-family%3A-apple-system%2CBlinkMacSystemFont%2C%26quot%3BSegoe%20UI%26quot%3B%2CRoboto%2C%26quot%3BHelvetica%20Neue%26quot%3B%2CArial%2C%26quot%3BNoto%20Sans%26quot%3B%2Csans-serif%2C%26quot%3BApple%20Color%20Emoji%26quot%3B%2C%26quot%3BSegoe%20UI%20Emoji%26quot%3B%2C%26quot%3BSegoe%20UI%20Symbol%26quot%3B%2C%26quot%3BNoto%20Color%20Emoji%26quot%3B%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1774ca3ddcc%22%3E%3Crect%20width%3D%2220%22%20height%3D%2220%22%20fill%3D%22%2328a745%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%227.1171875%22%20y%3D%2215.100000000000001%22%3EJ%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                    /> 
                    
            
               </OverlayTrigger>
              </div>*/}
                        <Form
                          className="tabform"
                          onSubmit={this.handaleSubmit}
                        >
                          <Row className="m-0 mt-4 mb-4">

                        
                            <Col md={12}>
                                {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
               {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
              
              
             
                              
                        <Form.Row className="align-items-center mb-3">
                          <Col md={3} className="d-flex justify-content-center">

                            {typeof FromData.profileimage == 'string' ? 
                           
                            <>
                            <img src={FromData.profileimage  } className="img-fluide pimage" alt=""/> 
                        
                       {this.state.profileimageCheck!=null?
                       <img src={profileimageRemove} onClick={()=>this.deleteProfileImg()} className="delete_image" height="20px" width="20px" alt=""/>
                       :""
                       }
                      
                      </>
                            : 
                            <>
                                    <img
                                      src={BASE_API_URL+"/images/Affiliate-(Individual).png"}
                                      className="img-fluide pimage_banaer"
                                      alt=""
                                    />
                                  
                                  </>
                            }
                            

                          </Col>
                              <Form.Group controlId="formBasicEmail" as={Col}>
                                    <Form.Label>Profile Image <span className="text-danger">*</span></Form.Label>
                                     <Form.File id="custom-file-translate-scss" 
                                                name='profileimage'
                                              
                                                onChange={this.handaleChange}
                                                label={FromData.profileimage.name || "Choose file"}  
                                                validaterule={['required','filesize|250000','mime|jpeg/png']}
                                                validatemsg={['This field is required']}
                                                lang="en"  custom />
                                              <span>Size must be less than 2 MB</span> 
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.profileimage=='false'?'': this.state.FromDataError.profileimage}</p>

                               </Form.Group>
                               {/* <Form.Group controlId="formBasicEmail" as={Col}>
                               {FromData.signature_image && <img src={FromData.signature_image} className="" height="100px" width="100px"/> } <br/>                                  
                                    <Form.Label>Digital Signature Image <span className="text-danger">*</span></Form.Label>
                                     <Form.File id="custom-file-translate-scss" 
                                                name='signature_image'
                                              
                                                onChange={this.handaleChange}
                                                label={this.state.FromData1.signature_image.name || "Choose file"}  
                                                validaterule={['required','filesize|250000','mime|jpeg/png']}
                                                validatemsg={['This field is required']}
                                                lang="en"  custom />
                                         <span>Size must be less than 2 MB</span>      
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.signature_image=='false'?'': this.state.FromDataError.signature_image}</p>

                               </Form.Group> */}
                               </Form.Row>
                               <Form.Row>
                                <Form.Group controlId="formBasicEmail" as={Col}>
                                  <Form.Label>First Name
                                  <span className="text-danger"> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="firstname"
                                    onChange={this.handaleChange}
                                    placeholder="Enter your first name"
                                    value={FromData.firstname}
                                    validaterule={['required', "text_max_value"]}
                                    validatemsg={['This field is required', "Invalid first name"]}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.firstname=='false'?'': this.state.FromDataError.firstname}</p>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail" as={Col}>
                                  <Form.Label>Last Name
                                  <span className="text-danger"> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="lastname"
                                    onChange={this.handaleChange}
                                    placeholder="Enter your last name"
                                    value={FromData.lastname}
                                    validaterule={['required', "text_max_value"]}
                                    validatemsg={['This field is required', "Invalid last name"]}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.lastname=='false'?'': this.state.FromDataError.lastname}</p>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                               

                                <Form.Group controlId="formBasicEmail" as={Col}>
                                  <Form.Label>Contact No
                                  <span className="text-danger"> *</span>
                                  </Form.Label>
                                  <PhoneInput
                                    international
                                    countryCallingCodeEditable={false}
                                    defaultCountry="US"
                                    value={FromData.contact_no}
                                    name="contact_no"
                                    onChange={this.handalePhoneNoChange.bind(this)}
                                    validaterule={['required', 'phone']}
                                    validatemsg={['This field is required', 'Invalid Phone number']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.contact_no=='false'?'': this.state.FromDataError.contact_no}</p>
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail" as={Col}>
                                  <Form.Label>Email</Form.Label>
                                  <Form.Control
                                    type="email"
                                    name="user_email"
                                    value={FromData.user_email}
                                    onChange={this.handaleChange}
                                    readOnly
                                  />
                              </Form.Group>
                              </Form.Row>

                              <Form.Row>
                                <Form.Group controlId="formGridAddress1" as={Col}>
                                  <Form.Label>Address (Optional)</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={2}
                                    name='address'
                                    onChange={this.handaleChange}
                                    placeholder="Example : 241 Turnpike Rd, Montague, MA, 01351, USA"
                                    value={FromData.address}
                                    // validaterule={['required','min|10']}
                                    // validatemsg={['This field is required','Enter Full Address']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.address=='false'?'': this.state.FromDataError.address}</p>

                                </Form.Group>
                                </Form.Row>

                              <Form.Row className="justify-content-center">
                                <Form.Group controlId="formBasicEmail4" as={Col} className="col-md-4 ">
                                  <Button
                                    variant="primary"
                                    type="submit"
                                    className="btn btn-primary btn-md tabformbutton"
                                  >
                                    Submit
                                  </Button>
                                </Form.Group>
                              </Form.Row>


                            </Col>
                            
                        {/* <Col md={8} className="pl-0 tabformRight">
                          <Button
                            variant="primary"
                            type="submit"
                            className="btn-main tabformbutton"
                          >
                            Submit
                          </Button>
                        </Col> */}
                      </Row>
                    </Form>

</Card>

                    </div>
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <p>....Loading</p>
        )}
      </div>
    );
  }
}
