import React, { useState, useEffect } from 'react';
import { FlashMess, POST_API, Loader } from '../Helpers/Helpers';
import { Container, Row, Col} from 'react-bootstrap';
import moment from "moment";

const CreditHistory = () => {
  const [transaction,setTransaction] = useState([]);
  const [showTable,setShowTable] = useState(false);
  const [loader, setLoader] = useState("hidden");
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');

  useEffect(() => {
    getHistory();
  }, []);

  const getHistory = () => {
    var formdata = new FormData();
    let request = {
      end_point: '/salesorbit-credits-history',
      token: localStorage.getItem("gs_token"),
      formdata: formdata,
    };
    setLoader('visible');
    POST_API(request)
      .then((resp) => {
        setLoader('hidden');
        if (resp.status === 'success') {
          setTransaction(resp.data);
          setShowTable(true)
        } else {
            setTransaction(null);
            setShowTable(false)
            setMsg("Credit Transaction : " +resp.message);
            setMsgType('danger');
        }
      })
      .catch((e) => {
        setTransaction(null);
        setShowTable(false)
        setLoader('hidden');
        setMsg("Credit History : " +e);
        setMsgType('danger');

      });
}

  return (
    <Container fluid className="my-3">
      <Loader loadmsg="please wait" visibility={loader} />
      <Row>
        <Col md={12}>
          <h3>Credit Details</h3>
          <p>Current Available User Credits : {transaction?.totalCredits}</p>
          {transaction?.orgTotalCredits && <p>Current Available ORG Credits : {transaction?.orgTotalCredits}</p> }
          <br />
          {msg !== '' ? (
            <FlashMess m={msg} mtype={msgType ? msgType : 'success'} />
          ) : (
            <></>
          )} 
        </Col>
        {showTable && <HistoryDetail txn={transaction.items} orgCreditHistory={transaction?.orgCreditHistory} /> }
      </Row>
    </Container>
  );
};

export default CreditHistory;

const HistoryDetail = (props)=>{
  const [txnData] = useState(props.txn);
  const [orgCreditHistory] = useState(props.orgCreditHistory);
 
  return <>
   
    <Col md={12}>

        {txnData && txnData.length > 0 && <>
          <p>User Credit History</p>
          <table className='table table-bordered table-responsive'>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Credit Amount</th>
                  <th>Expiry Date</th>
                  <th>Remaining Credits</th>
                </tr>
              </thead>
              <tbody>
                {txnData.map((item,index)=>{
                     return (  
                          <tr key={index}>
                              <td>{++index}</td>
                              <td>{item.amount}</td>
                              <td>{moment(item.expiresAt).format('MMMM Do, YYYY')}</td>
                              <td>{item.remainingCredits}</td>
                            </tr>
                          );
                })}

              </tbody>
          </table>
        </> }

        {orgCreditHistory && orgCreditHistory.length > 0 && <>
          <p>ORG Credit History</p>
          <table className='table table-bordered table-responsive'>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Credit Amount</th>
                  <th>Expiry Date</th>
                  <th>Remaining Credits</th>
                </tr>
              </thead>
              <tbody>
                {orgCreditHistory.map((item,index)=>{
                     return (  
                          <tr key={index}>
                              <td>{++index}</td>
                              <td>{item.amount}</td>
                              <td>{moment(item.expiresAt).format('MMMM Do, YYYY')}</td>
                              <td>{item.remainingCredits}</td>
                            </tr>
                          );
                })}

              </tbody>
          </table>
        </> }
        </Col>

  </>
}