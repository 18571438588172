import React, { Component } from 'react';
import  { FlashMess,GET_API,POST_API,Loader} from "../Helpers/Helpers";
import validation from '../Validate/validator';

import {API_URL,BASE_URL} from '../Config';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import './css/login.css';
import Loginrightimage from '../images/login_right_panel.png';
import left_arrow from "../images/left_arrow.png"


class ForgetPassword extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
                        FromData: {
                                        email : ''
                                       
                                    },
                        FromDataError :
                                        {
                                            email : 'false'
                                            
                                        },
                        isValid : false,
                        Msg: '',
                        MsgType:'',
                        loader:'hidden'

                        
                     };

        //Binding

        this.handaleChange = this.handaleChange.bind(this)
        this.handaleSubmit = this.handaleSubmit.bind(this)

    }

    validateForm  (errors) {
      let valid = true;
      let sta = this;
      let FromDataError = this.state.FromDataError;

      for (const [key, value] of Object.entries(errors)) {
        if(value.length > 0 )
        {

          if(value=='false')
          {
            FromDataError[key] = 'This field is required';
            sta.setState({FromDataError})
          }
          
          valid = false;
        }
      }
    
      return valid;
};

    handaleChange(data)
    {
        let sta = this;
        let FromDataError = this.state.FromDataError;
   

        //from data update state
        let name = data.target.name;
        let value = data.target.value;
        let FromData = this.state.FromData;
        FromData[name] = value;


        var valid_obj = {
          value:value,
          rules:data.target.getAttribute("validaterule"),
          message:data.target.getAttribute("validatemsg")
        }


      
        validation(valid_obj).then((error)=>{

         

          FromDataError[name] = error
          this.setState({FromDataError})
          

          this.setState({FromData})
        })

        
         
        
    }
    handaleSubmit(e)
    {   
        
        e.preventDefault();

        let sta = this;
        let FromDataError = this.state.FromDataError;
        let FromData = this.state.FromData;
        let formBody = [];


        if(this.validateForm(FromDataError))
        {
          sta.setState({loader:'visible'})///loader on

              var formdata = new FormData();
              formdata.append("email", FromData.email);
              formdata.append("url", BASE_URL+"#/change-password");

                let request = {
                            end_point : '/password-reset',
                            formdata:formdata
                            };


                  POST_API(request).then((resp)=>{
                    sta.setState({loader:'hidden'})///loader off

                      if(resp.status=='success')
                      {
                          console.log('forget_link',resp)
                          FromData.email=''
                          this.setState({FromData})
                          //sta.setState({Msg:'Thank You, Please Check Your Email.',MsgType:'success'})
                          sta.setState({Msg:'Reset Password Link Has Been Successfully Sent On Your Registered Mail ID.',MsgType:'success'})
                      }
                      else{
                          sta.setState({Msg:resp.message,MsgType:'danger'})
                      }

                  }).catch(()=>{

                  })

        }
        else
        {
            sta.setState({Msg:'Enter Email ID'})
            sta.setState({MsgType:'danger'})
        }


        


    }

    componentDidMount()
    {
     

      
      let sta = this;
      if(sta.props.location.state!=undefined)
      {
        sta.setState({Msg:sta.props.location.state.Msg})
        sta.setState({MsgType:sta.props.location.state.MsgType})
      }

       
    }



    render() {
        return (

            
           /////////////////////////////////////////////////////////////////////////////////////////

        <Container>
        <Row className="justify-content-center banner_container">
          <Col lg={5} className="login_box">
              <div className="loginForm">
               <Loader loadmsg="please wait" visibility={this.state.loader}/>

                <h3 className="text-center">Forgot Password</h3>
                  {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
               {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}

                <Form onSubmit={this.handaleSubmit}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control  
                    type="text"
                        className="email"
                            name="email"
                            value={this.state.FromData.email}
                            onChange={this.handaleChange}
                            placeholder="Your email" 
                            validaterule={['required','isEmail']}
                            validatemsg={['This field is required','input a Valid Email']}
                            />
                <p style={{ color: 'red' }}>{this.state.FromDataError.email=='false'? '' : this.state.FromDataError.email }</p>
                  </Form.Group>


                  <Row>
                    <Col md={12} className="d-flex keep_login_forgot_pass">
                     
                      
                    </Col>
                  </Row>
                  <Button type="submit" className="btn-main btn-block">
                    Submit
                  </Button>

                </Form>
                

                <Row>
                   <Col md={12} style={{textAlign:"center", paddingTop: "20px",fontWeight: "500"}}
                   className="ml-auto forgotPass">
                      <a href="#/login"><img className='left_arrow' src={left_arrow}/>Back to Login</a>
                   </Col>
                </Row>

              </div>
            </Col>
        </Row>
      </Container>
        /////////////////////////////////////////////////////////////////////////////////////////////
        );
    }
}

export default ForgetPassword;