import React, { Component, useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Tab,
    Nav,
    Form,
    Button,
    Image,
    OverlayTrigger,
    Tooltip,
    Modal,
    Dropdown,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Threedots_img from '../images/dotsss.png'
import { useDispatch, useSelector } from "react-redux";

const AllContacts = ({ searchInput, loading, searchable_data, resetHr, dispatch, props, creditBalance }) => {
    const [contacts, setContacts] = useState([])
    const [filterData, setFilterData] = useState([])
    const [emailModal, setEmailModal] = useState(false)
    const [emailValue, setEmailValue] = useState('')
    const [emailContent, setEmailContent] = useState('')
    const [viewModal, setViewModal] = useState(false)
    const [viewData, setViewData] = useState([])
    const [unlockModal, setunlockModal] = useState(false)
    const [unlockId, setunlockId] = useState('')
    const [creditCount, setcreditCount] = useState(1)
    const [selectedRows, setselectedRows] = useState([])
    const [dataIds, setdataIds] = useState([])
    const [loaderShow, setLoader] = useState('hidden');
    const [debitForUnlock, setdebitForUnlock] = useState(0)
    const [ApiMsg, setApiMsg] = useState('')
    const [ApiMsgType, setApiMsgType] = useState('')
    const [totalRows, settotalRows] = useState(0)
    const [offset, setoffset] = useState(1)
    const [limit, setlimit] = useState(10)
    const [searchSection, setsearchSection] = useState(false)
    const [singleUnlock, setsingleUnlock] = useState(false)


    const getDebitRules = () => {
        GET_API({ end_point: '/get-credit-debit-rules/unlock_hr_contacts', token: localStorage.getItem("gs_token") })
            .then((resp) => {
                if (resp.status == "success") {
                    setdebitForUnlock(resp.data.debit_value);
                }
            })
            .catch((err) => {
                console.log("getDebitRules", err)
            })
    }




    useEffect(() => {
        getDebitRules();

        setTimeout(
            () => setLoader('visible'),
            1000
        );

        let request = {
            end_point: `/get-hrContacts?offset=${offset}&limit=${limit}`,
            token: localStorage.getItem("gs_token")

        };
        GET_API(request).then(resp => {
            setTimeout(
                () => setLoader('hidden'),
                3000
            );
            console.log("getHr....", resp.contacts)
            setContacts(resp.contacts)
            setFilterData(resp.contacts)
            settotalRows(resp.row_count)
            setLoader('hidden');
        }).catch((ee) => {
            console.log(ee)
            setLoader('hidden');
        });
    }, []);

    useEffect(() => {
        console.log(props);
        if (props.loading) {
            setLoader('visible');
            setoffset(1);
            // let request = {
            //     end_point: '/get-hrContacts',
            //     token: localStorage.getItem("gs_token")

            // };
            // GET_API(request).then(resp => {
            //     console.log("getHr....", resp.contacts)
            //     setContacts(resp.contacts)
            //     setFilterData(resp.contacts)
            // }).catch((ee) => {
            //     console.log(ee)
            // });
            console.log(props.searchData);
            //setFilterData(props.searchable_data);

            var formdata = new FormData();
            formdata.append("searchIndustry", JSON.stringify(props.searchData.searchIndustry));
            formdata.append("searchAddressType", props.searchData.searchAddressType);
            formdata.append("searchAddress", props.searchData.searchAddress);
            formdata.append("status_type", props.searchData.status_type);

            let request_post = {
                end_point: `/import-hr-search-contact?offset=${offset}&limit=${limit}`,
                formdata: formdata,
                token: localStorage.getItem("gs_token")

            };


            POST_API(request_post).then(resp => {

                if (resp.status == 'success') {
                    setContacts(resp.contact_list)
                    setFilterData(resp.contact_list)
                    settotalRows(resp.row_count)
                    setLoader('hidden');
                    setsearchSection(true);
                } else {
                    setLoader('hidden');
                }

            }).catch((ee) => {
                setLoader('hidden');
                console.log('error', ee)
            });


        }
    }, [props.loading]);

    useEffect(() => {
        if (props.resetHr) {
            setoffset(1);
            setLoader('visible');
            let request = {
                end_point: `/get-hrContacts?offset=${offset}&limit=${limit}`,
                token: localStorage.getItem("gs_token")

            };
            GET_API(request).then(resp => {
                console.log("getHr....", resp.contacts)
                setsearchSection(false);
                setContacts(resp.contacts)
                setFilterData(resp.contacts)
                setLoader('hidden');
                settotalRows(resp.row_count)
            }).catch((ee) => {
                setsearchSection(false);
                console.log(ee)
                setLoader('hidden');
            });


        }
    }, [props.resetHr]);

    useEffect(() => {
        console.log("search", props.searchInput)
        let filteredData = contacts.filter((value) => {

            if (props.searchInput != "") {
                setLoader('visible');
                console.log("value", value)
                let Search =
                    value?.Company_Name?.toLowerCase().includes(props.searchInput.toLowerCase()) ||
                    value?.Full_Name?.toLowerCase().includes(props.searchInput.toLowerCase()) ||
                    value?.Email?.toLowerCase().includes(props.searchInput.toLowerCase()) ||
                    value?.Phone?.includes(props.searchInput) ||
                    value?.Country?.toLowerCase().includes(props.searchInput.toLowerCase()) ||

                    value?.City?.toLowerCase().includes(props.searchInput.toLowerCase()) ||
                    value?.State?.toLowerCase().includes(props.searchInput.toLowerCase())
                return Search

            }
            return true;
        });
        setFilterData(filteredData)
        setLoader('hidden');
    }, [props.searchInput]);

    const modalopenViewDetails = (id) => {

    };

    const modalopenForEmail = (id) => {
        setEmailModal(true)
        var formdata = new FormData();
        formdata.append("hr_id", id);
        let request_api_type = {
            end_point: '/get-hr-details',
            formdata: formdata,
            token: localStorage.getItem("gs_token")

        };
        // this.setState({loader:'visible'})///loader on
        POST_API(request_api_type).then(resp => {
            // this.setState({loader:'hidden'})
            setEmailValue(resp.contacts_details.Email)
            setEmailContent(resp.emailMsg)
        }).catch((ee) => {

            // this.setState({loader:'hidden'})
        });


    };

    const modalopenForView = (id) => {
        var formdata = new FormData();
        formdata.append("hr_id", id);
        let request_api_type = {
            end_point: '/get-hr-details',
            formdata: formdata,
            token: localStorage.getItem("gs_token")

        };
        // this.setState({loader:'visible'})///loader on
        POST_API(request_api_type).then(resp => {
            setViewData(resp.contacts_details);
            setViewModal(true);
        }).catch((ee) => {

            // this.setState({loader:'hidden'})
        });
    }

    const handaleSwitchModal = (type) => {
        if (type == 'emailModal') {
            if (emailModal) {
                setEmailModal(false)
            } else {
                setEmailModal(true)
            }
        } else if (type == 'viewModal') {
            if (viewModal) {
                setViewModal(false);
            } else {
                setViewModal(true);
            }
        } else if (type == 'unlockModal') {
            if (unlockModal) {
                setunlockModal(false);
            } else {
                setunlockModal(true);
            }
        }
    }
    const emailSendFunction = () => {
        var formdata = new FormData();
        formdata.append("email_content", emailContent);
        formdata.append("email", emailValue);
        let request_api_type = {
            end_point: '/submit-hr-email-send',
            formdata: formdata,
            token: localStorage.getItem("gs_token")

        };
        // this.setState({loader:'visible'})///loader on
        POST_API(request_api_type).then(resp => {
            // this.setState({loader:'hidden'})
            alert('Email sent successfully');
            setEmailModal(false);
        }).catch((ee) => {

            // this.setState({loader:'hidden'})
        });
    }

    const mailTosend = (mail) => {
        window.location.href = 'mailto:' + mail;
    }


    const searchFunction = () => {
        console.log(props.searchData);
        //setFilterData(props.searchable_data);

        var formdata = new FormData();
        formdata.append("searchIndustry", JSON.stringify(props.searchData.searchIndustry));
        formdata.append("searchAddressType", props.searchData.searchAddressType);
        formdata.append("searchAddress", props.searchData.searchAddress);
        formdata.append("status_type", props.searchData.status_type);

        let request_post = {
            end_point: `/import-hr-search-contact?offset=${offset}&limit=${limit}`,
            formdata: formdata,
            token: localStorage.getItem("gs_token")

        };


        POST_API(request_post).then(resp => {

            if (resp.status == 'success') {
                setContacts(resp.contact_list)
                setFilterData(resp.contact_list)
                settotalRows(resp.row_count)
                setsearchSection(true);
            } else {

            }

        }).catch((ee) => {

            console.log('error', ee)
        });
    }

    const getHrDetails = () => {
        let request = {
            end_point: `/get-hrContacts?offset=${offset}&limit=${limit}`,
            token: localStorage.getItem("gs_token")

        };
        GET_API(request).then(resp => {
            console.log("getHr....", resp.contacts)
            setContacts(resp.contacts)
            setFilterData(resp.contacts)
        }).catch((ee) => {
            console.log(ee)
        });
    }

    const unlockContactModal = (id) => {
        setsingleUnlock(true)
        setcreditCount(1)
        setunlockId(id)
        handaleSwitchModal('unlockModal');
    }

    const unlockContact = (id) => {
        setLoader('visible');
        var formdata = new FormData();
        formdata.append("hr_id", id);
        formdata.append("type", 'hr_contact');
        let request_api_type = {
            end_point: '/unlock-contact',
            formdata: formdata,
            token: localStorage.getItem("gs_token")

        };
        // this.setState({loader:'visible'})///loader on
        POST_API(request_api_type).then(resp => {
            if (resp.status == 'success') {
                if (selectedRows.length > 0) {
                    let indexArr = selectedRows.indexOf(id);
                    if (indexArr >= 0) {
                        selectedRows.splice(indexArr, 1);
                    }
                }
                dispatch({ type: "UPDATE-BALANCE", refreshBalance: true })
                setunlockModal(false);
                // this.setState({ unlockModal: false });
                setApiMsg("Contact Successfully Unlocked");
                setApiMsgType("success");

                if (searchSection) {
                    searchFunction();
                } else {
                    getHrDetails();
                }
                setTimeout(
                    () => setLoader('hidden'),
                    2000
                );
                // setLoader('hidden');
            } else {
                setApiMsg(resp.message);
                setApiMsgType("danger");
                setunlockModal(false);
                if (searchSection) {
                    searchFunction();
                } else {
                    getHrDetails();
                }
                setTimeout(
                    () => setLoader('hidden'),
                    2000
                );
            }
        }).catch((ee) => {
            setApiMsg("Low Credit Balance");
            setApiMsgType("danger");
            setunlockModal(false);
            if (searchSection) {
                searchFunction();
            } else {
                getHrDetails();
            }
            setTimeout(
                () => setLoader('hidden'),
                2000
            );
            // this.setState({loader:'hidden'})
        });
    }

    const unlockBulkContact = () => {
        setLoader('visible');
        var formdata = new FormData();
        formdata.append("hr_ids", selectedRows);
        formdata.append("type", 'hr_contact');
        let request_api_type = {
            end_point: '/unlock-contact',
            formdata: formdata,
            token: localStorage.getItem("gs_token")

        };
        // this.setState({loader:'visible'})///loader on
        POST_API(request_api_type).then(resp => {
            setsingleUnlock(true)
            console.log(resp)
            if (resp.status == 'success') {
                dispatch({ type: "UPDATE-BALANCE", refreshBalance: true });
                setunlockModal(false);
                if (searchSection) {
                    searchFunction();
                } else {
                    getHrDetails();
                }

                let selectedRowsAttr = [];
                setselectedRows(selectedRowsAttr);
                let dataIdsAttr = dataIds;
                selectedRows.map((items, key2) => {
                    dataIdsAttr.push(items);
                })

                setdataIds(dataIdsAttr);
                //this.setState({ Msg: "Contact Successfully Unlocked", MsgType: "success" });
                setTimeout(
                    () => setLoader('hidden'),
                    2000
                );

                setApiMsg("Contact Successfully Unlocked");
                setApiMsgType("success");
            } else {
                setunlockModal(false);
                setApiMsg(resp.message);
                setApiMsgType("danger");
                if (searchSection) {
                    searchFunction();
                } else {
                    getHrDetails();
                }
                setTimeout(
                    () => setLoader('hidden'),
                    2000
                );

            }
        }).catch((ee) => {
            setApiMsg("Low Credit Balance");
            setApiMsgType("danger");
            setunlockModal(false);
            if (searchSection) {
                searchFunction();
            } else {
                getHrDetails();
            }
            setTimeout(
                () => setLoader('hidden'),
                2000
            );
            // this.setState({loader:'hidden'})
        });
    }


    let conditionalRowStyles = [
        {
            when: row => (row.unlock_flag > 0),
            style: {
                backgroundColor: "#93ddea",
                'input[type="checkbox"]': {
                    display: "none",
                },
            }
        }
    ];

    const rowDisabledCriteria = row => (row.unlock_flag > 0) ? true : false;


    const handleChangeRow = (event) => {

        let selectedRows = [];
        console.log(event);

        event.selectedRows.map((items, key2) => {
            if (!dataIds.includes(items.id)) {
                selectedRows.push(items.id);
            }
        })
        setselectedRows(selectedRows);

    }

    const unlockContactModalMultiple = () => {
        console.log(selectedRows);
        if (selectedRows.length > 0) {
            setsingleUnlock(false)
            setcreditCount(selectedRows.length);
            handaleSwitchModal('unlockModal');
        } else {

        }
    }

    const customStyles = {
        header: {
            style: {
                display: 'none',
            },
        },
    };



    const handlePerRowsChange = async (page) => {
        //this.setState({ offset: page });
        if (searchSection) {
            setLoader('visible');
            var formdata = new FormData();
            formdata.append("searchIndustry", JSON.stringify(props.searchData.searchIndustry));
            formdata.append("searchAddressType", props.searchData.searchAddressType);
            formdata.append("searchAddress", props.searchData.searchAddress);
            formdata.append("status_type", props.searchData.status_type);

            let request_post = {
                end_point: `/import-hr-search-contact?offset=${offset}&limit=${page}`,
                formdata: formdata,
                token: localStorage.getItem("gs_token")

            };


            POST_API(request_post).then(resp => {

                if (resp.status == 'success') {
                    setContacts(resp.contact_list)
                    setFilterData(resp.contact_list)
                    settotalRows(resp.row_count)
                    setLoader('hidden');
                    setsearchSection(true);
                    setlimit(page);
                } else {
                    setLoader('hidden');
                }

            }).catch((ee) => {
                setLoader('hidden');
                console.log('error', ee)
            });
        } else {
            setLoader('visible');
            console.log(page);
            let request = {
                end_point: `/get-hrContacts?offset=${offset}&limit=${page}`,
                token: localStorage.getItem("gs_token")

            };
            GET_API(request).then(resp => {


                console.log("getHr....", resp.contacts)
                setContacts(resp.contacts)
                setFilterData(resp.contacts)
                setLoader('hidden');
                setlimit(page);
            }).catch((ee) => {
                console.log(ee)
                setLoader('hidden');
            });
        }
    };


    const handlePageChange = async (page) => {
        if (searchSection) {
            setLoader('visible');
            var formdata = new FormData();
            formdata.append("searchIndustry", JSON.stringify(props.searchData.searchIndustry));
            formdata.append("searchAddressType", props.searchData.searchAddressType);
            formdata.append("searchAddress", props.searchData.searchAddress);
            formdata.append("status_type", props.searchData.status_type);

            let request_post = {
                end_point: `/import-hr-search-contact?offset=${page}&limit=${limit}`,
                formdata: formdata,
                token: localStorage.getItem("gs_token")

            };


            POST_API(request_post).then(resp => {

                if (resp.status == 'success') {
                    setContacts(resp.contact_list)
                    setFilterData(resp.contact_list)
                    settotalRows(resp.row_count)
                    setLoader('hidden');
                    setsearchSection(true);
                    setoffset(page);
                } else {
                    setLoader('hidden');
                }

            }).catch((ee) => {
                setLoader('hidden');
                console.log('error', ee)
            });
        } else {
            setLoader('visible');
            console.log(page);
            let request = {
                end_point: `/get-hrContacts?offset=${page}&limit=${limit}`,
                token: localStorage.getItem("gs_token")

            };
            GET_API(request).then(resp => {


                console.log("getHr....", resp.contacts)
                setContacts(resp.contacts)
                setFilterData(resp.contacts)
                setLoader('hidden');
                setoffset(page);
            }).catch((ee) => {
                console.log(ee)
                setLoader('hidden');
            });
        }
    };



    const caseInsensitiveSortForComapny = (rowA, rowB) => {
        const a = rowA.Company_Name.toLowerCase();
        const b = rowB.Company_Name.toLowerCase();

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };

    const caseInsensitiveSortForTitle = (rowA, rowB) => {
        const a = rowA.Title.toLowerCase();
        const b = rowB.Title.toLowerCase();

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };

    const caseInsensitiveSortForName = (rowA, rowB) => {
        const a = rowA.Full_Name.toLowerCase();
        const b = rowB.Full_Name.toLowerCase();

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };



    let columns = [
        {
            name: "Company",
            selector: "Company_Name",
            sortable: true,
            width: "22%",
            sortFunction: caseInsensitiveSortForComapny,
        },
        {
            name: "Title",
            selector: "Title",
            sortable: true,
            width: "17%",
            sortFunction: caseInsensitiveSortForTitle,
        },
        {
            name: "Name",
            selector: "Full_Name",
            // selector: row => {
            //     let fName = row.Full_Name.split(' ')
            //     return (
            //         <span>
            //             {row.unlock_flag == 0 ? (
            //                 <>
            //                     <span>{fName[0]} *****</span>
            //                 </>
            //             ) : (<>
            //                 <span>{row.Full_Name}</span>
            //             </>)}
            //         </span>
            //     )

            // },
            sortable: true,
            width: "15%",
            sortFunction: caseInsensitiveSortForName,
        },
        // {
        //     name: "Title",
        //     selector: "Title",
        //     sortable: true,
        // },
        {
            name: "Email",
            // selector: "Email",
            // selector: row => (row.Email != 'null') ? <a href={'mailto:' + row.Email}>{row.Email}</a> : '',
            //selector: row => (row.unlock_flag == 0) ? (row.Email?.length > 2) ? row.Email.substring(0, 3) + '*********' : '' : <a href={'mailto:' + row.Email}>{row.Email}</a>,
            //sortable: true,
            selector: row => (row.unlock_flag == 0) ? row.Email : <a href={'mailto:' + row.Email}>{row.Email}</a>,
            width: "16%",
        },
        {
            name: "Phone",
            // selector: "Phone",
            // selector: row => (row.Phone != 'null') ? <a href={'tel:' + row.Phone}>{row.Phone}</a> : '',
            //selector: row => (row.unlock_flag == 0) ? (row.Phone?.length > 2) ? row.Phone.substring(0, 3) + '*********' : '' : <a href={'tel:' + row.Phone}>{row.Phone}</a>,
            selector: row => (row.unlock_flag == 0) ? row.Phone : <a href={'tel:' + row.Phone}>{row.Phone}</a>,

            //sortable: true,
            width: "10%",
        },
        {
            name: "Linkedin Profile",
            // selector: "Company_Url",
            // selector: row => (row.Company_Url != 'null') ? <a target="_blank" href={row.Company_Url}>Click Here</a> : '',
            // selector: row => (row.unlock_flag == 0) ? (row.Company_Url?.length > 2) ? row.Company_Url.substring(0, 3) + '**********' : '' : <a target="_blank" href={row.Company_Url}>Click Here</a>,
            selector: row => (row.unlock_flag == 0) ? row.Company_Url : <a target="_blank" href={row.Company_Url}>Click Here</a>,

            //sortable: true,
            width: "9%",
        },


        // {
        //     name: "Industry",
        //     selector: "Industry",
        //     sortable: true,
        // },

        // {
        //     name: "Address",
        //     selector: "address",
        //     sortable: true,
        // },
        // {
        //     name: "City",
        //     selector: "City",
        //     sortable: true,
        // },
        // {
        //     name: "State",
        //     selector: "State",
        //     sortable: true,
        // },
        // {
        //     name: "Zip",
        //     selector: "Zip",
        //     sortable: true,
        // },
        // {
        //     name: "Country",
        //     selector: "Country",
        //     sortable: true,
        // },





        // {
        //     name: "Sales",
        //     selector: "Sales",
        //     sortable: true,
        // },
        // {
        //     name: "Employees",
        //     selector: "Company_Size",
        //     sortable: true,
        // },
        // {
        //     name: "SIC Code",
        //     selector: "Sic_Code",
        //     sortable: true,
        // },
        {
            name: "Action",
            selector: row => {
                return (


                    <div>
                        {row.unlock_flag > 0 ? (
                            <div className="three_dotss">
                                <Dropdown className="drop_partts">
                                    <Dropdown.Toggle variant="" id="dropdown-basic">
                                        <img src={Threedots_img} />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item >
                                            <Button variant="danger" onClick={() => modalopenForView(row.id)}> <i className="fa fa-eye" aria-hidden="true"></i> View</Button>
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item >
                                    <Button variant="danger" onClick={() => mailTosend(row.Email)}> <i className="fa fa-envelope" aria-hidden="true"></i> Send Email</Button> */}

                                        {/* <Button variant="danger" onClick={() => modalopenForEmail(row.id)}> <i className="fa fa-envelope" aria-hidden="true"></i> Send Email</Button> */}
                                        {/* </Dropdown.Item> */}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                        ) : (<>
                            <Button title='Unlock contact information' className='btn btn-primary tabformbutton btn_total' onClick={() => { unlockContactModal(row.id) }}> <i className="fa fa-unlock" aria-hidden="true"></i></Button>
                        </>)}
                    </div>







                )
            },
            //sortable: true,
            width: "11%"
        },

    ]


    return (

        <Tab.Pane eventKey="second">
            <Loader loadmsg="please wait" visibility={loaderShow} />
            {ApiMsg !== '' ? (<><FlashMess m={ApiMsg} mtype={ApiMsgType ? ApiMsgType : 'success'} /></>) : (<></>)}
            <button
                variant="primary"
                type="submit"
                className={selectedRows.length > 0 ? "btn btn-primary btn-md tabformbutton  mt-3 mb-3 btn_total" : "btn btn-primary btn-md tabformbutton  mt-3 mb-3 d-none btn_total"}
                disabled={selectedRows.length > 0 ? false : true}
                onClick={() => { unlockContactModalMultiple() }}><i className="fa fa-unlock" aria-hidden="true"></i> Unlock</button>
            <DataTable
                title=""
                columns={columns}
                data={filterData}
                defaultSortField="title"
                pagination={true}
                selectableRows
                conditionalRowStyles={conditionalRowStyles}
                selectableRowDisabled={rowDisabledCriteria}
                onSelectedRowsChange={handleChangeRow}
                noContextMenu
                customStyles={customStyles}
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
            />
            {/* <p style={{ fontWeight: 600 }}><span className='text-danger'>*</span> One "Contact" is equivalent to either one or three valid details consisting of email address, phone number or Linkedin profile. 1 Credit will be deducted if any of the 3 (email address, phone number, linkedin URL) is found valid</p> */}



            <Modal
                show={emailModal}
                onHide={() => { handaleSwitchModal('emailModal') }}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                className='jobseeker_modal'
                centered
            >

                <Modal.Body>
                    <div className='close_btn' onClick={() => { handaleSwitchModal('emailModal') }}>x</div>
                    <div>
                        <Row >
                            <Col sm="12">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    readOnly
                                    size="large"
                                    name="email_address"
                                    value={emailValue}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col sm="12">
                                <Form.Label>Email Content</Form.Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={emailContent}
                                    name='content'

                                    onInit={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        // console.log( 'Editor is ready to use!', editor );
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setEmailContent(data)
                                    }}

                                    onBlur={(event, editor) => {
                                        //console.log( 'Blur.', editor );
                                    }}
                                    onFocus={(event, editor) => {
                                        //console.log( 'Focus.', editor );
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col sm="6">
                                <Button
                                    variant="primary"
                                    type="button"
                                    className="btn btn-primary btn-md tabformbutton mt-2 btn_total"
                                    onClick={() => emailSendFunction()}
                                >
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>

            </Modal>




            <Modal
                show={viewModal}
                onHide={() => { handaleSwitchModal('viewModal') }}
                // dialogClassName="modal-90w"
                // aria-labelledby="example-custom-modal-styling-title"
                // className='jobseeker_modal'
                centered
            >

                <Modal.Header closeButton>
                    <Modal.Title>HR Details</Modal.Title>
                    {/* <Row className="ml-1" style={{marginTop:'30px'}}>
            <div >Date : {this.state.fromDate} To : {this.state.toDate}  </div>
            </Row> */}
                </Modal.Header>

                <Modal.Body>
                    {/* <div className='close_btn' onClick={() => { handaleSwitchModal('viewModal') }}>x</div> */}
                    <div>

                        <Row className="m-0">
                            <Col>
                                <strong>
                                    Company :
                                </strong>
                            </Col>
                            <Col>
                                <p className="textwrap">{viewData.Company_Name}</p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col>
                                <strong>
                                    Name :
                                </strong>
                            </Col>
                            <Col>
                                <p className="textwrap">{viewData.Full_Name}</p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col>
                                <strong>
                                    Title :
                                </strong>
                            </Col>
                            <Col>
                                <p className="textwrap">{viewData.Title}</p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col>
                                <strong>
                                    Email :
                                </strong>
                            </Col>
                            <Col>
                                <p className="textwrap">{viewData.Email}</p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col>
                                <strong>
                                    Phone :
                                </strong>
                            </Col>
                            <Col>
                                <p className="textwrap">{viewData.Phone}</p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col>
                                <strong>
                                    Linkedin Profile :
                                </strong>
                            </Col>
                            <Col>
                                <p className="textwrap">{viewData.Company_Url}</p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col>
                                <strong>
                                    Industry :
                                </strong>
                            </Col>
                            <Col>
                                <p className="textwrap">{viewData.Industry}</p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col>
                                <strong>
                                    Address :
                                </strong>
                            </Col>
                            <Col>
                                <p className="textwrap">{viewData.address}</p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col>
                                <strong>
                                    City :
                                </strong>
                            </Col>
                            <Col>
                                <p className="textwrap">{viewData.City}</p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col>
                                <strong>
                                    State :
                                </strong>
                            </Col>
                            <Col>
                                <p className="textwrap">{viewData.State}</p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col>
                                <strong>
                                    Zip :
                                </strong>
                            </Col>
                            <Col>
                                <p className="textwrap">{viewData.Zip}</p>
                            </Col>
                        </Row>
                        {/* <Row className="m-0">
                            <Col>
                                <strong>
                                    Country :
                                </strong>
                            </Col>
                            <Col>
                                <p className="textwrap">{viewData.Country}</p>
                            </Col>
                        </Row> */}
                        <Row className="m-0">
                            <Col>
                                <strong>
                                    Sales :
                                </strong>
                            </Col>
                            <Col>
                                <p className="textwrap">{viewData.Sales}</p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col>
                                <strong>
                                    Employees :
                                </strong>
                            </Col>
                            <Col>
                                <p className="textwrap">{viewData.Company_Size}</p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col>
                                <strong>
                                    SIC Code :
                                </strong>
                            </Col>
                            <Col>
                                <p className="textwrap">{viewData.Sic_Code}</p>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>

            </Modal>



            <Modal show={unlockModal}
                backdrop="static"
                className='modal_upload'
                keyboard={false}
                onHide={() => { handaleSwitchModal('unlockModal') }}
                id="modal"
            >

                <Modal.Body style={{ minHeight: "250px" }}>
                    <div className="upload_file_area text-center">
                        <div className="upload_info">
                            <h4>Unlock Contact <span className='text-danger'>*</span></h4>
                            {(creditCount * debitForUnlock) <= creditBalance ? (
                                <p className="mb-0" style={{ fontSize: '15px' }}>{creditCount * debitForUnlock} credit{selectedRows.length > 1 ? '(s)' : ''} will be deducted to <br /> unlock {selectedRows.length > 1 ? "these contacts" : "this contact"} <span className='text-danger'>*</span></p>
                            ) : (
                                <p className="mb-0" style={{ fontSize: '15px', color: "red" }}>Low credit balance</p>

                            )}
                        </div>
                        <div className="upload_action row">
                            <div className="col pr-2">
                                <Button variant="primary" onClick={() => { handaleSwitchModal('unlockModal') }} className="btn btn-danger tabformbutton btn_total">Cancel</Button>
                            </div>
                            {(creditCount * debitForUnlock) <= creditBalance && (<>
                                {/* {selectedRows.length > 0 ? ( */}
                                {singleUnlock == false ? (

                                    <>
                                        <div className="col pl-2">
                                            <Button onClick={() => { unlockBulkContact() }} variant="primary" type="submit" className="btn btn-primary tabformbutton btn_total">Accept</Button>
                                        </div>
                                    </>) : (<>
                                        <div className="col pl-2">
                                            <Button onClick={() => { unlockContact(unlockId) }} variant="primary" type="submit" className="btn btn-primary tabformbutton btn_total">Accept</Button>
                                        </div>
                                    </>)}
                            </>)}

                        </div>
                    </div>
                </Modal.Body>

            </Modal>


        </Tab.Pane>
    );
};


const WithUseReducer = (props) => {
    console.log(props);
    let dispatch = useDispatch();
    const creditBalance = useSelector((state) => state.creditBalance);




    return <AllContacts {...{ dispatch, creditBalance }}  {...{ props }} />;
}


export default WithUseReducer;
// GET_API(request).then(resp => {

//     console.log("...../get-hrContacts",resp.contacts)

//     this.setState({ Allhrcontacts: resp.contacts })
//     // this.setState({loader:'hidden'})

// }).catch((ee) => {

//     // this.setState({loader:'hidden'})
// });
