import React from "react";
import  { FlashMess,POST_API,Loader} from "../../Helpers/Helpers";


import {
  Row,
  Col,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

export default class AddMemberInBulk extends React.Component {
  constructor(props)
    {
        super(props)
        this.state = {
                      Msg: '',
                      MsgType:'',
                      loader:'hidden',
                      urlPath:'/contact-details',
                      filename:"Choose file",
                    };
        this.handalecsvSubmit = this.handalecsvSubmit.bind(this)
        this.handaleChangeCSV = this.handaleChangeCSV.bind(this)
    }

  handaleChangeCSV(data)
  {
    let file=data.target.files[0]
    this.setState({filename:file.name})
  }

    handalecsvSubmit(e)
    {   
        e.preventDefault();
        let sta = this;
        var fileUpload = document.getElementById("csv"); 
        var regex = /^([a-zA-Z0-9\s_\\.\-:()])+(.csv|.txt)$/; 
       
        if (regex.test(fileUpload.value.toLowerCase())) { 
        if (typeof (FileReader) != "undefined") {
          try{ 
        var reader = new FileReader(); 
        reader.onload = function (e) { 
        var table = document.createElement("table"); 
        var rows = e.target.result.split("\n"); 
          

          var allTextLines = e.target.result.split("\n"); 
          var headers = allTextLines[0].split(',');
          var lines = [];
          
          allTextLines.forEach((element,key) => { 
            //console.log(element,key)

            if(key>0 && element)
            {
              element=element.split(",");
              let a={}
              element.forEach((eachelement,keyeach) => {
               let headerkeys=headers[keyeach]?.trim() 
               a[headerkeys]=eachelement?.trim()
              })
              a.employerID = JSON.parse(localStorage.getItem("gs_user")).userID;
              a.app_name = "NEXGEN";
              lines.push(a);
            }
          });

          
        //  return false;
          if(lines.length>0){
            sta.setState({loader:'visible'})///loader on
            var formdata = new FormData();
              
                formdata.append("allcontactData", JSON.stringify(lines));
                
                let request = {
                  end_point : '/bulk-member-csv-upload',
                  formdata:formdata,
                  token: localStorage.getItem("gs_token")
                };

                //console.log('......request',formdata)

                POST_API(request).then(resp=>{
                 

                  sta.setState({loader:'hidden'})///loader off
                  //console.log('......res',resp)

                        if(resp.status == 'success')
                        {
                          sta.setState({Msg:resp.message,MsgType:'success'})
                          

                            setTimeout(() => {
                              window.location.reload()
                              
                            }, 3500);

                        }
                        else{
                          document.getElementById("csv").value = ""
                             sta.setState({Msg:resp.message,MsgType:'danger'})
                           
                        }
                        }).catch((ee)=>{
                          
                            console.log('/addcontactERR',ee)
                        });
                      

              }else{
                document.getElementById("csv").value = ""
                sta.setState({Msg:'Do not upload blank csv file.',MsgType:'danger'})
              }     
        
      
          } 
          reader.readAsText(fileUpload.files[0]); 
        }
        catch(err){
          console.log('eeeeeeeeeeeeeeeeee',err)
          sta.setState({
            Msg: "Please upload a valid CSV file.",
            MsgType: "danger",
          });
        }
          } else { 
            alert("This browser does not support HTML5."); 
          } 
          } else { 
          
           sta.setState({Msg:'Please upload a valid CSV file.',MsgType:'danger'})
          }
    }

  render() {
    let  FromData = this.state.FromData
    
    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
         backdrop="static"
         keyboard={false}
         onHide={this.props.close}
         id="modal"
         onShow={this.stateCreate}
         >
        <Form
         className="tabform"
         onSubmit={this.handalecsvSubmit}
       >
          <Modal.Header closeButton>
            <Modal.Title>Upload In Bulk</Modal.Title>
          </Modal.Header>
          <Modal.Body>
         <Row className="m-0 mt-4 mb-4">
           <Col md={12} className="pl-0">
              {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
             <Form.Group>
             <Form.Label>Select CSV File</Form.Label>
             <div className="custom-file">
              <input type="file" className="custom-file-input"  
                  accept=".csv,.xlsx,.xls"
                  id='csv'
                  validaterule={['required']}
                  validatemsg={['This field is required']}
                  onChange={this.handaleChangeCSV}
                  
                  />
              <label className="custom-file-label" for="csv">{this.state.filename}</label>
              </div>
              </Form.Group>
  
           </Col>
           
         </Row>
            </Modal.Body>
          <Modal.Footer>
          <Button
               variant="primary"
               type="submit"
               className="btn btn-primary btn-md tabformbutton"
             >
               Submit
             </Button>
            
          </Modal.Footer>

          </Form>

        </Modal>
      </div>
    );
  }
}
