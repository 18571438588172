import React, { Component } from "react";
import "./header.css";

import { Link } from "react-scroll";

import logo1 from "../images/logo1.png";
import logo2 from "../images/logo2.png";
import logo3 from "../images/logo3.png";
import logo4 from "../images/logo4.png";
import logo5 from "../images/logo5.png";
import logo6 from "../images/logo6.png";
import logo7 from "../images/logo7.png";
import logo8 from "../images/logo8.png";

class header extends Component {
  constructor(props)
    {
        super(props)
        this.state = {
                        
                        ebook:1
                        
                     };
    }
    render(){
    return (
      <ul
        className="d-flex flex-column align-items-center justify-content-start listitem"
        style={{ listStyle: "none"}}
      >
        <li>
          <Link activeClass="active" to="card1" spy={true} smooth={true} offset={-105}>
          <div className="active_check"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"viewBox="0 0 512 512" width="12px" height="12px" fill="#fff"><g> <g><path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0 c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7 C514.5,101.703,514.499,85.494,504.502,75.496z"/> </g></g></svg></div>
            <div className="img_round"><img src={logo1} className="img-fluid" /></div>

          </Link>
        </li>
        <li>
          <Link to="card2" spy={true} smooth={true} offset={-105}>
          <div className="active_check"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"viewBox="0 0 512 512" width="12px" height="12px" fill="#fff"><g> <g><path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0 c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7 C514.5,101.703,514.499,85.494,504.502,75.496z"/> </g></g></svg></div>
          <div className="img_round"><img src={logo2} className="img-fluid" /></div>

         
          </Link>
        </li>
        <li>
          <Link to="card3" spy={true} smooth={true} offset={-105}>
          <div className="active_check"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"viewBox="0 0 512 512" width="12px" height="12px" fill="#fff"><g> <g><path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0 c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7 C514.5,101.703,514.499,85.494,504.502,75.496z"/> </g></g></svg></div>
          <div className="img_round"><img src={logo3} className="img-fluid" /></div>

          </Link>
        </li>
        <li>
          <Link to="card4" spy={true} smooth={true} offset={-105}>
          <div className="active_check"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"viewBox="0 0 512 512" width="12px" height="12px" fill="#fff"><g> <g><path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0 c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7 C514.5,101.703,514.499,85.494,504.502,75.496z"/> </g></g></svg></div>
          <div className="img_round"><img src={logo4} className="img-fluid" /></div>

          </Link>
        </li>
        <li>
          <Link to="card5" spy={true} smooth={true} offset={-105}>
          <div className="active_check"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"viewBox="0 0 512 512" width="12px" height="12px" fill="#fff"><g> <g><path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0 c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7 C514.5,101.703,514.499,85.494,504.502,75.496z"/> </g></g></svg></div>
          <div className="img_round"><img src={logo5} className="img-fluid" /></div>

          </Link>
        </li>
        <li>
          <Link to="card6" spy={true} smooth={true} offset={-105}>
          <div className="active_check"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"viewBox="0 0 512 512" width="12px" height="12px" fill="#fff"><g> <g><path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0 c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7 C514.5,101.703,514.499,85.494,504.502,75.496z"/> </g></g></svg></div>
          <div className="img_round"><img src={logo6} className="img-fluid" /></div>

          </Link>
        </li>
        <li>
          <Link to="card7" spy={true} smooth={true} offset={-105}>
          <div className="active_check"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"viewBox="0 0 512 512" width="12px" height="12px" fill="#fff"><g> <g><path d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0 c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7 C514.5,101.703,514.499,85.494,504.502,75.496z"/> </g></g></svg></div>
          <div className="img_round"><img src={logo7} className="img-fluid" /></div>
          </Link>
        </li>
      </ul>
    );
  }
}

export default header;
