import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./jobseeker.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Container, Row, Col } from "react-bootstrap";

import FeedBack from "./Section/FeedBack";

import Header from "./header";
import MiddleSection from "./middleSection";
import Leftpanel from "./Section/LeftPanel";
import TokenVerify,{ FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import PDEN from "../images/pden.png";
import PDEN2 from "../images/pden2.png";

class JobseekerDashboard extends Component {


  constructor(props) {
    super(props);
    this.state = {
      width:0,
      height:0,
      userDetails: {},
      sponsorDetails: "",
      FeedBackModal: false,
      urlPath: "/jobseeker-dash",
      reddemCode: "",
      Msg: "",
      MsgType: "",
      loader: "hidden",
      dashboardInfo: {},
      help: "",
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    window.scrollTo(0, 0);
    let sta = this;

    try {
      if (localStorage.getItem("gs_user") != null) {
        // this.props.dispatch({type:"UPDATE-BALANCE",refreshBalance:true})
       
        let userDetails = JSON.parse(localStorage.getItem("gs_user"));
        let sponsorDetails = JSON.parse(
          localStorage.getItem("gs_sponser_details")
        );
        this.setState({ userDetails }, () => {
          console.log('..sponsor detailss',sponsorDetails)
          if (sponsorDetails != null) {
            sta.setState({ sponsorDetails: sponsorDetails });
          }
          if (
            (userDetails.middleLayer_id == "" ||
            userDetails.middleLayer_id == null) && userDetails.sponsored_user == 0
          ) {
            sta.setState({ Msg: "Not Approved By Admin.", MsgType: "danger" });
            //this.setState({FeedBackModal: true})
          } else if (
            userDetails.has_feedback == 0 &&
            userDetails.middleLayer_id != "" &&
            userDetails.middleLayer_id != null &&
            userDetails.sponsored_user != 1 //not a paid user

          ) {
            this.setState({ FeedBackModal: true });
          }
        });
      }
    } catch (error) {}

    //help api
    let requestHelp = {
      end_point: "/help-details?key=jobseeker_dashboard",

      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(requestHelp)
      .then((resph) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resph.status == "success") {
          let help = resph.data.value;
          sta.setState({ help });
        }
      })
      .catch(() => {
        sta.setState({ loader: "hidden" }); ///loader off
      });
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight + 150 });
  }


  handleClose() {
    if (this.state.FeedBackModal) {
      this.setState({ FeedBackModal: false });
    } else {
      this.setState({ FeedBackModal: true }, () => {});
    }
  }

  render() {
    let userDetails = this.state.userDetails;
    // let loader=localStorage.getItem('loader')==true?'visible':'hidden';
    // console.log('loader',)
    return (
<>
       {/* <div className="scroll-element"> */}
      {/* <Loader loadmsg="please wait" visibility={loader}/> */}
        {/* <Container fluid className="dash_container">
          <Row> */}
            <>
            {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
            {/* <Col md="3" className="smalldevice" >
              <Header />
            </Col>
            <Col md="9" className="scrollelement"> */}
              <FeedBack
                {...this.props}
                show={this.state.FeedBackModal}
                close={() => this.handleClose()}
              />
              {this.state.Msg !== "" ? (
                <>
                  <FlashMess
                    m={this.state.Msg}
                    mtype={this.state.MsgType ? this.state.MsgType : "success"}
                  />
                </>
              ) : (
                <></>
              )}
              {this.state.sponsorDetails != "" &&  userDetails.is_paid == "0" ? (
                <>
                  <div className="alert alert-info" role="alert">
                    <strong>
                      Notice : Welcome{" "}
                      {JSON.parse(localStorage.getItem("gs_user")).firstname},
                      you have been sponsored by{" "}
                      <b>{this.state.sponsorDetails.name} ({this.state.sponsorDetails.email})</b>{" "}
                    </strong>
                  </div>
                </>
              ) : (
                <></>
              )}

              {/* If Not Appeoved By Admin Show A Image */}
              {(userDetails.middleLayer_id == "" ||
            userDetails.middleLayer_id == null) && userDetails.sponsored_user == 0 ? (
               
                <div className="notApproved">
                  <h3 className="notApprovedH3">Your account is not yet approved by Admin. You will be notified via email once you account is activated by admin.</h3>
                
                  <img src={PDEN} className="img-fluid" />
                  <img src={PDEN2} className="img-fluid" />
                  </div>
              
              ) : (
                <>
                  <MiddleSection {...this.props}/>
                </>
              )}
            {/* </Col> */}
            </>
          {/* </Row>
        </Container> */}
      {/* </div> */}
</>

    );
  }
}

const WithUseReducer = (props) => {

  let dispatch = useDispatch();




  return <JobseekerDashboard {...props} {...{ dispatch }} />;
}

export default WithUseReducer
