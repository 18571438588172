import React, { useState
  // , useEffect
 } from 'react';
import 'react-credit-cards/es/styles-compiled.css';
import { FlashMess, POST_API, Loader } from '../Helpers/Helpers';
import { Container, Row, Col, 
  // Form, 
  Button, Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player'
const MyTools = () => {
  const [loader, setLoader] = useState("hidden");
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  const [showProduct, setShowProduct] = useState(false);
  const [product, setProduct] = useState({});

  const showProductModal = (p,logo)=>{
    if(p === 'Biz-Link'){
      let product = {
        logo : logo,
        name : p,
        tag_line : "Biz-Link Tool Features:",
        bullets : <><li>Personalized AI-generated LinkedIn InMails.</li><li>Engages through likes, shares, comments of LinkedIn posts</li><li>Hosts, promotes LinkedIn webinars.</li><li>Provides detailed engagement analytics.</li></>,
        video : "https://www.youtube.com/embed/ROReVa_9xH8"
      };
      setProduct(product);
      setShowProduct(true);
    }else if(p === 'Biz-Chirp'){
      let product = {
        logo : logo,
        name : p,
        tag_line : "Biz-Chirp Tool Features:",
        bullets : <><li>Personalizes Twitter Direct Messages.</li><li>Increases engagement via retweeting, replying.</li><li>Facilitates targeting with smart algorithms.</li><li>Tracks detailed Twitter interactions.</li></>,
        video : "https://www.youtube.com/embed/x1WBh8p1IKw"
      };
      setProduct(product);
      setShowProduct(true);
    }else if(p === 'Tool-Admin'){
      let product = {
        logo : logo,
        name : p,
        tag_line : "Tool Admin Features:",
        bullets : <><li>Personalizes your Organization</li></>,
        video : "https://www.youtube.com/embed/x1WBh8p1IKw"
      };
      setProduct(product);
      setShowProduct(true);
    }
  }
 
const generateToken = (tool) => {
  var formdata = new FormData();
  formdata.append('app_name', 'NEXGEN');
  formdata.append('tool', tool);

  let request = {
    end_point: '/generate-token',
    token: localStorage.getItem("gs_token"),
    formdata: formdata,
  };
  setLoader('visible');
  POST_API(request)
    .then((resp) => {
      setLoader('hidden');
      if (resp.status === 'success') {
        window.open(resp.url, '_blank');
      } else {
        setMsg(resp.message);
        setMsgType('danger');
      }
  })
  .catch((e) => {
    setLoader('hidden');
    setMsg("Token Generation Error "+e);
    setMsgType('danger');
  });
};

  const handleClick = async (tool) => {
   
    switch (tool) {
      case 'Biz-Link':
      case 'Biz-Chirp':
          generateToken(tool);
          break;
          
      case 'Tool-Admin':
          // Assuming roleID is a variable in your scope
          if (localStorage.getItem("roleID") == 9) {
            generateToken(tool);
          }
          break;
          
      default:
          setMsg("Invalid Tool");
          setMsgType('danger');
          break;
      }
    
  };


  return (
    <Container fluid className="my-3">
<Modal
    show={showProduct}
    onHide={() => { setShowProduct(false) }}
    dialogClassName="modal-90w modal_all_ex_bun"
    aria-labelledby="example-custom-modal-styling-title"
    className='jobseeker_modal'
    centered
    >
      <div className="modal_overlay"></div>
      <Modal.Body>
        <div className='close_btn' onClick={() => { setShowProduct(false) }}>x</div>
        <Row>
          <Col sm={12} md={6} lg={6} className="d-flex flex-column justify-content-between align-items-start">

            <div className="modal_heading" style={{ backgroundColor: '#4E54C8' }}>
              <img src={product.logo} className="modal_service_img"  alt=""/>
              <h2 className="modal-content-h2">{product.name}</h2>
            </div>
            <p>{product.tag_line}</p>
            <ul>{product.bullets}</ul>
          </Col>
          <Col sm={12} md={6} lg={6} className="videoframe">

            {/* <div className="videoframe_img">
              <img src={product.logo} className="img-fluid w-100" />
            </div> */}

            <div className="video" style={{"position":"unset"}}>
              <ReactPlayer
                className='react-player'
                url={product.video}
                width='100%'
                height='100%'
              />
        </div>
      </Col>
    </Row>
  </Modal.Body>
</Modal>

      <Loader loadmsg="please wait" visibility={loader} />
      <Row>
        <Col md={12}>
          <h3>My Tools</h3>

          {msg !== '' ? (
    <FlashMess m={msg} mtype={msgType ? msgType : 'success'} />
  ) : (
    <></>
  )}

                  <Row>
                    <Col md={6} className="mt-3 mb-3 text-center">
                        <div className="card p-3 redemption_code h-100">
                          <div className='text-center'><img src="https://bizhubb.salesorbit.io/assets/home/images/bizlink2-fav.png" className='w-25' alt=""/></div>
                          <br />
                         <strong>Biz-Link</strong>
                          <Col className="mt-1">
                            <Row className='justify-content-center'>
                               <Button variant="outline-primary mb-2" onClick={() => handleClick('Biz-Link')}>Access Now</Button> &nbsp;&nbsp;&nbsp;
                               <Button variant="outline-primary mb-2" onClick={() => showProductModal('Biz-Link',"https://bizhubb.salesorbit.io/assets/home/images/bizlink2-fav.png")}>View Description</Button>
                            </Row>
                          </Col>
                        </div>
                      </Col>
                      <Col md={6} className="mt-3 mb-3 text-center">
                        <div className="card p-3 redemption_code h-100">
                        <div className='text-center'><img src="https://bizhubb.salesorbit.io/assets/home/images/bizlink2-fav.png" className='w-25' alt=""/></div>
                          <br />
                         <strong>Biz-Chirp</strong>
                          <Col className="mt-1">
                            <Row className='justify-content-center'>
                              <Button variant="outline-primary mb-2" onClick={() => handleClick('Biz-Chirp')}>Access Now</Button> &nbsp;&nbsp;&nbsp;
                              <Button variant="outline-primary mb-2" onClick={() => showProductModal('Biz-Chirp',"https://bizhubb.salesorbit.io/assets/home/images/bizlink2-fav.png")}>View Description</Button> 
                            </Row>
                          </Col>   
                        </div>
                      </Col>

                      {localStorage.getItem("roleID") == 9 && JSON.parse(localStorage.getItem("gs_user")).isMLM == 'Y' && 
                      
                      <Col md={6} className="mt-3 mb-3 text-center">
                        <div className="card p-3 redemption_code h-100">
                        <div className='text-center'><img src="https://bizhubb.salesorbit.io/assets/home/images/bizlink2-fav.png" className='w-25' alt=""/></div>
                          <br />
                         <strong>Tool Admin</strong>
                          <Col className="mt-1">
                            <Row className='justify-content-center'>
                              <Button variant="outline-primary mb-2" onClick={() => handleClick('Tool-Admin')}>Access Now</Button> &nbsp;&nbsp;&nbsp;
                              <Button variant="outline-primary mb-2" onClick={() => showProductModal('Tool-Admin',"https://bizhubb.salesorbit.io/assets/home/images/bizlink2-fav.png")}>View Description</Button> 
                            </Row>
                          </Col>   
                        </div>
                      </Col>
                      }
                  </Row> 
        </Col>
      </Row>
    </Container>
  );
};

export default MyTools;
