import React, { Component
	// , useState 
} from 'react';
import { Container, Row, Col,
	//  Tab, Nav, 
	 Form, Button,
	//   Image,
	   Modal } from "react-bootstrap";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';
// import Leftpanel from './Section/LeftPanel';
// import csvfilename from './files/add-contact.csv';
import './css/dashboard.css';
// import DataTable from "react-data-table-component";

// import DateTimePicker from "react-datetime-picker";
import dateFormat from "dateformat";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';






export default class EditCampaign extends Component {

	constructor(props) {
		super(props)
		this.state = {

			FromData: {

				campaign_name: '',
				description: '',
				allcontactID: [],
				templete_id: '',
				schedule_at: '',
				save_time: '',
				group_id: '',
				templete_type: '',
				template_content: '',


			},
			FromDataError:
			{

				campaign_name: 'false',
				description: 'false',
				allcontactID: '',
				templete_id: 'false',
				group_id: 'false',



			},
			FromDataError1:
			{

				contact_no: '',
				save_time: 'false',
				templete_type: 'false',

			},
			Msg: '',
			MsgType: '',
			loader: 'hidden',
			urlPath: '/campaign-details',
			ContactData: [],
			ContactDataError: [],

			TemplateData: [],
			SMSTemplateData: [],
			EmailemplateData: [],
			GroupData: [],
			GroupData1: [],
			templatePre: [],
			customTemplateflag: false,
			test_mail: false,
			test_email_address: '',
			testMailChecked: false,



		};

		//Binding

		this.handaleChange = this.handaleChange.bind(this)
		this.handaleSubmit = this.handaleSubmit.bind(this)
		this.handaleChangeTemplate = this.handaleChangeTemplate.bind(this)






	}




	validateForm(errors) {
		let valid = true;
		let sta = this;
		let FromDataError = this.state.FromDataError;

		for (const [key, value] of Object.entries(errors)) {
			if (value.length > 0) {
				if (key != 'description' && key != 'templete_id') {
					if (value == 'false') {
						FromDataError[key] = 'This field is required';
						sta.setState({ FromDataError })
					}

					valid = false;
					console.log('loop', valid)
				}
			}

		}


		console.log('funct', valid)

		return valid;
	};

	setContactValue(data) {


		//  let FromData = this.state.FromData;
		//  let FromDataError1 = this.state.FromDataError1;

		//  let check= FromData.allcontactID.includes(data)
		// if(!check){

		//   FromData.allcontactID.push(data);
		// }else{
		//   FromData.allcontactID.pop(data)
		// }  
		// FromDataError1.contact_no = 'false';
		// this.setState({FromDataError1})


		// console.log(FromData.allcontactID)
		// console.log(check)

	}


	handaleChange(data) {

		// let sta = this;
		let FromDataError = this.state.FromDataError;



		let name = data.target.name;
		let value = '';
		let FromData = this.state.FromData;


		value = data.target.value;
		FromData[name] = value;
		this.setState({ FromData }, () => {

		})

		//validate from
		var valid_obj = {
			value: value,
			rules: data.target.getAttribute("validaterule"),
			message: data.target.getAttribute("validatemsg")
		}

		validation(valid_obj).then((error) => {
			FromDataError[name] = error
			this.setState({ FromDataError }) //from error state
		})


	}

	handaleChange_date_new = (data) => {
		console.log(data);
		console.log('ddddd', dateFormat(data, "yyyy-mm-dd,H:MM:ss"))
		var dt = new Date(data);
		console.log(dt.toISOString())
		// let sta = this;
		// let FromDataError = this.state.FromDataError;
		let FromDataError1 = this.state.FromDataError1;
		let FromData = this.state.FromData;
		// console.log('ddddd',dateFormat(data, "yyyy-mm-dd,H:MM:ss"))
		FromData['schedule_at'] = dt.toISOString();
		this.setState({ FromData });
		if (data != "") {
			FromDataError1.schedule_at = '';
			this.setState({ FromDataError1 })
		}
	}

	handaleSubmit(e) {

		e.preventDefault();

		let sta = this;
		let FromDataError = this.state.FromDataError;
		let FromDataError1 = this.state.FromDataError1;
		let FromData = this.state.FromData;
		let id = this.props.match.params.code





		if (this.validateForm(FromDataError) && FromData.save_time && FromData.templete_type != '') {

			sta.setState({ loader: 'visible' })///loader on


			var formdata = new FormData();

			formdata.append("campaign_name", FromData.campaign_name);
			formdata.append("description", FromData.description);
			if (this.state.template_content == undefined) {
				formdata.append("templete_id", FromData.templete_id);
			} else {
				formdata.append("templete_id", '');
			}

			//formdata.append("schedule_at", FromData.schedule_at);
			formdata.append("schedule_at", dateFormat(FromData.schedule_at, "yyyy-mm-dd,H:MM:ss"));
			formdata.append("save_time", FromData.save_time);
			formdata.append("campaign_id", id);
			formdata.append("group_id", FromData.group_id);
			formdata.append("templete_type", 'EMAIL');
			formdata.append("template_content", this.state.template_content);

			let request = {
				end_point: '/edit-campaign',
				formdata: formdata,
				token: localStorage.getItem("gs_token")

			};



			POST_API(request).then(resp => {

				sta.setState({ loader: 'hidden' })///loader off

				console.log('......response', resp)

				if (resp.status == 'success') {
					//sta.setState({Msg:'Contact add Successfully',MsgType:'success'})


					this.props.history.push({
						pathname: '/campaign-details',
						state: { Msg: 'Campaign updated Successfully', MsgType: 'success' }

					})





				}
				else {

					sta.setState({ Msg: resp.message.campaign_name.join(), MsgType: 'danger' })

				}
			}).catch((ee) => {

				console.log('/Edit campaignERR', ee)
			});




		} else {
			if (FromData.templete_type == '') {

				FromDataError1.templete_type = 'This field is required';
				this.setState({ FromDataError1 })

			}
			if (!FromData.save_time) {

				FromDataError1.save_time = 'This field is required';
				this.setState({ FromDataError1 })
			}

			sta.setState({ Msg: 'Fill the form correctly!' })
			sta.setState({ MsgType: 'danger' })

		}


	}

	searchChange = (event) => {
		event.preventDefault();
		const {
			//  name,
			 value } = event.target;


		let sta = this;
		let ContactData = this.state.ContactData
		// let ContactDataError = this.state.ContactDataError
		let request = {
			end_point: '/list-contact?search_key=' + value,
			token: localStorage.getItem("gs_token")

		};

		sta.setState({ loader: 'visible' })///loader on


		GET_API(request).then(resp => {

			sta.setState({ loader: 'hidden' })///loader off


			if (resp.status == 'success') {

				ContactData = resp.data

				sta.setState({ ContactData })

			}
			else {



			}
		}).catch((ee) => {
			sta.setState({ loader: 'hidden' })///loader off

			console.log('/code list ERR', ee)
		});

	}

	handleCloseDatetime(param = '') {
		let FromData = this.state.FromData;
		let FromDataError1 = this.state.FromDataError1;
		this.setState({ testMailChecked: false })
		FromData.save_time = param

		if (param == 'schedule') {
			document.getElementById('showDatetime').style.display = 'block';
		} else {
			document.getElementById('showDatetime').style.display = 'none';
		}

		if (param) {
			FromDataError1.save_time = 'false';
			this.setState({ FromDataError1 })
		}


	}

	columns = [

		// {
		//     name: "Select",
		//     cell: row =>  {
		//                 return(
		//                     <Form.Group controlId="formBasicCheckbox">
		//                       <Form.Check   name="allcontactID" type="checkbox" defaultChecked={this.state.FromData['allcontactID'].includes(row.id)?true:''}  value={row.id} onClick={()=>this.setContactValue(row.id)} />                    
		//                     </Form.Group>


		//                     )
		//                     } 
		//                    ,
		//     sortable: true,
		//     width: '250px'

		// } ,

		{
			name: "Name",
			cell: row => (row.firstname || '') + ' ' + (row.lastname || ''),
			sortable: true
		},
		{
			name: "Email",
			cell: row => row.email,
			sortable: true
		},




	];



	componentDidMount() {

		let sta = this;
		let ContactData = this.state.ContactData
		let ContactDataError = this.state.ContactDataError
		let id = this.props.match.params.code
		let FromData = this.state.FromData
		let FromDataError = this.state.FromDataError;
		let FromDataError1 = this.state.FromDataError1;
		//for redirectin message show
		if (sta.props.location.state != undefined) {
			sta.setState({ Msg: sta.props.location.state.Msg })
			sta.setState({ MsgType: sta.props.location.state.MsgType })
		}


		let requestEdit = {
			end_point: '/details-campaign?id=' + id,
			token: localStorage.getItem("gs_token")
		};

		sta.setState({ loader: 'visible' })///loader on

		GET_API(requestEdit).then((resp) => {
			sta.setState({ loader: 'hidden' })///loader off
			console.log("ddddddddddddddddddd", resp.data)
			if (resp.status == 'success') {


				FromData['campaign_name'] = resp.data.campaign.campaign_name;
				FromDataError['campaign_name'] = (resp.data.campaign.campaign_name != null) ? '' : 'false'

				FromData['description'] = resp.data.campaign.description;
				FromDataError['description'] = (resp.data.campaign.description != null) ? '' : 'false'

				FromData['templete_id'] = resp.data.campaign.templete_id;
				FromDataError['templete_id'] = (resp.data.campaign.templete_id != null) ? '' : 'false'

				FromData['save_time'] = resp.data.campaign.save_time;
				FromDataError1['save_time'] = (resp.data.campaign.save_time != null) ? '' : 'false'
				// let newDate = new Date(resp.data.campaign.schedule_at)
				let newDate = resp.data.campaign.schedule_at
				FromData['schedule_at'] = newDate;
				FromDataError1['schedule_at'] = (resp.data.campaign.schedule_at != null) ? '' : 'false'

				FromData['type'] = resp.data.campaign.type;
				FromDataError1['type'] = (resp.data.campaign.type != null) ? '' : 'false'

				FromData['group_id'] = resp.data.campaign.group_id;
				FromDataError['group_id'] = (resp.data.campaign.group_id != null) ? '' : 'false'

				FromData['has_executed'] = resp.data.campaign.has_executed;

				this.handleCloseDatetime(FromData['save_time']);
				if (resp.data.campaign.templete_id == '') {
					sta.setState({ customTemplateflag: true })
				} else {
					sta.setState({ customTemplateflag: false })
				}

				sta.setState({ FromData })
				sta.setState({ FromDataError })
				sta.setState({ FromDataError1 })



			}
			else {

				sta.setState({ Msg: 'Not found', MsgType: 'danger' })


			}

		}).catch(() => {




		})



		let request = {
			end_point: '/list-contact',
			token: localStorage.getItem("gs_token")

		};

		sta.setState({ loader: 'visible' })///loader on


		GET_API(request).then(resp => {

			sta.setState({ loader: 'hidden' })///loader off


			if (resp.status == 'success') {

				ContactData = resp.data

				sta.setState({ ContactData })

			}
			else {



			}
		}).catch((ee) => {
			sta.setState({ loader: 'hidden' })///loader off

			console.log('/code list ERR', ee)
		});



		let TemplateData = this.state.TemplateData



		let requestTemp = {
			end_point: '/list-campaign-templete',
			token: localStorage.getItem("gs_token")

		};

		sta.setState({ loader: 'visible' })///loader on


		GET_API(requestTemp).then(resp => {

			sta.setState({ loader: 'hidden' })///loader off


			if (resp.status == 'success') {

				TemplateData = resp.data

				sta.setState({ TemplateData })
				this.handaleChangeTemplateOnLoad(this.state.FromData.type);


			}
			else {



			}
		}).catch((ee) => {
			sta.setState({ loader: 'hidden' })///loader off

			console.log('/TemplateData list ERR', ee)
		});


		let GroupData = this.state.GroupData



		let requestGroup = {
			end_point: '/list-contact-group',
			token: localStorage.getItem("gs_token")

		};

		sta.setState({ loader: 'visible' })///loader on


		GET_API(requestGroup).then(respGroup => {

			sta.setState({ loader: 'hidden' })///loader off


			if (respGroup.status == 'success') {

				GroupData = respGroup.data

				sta.setState({ GroupData })
				this.handleSelectGroup(GroupData)
			}
			else {



			}
		}).catch((ee) => {
			sta.setState({ loader: 'hidden' })///loader off

			console.log('/group list ERR', ee)
		});

		this.handleSelectTemplateGroup('predefined');

	}

	updateState = state => {

		let FromDataError1 = this.state.FromDataError1;
		let allData = []
		state.selectedRows.forEach((item) => {

			allData.push(item.id);
			//console.log(item);
		})
		let FromData = this.state.FromData
		FromData.allcontactID = allData
		this.setState({ FromData })

		console.log(allData)

		FromDataError1.contact_no = 'false';
		this.setState({ FromDataError1 })


	}
	rowSelected = state => {

		let FromData = this.state.FromData



		if (FromData.allcontactID.includes(state.id)) {
			return true;
		} else {
			return false;
		}
		//this.setState({ selectedRows: state.selectedRows });
	};

	handaleChangeTemplate(data) {

		// let sta = this;


		let value = '';
		let EmailemplateData = []

		if (data == 'SMS' || data == 'EMAIL') {
			value = data;
		} else {
			value = data.target.value;
		}

		this.state.TemplateData.forEach((items) => {
			if (items.template_type == value) {
				EmailemplateData.push(items);
			}
		})


		this.setState({ EmailemplateData })

		let FromData = this.state.FromData
		FromData.templete_type = value
		this.setState({ FromData })

		let FromDataError1 = this.state.FromDataError1;
		FromDataError1.templete_type = 'false';
		this.setState({ FromDataError1 })

	}

	handaleChangeTemplateOnLoad(data) {

		// let sta = this;


		let value = '';
		let EmailemplateData = []

		value = data;

		this.state.TemplateData.forEach((items) => {
			if (items.template_type == value) {
				EmailemplateData.push(items);
			}
		})


		this.setState({ EmailemplateData })

		let FromData = this.state.FromData
		FromData.templete_type = value
		this.setState({ FromData })

		let FromDataError1 = this.state.FromDataError1;
		FromDataError1.templete_type = 'false';
		this.setState({ FromDataError1 })

	}

	handleSelectGroup(param = '') {
		// let FromData = this.state.FromData;
		// let FromDataError1 = this.state.FromDataError1;

		let GroupData1 = [];

		if (param == 'sponsor' || param == 'jobseeker') {

			this.state.GroupData.map((items, key2) => {
				if (items.group_type == param) {
					GroupData1.push(items);
				}
			})



		} else {

			GroupData1 = param;
			// this.setState({GroupData1})
		}

		this.setState({ GroupData1 })


	}


	handleSelectTemplateGroup(param = '') {
		let sta = this;
		let FromData = this.state.FromData;
		// let FromDataError1 = this.state.FromDataError1;


		console.log(param)
		let TemplateData = this.state.TemplateData
		if (param == 'predefined') {

			let requestTemp = {
				end_point: '/list-predefine-templete',
				token: localStorage.getItem("gs_token")

			};

			sta.setState({ loader: 'visible' })///loader on

			GET_API(requestTemp).then(resp => {

				console.log(resp)
				sta.setState({ loader: 'hidden' })///loader off

				if (resp.status == 'success') {

					//let  EmailemplateData = resp.data
					let TemplateData = resp.data



					sta.setState({ TemplateData })
					// this.setState({EmailemplateData})
					this.handaleChangeTemplate(FromData.templete_type)
				}
				else {



				}
			}).catch((ee) => {
				sta.setState({ loader: 'hidden' })///loader off

				console.log('/TemplateData list ERR', ee)
			});

			sta.setState({ customTemplateflag: false })

		} else {

			let requestTemp = {
				end_point: '/list-campaign-templete',
				token: localStorage.getItem("gs_token")

			};

			sta.setState({ loader: 'visible' })///loader on


			GET_API(requestTemp).then(resp => {

				sta.setState({ loader: 'hidden' })///loader off


				if (resp.status == 'success') {
					let customTem = []
					resp.data.forEach((items) => {
						if (items.is_admin != 1) {
							customTem.push(items);
						}
					})



					TemplateData = customTem

					sta.setState({ TemplateData })
					this.handaleChangeTemplate(FromData.templete_type)

				}
				else {



				}
			}).catch((ee) => {
				sta.setState({ loader: 'hidden' })///loader off

				console.log('/TemplateData list ERR', ee)
			});

			sta.setState({ customTemplateflag: true })

		}

	}


	handaleSwitchModal = (type) => {
		if (type == 'emailModal') {
			if (this.state.test_mail) {
				this.setState({ test_mail: false })
				this.setState({ testMailChecked: false })
			} else {
				this.setState({ test_mail: true })
			}
		}
	}
	handleTestMail() {
		this.setState({ test_mail: true })
		this.setState({ testMailChecked: true })
	}

	handaleChangeEmailAddress = (event) => {
		this.setState({ test_email_address: event.target.value })
	}

	emailSendFunction = () => {
		this.setState({ loader: 'visible' })
		var formdata = new FormData();
		let FromData_s = this.state.FromData;
		formdata.append("test_email_address", this.state.test_email_address);
		if (this.state.customTemplateflag) {
			formdata.append("template_content", this.state.template_content);
			formdata.append("templete_id", '');
		} else {
			formdata.append("templete_id", FromData_s.templete_id);
			formdata.append("template_content", '');
		}
		formdata.append("campaign_name", FromData_s.campaign_name);



		let request = {
			end_point: '/test-mail-campaign',
			formdata: formdata,
			token: localStorage.getItem("gs_token")

		};



		POST_API(request).then(resp => {

			this.setState({ loader: 'hidden' })///loader off

			console.log('......response', resp)

			if (resp.status == 'success') {
				this.setState({ Msg: 'Email sent successfully', MsgType: 'success' })


				// this.props.history.push({
				// 	pathname: '/campaign-details',
				// 	state: { Msg: 'Campaign added Successfully', MsgType: 'success' }

				// })

				// setTimeout(() => {
				//   window.location.reload()

				// }, 3000);

				this.setState({ test_mail: false })
				this.setState({ testMailChecked: false })

			}
			else {

				this.setState({ Msg: 'Fill the form correctly!', MsgType: 'danger' })
				//sta.setState({Msg:'Contact add Failed',MsgType:'danger'})
				this.setState({ test_mail: false })
			}
		}).catch((ee) => {

			console.log('/addcontactERR', ee)
		});

	}

	render() {
		let FromData = this.state.FromData
		console.log(FromData);
		// let ContactData = this.state.ContactData
		return (
			<div>
				{FromData.hasOwnProperty("campaign_name") ? (<>


					<Container fluid className="dash_container">
						<Row>
							{/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
							<Col md={12}>
								<Loader loadmsg="please wait" visibility={this.state.loader} />

								<div className="tabpanelOneForm pt-3">


									<h3>Edit Campaign</h3>
									<Form
										className="tabform"
										onSubmit={this.handaleSubmit}
									>
										<Row className="m-0 mt-4 mb-4">


											<Col md={12} className="pl-0  ">
												{/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
												{this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}


												<Form.Row>
													<Form.Group controlId="formBasicEmail4" as={Col}>
														<Form.Label>Name</Form.Label>
														<Form.Control
															type="text"
															name="campaign_name"
															onChange={this.handaleChange}
															placeholder="Enter your campaign name"
															value={FromData.campaign_name}
															validaterule={['required']}
															validatemsg={['This field is required']}
														/>
														<p style={{ color: 'red' }}>{this.state.FromDataError.campaign_name == 'false' ? '' : this.state.FromDataError.campaign_name}</p>
													</Form.Group>
													{/* <Form.Group controlId="formBasicEmail4" as={Col} className="mt-4">
														<Form.Label style={{ marginLeft: "15px", marginRight: "10px" }}><Form.Check name="template_group" type="radio" value="predefined" onClick={() => this.handleSelectTemplateGroup('predefined')} label="Predefined" /></Form.Label>
														<Form.Label style={{ marginLeft: "15px", marginRight: "10px" }}><Form.Check name="template_group" type="radio" value="custom" onClick={() => this.handleSelectTemplateGroup('custom')} label="Custom" /></Form.Label>
													</Form.Group> */}

													{/* <Form.Group controlId="formBasicEmail22" as={Col}>
														<Form.Label>Template Type</Form.Label>
														<Form.Control as="select" name="templete_type" onChange={this.handaleChange, this.handaleChangeTemplate} defaultValue={''}>
															<option value="">--Select Type--</option>
															<option selected={FromData.type == 'SMS' ? 'selected' : ''} value="SMS">SMS Template</option>
															<option selected={FromData.type == 'EMAIL' ? 'selected' : ''} value="EMAIL">Email Tamplate</option>

														</Form.Control>
														<p style={{ color: 'red' }}>{this.state.FromDataError1.templete_type == 'false' ? '' : this.state.FromDataError1.templete_type}</p>
													</Form.Group> */}


												</Form.Row>
												<Form.Row>
													<Form.Group controlId="formBasicEmail22" as={Col} className="col-6">
														{this.state.customTemplateflag == false ? (<>

															<Form.Label>Select Template</Form.Label>
															<Form.Control as="select" name="templete_id" onChange={this.handaleChange} style={{ width: '100%' }}>
																<option value="">--Select Template--</option>

																{this.state.EmailemplateData.map((items, key2) => {
																	return (
																		<option selected={FromData.templete_id == items.id ? 'selected' : ''} value={items.id}>{items.templete_name}</option>
																	)
																})}
															</Form.Control>
															<p style={{ color: 'red' }}>{this.state.FromDataError.templete_id == 'false' ? '' : this.state.FromDataError.templete_id}</p>

														</>) : (<>
															<Form.Label>Template</Form.Label>
															<CKEditor
																editor={ClassicEditor}
																data={this.state.template_content}
																name='template_content'

																onInit={editor => {
																	// You can store the "editor" and use when it is needed.
																	// console.log( 'Editor is ready to use!', editor );
																}}
																onChange={(event, editor) => {
																	const data = editor.getData();
																	this.setState({ template_content: data })
																}}

																onBlur={(event, editor) => {
																	//console.log( 'Blur.', editor );
																}}
																onFocus={(event, editor) => {
																	//console.log( 'Focus.', editor );
																}}
															/>
														</>)}
													</Form.Group>
													<Form.Group controlId="formBasicEmail22" as={Col}>
														<Form.Label>Select Contact List</Form.Label>


														<Form.Control as="select" name="group_id" onChange={this.handaleChange} style={{ width: '100%' }}>
															<option value="">--Select Contact List--</option>

															{this.state.GroupData1.map((itemsg) => {
																if (itemsg.contactCount > 0) {
																	return (
																	<option 
																		key={itemsg.id} 
																		selected={FromData.group_id === itemsg.id} 
																		value={itemsg.id}
																	>
																		{itemsg.group_name}
																	</option>
																	);
																}
																return null; // Ensure to return null if the condition is not met
																})}
														</Form.Control>
														<p style={{ color: 'red' }}>{this.state.FromDataError.group_id == 'false' ? '' : this.state.FromDataError.group_id}</p>
													</Form.Group>

												</Form.Row>

												{/* <Form.Row>
													<Form.Group controlId="formGridAddress1" as={Col}>
														<Form.Label>Description</Form.Label>
														<Form.Control
															as="textarea"
															rows={3}
															name='description'
															placeholder="Enter Campaign Description"
															value={FromData.description}
															onChange={this.handaleChange}
															validaterule={['required']}
															validatemsg={['This field is required', 'Enter Description']}
														/>
														<p style={{ color: 'red' }}>{this.state.FromDataError.description == 'false' ? '' : this.state.FromDataError.description}</p>

													</Form.Group>
												</Form.Row> */}

												<Row>
													<Col md="3">
														<Form.Group controlId="formBasicCheckbox">
															<Form.Check name="save_time" type="radio" value="now" checked={FromData.save_time == 'now'} onClick={() => this.handleCloseDatetime('now')} label="Send Now" />
														</Form.Group>
													</Col>
													<Col md="3">
														<Form.Group controlId="formBasicCheckbox">
															<Form.Check name="save_time" type="radio" checked={FromData.save_time == 'schedule'} value="schedule" onClick={() => this.handleCloseDatetime('schedule')} label="Send Schedule" />
														</Form.Group>
													</Col>
													<Col md="3">
														<Form.Group controlId="formBasicCheckbox">
															<Form.Check name="test_mail" type="radio" value="test_mail" checked={this.state.testMailChecked} onClick={() => this.handleTestMail()} label="Test Mail" />
														</Form.Group>
													</Col>
													<Col md="12">
														<p style={{ color: 'red' }}>{this.state.FromDataError1.save_time == 'false' ? '' : this.state.FromDataError1.save_time}</p>
													</Col>
												</Row>


												<Form.Row id="showDatetime" style={{ display: 'none' }}>
													<Form.Group controlId="formBasicdate" as={Col}>
														<Form.Label>Send time</Form.Label><br />
														{/* <Form.Control
                                    type="datetime-local"
                                    name="schedule_at"
                                    
                                    value={FromData.schedule_at}
                                    onChange={this.handaleChange}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  /> */}

														<LocalizationProvider dateAdapter={AdapterDayjs}>
															<DemoContainer
																components={[
																	'MobileDateTimePicker',
																]}
															>
																<DemoItem>
																	<MobileDateTimePicker className='mb_datepicker_new' defaultValue={dayjs(FromData.schedule_at)} value={dayjs(FromData.schedule_at)} onChange={this.handaleChange_date_new} />
																</DemoItem>
															</DemoContainer>
														</LocalizationProvider>


														{/* <DateTimePicker
															onChange={this.handaleChange_date_new}
															value={FromData.schedule_at}
															minDate={new Date()}
															foonChange={this.handaleChange_date_new}rmat='MM-dd-yyyy, HH:mm aa'
														/> */}

														<p style={{ color: 'red' }}>{this.state.FromDataError.schedule_at == 'false' ? '' : this.state.FromDataError.schedule_at}</p>
													</Form.Group>

												</Form.Row>



											</Col>
											<Col md={8} className="pl-1 tabformRight">
												<Button
													variant="primary"
													type="submit"
													className="btn btn-primary btn-md tabformbutton"
												>
													Submit
												</Button>
											</Col>
										</Row>

									</Form>






								</div>
							</Col>
						</Row>
					</Container>

				</>) : (<p>....Loading</p>)}

				<Modal
					show={this.state.test_mail}
					onHide={() => { this.handaleSwitchModal('emailModal') }}
					dialogClassName=""
					aria-labelledby="example-custom-modal-styling-title"
					className='jobseeker_modal'
					centered
				>

					<Modal.Body>
						<div className='close_btn' onClick={() => { this.handaleSwitchModal('emailModal') }}>x</div>
						<div>
							<Row >
								<Col sm="12">
									<Form.Label>Email</Form.Label>
									<Form.Control
										type="text"
										size="large"
										name="test_email_address"
										onChange={this.handaleChangeEmailAddress}
									/>
								</Col>
							</Row>
							<Row className="mt-2">
								<Col sm="6">
									<Button
										variant="primary"
										type="button"
										className="btn btn-primary btn-md tabformbutton mt-2"
										onClick={() => this.emailSendFunction()}
									>
										Submit
									</Button>
								</Col>
							</Row>
						</div>
					</Modal.Body>

				</Modal>
			</div>




		);
	}
}


