import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Container, Row, Col, Tab, Nav, Form, Button, InputGroup } from "react-bootstrap";
import { withRouter } from "react-router";
import TokenVerify from "../Helpers/Helpers";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from "../Validate/validator";
import "./css/jobseekersignup.css"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import recurator from '../images/recruiters.png';
import 'react-phone-number-input/style.css'
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

export default class JobSekerBasicInfo extends Component {

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  }

  saveAndContinue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };




  render() {
    let { FromData } = this.props.inputValues;
    let { FromDataError } = this.props.formErr;
    console.log("err", FromData)
    return (

      <div>
        <div className="Becomesponsorfromarea">
          <Container>

            <Loader loadmsg="please wait" loadingtext={this.props.loadingtext} visibility={this.props.loader} />
            <Form className="tabform">
              <Row className="sponsorfrom p-4">

                <Col lg={7} >
                  {this.props.msgAffilicate !== '' ? (<><FlashMess m={this.props.msgAffilicate} mtype={this.props.MsgAffiliateType ? this.props.MsgAffiliateType : 'success'} /></>) : (<></>)}

                  {this.props.step1Msg !== '' ? (<><FlashMess m={this.props.step1Msg} mtype={this.props.step1MsgType} /></>) : (<></>)}
                  <Form.Row>
                    <Form.Group controlId="formBasicEmail" as={Col}>
                      <Form.Label>
                        First Name
                        <span className="text-danger"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="firstname"
                        value={FromData.firstname}
                        onChange={this.props.handaleChange}
                        placeholder="Enter first name"
                        validaterule={["required", "onlySpaceValueCheck"]}
                        validatemsg={["This field is required", "White or empty space is not allowed"]}
                      />
                      <p style={{ color: "red" }}>
                        {FromDataError.firstname == "false"
                          ? ""
                          : FromDataError.firstname}
                      </p>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formBasicEmail" as={Col}>
                      <Form.Label>
                        Last Name
                        <span className="text-danger"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="lastname"
                        value={FromData.lastname}
                        onChange={this.props.handaleChange}
                        placeholder="Enter last name"
                        validaterule={["required", "onlySpaceValueCheck"]}
                        validatemsg={["This field is required", "White or empty space is not allowed"]}
                      />
                      <p style={{ color: "red" }}>
                        {FromDataError.lastname == "false"
                          ? ""
                          : FromDataError.lastname}
                      </p>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formBasicEmail" as={Col} className="field_box">
                      <Form.Label>
                        Email
                        <span className="text-danger"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={FromData.email}
                        onChange={this.props.handaleChange}
                        placeholder="Enter email"
                        validaterule={["required", "isEmail"]}
                        validatemsg={["This field is required", "Invalid Email"]}
                      />
                      <p style={{ color: "red" }}>
                        {FromDataError.email == "false"
                          ? ""
                          : FromDataError.email}
                      </p>

                    </Form.Group>

                  </Form.Row>
                  <Form.Row>

                    <Form.Group controlId="formBasicEmail" as={Col} className="field_box">
                      <Form.Label>
                        Confirm Email
                        <span className="text-danger"> *</span>
                      </Form.Label>
                      <div className="d-flex w-100 verify_field">
                        <Form.Control
                          type="email"
                          name="confirm_email"
                          value={FromData.confirm_email}
                          onChange={this.props.handaleChange}
                          placeholder="Enter confirm email"
                          validaterule={["required", "isEmail", 'same|' + FromData.email]}
                          validatemsg={["This field is required", "Invalid Email", "Confirmed e-mail don't match"]}
                        />

                        {this.props.isMailValid &&
                          <div className="verify_icon d-flex align-items-center">
                            <i className="fa fa-check-circle" aria-hidden="true"></i>
                          </div>

                        }
                      </div>
                      <p style={{ color: "red" }}>
                        {FromDataError.confirm_email == "false"
                          ? ""
                          : FromDataError.confirm_email}
                      </p>

                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group controlId="formBasicEmail" as={Col}>
                      <Form.Label>
                        Phone
                        <span className="text-danger"> *</span>
                      </Form.Label>
                      
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="US"
                        value={FromData.contact_no}
                        onChange={this.props.handlePhoneChange}
                      />

                      <p style={{ color: "red" }}>
                        {FromDataError.contact_no == "false"
                          ? ""
                          : FromDataError.contact_no}
                      </p>
                    </Form.Group>

                  </Form.Row>
                  <Form.Row>

                    <Form.Group controlId="formBasicEmail" as={Col}>
                      <Form.Label>{"Profile Image (jpg,jpeg,png) (Optional)"}</Form.Label>
                      {/* <span className="text-danger"> *</span> */}
                      <Form.File
                        id="custom-file-translate-scss"
                        validaterule={["required",
                          "mime|jpeg/png", "filesize|1000000"]}
                        validatemsg={["This field is required"]}
                        name="profileimage"
                        onChange={this.props.handaleChange}
                        label={FromData.profilename || "Select file"}
                        lang="en"
                        custom
                      />
                      <p style={{ color: 'red' }}><small>Maximum file size allowed 1mb</small></p>
                      <p style={{ color: "green", marginTop: "5px" }}>
                        {FromData.profilename != ""
                          ? FromData.profilename
                          : ""}
                      </p>
                      <p style={{ color: "red" }}>
                        {FromDataError.profileimage == "false"
                          ? ""
                          : FromDataError.profileimage}
                      </p>
                    </Form.Group>
                    {!["CB", "RL", "HRC", "JNH", "SPV"].includes(this.props.servicetype) ? (
                      <Form.Group controlId="formBasicEmail" as={Col}>
                        <Form.Label>Upload Resume (.pdf)</Form.Label>
                        <span className="text-danger"> *</span>
                        <Form.File
                          id="custom-file-translate-scss2"
                          validaterule={["required", "mime|pdf", "filesize|2000000"]}
                          validatemsg={["This field is required"]}
                          name="file"
                          onChange={this.props.handaleChange}
                          label={FromData.filename || "Select file"}
                          lang="en"
                          custom
                        />
                        <p style={{ color: 'red' }}><small>Maximum file size allowed is 2mb</small></p>
                        <p style={{ color: "green", marginTop: "5px" }}>
                          {FromData.filename != ""
                            ? FromData.filename
                            : ""}
                        </p>

                        <p style={{ color: "red" }}>
                          {FromDataError.file == "false"
                            ? ""
                            : FromDataError.file}
                        </p>
                      </Form.Group>
                    ) : (<></>)}
                    {["SPV"].includes(this.props.servicetype) ? (
                      <Form.Group controlId="formBasicEmail" as={Col}>
                        <Form.Label>Upload Linkedin Profile (.pdf)</Form.Label>
                        <span className="text-danger"> *</span>
                        <Form.File
                          id="custom-file-translate-scss1"
                          validaterule={["required", "mime|pdf", "filesize|2000000"]}
                          validatemsg={["This field is required"]}
                          name="linkedin_resume_url"
                          onChange={this.props.liinkedinResumeUpload}
                          label={FromData.linkedin_resume_url_name || "Select file"}
                          lang="en"
                          custom
                        />
                        <p style={{ color: 'red' }}><small>Maximum file size allowed is 2mb</small></p>

                        <p style={{ color: "green", marginTop: "5px" }}>
                          {FromData.linkedin_resume_url_name != ""
                            ? FromData.linkedin_resume_url_name
                            : ""}
                        </p>
                        <p style={{ color: "red" }}>
                          {FromDataError.linkedin_resume_url == "false"
                            ? ""
                            : FromDataError.linkedin_resume_url}
                        </p>
                      </Form.Group>
                    ) : (<></>)}
                  </Form.Row>

                  <Form.Row className="justify-content-end mt-5">
                    <Form.Group as={Col} md={3}>
                      <Button
                        disabled={this.props.affiliate_code && !this.props.affiliateIsvalid}
                        variant="primary"
                        type="submit"
                        className="btn-main tabformbutton"
                        onClick={this.saveAndContinue}
                      >
                        NEXT
                      </Button>
                    </Form.Group>
                  </Form.Row>


                </Col>
                <Col lg={5}>
                  <img src={recurator} className="img-fluid" />
                </Col>

              </Row>
            </Form>

          </Container>
        </div>
      </div>

    )
  }
}
