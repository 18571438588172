import React, { Component } from 'react';
import { Route, Redirect } from "react-router-dom";

import { Container, Row, Col, ProgressBar } from "react-bootstrap";

import Header from "./Header";
import Sidepanel from "./Side-panel";
import NotificationHeader from "./NotificationHeader";
import Footer from "./Footer";
import TokenVerify from "../Helpers/Helpers";
import JobseekerSubscriptionBar from "./JobseekerSubscriptionBar";
import UserCreditBalance from "./UserCreditBalance";






class Frontend extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuCollapse: false,
            setMenuCollapse: false
        };
    }


    menuIconClick = () => {
        //condition checking to change state from true to false and vice versa
        this.setState({ menuCollapse: !this.state.menuCollapse })
    };

    componentDidMount() {
        if (window.innerWidth < 768) {
            this.setState({ menuCollapse: true })
        }

    }

 

    render() {

        setInterval(() => {
            TokenVerify()
        }, 200000);
        let condition = localStorage.getItem("gs_token") != null &&
            localStorage.getItem("gs_user") != null &&
            localStorage.getItem("gs_user") != undefined &&
            localStorage.getItem("gs_user") != ''
        //    &&
        //    this.props.children.props.match.path != "/jobseeker-purchase-cart"






        return (
            <>


                {/* || this.props.children.props.match.path == "/payment" */}
                {!condition ? (

                    <div>
                        {/* {this.props.children.props.match.path != "/login" && (
                    <Header {...this.props.children.props}/>
                )} */}

                        {this.props.children}

                        {this.props.children.props.match.path != "/login" && (
                            <Footer />
                        )}
                    </div>

                ) : (<>

                    <Container fluid>

                        <Row className='layout_row'>
                            <Sidepanel {...this.props.children.props} collapsed={this.state.menuCollapse} collapsedHandle={this.menuIconClick} />
                            <div className={this.state.menuCollapse ? ('topbar_active_package d-flex align-items-center justify-content-end mb-3 col-md-12') : ('topbar_active_package d-flex align-items-center justify-content-end mb-3 col-md-10')} style={this.state.menuCollapse ? ({ paddingLeft: "47px" }) : ({ paddingLeft: "120px" })}>
                            
                            
                            <UserCreditBalance/>
                            <NotificationHeader />
                            <JobseekerSubscriptionBar/>
                                
                           
                            </div>





                            {this.state.menuCollapse ?
                                (
                                    <Col sm={12} md={12} lg={12} className={this.state.menuCollapse ? "full_width_container pl-5 pr-5 right_panel" : ""} style={{ marginTop: "60px" }}>
                                        {/* <NotificationHeader /> */}
                                        {this.props.children}
                                    </Col>
                                ) :
                                (
                                    <Col sm={12} md={8} lg={9} className="right_panel" style={{ marginTop: "60px" }}>
                                        {/* <NotificationHeader /> */}
                                        {this.props.children}
                                    </Col>
                                )}

                        </Row>
                    </Container>
                    <Footer />
                </>)}




            </>

        );
    }
}


const FrontendRoute = ({ component: Component, auth, roles, ...rest }) => {



    if (auth == 'yes') {
        TokenVerify();
        //auth requeired
        if (localStorage.getItem("gs_token") != null &&
            localStorage.getItem("gs_user") != null &&
            localStorage.getItem("gs_user") != undefined &&
            localStorage.getItem("gs_user") != '') { //token have

            if (roles != undefined) {
                let user = JSON.parse(localStorage.getItem("gs_user"))
                if (roles.includes(user.roleID)) {//role matched and allow

                    if (user.roleID == 4) {
                        // if(user.sponsored_user== 1 && 
                        //     user.is_paid=="0" && 
                        //     rest.location.pathname != "/jobseeker-purchase" )
                        // {

                        //     return (  


                        //         <Route {...rest} render={matchProps => (  
                        //             <Redirect to="/jobseeker-purchase" />  
                        //         )} />  
                        //       ) 

                        // }
                        // else
                        // {
                        return (


                            <Route {...rest} render={matchProps => (
                                <Frontend>
                                    <Component {...matchProps} />
                                </Frontend>
                            )} />
                        )
                        // }

                    }
                    else {
                        return (


                            <Route {...rest} render={matchProps => (
                                <Frontend>
                                    {rest.type ? 
                                    <Component product_id = {rest?.product_id ?? 0} type={rest.type} {...matchProps} />
                                    :<Component  {...matchProps} /> 
                                    }
                                </Frontend>
                            )} />
                        )
                    }

                }
                else {//role not matched resirect login
                    return (


                        <Route {...rest} render={matchProps => (
                            <Redirect to="/login" />
                        )} />
                    )
                }
            }
            else {//all roles allowed
                return (


                    <Route {...rest} render={matchProps => (
                        <Frontend>
                            <Component {...matchProps} />
                        </Frontend>
                    )} />
                )
            }






        }
        else {
            //token not found
            return (


                <Route {...rest} render={matchProps => (
                    <Redirect to="/login" />
                )} />
            )

        }

    }
    else {
        //without auth allow
        return (


            <Route {...rest} render={matchProps => (
                <Frontend>
                    <Component {...matchProps} />
                </Frontend>
            )} />
        )
    }
};

export default FrontendRoute;