import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import  { FlashMess,GET_API,POST_API,Loader} from "../../Helpers/Helpers";
import validation from '../../Validate/validator';

import { BASE_URL } from "../../Config";

import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

export default class BulkReferalContactSentModal extends React.Component {
  constructor(props)
    {
        super(props)
        this.state = {
                        
                        FromData: {
                                       
                                        promo_code : '',
                                        
                                    
                                    },
                        FromDataError :
                                        {
                                          
                                        promo_code : 'false',
                                         
 
                                        
                                        },
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/contact-details',
                        all_promocode:[],
                                      
                        close: this.props.close
                     };

        //Binding

        this.handaleChange = this.handaleChange.bind(this)
        this.handaleSubmit = this.handaleSubmit.bind(this)
        this.stateCreate = this.stateCreate.bind(this)
       
        
            

    }

    validateForm  (errors) {
      let valid = true;
      let sta = this;
      let FromDataError = this.state.FromDataError;

      for (const [key, value] of Object.entries(errors)) {
        if(value.length > 0 )
        {

          if(value=='false')
          {
            FromDataError[key] = 'This field is required';
            sta.setState({FromDataError})
          }
          
          valid = false;
          console.log('loop',valid)
        }
      
      }
     
     
      console.log('funct',valid)
    
      return valid;
};
  

 

    handaleChange(data)
    {
       
        let sta = this;
        let FromDataError = this.state.FromDataError;
        

        let name = data.target.name;
        let value = '';
        let FromData = this.state.FromData;
        

        value = data.target.value;
        FromData[name] = value;
        this.setState({FromData},()=>{
         
        }) 

         //validate from
         var valid_obj = {
          value:value,
          rules:data.target.getAttribute("validaterule"),
          message:data.target.getAttribute("validatemsg")
        }

              validation(valid_obj).then((error)=>{
                  FromDataError[name] = error
                  this.setState({FromDataError}) //from error state
              })

       
    }

    handaleSubmit(e)
    {   
        // console.log(e)
        e.preventDefault();

        let sta = this;
        let FromDataError = this.state.FromDataError;
        let FromData = this.state.FromData;
        let group_id = this.props.match.params.id;
        
        if(this.validateForm(FromDataError))
        {
       
            
           
            sta.setState({loader:'visible'})///loader on
            var formdata = new FormData();
              
                formdata.append("promo_code",FromData.promo_code);
                formdata.append("group_id", group_id);
                formdata.append("link", BASE_URL);
                
                let request = {
                  end_point : '/affiliate-bulk-refer-friend',
                  formdata:formdata,
                  token: localStorage.getItem("gs_token")

                };

                POST_API(request).then(resp=>{
                 

                  sta.setState({loader:'hidden'})///loader off

                        if(resp.status == 'success')
                        {
                          sta.setState({Msg:'Referal sent successfully',MsgType:'success'})
                          
                         
                            setTimeout(() => {
                              window.location.reload()
                              
                            }, 3000);

                        }else{
                            sta.setState({ Msg: resp.message, MsgType: "danger" });
                        }
                       
                        }).catch((ee)=>{
                          
                            console.log('/bulkERR',ee)
                        });
                      
   
            }else
            {
                sta.setState({Msg:'Fill the form correctly!'})
                sta.setState({MsgType:'danger'})
            }
       
    }

    componentDidMount()
    {

        let sta = this;
        let FromData =this.state.FromData
        let FromDataError =this.state.FromDataError

        

        //for redirectin message show
        if(sta.props.location.state!=undefined)
        {
            sta.setState({Msg:sta.props.location.state.Msg})
            sta.setState({MsgType:sta.props.location.state.MsgType})
        }

        this.getall_promocode();
    }

    getall_promocode(){
        let sta = this;
        let all_promocode = this.state.all_promocode;
        try {
          if (localStorage.getItem("gs_user") != null) {
           
    
            GET_API({
              token: localStorage.getItem("gs_token"),
              end_point: "/get-all-affiliate-promocode",
            })
              .then((resp) => {
                if (resp.status == "success") {
                    all_promocode = resp.data;
                  this.setState({ all_promocode });
                }
                
              })
              .catch((ee) => {
              
              });
          }
        } catch (error) {}
        
      }


    stateCreate()
    {
      this.setState({Msg:''});
    }
  
 
 
  render() {
    let  FromData = this.state.FromData
    
    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
         backdrop="static"
         keyboard={false}
         onHide={this.state.close}
         id="modal"
         onShow={this.stateCreate}
         size="lg"
         centered
         >
        <Form
         className="tabform"
         onSubmit={this.handaleSubmit}
       >
          <Modal.Header closeButton>
            <Modal.Title>Send Bulk Referal.</Modal.Title>
          </Modal.Header>
          <Modal.Body>


      
         <Row className="m-0 mt-4 mb-4">
         <Loader loadmsg="please wait" visibility={this.state.loader}/>
       
           <Col md={12} className="pl-0">
               {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
      {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}


              <Form.Row>
              
                <Form.Group controlId="formBasicEmail4" as={Col}>
                <Form.Label>Select Promo Code</Form.Label>
                <Form.Control as="select" name="promo_code"  onChange={this.handaleChange}  defaultValue={''}  validaterule={['required']}
                   validatemsg={['This field is required']}>
                    <option value="">Select Promo Code</option>
                    {this.state.all_promocode.map((c_val, index) => (
                    <>
                    <option value={c_val.promo_code}>{c_val.promo_code}</option>
                    </>

                    ))}
                
                </Form.Control>
                <p style={{ color: 'red' }}>{this.state.FromDataError.promo_code=='false'?'': this.state.FromDataError.promo_code}</p>
                </Form.Group>

              
             </Form.Row>
            
  
           </Col>
           
         </Row>

    

            </Modal.Body>
          <Modal.Footer>
          <Button
               variant="primary"
               type="submit"
               className="btn btn-primary btn-md tabformbutton"
             >
               Submit
             </Button>
            
          </Modal.Footer>

          </Form>

        </Modal>
      </div>
    );
  }
}
