import React, { Component } from 'react';
import { Container, Row, Col, 
  // Tab, Nav, 
  Form, Button } from "react-bootstrap";
import { FlashMess, GET_API, POST_API,Loader } from "../../Helpers/Helpers";
import validation from '../../Validate/validator';
// import Leftpanel from '../Section/LeftPanel';
import PhoneInput
// , {
//   parsePhoneNumber,
//   isValidPhoneNumber,
// } 
from "react-phone-number-input";

class UpdateMember extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
                        list:{},
                        userId:'',
                        FromData: {
                            memberType : '',
                            member_contact_no : '',
                            member_firstname : '',
                            member_lastname : '',
                            member_email : '',
                            member_password : '',
                             max_user_share: '',
                        },
                        FromDataError :
                                        {
                                            memberType : 'false',
                                            member_contact_no : 'false',
                                            member_firstname : 'false',
                                            member_lastname : 'false',
                                            member_email : '',
                                            member_password : '',
                                             max_user_share: 'false',
                                        },
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/members',

                    }



                    ///bind
                    this.handaleChange = this.handaleChange.bind(this)
                    this.handaleSubmit = this.handaleSubmit.bind(this)
    }

    handalePhoneNoChange(data) {
      let FromData = this.state.FromData;
      let FromDataError = this.state.FromDataError;
      let value = '';
      FromData['member_contact_no'] = data;
      this.setState({ FromData }, () => {
  
      })
  
      if (FromData.member_contact_no != '') {
        var valid_obj = {
          value: value,
          rules: 'required',
          message: 'This field is required'
        }
        validation(valid_obj).then((error) => {
          FromDataError['member_contact_no'] = true
          this.setState({ FromDataError }) //from error state
        })
      }
  
    }
    
    validateForm  (errors) {
        let valid = true;
        let sta = this;
        let FromDataError = this.state.FromDataError;
  
        for (const [key, value] of Object.entries(errors)) {
          if(value.length > 0 )
          {
  
            if(value=='false')
            {
              FromDataError[key] = 'This field is required';
              sta.setState({FromDataError})
            }
            
            valid = false;
          }
        }
      
        return valid;
  };
  




    handaleChange(data)
  {
    if(data.target.type != "number" && data.target.type != "email"){
      const pointer = data.target.selectionStart;
      const element = data.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = pointer;
        element.selectionEnd = pointer;
      });
    }
      let sta = this;
      let {FromDataError,FromData} = sta.state;
      

      let name = data.target.name;
      let value =  data.target.value;
      FromData[name] = value;
    
      
     var valid_obj = {
        value:value,
        rules:data.target.getAttribute("validaterule"),
        message:data.target.getAttribute("validatemsg")
      }


    
      validation(valid_obj).then((error)=>{

       
       
        FromDataError[name] = error
        this.setState({FromDataError})
       

        this.setState({FromData},()=>{

         // console.log(FromDataError)
          
        })
      })
    
       

       
      
  }

  handaleSubmit(e)
    {   
        
        e.preventDefault();

        let sta = this;
        let {FromDataError,FromData} = this.state;


        if(this.validateForm(FromDataError))
        {
                    var formdata = new FormData();

                   
                 
                   

                    formdata.append("firstname", FromData.member_firstname);
                    formdata.append("lastname", FromData.member_lastname);
                   // formdata.append("email", FromData.member_email);
                  //  formdata.append("password", FromData.member_password);
                    formdata.append("contact_no", FromData.member_contact_no);
                    formdata.append("userID", this.state.userID);
                    formdata.append("employerID",JSON.parse(localStorage.getItem("gs_user")).userID);
                    formdata.append("assign_cap",FromData.max_user_share);
                    formdata.append("max_user_share",FromData.max_user_share);




                        let request = {
                                    end_point : '/update-employee',
                                    formdata:formdata,
                                    token: localStorage.getItem("gs_token")
                                    };
                    sta.setState({loader:'visible'})///loader on


                        POST_API(request).then((resp)=>{
                  sta.setState({loader:'hidden'})///loader off


                            if(resp.status=='success')
                            {
                                
                                


                                sta.props.history.push({
                                    pathname: '/members-affiliate',
                                    state: { Msg:'User update Successfully!',Msgtype:'succcess'}
                                })
                                
                                
                                
                            }
                            else{
                                //console.log(resp.message.password.join())
                                sta.setState({Msg:resp.message.email.join(),MsgType:'danger'})
                            }

                        }).catch((ee)=>{
                          
                        sta.setState({loader:'hidden'})///loader off
                        sta.setState({Msg:'Something Went Wrong,Please try again later.',MsgType:'danger'})
                        console.log('/CardADD ERR',ee)

                        })


        }
        else
        {
            sta.setState({Msg:'Fill the form correctly!'})
            sta.setState({MsgType:'danger'})
        }


       

    }


    componentDidMount()
    {
      let sta = this;
      let {FromData,FromDataError} = this.state

      this.setState({userID:this.props.match.params.id})

      let request = {
        end_point : '/get-profile-details?userID='+this.props.match.params.id,
        token: localStorage.getItem("gs_token")
        };

        sta.setState({loader:'visible'})///loader on
        
        GET_API(request).then((resp)=>{
          sta.setState({loader:'hidden'})///loader off

                    if(resp.status=='success')
                    {

                  
                      FromData['memberType'] = resp.data.user.role.rolename;
                      FromDataError['memberType'] = (resp.data.user.role.rolename != null)?'': 'false'

                      FromData['member_firstname'] = resp.data.user.firstname;
                      FromDataError['member_firstname'] = (resp.data.user.firstname != null)?'': 'false'


                      FromData['member_lastname'] = resp.data.user.lastname;
                      FromDataError['member_lastname'] = (resp.data.user.lastname != null)?'': 'false'


                      FromData['member_email'] = resp.data.user.email;
                      FromDataError['member_email'] = (resp.data.user.email != null)?'': 'false'
                    

                      // FromData['member_password'] = resp.data.member_password.dob;
                      // FromDataError['member_password'] = (resp.data.profile.member_password != null)?'': 'false'
                     
                      FromData['member_contact_no'] = resp.data.profile.contact_no;
                      FromDataError['member_contact_no'] = resp.data.profile.contact_no != null? '': '' ;

                       FromData['max_user_share'] = resp.data.user.maxSponsership;
                       FromDataError['max_user_share'] = resp.data.user.maxSponsership != null? '': 'false' ;

                      
                      sta.setState({FromData})
                      sta.setState({FromDataError})
                     

                    
                    }
                    else{
                              this.props.history.push({
                                pathname: '/members',
                                state: { Msg:'User Not Found!',MsgType:'danger'}
                            })
                        
                    }

        }).catch(()=>{
          sta.setState({loader:'hidden'})///loader off

                this.props.history.push({
                  pathname: '/members',
                  state: { Msg:'User Not Found!',MsgType:'danger'}
              })

        })
        
    }






    render() {

        let {FromData} = this.state
        return (
            <div>
                 
            

            <Container fluid className="dash_container">
          <Row>
            {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
            <Col md={12}>
            <Loader loadmsg="please wait" visibility={this.state.loader}/>

            <div className="tabpanelOneForm pt-3">

            <h3>Update Member</h3>
                {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
                
                


                <div className="tabpanelOneForm">
                {FromData.memberType!=''? (<>
              <Form className="tabform" onSubmit={this.handaleSubmit}>
                  
                    <Row className="m-0 mt-4 mb-4">
                            <Col md={12} className="">
                             
                              <Form.Row>

                              <Form.Group as={Col} controlId="formGridState" >
                                    <Form.Label>Member Type</Form.Label>
                                    <Form.Control
                                     validaterule={['required']}
                                     validatemsg={['This field is required']}
                                     as="select" readOnly defaultValue={FromData.memberType} name="memberType" onChange={this.handaleChange}>
                                    <option value="">select</option>
                                    <option selected={FromData.memberType=='affiliate_manager' ?'selected':''} value="affiliate_manager">manager</option>
                                    <option selected={FromData.memberType=='affiliate_employee' ?'selected':''} value="affiliate_employee">employee</option>
                                    </Form.Control>
                                    <p style={{ color: 'red' }}>{this.state.FromDataError.memberType=='false'?'': this.state.FromDataError.memberType}</p>

                                </Form.Group>


                                </Form.Row>
                                <Form.Row>

                                <Form.Group controlId="formBasicEmail" as={Col}>
                                  <Form.Label>First Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="member_firstname"
                                    value={FromData.member_firstname}
                                    onChange={this.handaleChange}
                                    placeholder="Enter  first name"
                                    validaterule={['required','isString']}
                                    validatemsg={['This field is required','First name must contain alphabets']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.member_firstname=='false'?'': this.state.FromDataError.member_firstname}</p>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail" as={Col}>
                                  <Form.Label>Last Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="member_lastname"
                                    value={FromData.member_lastname}
                                    onChange={this.handaleChange}
                                    placeholder="Enter  last name"
                                    validaterule={['required','isString']}
                                    validatemsg={['This field is required','Last name must contain alphabets']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.member_lastname=='false'?'': this.state.FromDataError.member_lastname}</p>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group controlId="formBasicEmail" as={Col}>
                                  <Form.Label>Email</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="member_email"
                                    value={FromData.member_email}
                                    readOnly
                                    onChange={this.handaleChange}
                                    placeholder="Enter  email"
                                    validaterule={['required','isEmail']}
                                    validatemsg={['This field is required','Invalid Email']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.member_email=='false'?'': this.state.FromDataError.member_email}</p>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail" as={Col}>
                                  <Form.Label>Phone</Form.Label>
                                  <PhoneInput
                                    international
                                    countryCallingCodeEditable={false}
                                    defaultCountry="US"
                                    value={FromData.member_contact_no}
                                    name="member_contact_no"
                                    onChange={this.handalePhoneNoChange.bind(this)}
                                    validaterule={['required', 'phone']}
                                    validatemsg={['This field is required', 'Invalid Phone number']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.member_contact_no=='false'?'': this.state.FromDataError.member_contact_no}</p>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                              <Form.Group controlId="formBasicEmailaff" className='col-md-6' as={Col}>
                                  <Form.Label>Max User Share</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="max_user_share"
                                    value={FromData.max_user_share}
                                    onChange={this.handaleChange}
                                    placeholder="Enter max user share"
                                    validaterule={['required', "notNegative"]}
                                    validatemsg={['This field is required', "Invalid max user share value"]}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.max_user_share=='false'?'': this.state.FromDataError.max_user_share}</p>
                                </Form.Group>
                                </Form.Row>

                              {/* <Form.Row>
                              <Form.Group controlId="formBasicEmail" as={Col}>
                                  <Form.Label>Password</Form.Label>
                                  <Form.Control
                                    type="password"
                                    name="member_password"
                                    onChange={this.handaleChange}
                                    value={FromData.member_password}
                                    placeholder="Enter Password"
                                    validaterule={['required','password']}
                                    validatemsg={['This field is required','password should be 6 charecter']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.member_password=='false'?'': this.state.FromDataError.member_password}</p>
                                </Form.Group>
                              </Form.Row> */}
                            </Col>
                            <Col md={12} className="">
                              <Button
                                variant="primary"
                                type="submit"
                                className="btn btn-primary btn-md tabformbutton"
                              >
                                Update
                              </Button>
                            </Col>
                         
                        
                    </Row>
                    </Form>
                    </>):(<h2>....Loading</h2>) }
                      
            </div>
               
                   
            </div>
            </Col>     
          </Row>
        </Container>

       


        </div>
        );
    }
}

export default UpdateMember;