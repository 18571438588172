import React, { useState, useEffect } from 'react';
import 'react-credit-cards/es/styles-compiled.css';
import { FlashMess, POST_API, Loader } from '../Helpers/Helpers';
import { Container, Row, Col,
  //  Form, 
   Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2'


const CreditRequestComponent = () => {
  
  const [transaction,setTransaction] = useState([]);
  const [showTable,setShowTable] = useState(false);
  const [credits,setCredits] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState("hidden");
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  
  
  useEffect(() => {
    getHistory();
  }, []);


  const getHistory = () => {
    var formdata = new FormData();
    let request = {
      end_point: '/get-request-credits',
      token: localStorage.getItem("gs_token"),
      formdata: formdata,
    };
    setLoader('visible');
    POST_API(request)
      .then((resp) => {
        setLoader('hidden');
        if (resp.status === 'success') {
          setTransaction(resp.data);
          setShowTable(true)
        } else {
            setTransaction([]);
            setShowTable(false)
        }
      })
      .catch((e) => {
        setLoader('hidden');
        setMsg("Credit Transaction : " +e);
        setMsgType('danger');

      });
}

const handleClick = async () => {
   setShowModal(true);
}

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handlePayment = async () => {
    if(localStorage.getItem("roleID") == 11){

    setLoader('visible');
    var formdata = new FormData();
    formdata.append('credits', credits);
    formdata.append('app_name', 'NEXGEN');
    formdata.append('gs_token', localStorage.getItem('gs_token'));
    let request = {
      end_point: '/request-credits',
      token: localStorage.getItem("gs_token"),
      formdata: formdata,
    };
    POST_API(request)
      .then((resp) => {
        setLoader('hidden');
        if (resp.status === 'success') {
          console.log('respsssspp', resp);
          setMsg(resp.message);
          setMsgType('succcess');
          setTimeout(function(){
           window.location.reload();
          },1500);
        } else {
          setMsg(resp.message);
          setMsgType('danger');
        }
      })
      .catch((e) => {
        console.log("Payment Error",e)
        setLoader('hidden');
        setMsg('Something Went Wrong,Please try again later.');
        setMsgType('danger');
      });

      return;
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    // Remove any non-numeric characters except for leading minus sign
    if (/^[0-9]*$/.test(value)) {
      setCredits(value);
    }
  };

  const handleKeyDown = (event) => {
    // Allow navigation keys, delete, backspace, tab, and enter
    const allowedKeys = [
      'Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Enter', 'Delete'
    ];

    if (allowedKeys.includes(event.key) || event.key === 'Control' || event.key === 'Shift') {
      return; // Allow these keys
    }

    // Prevent non-numeric keys
    if (event.key < '0' || event.key > '9') {
      event.preventDefault();
    }
  };

  return (
    <Container fluid className="my-3">
      <Loader loadmsg="please wait" visibility={loader} />
      <Row>
        <Col md={12}>
          <h3>Credit Request History</h3>
            <p>Current Available Credits : {transaction?.totalCredits}
            { 
                localStorage.getItem("roleID") == 11 &&
                <button  onClick={handleClick} className='float-right btn btn-primary' >Request Credit</button>
            }
          </p>
          <br />
          {msg !== '' ? (
    <FlashMess m={msg} mtype={msgType ? msgType : 'success'} />
  ) : (
    <></>
  )}

                  <Row>
            </Row> 
        </Col>
        {showTable && <CreditRequestHistory txn={transaction.items} /> }
      </Row>
      <Modal show={showModal} onHide={handleCloseModal}>
        <FlashMess m={msg} mtype={msgType ? msgType : 'success'} />
        <Modal.Header closeButton>
          <Modal.Title>Please enter Number of credits you wants</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="number" value={credits} className="form-control" step="1" min="10"  
          onChange={handleChange}
          onKeyDown={handleKeyDown}
            />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handlePayment}>Request</Button>
        </Modal.Footer>
      </Modal>
</Container>
  );
};

export default CreditRequestComponent;

const CreditRequestHistory = (props)=>{
  const [txnData,setTxnData] = useState(props.txn);
  const [loader, setLoader] = useState("hidden");
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  

  const handleRequest = async (status,id) => {
    let submit = false;
    let reason = "";
    if (status === "REJECTED") {
        
        const { value: reason2 } = await Swal.fire({
          title: "Please enter remarks",
          input: "text",
          inputLabel: "Enter Remarks",
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return "You need to write something!";
            }
          }
        });
        if (reason2 !== null && reason2.trim() !== "") {
            submit = true; // Assuming you want to proceed after rejection with a reason
            reason = reason2;
        }
    } else {

      const result = await Swal.fire({
        title: "Are you sure you want to perform this action ?",
        showCancelButton: true,
        confirmButtonText: "Go",
      });

      if (result.isConfirmed) {
        submit = true;
      }
    }
    if(submit){
        setLoader('visible');
        var formdata = new FormData();
        formdata.append('status', status);
        formdata.append('remarks', reason);
        formdata.append('id', id);
        formdata.append('app_name', 'NEXGEN');
        formdata.append('gs_token', localStorage.getItem('gs_token'));
        let request = {
        end_point: '/update-request-credits',
        token: localStorage.getItem("gs_token"),
        formdata: formdata,
        };
        POST_API(request)
        .then((resp) => {
            setLoader('hidden');
            if (resp.status === 'success') {
            console.log('respsssspp', resp);
            setMsg(resp.message);
            setMsgType('succcess');

            Swal.fire({
              title: "Success",
              text: resp.message,
              icon: "success",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Yes"
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });

           
            } else {
            setMsg(resp.message);
            setMsgType('danger');
            }
        })
        .catch((e) => {
            console.log("Payment Error",e)
            setLoader('hidden');
            setMsg('Something Went Wrong,Please try again later.');
            setMsgType('danger');
        });

        return;
    }

};

  return <>
   
    <Col md={12}>

    {msg !== '' ? (
    <FlashMess m={msg} mtype={msgType ? msgType : 'success'} />
  ) : (
    <></> )}
        

        {txnData && txnData.length > 0 && <>

        
              
          <table className='table table-bordered table-responsive'>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Date</th>
                  <th>Credits</th>
                  <th>Remarks</th>
                  <th>Status</th>
                  {localStorage.getItem("roleID") == 9 && <th>Action</th> }
                  
                </tr>
              </thead>
              <tbody>
                {txnData.map((item,index)=>{
                     return (  
                          <tr key={index}>
                              <td>{++index}</td>
                              <td>{item.created_at}</td>
                              <td>{item.credits}</td>
                              <td>{item?.remarks}</td>
                              <td>{item.status}</td>
                              {localStorage.getItem("roleID") == 9 && item.status == "PENDING" &&  <>
                              <td>
                                    <button className='btn btn-success p-2 mr-3' onClick={()=>{handleRequest('APPROVE',item.id)}}>Approve</button>
                                    <button className='btn btn-danger p-2' onClick={()=>{handleRequest('REJECTED',item.id)}}>Reject</button>
                                </td> 
                              </>}
                            </tr>
                          );
                })}

              </tbody>
          </table>
        </> }
        </Col>

  </>
}
