import React, { Component } from 'react';
import { useParams } from "react-router-dom";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';

import { API_URL } from '../Config';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Loginrightimage from '../images/login_right_panel.png';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";




class ChangePassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            FromData: {
                password: '',
                confirm_password: ''

            },
            FromDataError:
            {
                password: 'false',
                confirm_password: 'false'


            },
            isValid: false,
            Msg: '',
            MsgType: '',
            token: '',
            loadingMsg: '....Loading',
            loader: 'hidden',
            inputType: 'password',


        };

        //Binding

        this.handaleChange = this.handaleChange.bind(this)
        this.handaleSubmit = this.handaleSubmit.bind(this)

    }

    validateForm(errors) {
        let valid = true;
        let sta = this;
        let FromDataError = this.state.FromDataError;

        for (const [key, value] of Object.entries(errors)) {
            if (value.length > 0) {

                if (value == 'false') {
                    FromDataError[key] = 'This field is required';
                    sta.setState({ FromDataError })
                }

                valid = false;
            }
        }

        return valid;
    };

    toggleEye = () => {

        if (this.state.inputType === "password") {
            this.setState({ inputType: 'text' });

        }
        else {
            this.setState({ inputType: 'password' });

        }
    }


    handaleChange(data) {
        let sta = this;
        let FromDataError = this.state.FromDataError;


        //from data update state
        let name = data.target.name;
        let value = data.target.value;
        let FromData = this.state.FromData;
        FromData[name] = value;


        var valid_obj = {
            value: value,
            rules: data.target.getAttribute("validaterule"),
            message: data.target.getAttribute("validatemsg")
        }



        validation(valid_obj).then((error) => {



            FromDataError[name] = error
            this.setState({ FromDataError })


            this.setState({ FromData })
        })



    }
    handaleSubmit(e) {

        e.preventDefault();

        let sta = this;
        let FromDataError = this.state.FromDataError;
        let FromData = this.state.FromData;
        let formBody = [];


        if (this.validateForm(FromDataError)) {
            var formdata = new FormData();
            formdata.append("reset_token", sta.state.token);
            formdata.append("password", FromData.password);
            formdata.append("password_confirmation", FromData.confirm_password);

            let request = {
                end_point: '/set-password',
                formdata: formdata
            };

            sta.setState({ loader: 'visible' })///loader on

            POST_API(request).then((resp) => {
                sta.setState({ loader: 'hidden' })///loader on

                console.log('tttttttt', resp.message)
                if (resp.status == 'success') {
                    console.log('reset_password', resp)
                    sta.props.history.push({
                        pathname: '/login',
                        state: { Msg: 'Your Password Has Been Changed Successfully!', MsgType: 'success' }
                    })


                }
                else {
                    //console.log('tttttttt',resp.message.password.join())
                    sta.setState({ Msg: resp.message, MsgType: 'danger' })
                }

            }).catch(() => {
                sta.setState({ loader: 'hidden' })///loader on


            })


        }
        else {
            sta.setState({ Msg: 'Fill the form correctly!' })
            sta.setState({ MsgType: 'danger' })
        }




    }

    componentDidMount() {
        let sta = this;


        let token = this.props.match.params.token

        if (token != undefined) {
            var formdata = new FormData();
            formdata.append("reset_token", token);


            let request = {
                end_point: '/validate-reset-token',
                formdata: formdata
            };
            sta.setState({ loader: 'visible' })///loader on

            POST_API(request).then(resp => {
                sta.setState({ loader: 'hidden' })///loader on


                if (resp.status == 'success') {
                    sta.setState({ token })
                }
                else {
                    sta.setState({ loadingMsg: '.....Link Expired' })

                    setTimeout(function () {
                        sta.props.history.push({
                            pathname: '/forget-password'
                        })


                    }, 3000);
                }

            }).catch(() => {

            })

        }


        if (sta.props.location.state != undefined) {
            sta.setState({ Msg: sta.props.location.state.Msg })
            sta.setState({ MsgType: sta.props.location.state.MsgType })
        }


    }



    render() {

        let { token, loadingMsg } = this.state;
        const eye = <FontAwesomeIcon icon={faEye} />;
        const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
        return (





            <Container>
                <Row className="justify-content-center banner_container">
                    <Col lg={10} className="login_box">
                        <Row>
                            <Col lg={6} md={6} sm={12} className="loginForm">
                                <Loader loadmsg="please wait" visibility={this.state.loader} />

                                <h3 className="text-center">Change Password</h3>
                                {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
                                {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}

                                {(token !== '') ? (<>

                                    <Form onSubmit={this.handaleSubmit}>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                type={this.state.inputType}
                                                className="password"
                                                name="password"
                                                onChange={this.handaleChange}
                                                placeholder="New Password"
                                                validaterule={['required', 'password']}
                                                validatemsg={['This field is required', 'password should be of at least 6 characters']}
                                            />
                                            <i style={{ marginTop: '-38px', marginRight: '16px', position: 'absolute', zIndex: 1, cursor: 'pointer', right: '51px', bottom: '310px' }} onClick={() => { this.toggleEye() }}>{this.state.inputType == 'text' ? eyeSlash : eye}</i>

                                            <p style={{ color: 'red' }}>{this.state.FromDataError.password == 'false' ? '' : this.state.FromDataError.password}</p>
                                        </Form.Group>

                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Control type="password"
                                                className="password"
                                                name="confirm_password"
                                                onChange={this.handaleChange}
                                                placeholder="Confirm Password"
                                                validaterule={['required', 'password', 'same|' + this.state.FromData.password]}
                                                validatemsg={['This field is required', 'password should be of at least 6 characters', 'Confirmed Password should be same as password']}
                                            />
                                            <p style={{ color: 'red' }}>{this.state.FromDataError.confirm_password == 'false' ? '' : this.state.FromDataError.confirm_password}</p>
                                        </Form.Group>

                                        <Row>

                                        </Row>

                                        <Button type="submit" className="btn-main btn-block login_button">
                                            Change Password
                                        </Button>
                                    </Form>


                                </>) : (<><div><h2>{loadingMsg}</h2>
                                    <Loader loadmsg="please wait" visibility={this.state.loader} />
                                </div></>)}


                            </Col>
                            <Col lg={6} md={6} sm={12} className="p-0 d-none d-md-block"><img src={Loginrightimage} className="img-fluid" /></Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        );
    }
}

export default ChangePassword;