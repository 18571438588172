import React, { Component, useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Tab,
    Nav,
    Form,
    Button,
    Image,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";



const MyHrContactsGroup = ({ searchInput, myContactsSearchable_data, loading_searchable, resetMyHR }) => {
    const [contacts, setContacts] = useState([])
    const [filterData, setFilterData] = useState([])
    useEffect(() => {
        let request = {
            end_point: '/get-my-hr-group',
            token: localStorage.getItem("gs_token")
        };
        GET_API(request).then(resp => {
            setContacts(resp.contacts)
            setFilterData(resp.contacts)
            console.log("...........", resp.contacts)
        }).catch((ee) => {
            console.log(ee)
        });
    }, []);
    useEffect(() => {
        if (resetMyHR) {
            let request = {
                end_point: '/get-my-hr-group',
                token: localStorage.getItem("gs_token")
            };
            GET_API(request).then(resp => {
                setContacts(resp.contacts)
                setFilterData(resp.contacts)
                console.log("...........", resp.contacts)
            }).catch((ee) => {
                console.log(ee)
            });
        }
    }, [resetMyHR]);
    useEffect(() => {
        console.log("search", searchInput)
        let filteredData = contacts.filter((value) => {

            if (searchInput != "") {
                console.log("value", value)
                let Search =
                    value?.company_name?.toLowerCase().includes(searchInput.toLowerCase())
                   
                return Search

            }
            return true;
        });
        setFilterData(filteredData)
    }, [searchInput]);

    useEffect(() => {
        if (loading_searchable) {
            setFilterData(myContactsSearchable_data)
        }
    }, [loading_searchable]);

    return (
        <Tab.Pane eventKey="second">
            <DataTable
                title=""
                columns={columns}
                data={filterData}
                defaultSortField="title"
                pagination={true}
                // selectableRows
            />
        </Tab.Pane>
    );
}

let columns = [
    {
        name: "Title",
        selector: "job_title",
        sortable: true,
    },
    {
        name: "Company",
        selector: "company_name",
        sortable: true,
    },
    
    {
        name: "Link",
        selector: row =><a target="_blank" href={row.job_link}>Visit Job</a>,
        sortable: true,
    },
    
    {
        name: "Total Hr Count",
       // selector: row =><a  href={row.hr_count}>{row.hr_count}</a>,
       cell: row => {
        return (
          <div className='d-flex'><a href={'#/jobseeker-hrcontacts-list/' + row.id}>({row.hr_count}) Show List</a>
           
          </div>
        )
      },
        sortable: true,
    },


]
export default MyHrContactsGroup;
// GET_API(request).then(resp => {

//     console.log("...../get-hrContacts",resp.contacts)

//     this.setState({ Allhrcontacts: resp.contacts })
//     // this.setState({loader:'hidden'})

// }).catch((ee) => {

//     // this.setState({loader:'hidden'})
// });


