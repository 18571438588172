import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { FlashMess, GET_API, POST_API, Loader } from "../../Helpers/Helpers";
import validation from '../../Validate/validator';
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

export default class ShareLink extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

      FromData: {
        email: '',
        template: '',

        shared_to_name : ''
      },
      FromDataError:
      {
        email: 'false',
        shared_to_name : 'false'

      },
      Msg: '',
      MsgType: '',
      loader: 'hidden',
      button_disable: false,
      template: ''



    };

    //Binding

    this.handaleChange = this.handaleChange.bind(this)
    this.handaleSubmit = this.handaleSubmit.bind(this)


  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
      }
    }

    return valid;
  };

  handaleChange(data) {
    let sta = this;
    let FromDataError = this.state.FromDataError;


    let name = data.target.name;
    let value = data.target.value;
    let FromData = this.state.FromData;

    if(name == "shared_to_name"){
      this.getTemplate();
    }


    FromData[name] = value;

    this.setState({ FromData }, () => {

    })

    //validate from
    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg")
    }

    validation(valid_obj).then((error) => {
      FromDataError[name] = error
      this.setState({ FromDataError }) //from error state
    })


  }
  getTemplate(){
    return this.state.template.replace('{{link}}', this.props.refcode).replace('{{code}}', this.props.affiliate_code).
                                replace('{{name}}',
                                (this.state.FromData.shared_to_name != false && typeof this.state.FromData.shared_to_name != "undefined") ? this.state.FromData.shared_to_name :  this.state.FromData.shared_to_name ?? "");
   }  

  handaleSubmit(e) {

    e.preventDefault();

    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;

    if (this.validateForm(FromDataError)) {
      sta.setState({ button_disable: true })
      sta.setState({ loader: 'visible' })///loader on


      var formdata = new FormData();
      formdata.append("email", FromData.email || '');
      formdata.append("key", 'ref_link');
      formdata.append("link", this.props.refcode);
      formdata.append("template", FromData.template);


      let request = {
        end_point: '/affiliate-share-code',
        formdata: formdata,
        token: localStorage.getItem("gs_token")

      };



      POST_API(request).then(resp => {

        sta.setState({ loader: 'hidden' })///loader off


        if (resp.status == 'success') {
          sta.setState({ Msg: 'Mail Sent Successfully', MsgType: 'success' })
          if(sta.props.handleReedemClear){
            sta.props.handleReedemClear();
          }
          setTimeout(() => {
            sta.props.close()

          }, 500);

        }
        else {


          sta.setState({ Msg: resp.message, MsgType: 'danger' })
        }
      }).catch((ee) => {

        sta.setState({ loader: 'hidden' })///loader on
        sta.setState({ Msg: 'Something Went Wrong. Try After Some Time.', MsgType: 'danger' })
        console.log('/InvittationERR', ee)
      });



    } else {

      sta.setState({ Msg: 'Please Enter Email ID' })
      sta.setState({ MsgType: 'danger' })
    }


  }

  componentDidMount() {

    let request = {
      end_point: '/affiliate-ref-template-fetch',

      token: localStorage.getItem("gs_token")
    }

    GET_API(request).then((resp) => {

      if (resp.status == 'success') {
        let dataRes = resp.data;

        let user = JSON.parse(localStorage.getItem("gs_user"))
        //dataRes = dataRes.replace('{{firstname}}', user.firstname)
        dataRes = dataRes.replace('{{firstname}}', "(Enter Recipient Name)")
        //dataRes = dataRes.replace('{{lastname}}', user.lastname)
        dataRes = dataRes.replace('{{lastname}}', ",")
        this.setState({ template: dataRes })

      }

    }).catch(() => {


    })

  }


  onshowModal() {
    let FromData = this.state.FromData;
    FromData.email = ''
    this.setState({ button_disable: false, Msg: "", FromData })
  }




  render() {

    let { FromData } = this.state

    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
          backdrop="static"
          keyboard={false}
          onHide={this.props.close}
          onShow={() => { this.onshowModal() }}
          id="modal"
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Share Link</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Loader loadmsg="please wait" visibility={this.state.loader} />

            <Form
              className="tabform"
              onSubmit={this.handaleSubmit}
            >
              <Row className="m-0 mt-4 mb-4">
                <Col md={12} className="pr-5 ">
                  {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
                  {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}



                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      onChange={this.handaleChange}
                      placeholder="Enter Email"
                      value={FromData.email || ''}
                      validaterule={['required', 'isEmail']}
                      validatemsg={['This field is required', 'Enter a valid email']}

                    />
                    <p style={{ color: 'red' }}>{this.state.FromDataError.email == 'false' ? '' : this.state.FromDataError.email}</p>
                  </Form.Group>

                  <Form.Group >
                    <Form.Label>Name <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      name="shared_to_name"
                      id="shared_to_name"
                      onChange={this.handaleChange}
                      placeholder="Enter Name"
                      value={this.state.FromData.shared_to_name}
                      validaterule={['required']}
                      validatemsg={['This field is required']}

                    />
                    <p style={{ color: 'red' }}>{this.state.FromDataError.shared_to_name == 'false' ? '' : this.state.FromDataError.shared_to_name}</p>
                  </Form.Group>

                  <Form.Group controlId="formBasicTexteditor">
                    <Form.Label>Email template</Form.Label>
                    <CKEditor
                      disabled={true}
                      editor={ClassicEditor}
                      data={this.state.template.replace('{{link}}', this.props.refcode).replace('{{name}}', this.state.FromData.shared_to_name).replace('{{code}}', this.props.affiliate_code) || ''}
                      onInit={editor => {
                        // You can store the "editor" and use when it is needed.

                        const data = editor.getData();
                        FromData.template = data;
                        this.setState({ FromData })
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        FromData.template = data;
                        this.setState({ FromData })
                      }}
                      onBlur={(event, editor) => {
                        //console.log( 'Blur.', editor );
                      }}
                      onFocus={(event, editor) => {
                        //console.log( 'Focus.', editor );
                      }}
                    />





                  </Form.Group>






                  {/* company_description */}

                </Col>
                <Col md={8} className="">
                  <Button
                    disabled={this.state.button_disable}
                    variant="primary"
                    type="submit"
                    className="btn btn-primary btn-md tabformbutton"
                  >
                    Share
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>

        </Modal>
      </div>
    );
  }
}
