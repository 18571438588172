const setBalanceFlag = (state = [], action) => {
    switch (action.type) {
      case "UPDATE-BALANCE":
        return action.refreshBalance;
      default:
        return state;
    }
  };
  
  export default setBalanceFlag;
  