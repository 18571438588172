import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import  { FlashMess,GET_API,POST_API,Loader} from "../../Helpers/Helpers";
import validation from '../../Validate/validator';

import DataTable from "react-data-table-component";
import { BASE_URL } from '../../Config';

import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import moment from "moment";

export default class MemberDashboardViewModal extends React.Component {
  constructor(props)
    {
        super(props)
        this.state = {
                        
                        FromData: {
                                       
                                        firstname : '',
                                        lastname : '',
                                        contact_no : '',
                                        email : '',
                                        type : '',
                                       
                                        
                                    },
                        FromDataError :
                                        {
                                          
                                          firstname : 'false',
                                          lastname : 'false',
                                          contact_no : 'false',
                                          email : 'false',
                                          type : '',
                                        
                                        
                                        },
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/contact-details',
                        ContactList:[],
                        fromDate:'',
                        toDate:'',
                        memberDashModal:false,
                        sponsor_URL:'',
                        sponsor_URL:'',
                        sponsor_count:'',
                        Jobseeker_URL:'',
                        Jobseeker_count:'',
                        commissionList:'',
                        total_Commission:'',
                        due_commission:'',
                        monthcommission:'',
                        percentage:'',
                       
                                      
                        
                     };

    
        
        
            

    }

    
    columns = [
       
        {
            name: "Name",
            cell: row => row.aff_firstname+' '+row.aff_lastname ,
            sortable: true
          },
          {
            name: "Date",
            // cell: row => row.created_at ,
            cell: row => moment(row.created_at).format('MM-DD-YYYY'),
            sortable: true
          },
          {
            name: "Role",
            cell: row => row.roleID ,
            sortable: true
          },
          {
            name: "Amount",
            cell: row => row.commission_amount ,
            sortable: true
          },
          {
            name: "Status",
            cell: row =>
            {
              return(
                <div>{row.has_paid==1?'Paid':'Unpaid'}</div>
                         
                    
                      
                  )
            } 
            ,
            sortable: true
          },
          
         
          
         
          
        
      ];
      


    // componentDidMount()
    // {

    //     let sta = this;
    //     let FromData =this.state.FromData
    //     let FromDataError =this.state.FromDataError

       

        
    //     if(sta.props.location.state!=undefined)
    //     {
    //         sta.setState({Msg:sta.props.location.state.Msg})
    //         sta.setState({MsgType:sta.props.location.state.MsgType})
    //     }

    //     try {
    //         if(localStorage.getItem("gs_user") != null)
    //         {
                

                
                
    //         }
            
           
    //       } 
    //       catch (error) {
            
    //       }
     

      
    // }

    getoneRecord()
    {
      // console.log('Base url',BASE_URL)
        let sta = this;
        let ContactList =this.state.ContactList
        let member_id =this.props.member_id;
        
       // console.log(start_date)
     
        //for redirectin message show
   
        let request = {
            end_point : '/view-employee-list?userID='+member_id+'&baseurl='+BASE_URL,
            token: localStorage.getItem("gs_token")

        };

        sta.setState({loader:'visible'})///loader on


            GET_API(request).then(resp=>{

                     sta.setState({loader:'hidden'})///loader off

                   
                            if(resp.status == 'success')
                            {
                               // console.log(resp.data) 

                                ContactList = resp.data
                                    sta.setState({ContactList})
                                    let Jobseeker_count = resp.data.Jobseeker_count;
                                    let sponsor_count = resp.data.sponsor_count;
                                    let Jobseeker_URL = resp.data.Jobseeker_URL;
                                    let sponsor_URL = resp.data.sponsor_URL;
                                   
                                    let total_Commission = resp.data.total_Commission;
                                    let due_commission = resp.data.due_commission;
                                    let monthcommission = resp.data.monthcommission;
                                    let percentage=resp.data.percentage
                                    this.setState({sponsor_URL});
                                    this.setState({sponsor_count});
                                    this.setState({Jobseeker_URL});
                                    this.setState({Jobseeker_count});
                                    this.setState({total_Commission});
                                    this.setState({due_commission});
                                    this.setState({percentage});
                                    this.setState({monthcommission});
                                    
                               }
                                else{
                                    
                                    
                                        
                                }
                    }).catch((ee)=>{
                      sta.setState({loader:'hidden'})///loader off

                        console.log('/member view list ERR',ee)
                    });


           
        

    }


  
 
 
  render() {
    let  ContactList = this.state.ContactList
  
    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
        onShow={()=>this.getoneRecord()}
         backdrop="static"
         keyboard={false}
         onHide={this.props.close}
         id="modal"
         size="md"
         >
        
          <Modal.Header closeButton>
            <Modal.Title>Member Details</Modal.Title>
            {/* <Row className="ml-1" style={{marginTop:'30px'}}>
            <div >Date : {this.state.fromDate} To : {this.state.toDate}  </div>
            </Row> */}
          </Modal.Header>
          <Modal.Body>


          <Row className="m-0">
          
            <Col md={4}>
             <strong> 
             Share Link : 
              </strong> 
            </Col>
            <Col md={8}>
                <p className="textwrap">
                    {this.state.Jobseeker_URL}
                </p>
            </Col>
          
          </Row>
          <Row className="m-0">
          <Col md={6}>
           <strong> 
           Commission : 
            </strong> 
          </Col>
          <Col md={6}>
              <p className="textwrap">
                    {this.state.percentage} %
              </p>
          </Col>
        </Row>

        <Row className="m-0">
          <Col md={6}>
           <strong> 
           Total Jobseeker : 
            </strong> 
          </Col>
          <Col md={4}>
              <p className="textwrap">
                    {this.state.Jobseeker_count}
              </p>
          </Col>
        </Row>
        <Row className="m-0">
          <Col md={6}>
           <strong> 
           Total Sponsor : 
            </strong> 
          </Col>
          <Col md={4}>
              <p className="textwrap">
                    {this.state.sponsor_count}
              </p>
          </Col>
        </Row>
        <Row className="m-0">
          <Col md={6}>
           <strong> 
           Total Commission : 
            </strong> 
          </Col>
          <Col md={4}>
              <p className="textwrap">
                    {this.state.total_Commission}
              </p>
          </Col>
        </Row>
        <Row className="m-0">
          <Col md={6}>
           <strong> 
           Total Due Commission : 
            </strong> 
          </Col>
          <Col md={4}>
              <p className="textwrap">
                    {this.state.due_commission}
              </p>
          </Col>
        </Row>
        <Row className="m-0">
          <Col md={6}>
           <strong> 
           Last 30 Days Commission : 
            </strong> 
          </Col>
          <Col md={4}>
              <p className="textwrap">
                    {this.state.monthcommission}
              </p>
          </Col>
        </Row>
          
       

    

            </Modal.Body>
          <Modal.Footer>
         
            
          </Modal.Footer>

         

        </Modal>
      </div>
    );
  }
}
