import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import "./css/login.css";
import Loginrightimage from "../images/login_right_panel.png";
import { API_URL, CaptchaKey } from "../Config";
import validation from "../Validate/validator";
import L from "react-loader-spinner";
import TokenVerify from "../Helpers/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router";

import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FromData: {
        email: "",
        password: "",
        recapcha: "",
      },
      FromDataError: {
        email: "false",
        password: "false",
        recapcha: "false",
      },
      isValid: false,
      Msg: "",
      MsgType: "",
      loader: "hidden",
      inputType: "password",
      currentUrl: "",
      pagesource: "",
      servicetype: "",
    };

    //Binding

    this.handaleChange = this.handaleChange.bind(this);
    this.handaleSubmit = this.handaleSubmit.bind(this);
    this.toggleEye = this.toggleEye.bind(this);
  }
  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
        if (value == "false") {
          FromDataError[key] = "This field is required";
          sta.setState({ FromDataError });
        }

        valid = false;
      }
    }

    return valid;
  }

  handaleChange(data) {
    let sta = this;
    let FromDataError = this.state.FromDataError;
    let isValid = this.state.isValid;

    let name = data.target.name;
    let value = data.target.value;
    let FromData = this.state.FromData;
    FromData[name] = value;

    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((error) => {
      FromDataError[name] = error;
      this.setState({ FromDataError });
      this.setState({ isValid });

      this.setState({ FromData });
    });
  }

  handaleSubmit(e) {
    e.preventDefault();

    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;
    let formBody = [];

    FromData.recapcha = true;
    FromDataError.recapcha = "";

    if (this.validateForm(FromDataError)) {
      sta.setState({ loader: "visible" }); ///loader on

      var formdata = new FormData();
      formdata.append("email", FromData.email);
      formdata.append("password", FromData.password);
      formdata.append("app_name", "NEXGEN");

      let request = {
        end_point: "/login",
        formdata: formdata,
      };

      console.log("......request", formdata);

      POST_API(request)
        .then(async (resp) => {
          //return false;
          sta.setState({ loader: "hidden" }); ///loader off

          //console.log(resp)
          if (resp.status == "success") {
            localStorage.setItem("gs_token", resp.user.token);
            localStorage.setItem("roleID", resp.user.roleID);

            if (resp.user.resume_score != null) {
              localStorage.setItem("resumeScore", resp.user.resume_score);
            }

            localStorage.setItem("gs_user", JSON.stringify(resp.user));

            if (localStorage.getItem("gs_token") != null) {
              localStorage.setItem("profile_image", resp.image);
              if (
                resp.user.roleID == 9 ||
                resp.user.roleID == 10 || 
                resp.user.roleID == 18 ||
                resp.user.roleID == 19
              ) {
                localStorage.setItem("dashboardLink", "#/affiliate-dash");
                TokenVerify();

                localStorage.setItem("gs_auth_code", resp.user.auth_code);
                this.props.history.push("/affiliate-dash");
              } else if (resp.user.roleID == 11 || resp.user.roleID == 12) {
                localStorage.setItem(
                  "dashboardLink",
                  "#/affiliate-member-dash"
                );
                TokenVerify();

                localStorage.setItem("gs_auth_code", resp.user.auth_code);
                this.props.history.push("/affiliate-member-dash");
              } else {
                sta.setState({ Msg: "Oops! No user Found", MsgType: "danger" });
              }
            }
          } else {
            if (resp.message != undefined) {
              sta.setState({
                Msg: resp.message.email.join(),
                MsgType: "danger",
              });
              sta.setState({ MsgType: "danger" });
            } else {
              sta.setState({ Msg: resp.messages, MsgType: "danger" });
              sta.setState({ MsgType: "danger" });
            }
          }
        })
        .catch((ee) => {
          console.log("/loginERR", ee);
        });
    } else {
      sta.setState({ Msg: "Enter Email ID and Password" });
      sta.setState({ MsgType: "danger" });
    }
  }

  componentDidMount() {
    let sta = this;
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg });
      sta.setState({ MsgType: sta.props.location.state.MsgType });
    }

    try {
      if (
        localStorage.getItem("gs_token") != null &&
        localStorage.getItem("gs_user") != null &&
        localStorage.getItem("gs_user") != undefined
      ) {
        let user = JSON.parse(localStorage.getItem("gs_user"));

        if (user.roleID == 8 || user.roleID == 9 || user.roleID == 10 || user.roleID == 18 || user.roleID == 19) {
          this.props.history.push("/affiliate-dash");
        } else if (user.roleID == 11 || user.roleID == 12) {
          this.props.history.push("/affiliate-member-dash");
        } else {
          this.props.history.push("/login");
        }
      }
    } catch (error) {}
    const { match, location, history } = this.props;
    // console.log("location",location)
    this.setState({ currentUrl: location.search });

    const queryParams = new URLSearchParams(location.search);
    this.setState({
      pagesource: queryParams.get("pagesource")
        ? queryParams.get("pagesource")
        : "",
    });
    this.setState({
      servicetype: queryParams.get("servicetype")
        ? queryParams.get("servicetype")
        : "",
    });
    if (queryParams.get("pagesource") && queryParams.get("servicetype")) {
      localStorage.setItem("pagesource", queryParams.get("pagesource"));
      localStorage.setItem("servicetype", queryParams.get("servicetype"));
    } else {
      localStorage.removeItem("pagesource");
      localStorage.removeItem("servicetype");
    }

    console.log(queryParams.get("pagesource"));
  }

  toggleEye() {
    var temp = document.getElementById("password");
    if (temp.type === "password") {
      this.setState({ inputType: "text" });
    } else {
      this.setState({ inputType: "password" });
    }
  }

  capchaChange = (value) => {
    console.log("Captcha value:", value);
    let { FromData, FromDataError } = this.state;
    FromData.recapcha = value;
    FromDataError.recapcha = "";
    this.setState({ FromData, FromDataError }, () => {
      this.validateForm(FromDataError);
    });
  };

  render() {
    const eye = <FontAwesomeIcon icon={faEye} />;
    const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
    return (
      <>
        <div className="login_page">
          <Container>
            <Row className="justify-content-center ml-0 mr-0">
              <div className="main d-flex">
                <div className="container-login a-container" id="a-container">
                  <Form
                    onSubmit={this.handaleSubmit}
                    className="form"
                    id="a-form"
                  >
                    {this.state.Msg !== "" ? (
                      <>
                        <FlashMess
                          m={this.state.Msg}
                          mtype={
                            this.state.MsgType ? this.state.MsgType : "success"
                          }
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <Loader
                      loadmsg="please wait"
                      visibility={this.state.loader}
                    />
                    <h2 className="form_title title_form">Login</h2>
                    <Form.Group controlId="formBasicEmail" className="mb-0">
                      <Form.Control
                        type="text"
                        className="form__input"
                        name="email"
                        onChange={this.handaleChange}
                        placeholder="Email"
                        validaterule={["required", "isEmail"]}
                        validatemsg={[
                          "This field is required",
                          "input a Valid Email",
                        ]}
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword" className="mb-0">
                      <Form.Control
                        type={this.state.inputType}
                        className="form__input"
                        id="password"
                        name="password"
                        onChange={this.handaleChange}
                        placeholder="Password"
                        validaterule={["required", "password"]}
                        validatemsg={[
                          "This field is required",
                          "password should be 6 charecter",
                        ]}
                      />
                      <i
                        style={{
                          float: "right",
                          marginTop: "-38px",
                          marginRight: "8px",
                          position: "relative",
                          zIndex: 1,
                          cursor: "pointer",
                        }}
                        onClick={this.toggleEye}
                      >
                        {this.state.inputType == "text" ? eyeSlash : eye}
                      </i>
                    </Form.Group>
                    <Form.Row>
                      <Form.Group
                        controlId="formBasicEmail"
                        as={Col}
                        className="mt-2"
                      >
                        <ReCAPTCHA
                              sitekey={CaptchaKey}
                              onChange={this.capchaChange}
                            />

                        <p style={{ color: "red" }}>
                          {this.state.FromDataError.recapcha == "false"
                            ? ""
                            : this.state.FromDataError.recapcha}
                        </p>
                      </Form.Group>
                    </Form.Row>

                    <Row>
                      <Col md={12} className="d-flex keep_login_forgot_pass">
                        <a
                          href="#/forget-password"
                          className="ml-auto forgotPass"
                        >
                          Forgot password
                        </a>
                      </Col>
                    </Row>

                    <Form.Group controlId="formBasicPassword" className="mb-0">
                      <Button
                        type="submit"
                        className="btn btn-main btn-block login_button"
                      >
                        Log in
                      </Button>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default withRouter(Login);
