import React,{useState} from 'react';
import {API_URL} from "../Config"

const DeveloperTool = () => {
  const [showAPI1,setShowAPI1]= useState(false);
  const [showAPI2,setShowAPI2]= useState(false);

  return (
    <div>
      <h1>API Integration Guide</h1>
      <p>
        Welcome to the API Integration Guide. This guide will help you integrate our API into your project smoothly.
      </p>
      <h2>Endpoints</h2>
      <p>
        Our API provides the following endpoints for interacting with our services:
      </p>
      <ol>
        <li><strong>POST {API_URL}/check-affiliateCode</strong> <br /> - This API is to validate the Affiliate code 
        &nbsp;&nbsp;<span className='btn btn-danger p-1' onClick={()=>{
          setShowAPI1(!showAPI1)
          setShowAPI2(false);
        }}>{showAPI1 ? "Hide Description" : "View Description"}</span></li>
        {showAPI1 && <API1 /> }

      <li><strong>POST {API_URL}/submit-affiliate-request</strong> <br /> - Send data to our service For calculating the commission
      &nbsp;&nbsp;<span className='btn btn-danger p-1' onClick={()=>{
          setShowAPI2(!showAPI2)
          setShowAPI1(false);
        }}>{showAPI2 ? "Hide Description" : "View Description"}</span>
      </li>
      {showAPI2 && <API2 /> }
    </ol>

      
    </div>
  );
};


const API1 = ()=>{
return(
<>
      <h6>Payload</h6>
      <p>When sending data to our API, make sure to include the required fields in your payload. Here is an example of a payload for sending data:</p>
      <pre>
        {`
            {
                source: "nexgenaffiliate", //Fixed
                acode: AffiliateCode //This will be dynamically fetched from the URL for eg: Q51U7PN23IE6  
            }
        `}
      </pre>

      <h6>Header for <b>check-affiliateCode</b></h6>
      {`
       "Origin": Orgin // This will be the Product URL, you will receive from the Admin for eg : "https://coachmaster.io/",
       "Content-Type": "application/json"
    `}

      <h2>Sample Response</h2>
      <p>
        Our API responds with JSON data. Here is an example of a sample response:
      </p>
      <pre>
    {`
      Case 1: Missing acode

            {
              "status": 422,
              "errors": {
                "acode": [
                  "acode is required"
                ]
              }
            }


            Case 2: Invalid origin

            {
              "status": 422,
              "errors": {
                "origin": [
                  "Invalid Origin"
                ]
              }
            }

            Case 3: Missing source

            {
              "status": 422,
              "errors": {
                "source": [
                  " Source is Required"
                ]
              }
            }

            Case 4: Missing source and acode

            {
              "status": 422,
              "errors": {
                "source": [
                  " Source is Required"
                ],
                "acode": [
                  "acode is required"
                ]
              }
            }

            Case 5: Invalid source

            {
              "status": 422,
              "errors": {
                "source": [
                  "Invalid Source"
                ]
              }
            }

            Case 6: Invalid Affiliate Code

            {
              "status": 422,
              "errors": {
                "acode": [
                  "Invalid Affiliate Code"
                ]
              }
            }

            Case 7: Valid Affiliate Code

            {
              "status": 200,
              "message": "Valid Affiliate Code"
            }
    `}
      </pre> 
      </>)
}

const API2 = ()=>{
return (
<>
      <h6>Payload</h6>
      <p>When sending data to our API, make sure to include the required fields in your payload. Here is an example of a payload for sending data:</p>
      <pre>
        {`
            {
              "app_name: : "NEXGEN" //FIXED
              "source":"nexgenaffiliate", //FIXED
              "acode":"ER5K806OJLYF", //Dynamic Affiliate Code
              "amount" : "80", //Total Amount paid By Customer
              "product_ref_id" : "4", //Reference ID ( Unique ID against of payment has been processed )
              "transaction_no" : "1232fff44ggC", // Transaction No. Recived From Payment Gateway
              "payment_date" : "2023-03-19", //Payment Date (Can be current Date and time)
              "fullname" : "Ajay", //Customer Name
              "email" : "ajay.kumar@cicerotransact.com" //Customer Email
            }            
        `}
      </pre>

      <h6>Header for <b>submit-affiliate-request</b></h6>
      {`
       "Origin": Orgin // This will be the Product URL, you will receive from the Admin for eg : "https://coachmaster.io/",
       "Content-Type": "application/json"
    `}

      <h2>Sample Response</h2>
      <p>
        Our API responds with JSON data. Here is an example of a sample response:
      </p>
      <pre>
    {`
  
      Case 1: Invalid Affiliate Code :
      
      {
        "status": 422,
        "errors": {
          "acode": [
            "Invalid Affiliate Code"
          ]
        }
      }
      
      
      Case 2: Request Captured Successfully
      
      {
        "status": 200,
        "message": "Request Captured Successfully"
      }
      Case 1: Missing acode

            {
              "status": 422,
              "errors": {
                "acode": [
                  "acode is required"
                ]
              }
            }


            Case 2: Invalid origin

            {
              "status": 422,
              "errors": {
                "origin": [
                  "Invalid Origin"
                ]
              }
            }

            Case 3: Missing source

            {
              "status": 422,
              "errors": {
                "source": [
                  " Source is Required"
                ]
              }
            }

            Case 4: Missing source and acode

            {
              "status": 422,
              "errors": {
                "source": [
                  " Source is Required"
                ],
                "acode": [
                  "acode is required"
                ]
              }
            }

            Case 5: Invalid source

            {
              "status": 422,
              "errors": {
                "source": [
                  "Invalid Source"
                ]
              }
            }

            Case 6: Invalid Affiliate Code

            {
              "status": 422,
              "errors": {
                "acode": [
                  "Invalid Affiliate Code"
                ]
              }
            }

            Case 7: Valid Affiliate Code

            {
              "status": 200,
              "message": "Valid Affiliate Code"
            }
    `}
      </pre> 
      </>)
}


export default DeveloperTool;
