

const setSettings = (state = [], action) => {

  try {

    switch (action.type) {
      case "UPDATE-SETTINGS":
        return action.payload;
      default:
        return state;
    }

  } catch (error) {
    console.log("setSettings",error)
  }



   
  };
  
  export default setSettings;
  