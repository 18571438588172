import React, { Component } from 'react'
import { Container, Row, Col, Tab, Nav, Form, Button, Image, OverlayTrigger,Tooltip,Card } from "react-bootstrap";
import { FlashMess, GET_API, POST_API,Loader } from "../Helpers/Helpers";
import rs1 from "./images/rs1.png"
import rs2 from "./images/rs2.png"
import rs3 from "./images/rs3.png"
import rs4 from "./images/rs4.png"
import {ResumeSendingURL} from "../Config";

export default class ResumeSending extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
                        
 
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/jobseeker-personal-profile',
                        help:'',
                        hrcontacts:{},
                        searchInput: "",
                        filteredData: [],
                        coaches:[],
                        sendflag:1

                        
                     };
                     this.handlesubmit=this.handlesubmit.bind(this);
        

    }
    handlesubmit()
    {
        this.props.handlesending(this.state.sendflag); 
        var formdata=new FormData();
        formdata.append("flag", this.state.sendflag);
        let request = {
            end_point : '/ResumeSendingSelected',
            token: localStorage.getItem("gs_token"),
            formdata:formdata
            

        };
        this.setState({loader:'visible'})
            POST_API(request).then(resp=>{
                this.setState({loader:'hidden'})
                    // console.log("joblist",resp)
                    // this.setState({joblist:resp.jobs})
                    window.open(ResumeSendingURL+"/direct-login/" + localStorage.getItem("gs_auth_code"), "_blank")
                    }).catch((ee)=>{

                    });
    }
    render() {
        return (
            <div>
            <Loader loadmsg="please wait" visibility={this.state.loader}/>
                <h2>Resume Sending</h2>
                <p>Resumesending - The most advanced resume distributing service in the world.</p>

                <Row>
                    <Col md="5" className="d-flex align-items-center flex-wrap">
                        <div className="comitems d-flex">
                            <div className="comitem">
                                <img src={rs1} className="img-fluid" />
                            </div>
                            <div className="comitem">
                                <img src={rs2} className="img-fluid" />
                            </div>
                            <div className="comitem">
                                <img src={rs3} className="img-fluid" />
                            </div>
                            <div className="comitem">
                                <img src={rs4} className="img-fluid" />
                            </div>
                        </div>

                        <h3 className="rs_job_head"><strong>Upto 150+</strong>job board<br/>you will be auto register</h3>
                    </Col>
                    <Col md="7">
                            <ul className="job_listing">
                                <li>Post to Job boards</li>
                                <li>Save Time</li>
                                <li>C-Level Exces Can Access YOUR Resume</li>
                                <li>Post to ATS</li>                                
                                <li>Saves Money</li>  
                                <li>Sponsored</li>  
                                <li>Employers Can Access YOUR Resume</li>                                
                            </ul>

                    </Col>
                </Row>


                {(localStorage.getItem("gs_auth_code") != undefined) ? (<>
                   
                            <Button style={{marginBottom:"15px"}} onClick={this.handlesubmit} className="btn-1" >Go to dashboard</Button>
                </>) : (<>
                    {/* <a target="_blank" href="#" className="btn btn-primary">
                        Go to dashboard
                            </a> */}
                            <Button style={{marginBottom:"15px"}}  className="btn-1" >Go to dashboard</Button>
                </>)}
            </div>
        )
    }
}
