import React, { Component } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
//import './css/login.css';
import Loginrightimage from '../images/login_right_panel.png';
import {API_URL} from '../Config';
import validation from '../Validate/validator';
import L from 'react-loader-spinner'
import  TokenVerify from "../Helpers/Helpers";

import page404 from '../images/404-banner.jpg';
//
import  { FlashMess,GET_API,POST_API,Loader} from "../Helpers/Helpers";








class Notfound extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
                       
                        
                     };

        //Binding

       

    }




   


    componentDidMount()
    {
     

      
      
    }

     




    render() {

       
        return (

            
           
        /////////////////////////////////////////////////////////////////////////////////////////

        <Container>
        <Row className="justify-content-center banner_container">
          <Col lg={10} className="login_box">
            <Row>
              <Col lg={6} md={6} sm={12} className="">
                {/* <h3 className="text-center">404 Page not found!</h3> */}
                <img src={page404} />
        

              </Col>
             
            </Row>
          </Col>
        </Row>
      </Container>
        /////////////////////////////////////////////////////////////////////////////////////////////
        );
    }
}

export default Notfound;