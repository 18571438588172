import React, { Component,useEffect,useState } from 'react';
import { Container, Row, Col, Button, Dropdown,Modal, Form } from "react-bootstrap";
import { FlashMess, GET_API, POST_API,Loader } from "../../Helpers/Helpers";
import csvfilename from '../../Affiliate/files/affiliate_member_list.csv';
import DataTable from "react-data-table-component";
import MemberDashboardViewModal from '../Section/MemberDashboardViewModal'
import MemberBankViewModal from '../Section/MemberBankViewModal'
import moment from 'moment';
import Threedots_img from '../../images/dotsss.png'
import { Link } from '@mui/material';
import AddMemberInBulk from '../../Sponser/Section/AddMemberInBulk';


class UserList extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
                        show_assign_credit_model : false,
                        userID : "",
                        role_id : "",
                        ContactGroupModal :  false,
                        FromData: {},
                        FromDataError :
                                        {},
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/members',
                        member_id:0,
                        memberDashModal:false,
                        memberbankhModal:false,
                        help:''


                        
                     };

        //Binding

        this.handaleChange = this.handaleChange.bind(this)
        this.handaleSubmit = this.handaleSubmit.bind(this)
        

    }

    handleClose(member_id=0)
    {
     
      
      if(this.state.memberDashModal)
      {
         this.setState({memberDashModal: false})
      }
      else{
        this.setState({member_id: member_id,memberDashModal: true})
       
      }
    }

    handleCloseBank(member_id=0)
    {
     
      
      if(this.state.memberbankhModal)
      {
         this.setState({memberbankhModal: false})
      }
      else{
        this.setState({member_id: member_id,memberbankhModal: true})
       
      }
    }

    validateForm  (errors) {
        let valid = true;
        let sta = this;
        let FromDataError = this.state.FromDataError;

        for (const [key, value] of Object.entries(errors)) {
          if(value.length > 0 )
          {

            if(value=='false')
            {
              FromDataError[key] = 'This field is required';
              sta.setState({FromDataError})
            }
            
            valid = false;
          }
        }
      
        return valid;
};

    

    handaleChange(data)
    {
        // let sta = this;
        // let FromDataError = this.state.FromDataError;
        

        // let name = data.target.name;
        // let value = '';
        // let FromData = this.state.FromData;
        

       

       
    }
    handlemodalShow()
{
  
  if(this.state.ContactGroupModal)
  {
     this.setState({ContactGroupModal: false})
  }
  else{
    this.setState({ContactGroupModal: true})
   
  }
}

    handaleSubmit(e)
    {   
        
        e.preventDefault();

        // let sta = this;
        // let FromDataError = this.state.FromDataError;
        // let FromData = this.state.FromData;

        // console.log(this.state)
        // return 0;


        
     }

     handaleDelete(id)
     {
        let sta = this

        var formdata = new FormData();
        formdata.append("userID", id);

        let request = {
            end_point : '/deactive-employee',
            formdata:formdata,
            token: localStorage.getItem("gs_token")
            };

        


        POST_API(request).then((resp)=>{

            if(resp.status=='success')
            {
                
                
              if(resp.data.is_activated!=0){
                sta.setState({Msg:'User inactivated successfully!',Msgtype:'succcess'})
              }else{
              sta.setState({Msg:'User Activated Successfully!',Msgtype:'succcess'})
              }

                setTimeout(() => {
                    window.location.reload()
                    
                }, 3000);

                
                
                
                
            }
            else{
                //console.log(resp.message.password.join())
                sta.setState({Msg:resp.message.email.join(),MsgType:'danger'})
            }

        }).catch(()=>{

        })
        
     }


    componentDidMount()
    {
         window.scrollTo(0, 0)

        let userDetails = JSON.parse(localStorage.getItem("gs_user"));
        this.setState({role_id:userDetails.roleID});

        let sta = this;
        let FromData =this.state.FromData
        
        //for redirectin message show
        if(sta.props.location.state!=undefined)
        {
            sta.setState({Msg:sta.props.location.state.Msg})
            sta.setState({MsgType:sta.props.location.state.MsgType})
        }
        var formdata = new FormData();
        formdata.append("employerID",userDetails.userID);
                let request = {
                    end_point : '/employee-List',
                    formdata:formdata,
                    token: localStorage.getItem("gs_token")

                };

                sta.setState({loader:'visible'})///loader on


                    POST_API(request).then(resp=>{

                             sta.setState({loader:'hidden'})///loader off


                                    if(resp.status == 'Success')
                                    {
                                            
                                            FromData = resp.data

                                            sta.setState({FromData})
                                            
                                       }
                                        else{
                                            
                                            
                                                
                                        }
                            }).catch((ee)=>{
                              sta.setState({loader:'hidden'})///loader off

                                console.log('/code list ERR',ee)
                            });

                            //help api
        let requestHelp = {
          end_point : '/help-details?key=affiliate_member_list',
          
          token: localStorage.getItem("gs_token")
        }

        sta.setState({loader:'visible'})///loader on

          GET_API(requestHelp).then((resph)=>{
            sta.setState({loader:'hidden'})///loader off

            if(resph.status == 'success')
            {
              let help = resph.data.value
              sta.setState({help})
            }

          }).catch(()=>{
            sta.setState({loader:'hidden'})///loader off


          })

    }

    assignCreditToEmp(userID){
      this.setState({show_assign_credit_model:true,userID : userID});
    }
 
    render() {
        let  FromData = this.state.FromData


 let columns = [
  {
    name: "SL. No.",
    selector: (row, index) =>  index + 1,
    sortable: true,
    width: "100px",
    wrap:true,
  },
  {
    name: "Type",
    selector: row => (
        row.roleID == 11 ? 
            <span className="badge badge-primary">Employee</span> :
            (row.roleID == 12 ? 
                <span className="badge badge-info">Manager</span> :
                (row.roleID == 9 ? <span className="badge badge-info">SELF</span> : <span className="badge badge-info">Affiliate</span> )
            )
            
    )
},
    {
        name: "Name",
        selector: row => row.firstname+' '+row.lastname ,
        width:'150px',
        sortable: true,
        wrap:true,
      },
      {
        name: "Email",
        // selector: "email",
        selector: (row) =>{return <a href={`mailto:${row.email}`}>{row.email}</a>},
        width:'250px',
        sortable: true,
        wrap:true,
      },
      {
        name: "Available Credits",
        selector: row => { return <>{row?.credits}
                  { this.state.role_id == "9" && <button href="javascript:void(0)"  onClick={()=>this.assignCreditToEmp(row.userID)}> &nbsp;&nbsp;&nbsp;Add More</button> }
        </>},
        width:'300px',
        sortable: true,
        wrap:true,
      },
      // {
      //   name: "Max User Share",
      //   selector: "maxSponsership",
      //   width:'115px',
      //   sortable: true,
      //   wrap:true,
      // },
      {
        name: "Status",
        // selector: "created_at",
        selector: row => row.is_activated ===1  ? "Inactive" : "Active",
        width:'150px',
        sortable: false,
        wrap:false,
       
      },
    {
      name: "Created at",
      // selector: "created_at",
      selector: row => moment(row.created_at).format('MM-DD-YYYY'),
      width:'150px',
      sortable: true,
      wrap:true,
     
    },

    
  
      {
        name: "Actions",
        omit : this.state.role_id != 9,
        cell: row =>  {
                    return(
                            //     <div><a href={'#/update-members-affiliate/'+row.userID}><Button variant="primary mr-1">Edit</Button></a>
                            // <Button className="mr-1" variant={row.is_activated==0? 'danger' : 'info'} onClick={()=>{if(window.confirm(row.is_activated==0? 'Inactive  the Member?' : 'Active the Member?')){this.handaleDelete(row.userID)};}}>{row.is_activated==0? 'Activate' : 'Deactivate'}</Button>
                            // <Button className="mr-1" variant="primary mr-1" onClick={()=>this.handleClose(row.userID)}>View</Button>
                            // <Button className="p-1" variant="primary mr-1"  onClick={()=>this.handleCloseBank(row.userID)}>View Bank</Button>
                            // </div>


                            <div className="three_dotss">
                          <Dropdown className="drop_partts">
                          <Dropdown.Toggle variant="" id="dropdown-basic">
                            <img src={Threedots_img} alt="" />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                          { this.state.role_id == "9" &&
                              <Dropdown.Item >
                                  <Button className="p-1" variant="primary mr-1"  onClick={()=>this.assignCreditToEmp(row.userID)}>Assign Credits</Button>
                              </Dropdown.Item>
                          }

                            <Dropdown.Item>
                            <Link onClick={()=>window.location.href='#/update-members-affiliate/'+row.userID} to={'#/update-members-affiliate/'+row.userID}><Button variant="primary mr-1">Edit</Button></Link>
                            </Dropdown.Item>
                            <Dropdown.Item >
                            <Button className="mr-1" variant={row.is_activated==0? 'danger' : 'info'} onClick={()=>{if(window.confirm(row.is_activated===1? 'Activate the member?' : 'De-activate the member?')){this.handaleDelete(row.userID)};}}>{row.is_activated===1? 'Activate' : 'Deactivate'}</Button>
                            </Dropdown.Item>
                            <Dropdown.Item >
                            <Button className="mr-1" variant="primary mr-1" onClick={()=>this.handleClose(row.userID)}>View</Button>
                            </Dropdown.Item>
                            <Dropdown.Item >
                              <Button className="p-1" variant="primary mr-1"  onClick={()=>this.handleCloseBank(row.userID)}>View Bank</Button>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        </div>
                        )
                        } 
                       ,
        // sortable: true,
        width: '350px'
      },
  ];

        // console.log("ajay",FromData)
        return (
            <div>
                 {FromData!=null? (<>
            

            <Container fluid className="dash_container">
          <Row>
            {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
            <Col md={12}>
            <Loader loadmsg="please wait" visibility={this.state.loader}/>

            <div className="tabpanelOneForm pt-3">
            {(this.state.member_id != 0)? (<>
                <MemberDashboardViewModal {...this.props} member_id={this.state.member_id} show={this.state.memberDashModal} close={()=>this.handleClose()}/>
                </>) : (<></>) }  

                {(this.state.member_id != 0)? (<>
                <MemberBankViewModal {...this.props} member_id={this.state.member_id} show={this.state.memberbankhModal} close={()=>this.handleCloseBank()}/>
                </>) : (<></>) }  

                

            <h3>{this.state.role_id == "9" ? "Member List": "Affiliate List"}  </h3>
            <p
                dangerouslySetInnerHTML={{
                  __html: this.state.help
                }}></p>
          
            {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
            {
              this.state.role_id == "9" &&
            <>
            <div style={{float: "right"}}>
              &nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                      href={csvfilename}
                      download="add-member-contact.csv"
                      className="btn btn-primary ghost_button mr-3"
                    >
                      Download Sample CSV
                    </a>
                  <button  onClick={()=>this.handlemodalShow()} className="btn btn-primary ghost_button">Upload In Bulk </button>
               </div>

               <AddMemberInBulk {...this.props} 
                show={this.state.ContactGroupModal} 
                close={()=>this.handlemodalShow()}/>

                <a href="#/create-members-affiliate" className='btn btn-primary ghost_button float-right'>Create</a>
                </>
                }
                <div className="table_search" style={{float: "right",margin:'10px'}}>   
                Search : <input type='text' placeholder='Search' name="search_val"  onChange={this.searchChange}  /></div>
                <div className='table-responsive'>
              <DataTable
                    title=""
                    columns={columns}
                    data={FromData}
                    defaultSortField="title"
                    pagination={true}
                    NoDataComponent={"No data"}
                    noHeader={true}
                    />
                   </div>     
               
              </div>
                              
                              
                          
            </Col>     
          </Row>
          {this.state.show_assign_credit_model && <AssignCredit userID={this.state.userID} show={true} close={()=>{this.setState({show_assign_credit_model:false})}}/>}
        </Container>

        </>):(<p>....Loading</p>) }


        </div>
          
                
                
           
        );
    }
}

export default UserList;


const AssignCredit = (props) => {
  const [userID, setUserID] = useState(props.userID);
  const [credits, setCredits] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  const [loader, setLoader] = useState('hidden');

  useEffect(() => {
    getCurrentCredits();
  }, []);

  const getCurrentCredits = () => {
    var formdata = new FormData();
    let request = {
      end_point: '/salesorbit-get-credits',
      token: localStorage.getItem("gs_token"),
      formdata: formdata,
    };
    setLoader('visible');
    POST_API(request)
      .then((resp) => {
        setLoader('hidden');
        if (resp.status === 'success') {
          setTotalCredits(resp.data);
        } else {
            setTotalCredits([]);
        }
      })
      .catch((e) => {
        setLoader('hidden');
        setMsg("Credit Transaction : " +e);
        setMsgType('danger');

      });
}

  const handleChange = (event) => {
    const { value } = event.target;
    // Remove any non-numeric characters except for leading minus sign
    if (/^[0-9]*$/.test(value)) {
      setCredits(value);
    }
  };


  const handleKeyDown = (event) => {
    const allowedKeys = [
      'Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Enter', 'Delete'
    ];

    if (allowedKeys.includes(event.key) || event.key === 'Control' || event.key === 'Shift') {
      return; // Allow these keys
    }

    if (event.key < '0' || event.key > '9') {
      event.preventDefault();
    }
  };


  const handleFinalSubmit = () => {
    if(credits <= 0 || credits == ""){
      setMsg("Please enter number of credits");
      setMsgType('danger');
      return;
    }

    if(credits > totalCredits){
      setMsg("You Do not have enough credits to assign, Please add credit to your account first");
      setMsgType('danger');
      return;
    }

    setMsg("");
    setMsgType('danger');
    setLoader('visible');

    var formdata = new FormData();
    formdata.append('userID', userID);
    formdata.append('credits', credits);
    formdata.append('app_name', 'NEXGEN');
    formdata.append('gs_token', localStorage.getItem('gs_token'));
    let request = {
      end_point: '/assign-credits',
      token: localStorage.getItem("gs_token"),
      formdata: formdata,
    };
    POST_API(request)
      .then((resp) => {
        setLoader('hidden');
        if (resp.status === 'success') {
          console.log('respsssspp', resp);
          setMsg(resp.message);
          setMsgType('succcess');
          setTimeout(function(){
           window.location.reload();
          },1500);
        } else {
          setMsg(resp.message);
          setMsgType('danger');
        }
      })
      .catch((e) => {
        console.log("Assign Credit Error",e)
        setLoader('hidden');
        setMsg('Something Went Wrong,Please try again later.');
        setMsgType('danger');
      });
}

  return (
    <div id="PaymentForm">
      <Modal
        show={props.show}
        backdrop="static"
        keyboard={false}
        onHide={props.close}
        id="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title> Assign Credits  : <br />
          Available Credits : {totalCredits}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Loader loadmsg="please wait" visibility={loader} />
            {msg && <FlashMess m={msg} mtype={msgType ? msgType : 'success'} />}
            
            <Form.Group>
            <input type="number" value={credits} className="form-control" step="1" min="10"  
              onChange={handleChange}
              onKeyDown={handleKeyDown}
                />
            </Form.Group>
            <Form.Group>
              <button className="btn btn-primary" onClick={handleFinalSubmit}>Add Now</button>
            </Form.Group>

        </Modal.Body>
      </Modal>
    </div>
    
  );
};

