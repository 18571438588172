import React, { Component, useEffect,useState } from 'react';
import { Modal } from "react-bootstrap";
import{POST_API } from "../Helpers/Helpers";

const  SupportForm = (props)=>{
    const [cateGory, setCateGory] = useState("");
    const [message, setMessage] = useState("");
    const [res, setRes] = useState("");
    const [status, setStatus] = useState("danger");
    
    
    const submitSupportForm = ()=>{
      var formdata = new FormData();
      formdata.append("cateGory", cateGory);
      formdata.append("message", message);
      let gs_user = localStorage.getItem('gs_user');
      gs_user = JSON.parse(gs_user);
      formdata.append("email", gs_user.email);
      formdata.append("name", gs_user.firstname+" "+gs_user.lastname);
      formdata.append("userID", gs_user.userID);
  
      let request = {
        end_point: '/gbtwSupportForm',
        token: localStorage.getItem("gs_token"),
        formdata: formdata
      };
      POST_API(request).then(resp => {
        if(resp.status == "success"){
          setRes("Thank you for submitting your query. Our Support team will get in touch with you shorlty.");
          setStatus("success");
          setCateGory("");
          setMessage("");
          setTimeout(function(){
            setRes("");
            props.setShowSupportModal(false);
          },2000);
        }else{
          setRes("There is some error while submitting the form, please try again later");
          setStatus("danger");
          setTimeout(function(){
            setRes("");
            setStatus("");
          },1500);
           console.log(resp);
        }
      }).catch((ee) => {
  
      });
    }
  
    return (
      <Modal
      show={props.showSupportModal}
      onHide={()=>{props.setShowSupportModal(false)}}
      dialogClassName="modal-50w"
      aria-labelledby="example-custom-modal-styling-title"
      className='jobseeker_modal'
      centered
    >
  
      <Modal.Body>
        <div className='close_btn' onClick={()=>{props.setShowSupportModal(false)}}>x</div>
        <div className='modal_left_top_content_box'>
          {res != "" && <div className={"alert alert-"+status}>{res}</div>  }
              <h2 className='modal_jobseeker_title'>How Can We Help ?</h2>
              <div className="seperator"></div>
              <form onSubmit={submitSupportForm}>
                <div className="form-group">
                  <label>Please Select <span className="text-danger">*</span></label> 
                  <select value={cateGory} onChange={(e)=>{
                    setCateGory(e.target.value);
                  }} className="form-control" required>
                      <option value="">Please Select</option>
                      <option value="Password Reset/Recovery">Password Reset/Recovery</option>
                      <option value="Update Profile Information">Update Profile Information</option>
                      <option value="Resume Upload Failure">Resume Upload Failure</option>
                      <option value="LinkedIn Profile PDF Upload Failure">LinkedIn Profile PDF Upload Failure</option>
                      <option value="Difficulty Accessing Certain Features">Difficulty Accessing Certain Features</option>
                      <option value="Report a Bug or System Error">Report a Bug or System Error</option>
                      <option value="Single Sign On Feature Not Working">Single Sign On Feature Not Working</option>
                      <option value="Browser Compatibility Issues">Browser Compatibility Issues</option>
                      <option value="Need Help with User Interface Navigation">Need Help with User Interface Navigation</option>
                      <option value="E-Book Download Failure">E-Book Download Failure</option>
                      <option value="Resume Template Download Failure">Resume Template Download Failure</option>
                      <option value="General Inquiry/Other Issues">General Inquiry/Other Issues</option>
                  </select>  
                </div>
                <div className="form-group">
                  <label>Message <span className="text-danger">*</span></label> 
                    <textarea rows="6" maxLength="1000" value={message} onChange={(e)=>{
                    setMessage(e.target.value);
                  }}  required className="form-control"></textarea>
                </div>
  
                <div className="form-group">
                  <button className='btn btn-primary btn-md tabformbutton btnfix btn_total btn btn-primary'>Send Message</button>
                </div>
              </form>
            </div>
      </Modal.Body>
    </Modal>
    );
  }

export default SupportForm;