import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Table,
  Modal,
} from "react-bootstrap";
import TokenVerify, {
  FlashMess,
  GET_API,
  POST_API,
  Loader,
} from "../../Helpers/Helpers";
import "./resumeupload.css";
import img4725573 from "../images/4725573.png";

export default class ResumeUploadLinkedin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: "hidden",
      show: this.props.modalShow,
      FromData: {
        file: "",
        format: "",
        filename: "",
      },
      error: ''
    };

    this.handaleChange = this.handaleChange.bind(this);
  }

  componentDidMount() { }

  handaleChange(data) {
    let sta = this;

    console.log("handle change", data.target.name);

    let name = data.target.name;
    let value = "";
    let FromData = this.state.FromData;

    if (name == "file") {
      console.log("a");
      let file = data.target.files[0];
      if (file?.type == "application/pdf") {

        value = file;
        FromData[name] = file;
        FromData.filename = file.name;
        FromData.format = file.type;
      }
      else {
        this.setState({ error: "Pleade upload a Linkedin Profile PDF" })
      }
    }
    this.setState({ FromData });
  }

  uploadResume = () => {
    let sta = this;
    let { FromData } = this.state;
    if (FromData.file != "") {
      sta.setState({ loader: "" }); ///loader on

      var formdata = new FormData();
      formdata.append("linkedin_resume", FromData.file);

      console.log("......request2", formdata);

      let request = {
        end_point: "/upload-linkedin-resume",
        token: localStorage.getItem("gs_token"),
        formdata: formdata,
      };

      console.log("......request", formdata);
      // Uploading file to s3
      POST_API(request)
        .then((resp) => {
          if (resp.status == "success") {
            var formdata2 = new FormData();
            formdata.append("token", localStorage.getItem("gs_token"));
            formdata.append("linkedin_resume_url", resp.url);

            console.log("......request3", formdata);

            let request2 = {
              end_point: "/update-linkedin-resume",
              token: localStorage.getItem("gs_token"),
              formdata: formdata,
            };
            // Uploading file url to db
            POST_API(request2)
              .then(async (resp) => {
                ///loader off

                if (resp.status == "success") {
                  localStorage.setItem("gs_auth_code", resp.ml_auth_code);
                  await TokenVerify();
                  // setTimeout(() => {
                  //   window.location.reload();
                  // }, 3000);
                  sta.setState({ loader: "hidden" });

                  await this.props.userdataUpdate();
                  this.props.switchHandaleModal(this.props.sectionClick);
                  this.props.switchResumeModal();
                }
              })
              .catch((ee) => {
                sta.setState({ loader: "hidden" });
                console.log("/resumeUpload error", ee);
              });
          }
          else {
            sta.setState({ loader: "hidden" }); ///loader off

            this.setState({ error: resp.message })

          }
        })
        .catch((ee) => {
          console.log("/resumeUpload error", ee);
        });
    } else {
      alert("Choose a file first");
    }
  };
  render() {
    let { FromData, error } = this.state;
    return (
      <div id="resumeUpload">
        <Modal
          show={this.props.modalShow}
          backdrop="static"
          className="modal_upload"
          keyboard={false}
          onHide={this.props.switchResumeModal}
          id="modal"
        >
          {/* <Modal.Header closeButton>
            <Modal.Title>Upload Resume</Modal.Title>
          </Modal.Header> */}
          <Modal.Body style={{ minHeight: "250px" }}>
            <Loader loadmsg="please wait" visibility={this.state.loader} />
            {/* <Form.Group controlId="formBasicEmail" as={Col}> */}
            {/* <Form.Label>Upload CV (.pdf)</Form.Label>
                    <span className="text-danger"> *</span>
                    <input
                        type="file"
                      id="custom-file-translate-scss"
                      validaterule={["required", "mime|pdf"]}
                      validatemsg={["This field is required"]}
                      name="file"
                      className="form-control"
                      onChange={this.handaleChange}
                      label={FromData.filename || "Select file"}
                      lang="en"
                      custom
                    />
                    
                  </Form.Group> */}
            {/* <p>Congratulations for purchasing the "All in one package", you will get all the services of $1299 for now $99. Click below to upload your resume to start.</p> */}
            <div className="upload_file_area text-center">
              <label className="upload_file_label">
                <input
                  type="file"
                  id="custom-file-translate-scss"
                  validaterule={["required", "mime|pdf"]}
                  validatemsg={["This field is required"]}
                  name="file"
                  className="form-control"
                  accept='application/pdf'
                  onChange={this.handaleChange}
                  label={FromData.filename || "Select file"}
                  lang="en"
                  custom
                />
                <img width="120" src={img4725573} className="img-fluid" />
              </label>
              <div className="upload_info">
                <h4>Click the icon to Upload Linkedin Profile</h4>
                {/* <p className="mb-0">File size should not be exceed 2mb</p> */}
                {FromData.filename && (
                  <p style={{ color: "green", fontWeight: "bold" }}>
                    {FromData.filename}
                  </p>
                )}

                {error != '' && (
                  <p style={{ color: "red", fontWeight: "bold" }}>
                    {error}
                  </p>
                )}
              </div>
              <div className="upload_action row">
                <div className="col pr-2">
                  <Button
                    variant="primary"
                    onClick={this.props.switchResumeModal}
                    className="btn btn-danger tabformbutton btn-block btn_total"
                  >
                    Cancel
                  </Button>
                </div>
                <div className="col pl-2">
                  <Button
                    onClick={this.uploadResume}
                    variant="primary"
                    type="submit"
                    className="btn btn-primary tabformbutton btn-block btn_total"
                  >
                    Upload
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="primary" onClick={this.props.switchResumeModal} className="btn-main tabformbutton mr-2">Cancel</Button>
            <Button onClick={this.uploadResume} variant="primary" type="submit" className="btn-main tabformbutton">Upload</Button>

          </Modal.Footer> */}
        </Modal>
      </div>
    );
  }
}
