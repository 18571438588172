import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { FlashMess, GET_API, POST_API, Loader } from "../../Helpers/Helpers";
import validation from "../../Validate/validator";

import img4725573 from "../../Jobseeker/images/4725573.png";

import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

export default class AddContactcsvModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUpload: "",
      FromData: {
        listname: "",
      },
      FromDataError: {
        listname: "false",
      },
      Msg: "",
      MsgType: "",
      loader: "hidden",
      urlPath: "/contact-details",
    };

    //Binding

    this.handaleChange = this.handaleChange.bind(this);
  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
        if (value == "false") {
          FromDataError[key] = "This field is required";
          sta.setState({ FromDataError });
        }

        valid = false;
        console.log("loop", valid);
      }
    }

    console.log("funct", valid);

    return valid;
  }

  handaleChange(data) {
    let value = data.target;
    var fileUpload = document.getElementById("csv");
    this.setState({ fileUpload: data.target.files[0] });
  }
  handaleInputValue = (data) => {
    let sta = this;
    let FromDataError = this.state.FromDataError;

    let name = data.target.name;
    let value = "";
    let FromData = this.state.FromData;

    value = data.target.value;
    FromData[name] = value;
    this.setState({ FromData }, () => { });

    //validate from
    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((error) => {
      FromDataError[name] = error;
      this.setState({ FromDataError }); //from error state
    });
  };

  handalecsvSubmit = (e) => {
    // console.log(e)
    e.preventDefault();

    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;

    if (sta.validateForm(FromDataError)) {
      sta.setState({ loader: "" }); ///loader on

      var fileUpload = document.getElementById("csv");
      var regex = /^([a-zA-Z0-9\s_\\.\-:()])+(.csv|.txt)$/;

      if (regex.test(fileUpload.value.toLowerCase())) {
        if (typeof FileReader != "undefined") {
          try {
            var reader = new FileReader();
            reader.onload = async function (e) {
              console.log("regex ", e.target.result);
              var table = document.createElement("table");
              var rows = e.target.result.split("\n");

              var allTextLines = e.target.result.split("\n");
              var headers = allTextLines[0].split(",");
              var lines = [];

              allTextLines.forEach((element, key) => {
                console.log("ddddddddddddd", element, key);

                if (key > 0 && element) {
                  element = element.split(",");
                  let a = {};
                  element.forEach((eachelement, keyeach) => {
                    let headerkeys = headers[keyeach]?.trim();

                    a[headerkeys] = eachelement?.trim();
                    // console.log(eachelement, keyeach,headers[keyeach])
                  });
                  lines.push(a);
                }
              });

              console.log("rr " + JSON.stringify(lines));
              //return false;
              if (lines.length > 0) {
                sta.setState({ loader: "visible" }); ///loader on

                //Group create

                var formdata = new FormData();

                formdata.append("group_name", FromData.listname);
                formdata.append("group_type", 'jobseeker');

                let request = {
                  end_point: '/create-contact-group',
                  formdata: formdata,
                  token: localStorage.getItem("gs_token")

                };


                POST_API(request).then(resp => {

                  if (resp.status == 'success') {

                    var formdata2 = new FormData();

                    formdata2.append("allcontactData", JSON.stringify(lines));
                    formdata2.append("group_id", resp.data.id);

                    let request2 = {
                      end_point: "/create-bulkcontact",
                      formdata: formdata2,
                      token: localStorage.getItem("gs_token"),
                    };

                    //console.log('......request',formdata)

                    POST_API(request2)
                      .then((resp) => {
                        sta.setState({ loader: "hidden" }); ///loader off
                        //console.log('......res',resp)
                        sta.setState({ loader: "hidden" }); ///loader on
                        if (resp.status == "success") {
                          sta.setState({
                            Msg: resp.data + " Contact(s) added successfully",
                            MsgType: "success",
                          });

                          setTimeout(() => {
                            window.location.reload();
                          }, 3000);
                        } else {
                          document.getElementById("csv").value = "";
                          sta.setState({ Msg: resp.message, MsgType: "danger" });
                        }
                      })
                      .catch((ee) => {
                        sta.setState({ loader: "hidden" }); ///loader on
                        console.log("/addcontactERR", ee);
                      });




                  }
                  else {
                    sta.setState({ loader: "hidden" }); ///loader on
                    sta.setState({ Msg: resp.message, MsgType: 'danger' })
                    //sta.setState({Msg:'Contact add Failed',MsgType:'danger'})
                  }
                }).catch((ee) => {
                  sta.setState({ loader: "hidden" }); ///loader on
                  console.log('/addcontactERR', ee)
                });



              } else {
                document.getElementById("csv").value = "";
                sta.setState({
                  Msg: "Do not upload blank csv file.",
                  MsgType: "danger",
                });
              }
            };
            reader.readAsText(fileUpload.files[0]);
          } catch (err) {
            console.log("eeeeeeeeeeeeeeeeee", err);
            sta.setState({
              Msg: "Please upload a valid CSV file.",
              MsgType: "danger",
            });
          }
        } else {
          alert("This browser does not support HTML5.");
        }
      } else {
        sta.setState({ loader: "hidden" }); ///loader on
        sta.setState({
          Msg: "Please upload a valid CSV file.",
          MsgType: "danger",
        });
      }
    } else {
      sta.setState({ loader: "hidden" }); ///loader on
      sta.setState({ Msg: "Please Fill Mandatory fields", MsgType: "danger" });
    }
  };

  componentDidMount() {
    let sta = this;
    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError;

    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg });
      sta.setState({ MsgType: sta.props.location.state.MsgType });
    }

    try {
      if (localStorage.getItem("gs_user") != null) {
        //let FromData = JSON.parse(localStorage.getItem("gs_user"))
        //sta.setState({loader:'visible'})///loader on
      }
    } catch (error) { }
  }

  dataClear() {
    this.setState({ Msg: "" });
  }

  render() {
    let FromData = this.state.FromData;

    return (
      <div id="PaymentForm">
        <Modal
          show={this.props.show}
          backdrop="static"
          className="modal_upload"
          keyboard={false}
          onHide={this.props.close}
          id="modal"
          centered
          size="lg"
          onShow={() => this.dataClear()}
        >
          <Form className="tabform" onSubmit={this.handalecsvSubmit}>
            {/* <Modal.Header closeButton>
            <Modal.Title>Add Contact</Modal.Title>
          </Modal.Header> */}
            <Modal.Body>
              <Loader loadmsg="please wait" visibility={this.state.loader} />
              <Row className="m-0 mt-4 mb-4">
                <Col md={12} className="pl-0">
                  {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
                  {this.state.Msg !== "" ? (
                    <>
                      <FlashMess
                        m={this.state.Msg}
                        mtype={
                          this.state.MsgType ? this.state.MsgType : "success"
                        }
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  <Form.Row>

                    <Form.Group controlId="formBasicEmail4" as={Col}>
                      <Form.Label>List Name<span>*</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="listname"
                        onChange={this.handaleInputValue}
                        placeholder="Enter list name"

                        validaterule={['required']}
                        validatemsg={['This field is required']}
                      />
                      <p style={{ color: 'red' }}>{this.state.FromDataError.listname == 'false' ? '' : this.state.FromDataError.listname}</p>
                    </Form.Group>
                  </Form.Row>


                  <div className="upload_file_area text-center">
                    <h4>Click</h4>
                    <label className="upload_file_label">
                      <input
                        type="file"
                        accept=".csv"
                        id="csv"
                        validaterule={["required"]}
                        validatemsg={["This field is required"]}
                        onChange={this.handaleChange}
                      />
                      <img width="120" src={img4725573} className="img-fluid" />
                    </label>
                    <div className="upload_info">
                      <h4>to Upload CSV File</h4>
                      <p className="mb-0">{this.state.fileUpload.name}</p>
                    </div>

                    <div className="upload_action row">
                      <div className="col pr-2">
                        <Button
                          variant="primary"
                          onClick={this.props.close}
                          className="btn btn-danger tabformbutton btn-block"
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="col pl-2">
                        <Button
                          variant="primary"
                          type="submit"
                          className="btn btn-primary tabformbutton btn-block"
                        >
                          {" "}
                          Submit{" "}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    );
  }
}
