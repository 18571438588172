import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { FlashMess, GET_API, POST_API, Loader } from "../../Helpers/Helpers";
import validation from "../../Validate/validator";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

export default class AddTempleteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      FromData: {
        email: "",
        templete_id: "",
        template_content: "",
        subject: "",
      },
      FromDataError: {
        email: "",
        templete_id: "false",
        subject: "",
      },
      Msg: "",
      MsgType: "",
      loader: "hidden",

      TemplateData: [],
      EmailemplateData: [],
      ContactData: [],
      emailTempContent: [],
      gtTemplate: "",
      body_content: "",
    };

    //Binding

    this.handaleChange = this.handaleChange.bind(this);
    this.handaleSubmit = this.handaleSubmit.bind(this);
    this.handaleChangeTemplate = this.handaleChangeTemplate.bind(this);
  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
        if (key != "description" && key != "templete_id") {
          if (value == "false") {
            FromDataError[key] = "This field is required";
            sta.setState({ FromDataError });
          }

          valid = false;
          console.log("loop", valid);
        }
      }
    }

    console.log("funct", valid);

    return valid;
  }

  handaleChange = (data) => {
    let sta = this;

    let name = data.target.name;
    let value = "";
    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError;

    value = data.target.value;
    FromData[name] = value;
    this.setState({ FromData }, () => { });

    if (name == 'templete_id') {
      let request1 = {
        end_point: "/template-view/" + value,
        token: localStorage.getItem("gs_token"),
      };

      sta.setState({ loader: "visible" }); ///loader on

      GET_API(request1)
        .then((resp) => {
          sta.setState({ loader: "hidden" }); ///loader off

          if (resp.status == "success") {
            this.setState({ gtTemplate: resp.data.content });
          } else {
            this.setState({ gtTemplate: "" });
          }
        })
        .catch((ee) => {
          sta.setState({ loader: "hidden" }); ///loader off

          console.log("/code list ERR", ee);
        });
    }


    // if (FromData.templete_id == 33) {
    //   this.setState({ gtTemplate: this.state.emailTempContent?.[0] }, () => { });
    // } else if (FromData.templete_id == 32) {
    //   this.setState({ gtTemplate: this.state.emailTempContent?.[1] }, () => { });
    // } else if (FromData.templete_id == 27) {
    //   this.setState({ gtTemplate: this.state.emailTempContent?.[2] }, () => { });
    // } else {
    //   this.setState({ gtTemplate: this.state.emailTempContent }, () => { });
    // }

    //validate from
    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((error) => {
      FromDataError[name] = error;
      this.setState({ FromDataError }); //from error state
    });
  }

  handaleSubmit(e) {
    e.preventDefault();

    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;
    // return false

    if (this.validateForm(FromDataError)) {
      sta.setState({ loader: "visible" }); ///loader on

      var formdata = new FormData();

      formdata.append("email", this.props.onClickEmail);
      formdata.append(
        "subject",
        FromData.templete_id == "custom"
          ? FromData.subject
          : FromData.templete_id
      );
      formdata.append(
        "body", this.state.body_content
      );
      formdata.append(
        "type",
        FromData.templete_id != "custom"
          ? FromData.templete_id : ""
      );


      let request = {
        end_point: "/jobseeker-send-message",
        formdata: formdata,
        token: localStorage.getItem("gs_token"),
      };

      POST_API(request)
        .then((resp) => {
          sta.setState({ loader: "hidden" }); ///loader off
          if (resp.status == "success") {
            this.setState({
              Msg: "Email sent successfully",
              MsgType: "success",
            });
          } else {
            this.setState({
              Msg: "Fill the form correctly!",
              MsgType: "danger",
            });
          }
        })
        .catch((ee) => {
          console.log("/addcontactERR", ee);
        });
    }
  }

  searchChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    let sta = this;
    let ContactData = this.state.ContactData;
    let request = {
      end_point: "/list-contact?search_key=" + value,
      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(request)
      .then((resp) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          ContactData = resp.data;

          sta.setState({ ContactData });
        } else {
        }
      })
      .catch((ee) => {
        sta.setState({ loader: "hidden" }); ///loader off

        console.log("/code list ERR", ee);
      });
  };

  componentDidMount() {
    this.handleSelectTemplateGroup();
    this.getTemplate();
  }


  getTemplate() {
    let sta = this;
    let FromData = this.state.FromData;

    let requestTemp = {
      end_point: "/list-campaign-templete",
      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(requestTemp)
      .then((resp) => {
        sta.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          let TemplateData = resp.data;

          let emailTempContent = TemplateData.map((ele) => ele.content);

          sta.setState({ TemplateData, emailTempContent });
        } else {
        }
      })
      .catch((ee) => {
        sta.setState({ loader: "hidden" }); ///loader off

        console.log("/TemplateData list ERR", ee);
      });
  }

  handaleChangeTemplate(data) {
    let sta = this;

    if (data) {
      let value = "";
      let EmailemplateData = [];
      this.state.TemplateData.map((items, key2) => {
        if (items.template_type == value) {
          EmailemplateData.push(items);
        }
      });
      this.setState({ EmailemplateData });
    }
  }

  handleSelectTemplateGroup() {
    let sta = this;
    let FromData = this.state.FromData;

    let requestTemp = {
      end_point: "/list-predefine-templete",
      token: localStorage.getItem("gs_token"),
    };

    sta.setState({ loader: "visible" }); ///loader on

    GET_API(requestTemp)
      .then((resp) => {
        console.log(resp);
        sta.setState({ loader: "hidden" }); ///loader off

        if (resp.status == "success") {
          let EmailemplateData = resp.data;

          sta.setState({ EmailemplateData });
          this.handaleChangeTemplate();
        } else {
        }
      })
      .catch((ee) => {
        sta.setState({ loader: "hidden" }); ///loader off

        console.log("/TemplateData list ERR", ee);
      });
  }

  render() {
    let FromData = this.state.FromData;
    console.log("getData", this.state.body_content)
    return (
      <div id="PaymentForm">
        <Modal
          show={this.props.show}
          backdrop="static"
          keyboard={false}
          onHide={this.props.close}
          id="modal"
        >
          <Form className="tabform" onSubmit={this.handaleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Send Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="m-0 mt-4 mb-4">
                <Col md={12} className="pl-0">
                  <Form.Row>
                    <Form.Group controlId="formBasicEmail4" as={Col}>
                      <Form.Label>To</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        readOnly
                        value={this.props.onClickEmail}
                        validaterule={["required"]}
                        validatemsg={["This field is required"]}
                      />
                      <p style={{ color: "red" }}>
                        {this.state.FromDataError.email == "false"
                          ? ""
                          : this.state.FromDataError.email}
                      </p>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group controlId="formBasicEmail22" as={Col}>
                      <Form.Label>Select Template</Form.Label>
                      <Form.Control
                        as="select"
                        name="templete_id"
                        onChange={this.handaleChange}
                        className="w-100"
                      >
                        <option value="">--Select Template--</option>
                        <option value="custom">Custom</option>
                        {this.state.EmailemplateData.map((ele, ind) => {
                          return (
                            <option value={ele.id}>{ele.templete_name} {(ele.section_type == 'non_customize') ? '(Non-personalised)' : '(Personalised)'}</option>
                          );
                        })}
                      </Form.Control>
                      <p style={{ color: "red" }}>
                        {this.state.FromDataError.templete_id == "false"
                          ? ""
                          : this.state.FromDataError.templete_id}
                      </p>
                    </Form.Group>
                  </Form.Row>

                  {FromData.templete_id == "custom" && (
                    <>
                      <Form.Row>
                        <Form.Group controlId="formBasicEmail4" as={Col}>
                          <Form.Label>Subject</Form.Label>
                          <Form.Control
                            type="text"
                            name="subject"
                            onChange={this.handaleChange}
                            placeholder="Subject"
                            validaterule={["required"]}
                            validatemsg={["This field is required"]}
                          />
                          <p style={{ color: "red" }}>
                            {this.state.FromDataError.subject == "false"
                              ? ""
                              : this.state.FromDataError.subject}
                          </p>
                        </Form.Group>
                      </Form.Row>
                      <Form.Label>Message</Form.Label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={""}
                        name="template_content"
                        onInit={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          // console.log( 'Editor is ready to use!', editor );
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          this.setState({ body_content: data });
                        }}
                        onBlur={(event, editor) => {
                          //console.log( 'Blur.', editor );
                        }}
                        onFocus={(event, editor) => {
                          //console.log( 'Focus.', editor );
                        }}
                      />
                    </>
                  )}

                  {FromData.templete_id !== "custom" &&
                    FromData.templete_id !== "" && (
                      <>
                        <Form.Label>Message</Form.Label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={this.state.gtTemplate.replace('{{referral_link}}', localStorage.getItem("Jobseeker_URL"))
                            .replace('{{full_name}}', this.props.userName)}
                          name="template_content"
                          onInit={(editor) => {
                            const data = editor.getData();
                            this.setState({ body_content: data });
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            this.setState({ body_content: data });
                          }}
                          onBlur={(event, editor) => {
                            //console.log( 'Blur.', editor );
                          }}
                          onFocus={(event, editor) => {
                            //console.log( 'Focus.', editor );
                          }}
                        />
                      </>
                    )}

                  <Form.Row className="justify-content-center mt-4">
                    <Form.Group
                      controlId="formBasicEmail4"
                      as={Col}
                      className="col-md-4 "
                    >
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn btn-primary btn-md tabformbutton"
                      >
                        Send
                      </Button>
                    </Form.Group>
                  </Form.Row>
                </Col>
              </Row>
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    );
  }
}
