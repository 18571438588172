import React, { Component, useState } from 'react';
import { Container, Row, Col, Tab, Nav, Form, Button, Image, Card } from "react-bootstrap";
import { FlashMess, GET_API, POST_API,Loader } from "../Helpers/Helpers";
import validation from '../Validate/validator';
import Leftpanel from './Section/LeftPanel';
import './css/dashboard.css';
import DataTable from "react-data-table-component";


export default class addAffiliateBankDetails extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
                        
                        FromData: {
                                       
                                        account_name : '',
                                        account_number : '',
                                        confirm_account_number:'',
                                        account_ifsc_code:'',
                                        bank_name:'',
                                        confirm_account_number: "",
                                        aba_ach_number: ""
                                        
                                    },
                        FromDataError :
                                        {
                                          
                                            account_name : 'false',
                                            account_number : 'false',
                                            confirm_account_number:'',
                                            account_ifsc_code:'false',
                                            bank_name:'false',
                                            confirm_account_number: "false",
                                            aba_ach_number: "false"
                                         
                                         
                                        
                                        },
                                       
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/add-affiliate-bank',
           
                     };

        //Binding

        this.handaleChange = this.handaleChange.bind(this)
        this.handaleSubmit = this.handaleSubmit.bind(this)
       
       
     
    }

    

    

    validateForm  (errors) {
      let valid = true;
      let sta = this;
      let FromDataError = this.state.FromDataError;

      for (const [key, value] of Object.entries(errors)) {
        if(value.length > 0 )
        {
          
          if(value=='false')
          {
            FromDataError[key] = 'This field is required';
            sta.setState({FromDataError})
          }
          
          valid = false;
          console.log('loop',valid)
        }
      
      }
     
     
      console.log('funct',valid)
    
      return valid;
};
  
    
    

    handaleChange(data)
    {
       
        let sta = this;
        let FromDataError = this.state.FromDataError;
        

        let name = data.target.name;
        let value = '';
        let FromData = this.state.FromData;
        

        value = data.target.value;
        FromData[name] = value;
        this.setState({FromData},()=>{
         
        }) 

         //validate from
         var valid_obj = {
          value:value,
          rules:data.target.getAttribute("validaterule"),
          message:data.target.getAttribute("validatemsg")
        }

              validation(valid_obj).then((error)=>{
                  FromDataError[name] = error
                  this.setState({FromDataError}) //from error state
              })

       
    }

    handaleSubmit(e)
    {   
        
        e.preventDefault();

        let sta = this;
        let FromDataError = this.state.FromDataError;
        let FromDataError1 = this.state.FromDataError1;
        let FromData = this.state.FromData;
        let gs_user = '';

      
    

       //console.log(FromData)

        if(this.validateForm(FromDataError)  )
        {
        
          sta.setState({loader:'visible'})///loader on


          var formdata = new FormData();
              
                formdata.append("holder_name", FromData.account_name);
                formdata.append("account_number", FromData.account_number);
                formdata.append("IFSC_code", FromData.account_ifsc_code);
                formdata.append("bank_name", FromData.bank_name);
                formdata.append("aba_ach_number", FromData.aba_ach_number);
               
                    
                let request = {
                  end_point : '/create-bankdetails',
                  formdata:formdata,
                  token: localStorage.getItem("gs_token")

                };

                

                POST_API(request).then(resp=>{

                  sta.setState({loader:'hidden'})///loader off

                  console.log('......response',resp)

                        if(resp.status == 'success')
                        {
                          //sta.setState({Msg:'Contact add Successfully',MsgType:'success'})
                          
                          
                          this.props.history.push({
                            pathname: '/add-affiliate-bank',
                            state:{Msg:'Bank Details Added Successfully',MsgType:'success'}
                  
                          })

                            // setTimeout(() => {
                            //   window.location.reload()
                              
                            // }, 3000);

                          

                        }
                        else{
                            
                            //sta.setState({Msg:resp.message.campaign_name.join(),MsgType:'danger'})
                                sta.setState({Msg:'Bank Details Failed',MsgType:'danger'})
                        }
                        }).catch((ee)=>{

                            console.log('/addcontactERR',ee)
                        });
                      

           

        }else
        { 

   
           
            sta.setState({Msg:'Fill the form correctly!'})
            sta.setState({MsgType:'danger'})
           
        }

       
    }

  
  
    componentDidMount()
    {

                let sta = this;
                let ContactData =this.state.ContactData
                let ContactDataError =this.state.ContactDataError

                

                //for redirectin message show
                if(sta.props.location.state!=undefined)
                {
                    sta.setState({Msg:sta.props.location.state.Msg})
                    sta.setState({MsgType:sta.props.location.state.MsgType})
                }
      

      
    }

   
    data=[
      "Bank of America","Citigroup","Wells Fargo","Goldman Sachs","Morgan Stanley","The Bank of New York Mellon"
      ,"Charles Schwab Corporation","Truist Financial","PNC Financial Services","TD Bank, N.A."
      ,"Capital One","State Street Corporation","HSBC Bank USA","Citizens Financial Group","SVB Financial Group"
      ,"UBS","Fifth Third Bank","USAA","American Express","M&T Bank","Mechanics Bank","Glacier Bancorp"
      ,"BMO Harris Bank","KeyCorp","Ally Financial","Huntington Bancshares","Barclays","Central Bancompany"
      ,"Santander Bank","RBC Bank","Regions Financial Corporation","Ameriprise","Northern Trust"
      ,"Union Bank","BNP Paribas / Bank of the West","Deutsche Bank","Discover Financial","First Citizens BancShares"
      ,"Synchrony Financial","Credit Suisse","Comerica","Raymond James Financial","First Horizon National Corporation"
      ,"Popular, Inc.","Webster Bank","Western Alliance Bank","CIBC Bank USA","New York Community Bank"
      ,"East West Bank","Synovus","Valley National Bank","Cullen/Frost Bankers, Inc.","Wintrust Financial"
      ,"Mizuho Financial Group","South State Bank","John Deere Capital Corporation","Old National Bank"
      ,"FNB Corporation","PacWest Bancorp","Pinnacle Financial Partners","TIAA","UMB Financial Corporation"
      ,"Prosperity Bancshares","Associated Banc-Corp","BankUnited","Stifel","Hancock Whitney","BOK Financial Corporation"
      ,"Commerce Bancshares","MidFirst Bank","Texas Capital Bank","First Interstate BancSystem","Umpqua Holdings Corporation"
      ,"Sumitomo Mitsui Banking Corporation","United Bank (West Virginia)","FirstBank Holding Co","Simmons Bank"
      ,"Arvest Bank","First National of Nebraska","First Hawaiian Bank","Fulton Financial Corporation","Flagstar Bank"
      ,"Home BancShares","United Community Bank","Ameris Bancorp","Bank of Hawaii","BCI Financial Group"
      ,"Eastern Bank","Pacific Premier Bancorp","Cathay Bank","Columbia Bank","WSFS Bank","First BanCorp"
      ,"Customers Bancorp","Washington Federal","Independent Bank","Atlantic Union Bank","Heartland Financial USA"
  ]


    render() {
        let  FromData = this.state.FromData
        let  ContactData = this.state.ContactData
        return (
            <div>
                 {FromData.hasOwnProperty("account_name")? (<>
            

            <Container fluid className="dash_container">
          <Row>
            {/* <Leftpanel urlPath={this.state.urlPath} {...this.props} /> */}
            <Col md={12}>
            <Loader loadmsg="please wait" visibility={this.state.loader}/>

            <div className="tabpanelOneForm">
             
             <Card className="p-5 body_card">
           
            <h3>Add Bank Details </h3>
                        <Form
                          className="tabform"
                          onSubmit={this.handaleSubmit}
                        >
                          <Row className="m-0 mt-4 mb-4">

                        
                            <Col md={12} className="pl-0 pr-0">
                                {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
               {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}
              
                
                       
                               <Form.Row>
                                <Form.Group controlId="formBasicEmail4" as={Col}>
                                  <Form.Label>Account Holder Name 
                                    <span className="text-danger"> *</span></Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="account_name"
                                    onChange={this.handaleChange}
                                    placeholder="Enter your account holder name"
                                   
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.account_name=='false'?'': this.state.FromDataError.account_name}</p>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail4" as={Col}>
                                <Form.Label>Bank Name
                                <span className="text-danger"> *</span>
                                </Form.Label>
                                    <Form.Control list="data"
                                    type="text"
                                    placeholder="Enter your bank name" 
                                    name="bank_name"
                                    onChange={this.handaleChange}
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                    />
                                    <datalist id="data">
                                        {this.data.map((ele)=><option>{ele}</option>)}
                                    </datalist>
                                    <p style={{ color: 'red' }}>{this.state.FromDataError.bank_name=='false'?'': this.state.FromDataError.bank_name}</p>
                                </Form.Group>
                              </Form.Row>

                              <Form.Row>
                              <Form.Group controlId="formBasicEmail4" as={Col}>
                                  <Form.Label>Bank Account Number
                                  <span className="text-danger"> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="account_number"
                                    onChange={this.handaleChange}
                                    placeholder="Enter your bank account number"
                                   
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.account_number=='false'?'': this.state.FromDataError.account_number}</p>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail4" as={Col}>
                                  <Form.Label>Confirm Bank Account Number
                                  <span className="text-danger"> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="confirm_account_number"
                                    onChange={this.handaleChange}
                                    placeholder="Enter your confirm bank account number"
                                    validaterule={['required', 'same|'+FromData.account_number]}
                                    validatemsg={['This field is required', "Confirm bank account number don't match"]}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.confirm_account_number=='false'?'': this.state.FromDataError.confirm_account_number}</p>
                                </Form.Group>
                              </Form.Row>
                              


                              <Form.Row>
                                

                                <Form.Group controlId="formBasicEmail4" as={Col}>
                                  <Form.Label>Your Bank SWIFT Code
                                  <span className="text-danger"> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="account_ifsc_code"
                                    onChange={this.handaleChange}
                                    placeholder="Enter your bank SWIFT code"
                                   
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.account_ifsc_code=='false'?'': this.state.FromDataError.account_ifsc_code}</p>
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail4" as={Col}>
                                  <Form.Label>Your Bank ABA-ACH Number
                                  <span className="text-danger"> *</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="aba_ach_number"
                                    onChange={this.handaleChange}
                                    placeholder="Enter your bank ABA-ACH Number"
                                   
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.aba_ach_number=='false'?'': this.state.FromDataError.aba_ach_number}</p>
                                </Form.Group>
                        
{/*                              
                                <Form.Group controlId="formBasicEmail4" as={Col}>
                                  <Form.Label>Bank Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="bank_name"
                                    onChange={this.handaleChange}
                                    placeholder="Enter your bank name"
                                   
                                    validaterule={['required']}
                                    validatemsg={['This field is required']}
                                  />
                                     <p style={{ color: 'red' }}>{this.state.FromDataError.bank_name=='false'?'': this.state.FromDataError.bank_name}</p>
                                </Form.Group> */}
                                
                               
                              </Form.Row>

                              <Form.Row className="justify-content-center">
                                <Form.Group controlId="formBasicEmail4" as={Col} className="col-md-4 ">
                                  <Button
                                    variant="primary"
                                    type="submit"
                                    className="btn btn-primary btn-md tabformbutton"
                                  >
                                    Submit
                                  </Button>
                                </Form.Group>
                              </Form.Row>

                         
                            </Col>

                            {/* <Col md={8} className="pl-1 tabformRight">
                              <Button
                                variant="primary"
                                type="submit"
                                className="btn btn-primary btn-md tabformbutton"
                              >
                                Submit
                              </Button>
                            </Col> */}

                          </Row>
      
                        </Form>
           

                      
                        </Card>    
                      
                      </div>





            </Col>     
          </Row>
        </Container>

        </>):(<p>....Loading</p>) }


        </div>
          
                
                
           
        );
    }
}