import React,{useState,useEffect} from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import { GET_API, POST_API,Loader } from "../Helpers/Helpers";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useParams } from 'react-router-dom'

const OtherProducts = (props) => {
  const {id:product_id,type} = useParams();
  const [loader,setLoader] = useState("hidden");
  const [dataCount,setdataCount] = useState(0);
  const [start_date,setStartDate] = useState("");
  const [end_date,setEndDate] = useState("");
  const [FromData,setFromData] = useState([]);
  const [pageNo,setPageNo] = useState(1);
  const [pagination,setPagination] = useState("");

  const [paymentSource,setPaymentSource] = useState(0);
  const [paymentStatus,setPaymentStatus] = useState(0);
  
  const getPageNumber = (url)=>{
    let params = new URL(url).searchParams;
    return params.get("page");
  }

  const fetchData = async (pageNo) => {
    var formdata = new FormData();
    formdata.append("product_id", product_id);
    formdata.append("app_name", "NEXGEN");
    formdata.append("paymentSource", paymentSource);
    formdata.append("paymentStatus", paymentStatus);
    if(start_date && end_date){
      formdata.append("start_date", moment(start_date).format("YYYY-MM-DD"));
      formdata.append("end_date", moment(end_date).format("YYYY-MM-DD"));
    }
    let request = {
      end_point : '/nexgen-affiliate-list?page='+pageNo,
      token: localStorage.getItem("gs_token"),
      formdata : formdata
  };
  
  setLoader('visible')///loader on
  POST_API(request).then(resp=>{
    setLoader('hidden')
        if(resp.status == 'success')
        {
          setFromData(resp.data.data)
          setdataCount(resp.data.data.length)

          let pagination =  (
          <nav aria-label="Page navigation example">
            <ul className="pagination">
            {
              resp.data.prev_page_url && 
              <li className="page-item"><a className="page-link" 
              onClick={()=>{
                fetchData(getPageNumber(resp.data.prev_page_url))
              }} href="javascript:void(0)"
              >Previous</a></li>
            }

            {resp.data.total/resp.data.per_page > 1 &&
              Array.apply(1, Array(resp.data.total/resp.data.per_page)).map(function (x, i) {
                return <li className="page-item" ><a className="page-link" 
                onClick={()=>{
                  fetchData(i+1)
                }} href="javascript:void(0)"
                >{i+1}</a></li>;
              }
                )
            }

            {
              resp.data.next_page_url && <li className="page-item">
                <a className="page-link" onClick={()=>{
                  fetchData(getPageNumber(resp.data.next_page_url))
                }} href="javascript:void(0)">Next</a></li>
            }
            </ul></nav>
          );
          setPagination(pagination);

        }else{
          setFromData({})
          setdataCount(0)
        }
    }).catch((ee)=>{
      setLoader('hidden')///loader off
      console.log('/code list ERR',ee)
    });
  };

  useEffect(() => {
    fetchData(pageNo);
}, [paymentStatus,paymentSource,product_id]);



    return (<div>
     
 

        <Container fluid className="dash_container">
        <Row>

        <Col md={12}>
        
        <Loader loadmsg="please wait" visibility={loader}/>
        <div className="tabpanelOneForm pt-3">

        <h3 style={{"text-transform":"capitalize"}}> {type.replaceAll("-"," ") } Commision </h3>
      
        <Row className='justify-content-end ml-0 mr-0 mb-3'>
                        <div className='search_block pl-3 pr-3 pt-2 pb-2 justify-content-end align-items-center'>
                          <Col md="4"></Col>
                          <Col sm="4" md="4" className='d-flex align-items-center'><span>From Date</span>
                            <DatePicker
                              className='pl-2 form-control'
                              selected={start_date}
                              name="start_date"
                              placeholderText="mm-dd-yyyy"
                              dateFormat="MM-dd-yyyy"
                              onChange={setStartDate}
                            />
                            <br />
                            
                          </Col>
                          <Col sm="4" md="4" className='d-flex align-items-center'> <span>To Date</span>
                           
                            <DatePicker
                              className='pl-2 form-control'
                              selected={end_date}
                              name="end_date"
                              placeholderText="mm-dd-yyyy"
                              dateFormat="MM-dd-yyyy"
                              onChange={setEndDate}
                            />
                            <br />
                            
                          </Col>
                          <Col className="search_btn" >
                            <Button
                              onClick={(e)=>{
                                fetchData(1)
                              }}
                              variant="primary"
                              type="submit"
                              className="btn btn-primary btn-md">

                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                viewBox="0 0 256 256"
                                className='search_icon'
                              >
                                <defs>
                                </defs>
                                <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                                  <path d="M 88.828 83.172 L 64.873 59.216 c 5.182 -6.443 8.002 -14.392 8.002 -22.779 c 0 -9.733 -3.79 -18.883 -10.673 -25.766 v 0 C 55.32 3.79 46.171 0 36.438 0 c -9.733 0 -18.883 3.79 -25.766 10.672 C 3.79 17.554 0 26.705 0 36.438 c 0 9.733 3.79 18.883 10.672 25.765 c 6.882 6.883 16.033 10.673 25.766 10.673 c 8.387 0 16.336 -2.82 22.779 -8.002 l 23.955 23.955 C 83.952 89.609 84.977 90 86 90 s 2.048 -0.391 2.828 -1.172 C 90.391 87.267 90.391 84.733 88.828 83.172 z M 16.329 56.546 C 10.958 51.175 8 44.034 8 36.438 c 0 -7.596 2.958 -14.737 8.329 -20.108 S 28.842 8 36.438 8 c 7.596 0 14.737 2.958 20.108 8.329 s 8.329 12.513 8.329 20.108 c 0 7.596 -2.958 14.737 -8.329 20.108 s -12.512 8.329 -20.108 8.329 C 28.842 64.875 21.7 61.917 16.329 56.546 z" />
                                </g>
                              </svg>
                            </Button>
                          </Col>
                        </div>
                      </Row>

                      
        <Row className='justify-content-end ml-0 mr-0 mb-3'>
        {localStorage.getItem('roleID') == 9 && 
        <Col>
        <label>Commision Source</label>
          <select className='form-control' onChange={(e)=>{
            setPaymentSource(e.target.value);
            
          }}>
            <option value="0">All</option>
            <option value="1">Self</option>
            <option value="2">Member</option>
          </select>   
          </Col>
          
        }
        <Col>
          <label>Payment Status</label>
          <select className='form-control' onChange={(e)=>{
            setPaymentStatus(e.target.value);
            
          }}>
            <option value="0">All</option>
            <option value="1">Paid</option>
            <option value="2">Unpaid</option>
          </select>   
          </Col>
        {/* </div> */}
            </Row>
            <table className='table table-responsive'>
	<thead>
		<tr>
    {localStorage.getItem('roleID') == 9 && 
      <>
        <th>Commision Source</th>
        <th>Affiliate Code</th>
      </>
    }
      <th>Amount</th> 
			<th>Commision Amount</th>
			<th>Commision Rate</th>
			
			{/* <th>Received From</th> */}
			<th>Received At</th>
			<th>Transaction No</th>
			<th>Is Paid ?</th>
      {localStorage.getItem('roleID') == 9 && 
      <th>Remarks</th>  
      }
		</tr>
	</thead>
	<tbody>
		{
     FromData.map(function(item,index){
        return (<tr key={index}>

        {
          localStorage.getItem('roleID') == 9 && 
          <>
            <td>{item.parent_id ? "Member" : "Self"}</td> 
            <td>{item.acode}</td> 
          </>
        }
          <td>${item.amount}</td>
          <td>${item.commision_amount}</td>
          <td>{item.commision_rate} {item.commision_type == "percentage" ? "%" : "Fixed"} </td>
          
          {/* <td>{item.origin}</td> */}
          <td>{item.created_at}</td>
          <td>{item.transaction_no}</td>
          <td>{item.is_paid ==1 ? 
              <span className='badge badge-success'> Paid </span> 
            : 
               <span className='badge badge-danger'>Unpaid</span>
          }</td>
          {localStorage.getItem('roleID') == 9 && 
            <td>{item.remarks}</td>  
          }
        </tr>)
      })
  }
		
	</tbody>
</table>

      {pagination}
        </div>
                          
                          
                      
        </Col>     
        </Row>
        </Container>


        </div>);
  
}

export default OtherProducts;