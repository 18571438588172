import React, { Component } from 'react'
import { FlashMess, GET_API, POST_API,Loader } from "../Helpers/Helpers";
import { Container, Row, Col, Tab, Nav, Form, Button, Image, OverlayTrigger,Tooltip,Card,Alert } from "react-bootstrap";
import {JobAlertsURL} from "../Config";

export default class JobAlert extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
                        
                        joblist:[],
                        rowid:100,
                        readless:100,
                        loader:'hidden',
                        
                     };
    }
    async handledata(id)
    {
        // console.log('handle data');
        await this.setState({rowid:id,readless:id});
        // console.log("rowid",this.state.rowid)
    }
    async handlelessdata(id)
    {
    // console.log('handle data');
        await this.setState({rowid:100,readless:100});
    // console.log("rowid",this.state.rowid)
    }
    componentDidMount()
    {
        var formdata = new FormData();
        let get_category=JSON.parse(localStorage.getItem('get_category'))
        let selecteddata= get_category!=null?get_category.map(x => x.value):'';
        formdata.append("search", selecteddata);
        let request = {
            end_point : '/get-joblist',
            token: localStorage.getItem("gs_token"),
            formdata:formdata
            

        };this.setState({loader:'visible'})
            POST_API(request).then(resp=>{
                this.setState({loader:'hidden'})
                    // console.log("joblist",resp)
                    this.setState({joblist:resp.jobs})
                    }).catch((ee)=>{

                    });
    }
    render() {
        // let job_category=localStorage.getItem("job_category")
        let userDetails = JSON.parse(localStorage.getItem("gs_user"))
        // let category=JSON.parse(localStorage.getItem('selected'))
        let get_category=JSON.parse(localStorage.getItem('get_category'))
        let selecteddata= get_category!=null?get_category.map(x => x.value):'';

        return (
            <div>
                <div >
                <Loader loadmsg="please wait" visibility={this.state.loader}/>
                    {selecteddata==null || selecteddata==""?
                    (<Alert variant="danger">
                    You can always select job category from your profile...
                    </Alert>):(<p>&nbsp;</p>)}
                    {this.state.joblist != undefined && this.state.joblist != null &&(<>
                    {this.state.joblist.map((job, index) => (
                        <>
                        <div className="card p-3 mb-3">
                        {localStorage.setItem("Country",job.country)}
                        <h3>{index+1}.&nbsp;<a target="_blank"  href={job.url}>{job.category}</a></h3>
                        <div className="jobaltitems" ><p><b style={{color: "#006DCE"}}>Post : </b>{job.name}</p></div>
                       {job.salary_min_annual!=null?(<div className="jobaltitems"><p><b style={{color: "#006DCE"}}>Salary : </b>{job.salary_min_annual}-{job.salary_max_annual}&nbsp;&nbsp;&nbsp;<b style={{color: "#006DCE"}}>Location : </b>{job.location}&nbsp;&nbsp;&nbsp;<b style={{color: "#006DCE"}}>Posted  : </b>{job.posted_time_friendly}</p></div>):(<div className="jobaltitems"><p><b style={{color: "#006DCE"}}>Salary : </b>N/A&nbsp;&nbsp;&nbsp;<b style={{color: "#006DCE"}}>Location : </b>{job.location}&nbsp;&nbsp;&nbsp;<b style={{color: "#006DCE"}}>Posted  : </b>{job.posted_time_friendly}</p></div>)}
                        {/* <p><b>Location : </b>{job.location}</p> */}
                        {/* <p><b>Source : </b><a target="_blank"  href={job.url}>{job.url}</a></p> */}
                        <div className="jobaltitems" ><p><b style={{color: "#006DCE"}}>Details : </b>{this.state.rowid!=index?(<span  dangerouslySetInnerHTML={{__html: job.snippet.substring(0,300) }}></span>):(<span  dangerouslySetInnerHTML={{__html: job.snippet.substring(0,300) }}></span>)}{this.state.readless!=index?(<Nav.Link onClick={()=>{this.handledata(index); }  } ><b>...View more</b></Nav.Link>):(<Nav.Link onClick={()=>{this.handlelessdata(index); }  } ><b>...View less</b></Nav.Link>)}</p></div>
                        {/* <div className="jobaltitems"><p><b>Details : </b></p><span  dangerouslySetInnerHTML={{__html: job.snippet }}></span></div> */}
                        {/* <p><b>Posted  : </b>{job.posted_time_friendly}</p> */}
                        </div>                        
                        </>

                    ))}
                    </>)}
                    <div>
                    <a onClick={()=> window.open(JobAlertsURL+"/view_job?job_type="+encodeURIComponent(selecteddata)+"&location="+localStorage.getItem("Country")+"&email="+userDetails.email+"&promo=&refcode=", "_blank")}  className="btn-1">View more</a>
                    </div>
                </div>
            </div>
        )
    }
}
