import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { FlashMess, GET_API, POST_API, Loader } from "../../Helpers/Helpers";
import validation from '../../Validate/validator';
import 'react-phone-number-input/style.css'
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";



import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

export default class AddContactModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

      FromData: {

        firstname: '',
        lastname: '',
        contact_no: '',
        email: '',
        listname: '',


      },
      FromDataError:
      {

        firstname: 'false',
        lastname: 'false',
        contact_no: 'false',
        email: 'false',
        listname: 'false',


      },
      Msg: '',
      MsgType: '',
      loader: 'hidden',
      urlPath: '/contact-details',



    };

    //Binding

    this.handaleChange = this.handaleChange.bind(this)
    this.handaleSubmit = this.handaleSubmit.bind(this)




  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
        console.log('loop', valid)
      }

    }


    console.log('funct', valid)

    return valid;
  };

  handalePhoneNoChange(data) {
    let FromData = this.state.FromData;
    let FromDataError = this.state.FromDataError;
    let value = '';
    FromData['contact_no'] = data;
    this.setState({ FromData }, () => {

    })

    if (FromData.contact_no != '') {
      var valid_obj = {
        value: value,
        rules: 'required',
        message: 'This field is required'
      }
      validation(valid_obj).then((error) => {
        FromDataError['contact_no'] = true
        this.setState({ FromDataError }) //from error state
      })
    }

  }



  handaleChange(data) {

    let sta = this;
    let FromDataError = this.state.FromDataError;


    let name = data.target.name;
    let value = '';
    let FromData = this.state.FromData;


    value = data.target.value;
    FromData[name] = value;
    this.setState({ FromData }, () => {

    })

    //validate from
    var valid_obj = {
      value: value,
      rules: data.target.getAttribute("validaterule"),
      message: data.target.getAttribute("validatemsg")
    }

    validation(valid_obj).then((error) => {
      FromDataError[name] = error
      this.setState({ FromDataError }) //from error state
    })


  }

  handaleSubmit(e) {

    e.preventDefault();

    let sta = this;
    let FromDataError = this.state.FromDataError;
    let FromData = this.state.FromData;
    let gs_user = '';
    let value = '';
    if (FromData.contact_no == '') {
      var valid_obj = {
        value: value,
        rules: 'required',
        message: 'This field is required'
      }
      validation(valid_obj).then((error) => {
        FromDataError['contact_no'] = error
        this.setState({ FromDataError }) //from error state
      })
    }



    console.log(FromData);

    if (this.validateForm(FromDataError)) {

      sta.setState({ loader: 'visible' })///loader on


      var formdata = new FormData();

      formdata.append("contact_no", FromData.contact_no);
      formdata.append("firstname", FromData.firstname);
      formdata.append("lastname", FromData.lastname);
      formdata.append("email", FromData.email);
      formdata.append("group_id", FromData.listname);

      let request = {
        end_point: '/create-contact',
        formdata: formdata,
        token: localStorage.getItem("gs_token")

      };

      console.log('......request', formdata)

      POST_API(request).then(resp => {

        sta.setState({ loader: 'hidden' })///loader off


        if (resp.status == 'success') {
          sta.setState({ Msg: 'Contact added successfully', MsgType: 'success' })


          //localStorage.setItem('gs_user',JSON.stringify(resp.data.user))
          // update

          setTimeout(() => {
            window.location.reload()

          }, 3000);



        }
        else {

          sta.setState({ Msg: resp.message.email.join(), MsgType: 'danger' })
          //sta.setState({Msg:'Contact add Failed',MsgType:'danger'})
        }
      }).catch((ee) => {

        console.log('/addcontactERR', ee)
      });




    } else {
      sta.setState({ Msg: 'Fill the form correctly!' })
      sta.setState({ MsgType: 'danger' })
    }


  }

  componentDidMount() {

    let sta = this;
    let FromData = this.state.FromData
    let FromDataError = this.state.FromDataError

    let group_id = this.props.group_id;

    console.log(group_id)

    //for redirectin message show
    if (sta.props.location.state != undefined) {
      sta.setState({ Msg: sta.props.location.state.Msg })
      sta.setState({ MsgType: sta.props.location.state.MsgType })
    }

    try {
      if (localStorage.getItem("gs_user") != null) {
        //let FromData = JSON.parse(localStorage.getItem("gs_user"))
        //sta.setState({loader:'visible'})///loader on



      }


    }
    catch (error) {

    }



  }

  getoneRecord() {

    let sta = this;
    let FromData = this.state.FromData
    let FromDataError = this.state.FromDataError
    let group_id = this.props.group_id;

    console.log(group_id)
    //for redirectin message show





  }





  render() {
    let FromData = this.state.FromData

    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
          onShow={() => this.getoneRecord()}
          backdrop="static"
          keyboard={false}
          onHide={this.props.close}
          id="modal"
          size="lg"
          centered
        >
          <Form
            className="tabform"
            onSubmit={this.handaleSubmit}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Add Contact
                <p style={{ color: 'red', fontSize: "10px", paddingTop: "10px" }}>You can add individual contacts within any existing list from here. Select the list name and add your contact details to proceed</p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>



              <Row className="m-0 mt-4 mb-4">


                <Col md={12} className="pl-0">
                  {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
                  {this.state.Msg !== '' ? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType ? this.state.MsgType : 'success'} /></>) : (<></>)}







                  <div>
                    <Form.Row>
                      <Form.Group controlId="formBasicEmail22" as={Col}>
                        <Form.Label>List Name</Form.Label>
                        <Form.Control style={{ width: "100%" }} as="select" name="listname" validaterule={['required']}
                          validatemsg={['This field is required']}
                          onChange={this.handaleChange}>
                          <option value="">Select</option>
                          {
                            this.props.groupList.length > 0 && (
                              <>
                                {this.props.groupList.map((listRow, index) => {
                                  if (listRow.has_send == 0) {
                                    return (
                                      <option id={index} value={listRow.id}>{listRow.group_name}</option>
                                    )
                                  }
                                })}
                              </>
                            )
                          }
                        </Form.Control>
                        <p style={{ color: 'red' }}>{this.state.FromDataError.listname == 'false' ? '' : this.state.FromDataError.listname}</p>
                      </Form.Group>
                    </Form.Row>


                    <Form.Row>
                      <Form.Group controlId="formBasicEmail4" as={Col}>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="firstname"
                          onChange={this.handaleChange}
                          placeholder="Enter your first name"

                          validaterule={['required']}
                          validatemsg={['This field is required']}
                        />
                        <p style={{ color: 'red' }}>{this.state.FromDataError.firstname == 'false' ? '' : this.state.FromDataError.firstname}</p>
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail3" as={Col}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="lastname"
                          onChange={this.handaleChange}
                          placeholder="Enter your last name"

                          validaterule={['required']}
                          validatemsg={['This field is required']}
                        />
                        <p style={{ color: 'red' }}>{this.state.FromDataError.lastname == 'false' ? '' : this.state.FromDataError.lastname}</p>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group controlId="formBasicEmail2" as={Col}>
                        <Form.Label>Contact No</Form.Label>
                        <span style={{ color: 'red', fontSize: "10px", paddingTop: "10px" }}> (With country code)</span>

                        <PhoneInput
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="US"
                          value=""
                          name="contact_no"
                          onChange={this.handalePhoneNoChange.bind(this)}
                          validaterule={['required', 'phone']}
                          validatemsg={['This field is required', 'Invalid Phone number']}
                        />



                        <p style={{ color: 'red' }}>{this.state.FromDataError.contact_no == 'false' ? '' : this.state.FromDataError.contact_no}</p>
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail1" as={Col}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"

                          onChange={this.handaleChange}
                          placeholder="Enter email"
                          validaterule={['required', 'isEmail']}
                          validatemsg={['This field is required', 'Input a valid Email']}
                        />
                        <p style={{ color: 'red' }}>{this.state.FromDataError.email == 'false' ? '' : this.state.FromDataError.email}</p>
                      </Form.Group>
                    </Form.Row>



                    <div className="upload_action row">
                      <div className="col pr-2">
                        <Button variant="primary" onClick={this.props.close} className="btn btn-danger tabformbutton btn-block">Cancel</Button>
                      </div>
                      <div className="col pl-2">
                        <Button variant="primary" type="submit" className="btn btn-primary tabformbutton btn-block">Submit</Button>
                      </div>
                    </div>
                  </div>




                </Col>
                {/* <Col md={8} className="pl-1 tabformRight">
             <Button
               variant="primary"
               type="submit"
               className="btn btn-primary btn-md tabformbutton"
             >
               Submit
             </Button>
           </Col> */}
              </Row>



            </Modal.Body>
            {/* <Modal.Footer>
          <Button
               variant="primary"
               type="submit"
               className="btn btn-primary btn-md tabformbutton"
             >
               Submit
             </Button>
            
          </Modal.Footer> */}

          </Form>

        </Modal>
      </div>
    );
  }
}
