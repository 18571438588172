import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Container, Row, Col, Tab, Nav, Form, Button } from "react-bootstrap";
import { withRouter } from "react-router";
import TokenVerify from "../Helpers/Helpers";
import { FlashMess, GET_API, POST_API, Loader } from "../Helpers/Helpers";
import validation from "../Validate/validator";
import "./css/jobseekersignup.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {STRIPE_KEY} from "../Config";
const promise = loadStripe(STRIPE_KEY);

const cardStyle = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#32325d"
      }
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};
export default class JobSeekerOTPandPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipInfo: "",
      resendTooltipInfo: "",
      inputType: 'password',
    }
  }

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  ResendOTP = (e) => {
    e.preventDefault();
    this.props.sendOTP();
  };

  toggleEye = () => {

    if (this.state.inputType === "password") {
      this.setState({ inputType: 'text' });

    }
    else {
      this.setState({ inputType: 'password' });

    }
  }

  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>{this.state.tooltipInfo}</Tooltip>
  );

  resendRenderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>{this.state.resendTooltipInfo}</Tooltip>
  );
  componentDidMount() {
    var formdata = new FormData();
    formdata.append("tooltip_key", "otp");

    let request = {
      end_point: "/get-tooltip",
      formdata: formdata
    };
    POST_API(request)
      .then((resp) => {

        if (resp.status == "success") {
          let tooltipText = resp.data.tooltip_description
          this.setState({ tooltipInfo: tooltipText })
        }
      })

    var formdata = new FormData();
    formdata.append("tooltip_key", "resend_otp");

    let request1 = {
      end_point: "/get-tooltip",
      formdata: formdata
    };
    POST_API(request1)
      .then((resp) => {

        if (resp.status == "success") {
          let tooltipText = resp.data.tooltip_description
          this.setState({ resendTooltipInfo: tooltipText })
        }
      })
  }


  render() {

    let { FromData } = this.props.inputValues;
    let { FromDataError1 } = this.props.formErr1;
    const eye = <FontAwesomeIcon icon={faEye} />;
    const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
    //console.log("err",FromDataError1)
    return (
      <Container className="sponsorfrom p-4">
        <Loader loadmsg="please wait" visibility={this.props.loader} />
        {this.props.Msg !== "" ? (
          <>
            <FlashMess
              m={this.props.Msg}
              mtype={this.props.MsgType ? this.props.MsgType : "success"}
            />
          </>
        ) : (
          <></>
        )}
        <Tab.Pane eventKey="first">
          <div className="tabpanelOneForm">
            <Form className="tabform" onSubmit={this.props.handaleSubmit}>
              <Row className="m-0 mt-4 mb-4">
                <Col md={12} className="tabformLeft">
                  <h5>Verification Details</h5>
                  <Form.Row>

                    <div className='col-md-8'>
                      <div className='row'>
                        <Form.Group
                          controlId="formBasicEmail"
                          as={Col}
                          className="field_box col-md-8"
                        >

                          <Form.Label>
                            One Time Password
                            <span className="text-danger "> *</span>
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={this.renderTooltip}>
                              <i className="fa-sharp fa-solid fa-circle-info pl-3"></i>
                            </OverlayTrigger>

                          </Form.Label>
                          {/* <div className="d-flex w-100 verify_field"> */}
                          <Form.Control
                            type="text"
                            className="col-md-12"
                            name="otptext"
                            value={FromData.otptext}
                            onChange={this.props.handaleChange}
                            placeholder="Enter One Time Password"
                            validaterule={["required"]}
                            validatemsg={[
                              "This field is required",
                              "Invalid one time code",
                            ]}
                          />


                          {this.props.VerifyapiStatus == "success" &&
                            <div className="verify_icon d-flex align-items-center">
                              <i className="fa fa-check-circle" aria-hidden="true"></i>
                            </div>

                          }
                          {/* </div> */}

                          <p style={{ color: "red" }}>
                            {FromDataError1.otptext == "false"
                              ? ""
                              : FromDataError1.otptext}
                          </p>



                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" as={Col} className="col-md-4">
                          <Form.Label>&nbsp;</Form.Label>
                          <Button type="submit" className="btn-main tabformbutton" onClick={this.ResendOTP}>
                            Resend
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={this.resendRenderTooltip}>
                              <i className="fa-sharp fa-solid fa-circle-info pl-3"></i>
                            </OverlayTrigger></Button>
                        </Form.Group>
                      </div>
                    </div>
                  </Form.Row>


                  <Form.Row>
                    <Form.Group controlId="formBasicEmail" as={Col} className="field_box col-md-8">
                      <Form.Label>Create Password</Form.Label>
                      <Form.Control
                        type={this.state.inputType}
                        name="password"
                        onChange={this.props.handaleChange}
                        value={FromData.password}
                        placeholder="Your Password"
                        validaterule={["required", "password"]}
                        validatemsg={[
                          "This field is required",
                          "password should be of at least 6 characters",
                        ]}
                      />
                      <i style={{ marginTop: '-38px', marginRight: '16px', position: 'relative', zIndex: 1, cursor: 'pointer', left: '646px' }} onClick={() => { this.toggleEye() }}>{this.state.inputType == 'text' ? eyeSlash : eye}</i>

                      <p style={{ color: "red" }}>
                        {FromDataError1.password == "false"
                          ? ""
                          : FromDataError1.password}
                      </p>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formBasicEmail" as={Col} className="field_box col-md-8">
                      <Form.Label>Confirm Password</Form.Label>

                      <div className="d-flex w-100 verify_field">

                        <Form.Control
                          type="password"
                          name="confirm_password"
                          onChange={this.props.handaleChange}
                          placeholder="Confirm Password"
                          value={FromData.confirm_password}
                          validaterule={[
                            "required",
                            "password",
                            "same|" + FromData.password,
                          ]}
                          validatemsg={[
                            "This field is required",
                            "password should be of at least 6 characters",
                            "Confirmed Password should be same as password",
                          ]}
                        />

                        {this.props.isPasswordValid &&
                          <div className="verify_icon d-flex align-items-center">
                            <i className="fa fa-check-circle" aria-hidden="true"></i>
                          </div>

                        }
                      </div>


                      <p style={{ color: "red" }}>
                        {FromDataError1.confirm_password == "false"
                          ? ""
                          : FromDataError1.confirm_password}
                      </p>
                    </Form.Group>
                  </Form.Row>

                </Col>


                {/* <hr className="w-100 mr-3 ml-3"/> */}

                <Col md={12} className="tabformRight mt-3">
                  <Col md={8}>
                    <Row className="justify-content-between mr-0">
                      <h5 className="reg_block_head">Card Details</h5>
                      {this.props.servicetype != "" ? <div className="payable_ammount">
                        <span>Payable Amount:</span> <strong>${this.props.sta.hasPromo ? this.props.sta.promoApplied.offerPrice : this.props.siteDetails.offerPrice}</strong>
                      </div> : <div className="payable_ammount">
                        <span>Payable Amount:</span> <strong>$99</strong>
                      </div>}
                    </Row>

                  </Col>

                  <div className='d-flex align-items-center mb-3'>
                    {/* <input type="checkbox" id="html" checked={this.props.sta.hasPromo} className='check_input' onChange={this.props.applyCheckbox} /> */}
                    <input type="checkbox" id="html" disabled checked={false} className='check_input' onChange={this.props.applyCheckbox} />
                    <label htmlFor="html" className="mb-0 ml-2">Have a promo code? <span className="text-danger"><b>Your Jobseeker Bundle Promo Code has Already Been Applied</b></span></label>
                  </div>



                  {/* <div className='col-md-8'> */}
                  <Form.Row>
                    {this.props.sta.hasPromo && (
                      <div className='col-md-8'>
                        <div className='row'>
                          <Form.Group controlId="formBasicEmail" as={Col} className="col-md-8">
                            <Form.Control type="text" id="promoCode" placeholder="promo" />
                            {this.props.sta.Msg != "" && (<p style={{ color: this.props.sta.MsgType == "danger" ? "red" : "green" }}>{this.props.sta.Msg}</p>)}
                          </Form.Group>
                          <Form.Group controlId="formBasicEmail" as={Col} className="col-md-4">
                            <Button type="button" className="promo_code_apply" size="sm" onClick={this.props.validatePromo}>Apply</Button>
                          </Form.Group>
                        </div>
                      </div>
                    )}
                  </Form.Row>
                  {/* </div> */}

                  <Form.Row>
                    <Form.Group controlId="formBasicEmail" as={Col} className="col-md-8">
                      <Form.Label>
                        Card Holder Name
                        <span className="text-danger"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="card_name"
                        placeholder="Name on Card"
                        onChange={this.props.handaleChange}
                        validaterule={["required"]}
                        validatemsg={["Card Name required"]}
                      />
                      <p style={{ color: "red" }}>
                        {FromDataError1.card_name == "false"
                          ? ""
                          : FromDataError1.card_name}
                      </p>
                    </Form.Group>



                    {/* <Form.Group controlId="formBasicEmail" as={Col} className="d-flex justify-content-end">
                        <ReCAPTCHA
                          sitekey="6Lf7d4UjAAAAAPwzzA6RVnGmq6KAWQkHwjz3KtXy"
                          onChange={this.props.capchaChange}
                        />

                        <p style={{ color: "red" }}>
                          {FromDataError1.recapcha == "false"
                            ? ""
                            : FromDataError1.recapcha}
                        </p>
                    </Form.Group> */}



                  </Form.Row>
                  <Elements stripe={promise}>
                    <CheckoutForm {...this.props} />
                  </Elements>





                </Col>
              </Row>
            </Form>
          </div>
        </Tab.Pane>
      </Container>
    );
  }
}
