import React, { Component } from "react";

import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";

export default class AutocompleteSearch extends Component {
  constructor(props) {
    super(props);
    this.state = { address: "", street: " ", placeHolder: "" };
  }

  componentDidMount() {
    let { placeHolder } = this.props;
    if (placeHolder === undefined && placeHolder === null) {
      placeHolder = "Search Places ...";
    }
    this.setState({ placeHolder });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log('Component received new props', nextProps);
  }

  onChange = (e) => {
    // e.persist();
    console.log(e);
  };

  extractAddress = (place) => {
    // console.log("place", place);
    const address = {
      city: "",
      state: "",
      postal_code: "",
      country: "",
      street_address: "",
    };

    if (!Array.isArray(place?.address_components)) {
      return address;
    }
    address.street_address = place.formatted_address;
    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component.long_name;

      if (types.includes("locality")) {
        address.city = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.state = component.long_name;
      }

      if (types.includes("postal_code")) {
        address.postal_code = value;
      }

      if (types.includes("country")) {
        address.country = component.long_name;
      }
    });

    return address;
  };
  handleChange = async (value) => {
    const results = await geocodeByAddress(value);
    // console.log("results",results)

    const add = await this.extractAddress(results[0]);
    if (this.props.addressType == 'City') {
      this.setState({ address: add.city, street: add, placeHolder: add.city });
    } else if (this.props.addressType == 'State') {
      this.setState({ address: add.state, street: add, placeHolder: add.state });
    } else if (this.props.addressType == 'Country') {
      this.setState({ address: add.country, street: add, placeHolder: add.country });
    }
    //this.setState({ address: add.state, street: add, placeHolder: add.state });
    // console.log("add", add);
    // this.props.setFieldValue("city", add.city);
    // this.props.setFieldValue("country", add.country);
    // this.props.setFieldValue("postal_code", add.postal_code);
    // this.props.setFieldValue("state", add.state);
    // this.props.setFieldValue("street_address", add.street_address);
    this.props.getAddress && this.props.getAddress(add);
    //console.log(this.props.addressType)
  };



  render() {
    return (
      <PlacesAutocomplete
        value={this.props.reset ? "" : this.state.address}
        onChange={(e) => {
          this.setState({ address: e, placeHolder: e });
        }}
        onSelect={this.handleChange}
        searchOptions={{
          //   componentRestrictions: { country: this.props.countryCode },
          types: ["(regions)"],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (

          <div>

            <div className="auto_address">
              {this.props.reset ? (<>
                <input
                  {...getInputProps({
                    placeholder: "Search ...",
                    className: "form-control",
                  })}
                  value={''}
                />
              </>) : (<>
                <input
                  {...getInputProps({
                    placeholder: "Search ...",
                    className: "form-control",
                  })}
                  value={this.state.placeHolder}
                />
              </>)}
              <div
                className={suggestions.length ? "auto_list" : "auto_list_hide"}
              >
                {/* {loading && <div>Loading...</div>} */}

                {suggestions.map((suggestion, ind) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span key={ind}>{suggestion.description}</span>

                    </div>
                  );
                })}
              </div>
            </div>

            <div className="autocomplete-dropdown-container"></div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
