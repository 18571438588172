import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import  { FlashMess,GET_API,POST_API,Loader} from "../../Helpers/Helpers";
import validation from '../../Validate/validator';



import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Form,
  Button,
  Modal,
} from "react-bootstrap";

export default class AddReferalContactGroupModal extends React.Component {
  constructor(props)
    {
        super(props)
        this.state = {
                        
                        FromData: {
                                       
                                        group_name : '',
                                        
                                    
                                    },
                        FromDataError :
                                        {
                                          
                                          group_name : 'false',
                                         
 
                                        
                                        },
                        Msg: '',
                        MsgType:'',
                        loader:'hidden',
                        urlPath:'/contact-details',
                        filename:"Choose file",
                                      
                        
                     };

        //Binding

        this.handaleChange = this.handaleChange.bind(this)
        this.handalecsvSubmit = this.handalecsvSubmit.bind(this)
        this.stateCreate = this.stateCreate.bind(this)
        this.handaleChangeCSV = this.handaleChangeCSV.bind(this)
        
            

    }

    validateForm  (errors) {
      let valid = true;
      let sta = this;
      let FromDataError = this.state.FromDataError;

      for (const [key, value] of Object.entries(errors)) {
        if(value.length > 0 )
        {

          if(value=='false')
          {
            FromDataError[key] = 'This field is required';
            sta.setState({FromDataError})
          }
          
          valid = false;
          console.log('loop',valid)
        }
      
      }
     
     
      console.log('funct',valid)
    
      return valid;
};
  

  handaleChangeCSV(data)
  {
    let file=data.target.files[0]
    this.setState({filename:file.name})
  }

    handaleChange(data)
    {
       
        let sta = this;
        let FromDataError = this.state.FromDataError;
        

        let name = data.target.name;
        let value = '';
        let FromData = this.state.FromData;
        

        value = data.target.value;
        FromData[name] = value;
        this.setState({FromData},()=>{
         
        }) 

         //validate from
         var valid_obj = {
          value:value,
          rules:data.target.getAttribute("validaterule"),
          message:data.target.getAttribute("validatemsg")
        }

              validation(valid_obj).then((error)=>{
                  FromDataError[name] = error
                  this.setState({FromDataError}) //from error state
              })

       
    }

    handalecsvSubmit(e)
    {   
        // console.log(e)
        e.preventDefault();

        let sta = this;
        let FromDataError = this.state.FromDataError;
        let FromData = this.state.FromData;
       
        if(this.validateForm(FromDataError))
        {
       
          var fileUpload = document.getElementById("csv"); 
          var regex = /^([a-zA-Z0-9\s_\\.\-:()])+(.csv|.txt)$/; 
         
          if (regex.test(fileUpload.value.toLowerCase())) { 
          if (typeof (FileReader) != "undefined") { 
            try{
          var reader = new FileReader(); 
          reader.onload = function (e) { 
          var table = document.createElement("table"); 
          var rows = e.target.result.split("\n"); 
          

          var allTextLines = e.target.result.split("\n"); 
          var headers = allTextLines[0].split(',');
          var lines = [];
          
          allTextLines.forEach((element,key) => { 
            //console.log(element,key)

            if(key>0 && element)
            {
              element=element.split(",");
              let a={}
              element.forEach((eachelement,keyeach) => {
                
               let headerkeys=headers[keyeach]?.trim() 
               
               a[headerkeys]=eachelement?.trim()
               // console.log(eachelement, keyeach,headers[keyeach])
                
              })
              lines.push(a);
            }
          });

          

         
          console.log('rr '+JSON.stringify(lines));
         // return false;
          if(lines.length>0){
            sta.setState({loader:'visible'})///loader on
            var formdata = new FormData();
              
                formdata.append("allcontactData", JSON.stringify(lines));
                formdata.append("group_name",FromData.group_name);
                
                let request = {
                  end_point : '/affiliate-bulk-referal-csv-upload',
                  formdata:formdata,
                  token: localStorage.getItem("gs_token")

                };

                //console.log('......request',formdata)

                POST_API(request).then(resp=>{
                 

                  sta.setState({loader:'hidden'})///loader off
                  //console.log('......res',resp)

                        if(resp.status == 'success')
                        {
                          sta.setState({Msg:resp.data+' Contact added successfully',MsgType:'success'})
                          

                            setTimeout(() => {
                              window.location.reload()
                              
                            }, 3000);

                        }
                        else{
                          document.getElementById("csv").value = ""
                             sta.setState({Msg:resp.message,MsgType:'danger'})
                           
                        }
                        }).catch((ee)=>{
                          
                            console.log('/addcontactERR',ee)
                        });
                      

              }else{
                document.getElementById("csv").value = ""
                sta.setState({Msg:'Do not upload blank csv file.',MsgType:'danger'})
              }     
        
      
          } 
          reader.readAsText(fileUpload.files[0]); 
        }
        catch(err){
          console.log('eeeeeeeeeeeeeeeeee',err)
          sta.setState({
            Msg: "Please upload a valid CSV file.",
            MsgType: "danger",
          });
        }
          } else { 
          alert("This browser does not support HTML5."); 
          } 
          } else { 
          
           sta.setState({Msg:'Please upload a valid CSV file.',MsgType:'danger'})
          } 

        }else
        {
            sta.setState({Msg:'Fill the form correctly!'})
            sta.setState({MsgType:'danger'})
        }

       


       
    }

    componentDidMount()
    {

        let sta = this;
        let FromData =this.state.FromData
        let FromDataError =this.state.FromDataError

        

        //for redirectin message show
        if(sta.props.location.state!=undefined)
        {
            sta.setState({Msg:sta.props.location.state.Msg})
            sta.setState({MsgType:sta.props.location.state.MsgType})
        }

        try {
            if(localStorage.getItem("gs_user") != null)
            {
                //let FromData = JSON.parse(localStorage.getItem("gs_user"))
                //sta.setState({loader:'visible'})///loader on

                
                
            }
            
           
          } 
          catch (error) {
            
          }
     

      
    }
    stateCreate()
    {
      this.setState({Msg:''});
    }
  
 
 
  render() {
    let  FromData = this.state.FromData
    
    return (
      <div id="PaymentForm">
        <Modal show={this.props.show}
         backdrop="static"
         keyboard={false}
         onHide={this.props.close}
         id="modal"
         onShow={this.stateCreate}
         size="lg"
         centered
         >
        <Form
         className="tabform"
         onSubmit={this.handalecsvSubmit}
       >
          <Modal.Header closeButton>
            <Modal.Title>Upload Bulk Referal.</Modal.Title>
          </Modal.Header>
          <Modal.Body>


      
         <Row className="m-0 mt-4 mb-4">

       
           <Col md={12} className="pl-0">
               {/* {userDetails.hasOwnProperty("firstname")? (<>):(</>)} */}
      {this.state.Msg!==''? (<><FlashMess m={this.state.Msg} mtype={this.state.MsgType?this.state.MsgType:'success'} /></>):(<></>)}



             
      
              <Form.Row>
               <Form.Group controlId="formBasicEmail4" as={Col}>
                 <Form.Label>Group Name</Form.Label>
                 <Form.Control
                   type="text"
                   name="group_name"
                   onChange={this.handaleChange}
                   placeholder="Enter Group Name"
                  
                   validaterule={['required']}
                   validatemsg={['This field is required']}
                 />
                    <p style={{ color: 'red' }}>{this.state.FromDataError.group_name=='false'?'': this.state.FromDataError.group_name}</p>
               </Form.Group>

              
             </Form.Row>
            

             <Form.Group>
             <Form.Label>Select CSV File</Form.Label>
             <div className="custom-file">
              <input type="file" className="custom-file-input"  
                  accept=".csv"
                  id='csv'
                  validaterule={['required']}
                  validatemsg={['This field is required']}
                  onChange={this.handaleChangeCSV}
                  
                  />
              <label className="custom-file-label" for="csv">{this.state.filename}</label>
              </div>
              </Form.Group>
  
           </Col>
           
         </Row>

    

            </Modal.Body>
          <Modal.Footer>
          <Button
               variant="primary"
               type="submit"
               className="btn btn-primary btn-md tabformbutton"
             >
               Submit
             </Button>
            
          </Modal.Footer>

          </Form>

        </Modal>
      </div>
    );
  }
}
