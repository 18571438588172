import { combineReducers } from 'redux'
import refreshBalance from './setBalance';
import setSettings from './setSettings';
import creditBalance from './creditBalance';

const rootReducers = combineReducers({
    refreshBalance,
    setSettings,
    creditBalance
    
  });
  
  export default rootReducers;