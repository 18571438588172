
import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import { API_URL } from '../Config';
import loader from '../images/resume_man.gif';

import L from 'react-loader-spinner'
import { render } from '@testing-library/react';

// all icon import
import Affiliate_Associate from "../images/Affiliate-(Associate).png";
import Affiliate_Corporate from "../images/Affiliate-(Corporate).png";
import Affiliate_Employee from "../images/Affiliate-(Employee).png";
import Affiliate_Individual from "../images/Affiliate-(Individual).png";
import Affiliate_Manager from "../images/Affiliate-(Manager).png";
import Employee from "../images/Employee.png";
import Government_Municipality from "../images/Government_Municipality.png";
import Local_Business from "../images/Local-Business.png";
import Manager from "../images/Manager.png";
import Sponsor_Corporate from "../images/Sponsor-(Corporate).png";
import Sponsor_Individual from "../images/Sponsor-(Individual).png";
import Sponsor_Partnership from "../images/Sponsor-(Partnership).png";
import Sponsor_JobSeeker from "../images/Sponsored-Job-Seeker.png";
import University_Alumni_Sponsorship from "../images/University_Alumni-Sponsorship.png";



const TokenVerify = () => {
  return new Promise(function (resolve, reject) {


    if (localStorage.getItem("gs_token") != null) {
      fetch(API_URL + '/get-user-by-token', {
        method: "GET",
        headers: { "Authorization": "Bearer " + localStorage.getItem("gs_token") }
      }).then((result) => {
        result.json().then((resp) => {
          //console.log('...AuthToken',resp)

          if (resp.status == 'success') {
            localStorage.setItem('gs_company', JSON.stringify(resp.data.company_profile))
            localStorage.setItem('gs_user', JSON.stringify(resp.data.user))
            if (resp.data.sponsor_details != undefined) {
              localStorage.setItem('gs_sponser_details', JSON.stringify(resp.data.sponsor_details))
            }
          }

          if (resp.message === 'unauthorized') {
            localStorage.clear("")
            console.log('...AuthToken yess',resp)
            window.location.reload()
            resolve()
          }
          resolve()



        })

      })
    }
    else {
      resolve()
    }




  });
}



const logout = (history) => {



  if (localStorage.getItem("gs_token") != null) {
    fetch(API_URL + '/logout', {
      method: "POST",
      headers: { "Authorization": "Bearer " + localStorage.getItem("gs_token") }
    }).then((result) => {
      result.json().then((resp) => {
        console.log('...Logout', resp)

        if (resp.status === 'success') {
          //alert('unauthorized')
          localStorage.removeItem("gs_token")
          localStorage.removeItem("gs_user")
          localStorage.removeItem("gs_company")
          localStorage.removeItem("gs_sponser_details")
          localStorage.removeItem("gs_auth_code")
          localStorage.removeItem("roleID")
          localStorage.removeItem("get_category")
          history.push({
            pathname: '/login',
            state: { Msg: 'Logout Successfully!', Msgtype: 'succcess' }
          });




        }
        else if (resp.message == "unauthorized") {
          localStorage.removeItem("gs_token")
          localStorage.removeItem("gs_user")
          localStorage.removeItem("gs_company")
          localStorage.removeItem("gs_sponser_details")
          localStorage.removeItem("gs_auth_code")
          localStorage.removeItem("roleID")
          localStorage.removeItem("get_category")
          history.push({
            pathname: '/login',
            state: { Msg: 'Logout Successfully!', Msgtype: 'succcess' }
          });
        }



      })

    })
      .catch((err) => {
        console.log(err.response.data.message)
        if (err.response.data.message == "unauthorized") {
          localStorage.removeItem("gs_token")
          localStorage.removeItem("gs_user")
          localStorage.removeItem("gs_company")
          localStorage.removeItem("gs_sponser_details")
          localStorage.removeItem("gs_auth_code")
          localStorage.removeItem("roleID")
          localStorage.removeItem("get_category")
          history.push({
            pathname: '/login',
            state: { Msg: 'Logout Successfully!', Msgtype: 'succcess' }
          });

        }

      })
  }
  else {
    return 0;
  }



}


const GET_API = ({ formdata, token, end_point }) => {




  return new Promise(function (resolve, reject) {

    end_point = API_URL + end_point;
    let header = {};
    if (token != undefined) {
      header = { "Authorization": "Bearer " + token }
    }
    else {
      header = {}
    }


    try {

      fetch(end_point, {
        method: "GET",
        headers: header,
      }).then((result) => {
        result.json().then((resp) => {
          //console.log('.....respnse', resp)

          resolve(resp);

        }).catch(error => {
          console.log('ApiExp', error)
          reject()
        });

      })

    } catch (error) {
      reject()
    }




  })

}


const POST_API = ({ formdata, token, end_point }) => {


  return new Promise(function (resolve, reject) {

    end_point = API_URL + end_point
    let header = {};
    if (token != undefined) {
      header = { "Authorization": "Bearer " + token }
    }
    else {
      header = {}
    }



    try {
      fetch(end_point, {
        method: "POST",
        headers: header,
        body: formdata,
      }).then((result) => {
        result.json().then((resp) => {
          //console.log('.....respnse', resp)

          resolve(resp);

        }).catch(error => {
          console.log('ApiExp', error)
          reject()
        });

      })

    } catch (error) {
      reject()
    }




  })



}



const FlashMess = (props) => {

  let cals = "alert alert-" + props.mtype;
  return (



    <div className={cals + " w-100 text-center "} role="alert">

      <strong>{props.m}</strong>
    </div>




  )



}

const Loader = (props) => {

  if (props.visibility == 'hidden') {

    return (<div></div>)

  }
  else {

    return (
      //   <div className="loader" style={{visibility:props.visibility}}>
      //     <Row>
      //       <Col lg={6} md={6} sm={12} style={{textAlign: 'center',verticalAlign: 'middle',lineHeight: '90px' }}>
      //     <img src={loader} className="img-fluid" /> 
      //       </Col>
      //       <Col lg={6} md={6} sm={12} style={{textAlign: 'center',verticalAlign: 'middle',lineHeight: '90px' }} >
      //       <span >{props.loadmsg}</span>
      //         </Col>
      //       </Row>


      // </div>

      <div className="loadertwo">
        {/* <L
                  type="Circles"
                  color="#00BFFF"
                  height={100}
                  width={100}
                  visible={true} //3 secs
                    /> */}

        <img src={loader} />
        <span className='loaderText'>{props.loadingtext}</span>

        {/* <h2>........Loading</h2>  */}

      </div>
    )

  }



}

// after calling work 
const tt = () => new Promise(function (resolve, reject) {
  // executor (the producing code, "singer")
  setTimeout(() => {
    console.log("World!");
    resolve('resolve world')

  }, 2000);



});

let roleName = (rolId) => {
  let user = localStorage.getItem("gs_user")
  user = JSON.parse(user)
  switch (rolId) {
    // (Job Assist) 
    case "4": return user.sponsored_user == 0 ? user.is_jobassist_reffered == 1 ? "Job Outplacement" : "Sponsored Jobseeker" : "Jobseeker"
      break;
    case "2": return "Sponsor (Corporate)"
      break;
    case "3": return "Employee"
      break;
    case "5": return "Sponsor (Individual)"
      break;
    case "6": return "Sponsor (Partnership)"
      break;
    case "7": return "Manager"
      break;

    case "8": return "Recruiter"
      break;

    case "9": 
    return user.isMLM == "Y" ? "MLM Affiliate" : "Corporate Affiliate";
      break;
    case "10": return "Corporate Associate"
      break;
    case "11": return "Affiliate (Employee)"
      break;
    case "12": return "Affiliate (Manager)"
      break;
    case "13": return "Sponsor (University / Alumni Sponsorship)"
      break;
    case "14": return "Sponsor (Government / Municipality)"
      break;
    case "15": return "Sponsor (Local Business)"
      break;
    case "18": return "Affiliate Partner"
    break;
    case "19": return "Sub Affiliate"
    break;
    // case "16": return "Job Assist"
    //   break;
    case "16": return "Company Administrator"
      break;
    default: return ""
      break;
  }
}

//  dircet auto call
//   const tt = new Promise(function(resolve, reject) {
//     // executor (the producing code, "singer")
//     setTimeout(() => {  console.log("World!");  
//     resolve('resolve world')

//         }, 2000);

//   });


// default image set
let roleImg = () => {
  let roleID = localStorage.getItem("roleID")
  console.log(".............//////", roleID)
  switch (roleID) {
    case "4": return Sponsor_JobSeeker
      break;
    case "2": return Sponsor_Corporate
      break;
    case "3": return Employee
      break;
    case "5": return Sponsor_Individual
      break;
    case "6": return Sponsor_Partnership
      break;
    case "7": return Manager
      break;

    case "8": return Affiliate_Individual
      break;

    case "9": return Affiliate_Corporate
      break;
    case "10": return Affiliate_Associate
      break;
    case "11": return Affiliate_Employee
      break;
    case "12": return Affiliate_Manager
      break;
    case "13": return University_Alumni_Sponsorship
      break;
    case "14": return Government_Municipality
      break;
    case "15": return Local_Business
      break;
    default: return ""
      break;
  }
}


export { Loader };
export { POST_API };
export { GET_API };
export { FlashMess };
export { logout };
export { tt };
export { roleName };
export { roleImg };

export default TokenVerify;
